<style scoped>

.verantwortliche-container {
  height: 425px;
  display: grid;
  grid-template-rows: min-content auto;
  grid-template-areas:
  "header"
  "preview";
  padding: 33px 16px 10px 16px;
  text-align: left;
     text-align: -webkit-left;
   text-align: -moz-left;
  background-color: rgba(255,255,255,0.7);
}

.verantwortliche-header {
  grid-area: header;
  justify-self: start;
  margin-left: 33px;
  margin-bottom: 15px;
}

.verantwortliche-header-title {
  margin: 0;
  padding: 0;
}

.verantwortliche-preview-container {
  grid-area: preview;
}

.verantwortliche-preview-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow-y: auto;
}

.verantwortliche-preview-item-person {
  margin: 10px 10px 10px 20px;
}


</style>

<template>
  <div class="verantwortliche-container">
    <div class="verantwortliche-header">
     <h2 class="verantwortliche-header-title" v-if="refZid != null">
        Zyklus-Verantwortliche
      </h2>
      <h2 class="verantwortliche-header-title" v-if="refZid === null">
        Plan-Verantwortliche
      </h2>
      
       </div>

              <ResponsiblePersonDropdown
               :student="sid"
               :zykn="refZid"
               :useCase="'Zykn'"
               :position="'center'"
               v-if="refZid !== null"
              />
               <ResponsiblePersonDropdown
               :student="sid"
               :useCase="'EntplCenter'"
               :position="'center'"
               v-if="refZid === null"
              />
</div>
  
</template>

<script>
import ResponsiblePersonDropdown from "../Reusable/ResponsiblePersonDropdown.vue";

export default {
  name: "ProfilVerantwortliche",
  props: ['verantwortliche', 'sid', 'refZid'],
  components: {
    ResponsiblePersonDropdown,
    
  },
}
</script>
