import store from "../index"
import api from '../../api/api.js'

const state = {
  documents: null,
  chiffre: null,
  loading: null,
  federalstate: "",
}

const actions = {
  loadDocuments({commit, state}, payload) {
    if (typeof payload.federalstate === 'boolean') {
      console.error('Invalid federalstate type:', payload.federalstate);
      payload.federalstate = ""; // Setzen Sie einen Standardwert oder behandeln Sie den Fehler
    }
    // only load, if necessary
    if(state.chiffre !== payload.chiffre  && !state.loading) {
      commit('setDocumentsLoading', true)
      api.loadDocuments(payload)
      .then(res => {
        commit('setDocuments', res)
        commit('setDocumentChiffre', payload.chiffre)
        commit('setDocumentFederalstate', payload.federalstate)
      })
      .catch(err => console.log("Error while loading data protection documents: " +err))
      .finally(() => {
        setTimeout(() => {
          commit('setDocumentsLoading', false)
        }, 1000)
      })
    }
  }
}

const getters = {
  documents: state => (chiffre, federalstate) => {
    
    // fetch data if no other data is available or there is no other fetching process.
    if(state.chiffre !== chiffre && !state.loading) {
      let payload = {
        chiffre: chiffre,
        federalstate: federalstate
      }
      store.dispatch("loadDocuments", payload)
      return null
    }
    return state.documents
  }
}

const mutations = {
  setDocumentsLoading: (state, loading) => {
    state.loading = loading
  },
  setDocuments: (state, documents) => {
    state.documents = documents
  },
  setDocumentChiffre: (state, chiffre) => {
    state.chiffre = chiffre
  },
  setDocumentFederalstate: (state, federalstate) => {
    state.federalstate = federalstate
  }
}


export default {
  state,
  mutations,
  getters,
  actions
}
