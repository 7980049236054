<style scoped>

.documents-container {
  height: 425px;
  display: grid;
  grid-template-rows: min-content auto;
  grid-template-areas:
  "header"
  "preview";
  padding: 33px 16px 10px 16px;
  text-align: left;
     text-align: -webkit-left;
   text-align: -moz-left;
  background-color: rgba(255,255,255,0.7);
  cursor: default;
}

.documents-header {
  grid-area: header;
  justify-self: start;
  margin-left: 33px;
  margin-bottom: 15px;
}

.documents-title {
  margin: 0;
  padding: 0;
}

.documents-preview-container {
  grid-area: preview;
}

.documents-preview-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  height: 342px;
  overflow-y: auto;
}

.documents-preview-item {
  display: grid;
  grid-template-columns: 30px auto auto;
  grid-template-areas:
  "type name created";
  cursor: pointer;
  border: 1px solid rgba(255,255,255,0.4);
}

.documents-preview-item:hover {
  background-color: rgb(255 255 255);
  border: 1px solid lightgrey;
}

.removeLinkStyle {
  text-decoration: none;
  outline: none;
  color: inherit;
}

.documents-preview-item-type {
  grid-area: type;
  justify-self: center;
  align-self: center;
  margin: 10px 15px 10px 15px;
}

.documents-preview-item-name {
  grid-area: name;
  justify-self: start;
  align-self: center;
  margin: 10px 10px 10px 10px;
  overflow-wrap: anywhere;
}

.documents-preview-item-created {
  grid-area: created;
  justify-self: end;
  align-self: center;
  margin: 10px 20px 10px 10px;
}

.aileron-thin-small {
  font-family: 'aileronthin';
  font-size: 16px;
}

.aileron-regular-small {
  font-family: 'aileronregular';
  font-size: 16px;
}

.material-icons.doc { color: #00488d; }
.material-icons.xls { color: #2e7d32; }
.material-icons.ppt { color: #e64a19; }
.material-icons.note { color: #7b1fa2; }
.material-icons.pdf { color: #b11010; }
</style>

<template>
  <div>
    <div class="documents-container">
      <div class="documents-header">
        <h2 class="documents-title"> Dokumente </h2>
      </div>
      <div class="documents-preview">
        <ul class="documents-preview-list">
          <li v-for="document in documents" :key="document.ContentTag">
            <a class="documents-preview-item removeLinkStyle" :href="document.ServerRelativeUrl | addBaseUrl" target="_blank">
              <div class="documents-preview-item-type">
                <i v-bind:class="getIconColor(document.Name)" class="material-icons">description</i>
              </div>
              <div class="documents-preview-item-name aileron-regular-small" id="documentlabel">
                {{document.Name}}
              </div>
              <div v-if="showDate" class="documents-preview-item-created aileron-thin-small">
                {{document.TimeCreated | dateformer}}
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
/* global _spPageContextInfo */
/*eslint no-undef: "error" */


export default {
  name: "ProfilDokumente",
  props: ['showDate'],
  computed: {
    chiffre() {
      if( this.$store.getters.api === "pepApi") {
        if(this.$store.getters.hasSetting("DS1")) return true
        else return false
      }

      let as = this.$store.getters.adminsettings
      if (Array.isArray(as) && as.length && as.includes("DS1")) return true
      else return false
    },
    federalstate() {
let fs = this.$store.getters.groups 
 if (fs !== null && Array.isArray(fs.groupsState) && fs.groupsState.length ) return fs.groupsState
 else return false
    },
    documents() {
      return this.$store.getters.documents(this.chiffre, this.federalstate)
    }
  },
  filters: {
    addBaseUrl(relUrl) {
      // dev environment
      if (typeof _spPageContextInfo === "undefined") {
          return "https://pep.orga-cloud.de" + relUrl
      } else {
        return _spPageContextInfo.siteAbsoluteUrl + relUrl
      }
    }
  },
  methods: {
    getIconColor(filename) {
      let extension = filename.split('.').pop()
      switch(extension) {
        case "doc":
        case "docx":
          return "doc"
        case "pdf":
          return "pdf"
        case "xls":
        case "xlsx":
          return "xls"
        case "ppt":
        case "pptx":
          return "ppt"
        case "one":
          return "note"
        default:
          return ""
      }
    }
  }
}
</script>
