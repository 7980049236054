<template>
<div class="login-page">
    <div id="popup-notification"></div>
    <form  v-if="api==='pepApi'" @submit.prevent="login" class="login-container">
      <div class="login-container-header ">
        <div id="peplogo" class="login-container-header-logo"></div>
        <div class="login-container-header-title">
          <div>Willkommen auf der</div>
          <div class="login-container-header-title-meinpep">WebApp</div>
        </div>
      </div>
      <div class="login-input-container">
        <label class="login-input-label" for="siteurl">Datenbank</label>
        <select class="login-input-field" id="siteurl" required v-model="siteurl">
          <option value="https://pep.orga-cloud.de/meinPEP">WebApp</option>
          <option value="https://pep.orga-cloud.de/dev">dev</option>
          <option value="https://pep.orga-cloud.de/devH">devH</option>
          <option value="https://pep.orga-cloud.de/TestPEP">TestPEP</option>
        </select>
      </div>
      <div class="login-input-container">
        <label class="login-input-label" for="username">Email</label>
        <input class="login-input-field" id="username" required v-model="username">
      </div>
      <div class="login-input-container">
        <label class="login-input-label" for="password">Passwort</label>
        <input class="login-input-field" id="password" required v-model="password" type="password">
      </div>
      <input class="login-container-submit-button" type="submit" value="Anmelden">
    </form>
</div>
</template>
<script>
import { auth } from '../axiosPepApi';

export default {
  name: 'Login',
  data() {
    return {
      username: "",
      password: "",
      siteurl: "https://pep.orga-cloud.de/meinPEP"
    }
  },
  mounted() {
    let cookie = document.cookie
    if (cookie && cookie.includes("pep_token")) {
      cookie = cookie.split(";")
      for(let c of cookie) {
        if(c.includes("pep_token")) {
          this.$store.commit("setToken", c.split("=")[1])
          location.href = window.location.origin + "#/EP"
        }
      }
    }
  },
  computed: {
    api() {
      return this.$store.getters.api
    }
  },
  methods: {
    async login() {
      // generate form data for oauth request
      var formData = new FormData();
      formData.append('username', this.username);
      formData.append('password', this.password);
      formData.append('scope', this.siteurl);

      // get JSON web token
      const authRes = await auth().post("/auth/token", formData)
      if(authRes && authRes.data && authRes.data.access_token) {
        this.$store.commit("setToken", authRes.data.access_token)
        // save token as cookie
        document.cookie = "pep_token="+authRes.data.access_token+";SameSite=Strict; Secure"
        this.$store.dispatch("startTokenRenewInterval")

        // load data, which is not triggered by UI
        this.$store.dispatch("loadColleagues")
        this.$store.dispatch("loadMyself")
        this.$store.dispatch("loadColGroup")

        // create log entry
        this.$store.dispatch("createLog")

        // go to start page
        this.$router.push({
          name: "UebersichtPlaene",
        })
      }
    },
  }
}
</script>

<style scoped>

html {
  font-size: 15px;
}

#popup-notification {
  display: none;
  position: fixed;
  z-index: 10001;
  top: 0;
  left: 0;
  right: 0;
  background: #fde073;
  text-align: center;
  text-align: -webkit-center;
  text-align: -moz-center;
  line-height: 2.5;
  overflow: hidden;
  -webkit-box-shadow: 0 0 5px black;
  -moz-box-shadow: 0 0 5px black;
  box-shadow: 0 0 5px black;
}

.login-page {
  position: absolute;
  width: 100%;
  height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-container {
  display: grid;
  grid-template-rows: auto auto auto auto;
  align-content: start;

  width: 440px;
  padding: 2em;

  background-color: #fff;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  -moz-box-shadow: 0 0 2em #ccc;
  -webkit-box-shadow: 0 0 2em #ccc;
  box-shadow: 0 0 2em #ccc;
}

.login-input-container {
  display: grid;
  grid-template-rows: max-content max-content;
  padding-bottom: 30px;
}
.login-input-label {
  text-align: left;
  font-weight: bold;
}
.login-input-field {
  padding: 10px;
  font-size: 1em;
}

.login-container-header {
  display: grid;
  grid-template-columns: auto;
  padding-bottom: 30px;
}
.login-container-header-title {
  justify-self: start;
  font-size: 130%;
  padding-top: 20px;
  display: grid;
  grid-template-columns: max-content auto;
}
.login-container-header-title-meinpep {
    font-weight: bold;
    margin-left: 8px;
}
.login-container-header-logo {
  justify-self: end;
}

.login-container-submit-button {
  width: 50%;
  line-height: 2em;
  justify-self: center;
  background-color: #806868c9;;
  color: white;
  font-size: medium;
  padding: 5px;
  margin-top: 10px;
  font-weight: bold;
  cursor: pointer;
}

#peplogo {
	background-image: url("./../../src/assets/optimiert-PEP-Logo_trans-400x200.png");
  background-size: contain;
	height: 70px;
  width: 140px;
}
</style>
