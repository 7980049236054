<template>
  <div class="testContainer">
    <header class="vff-header">
      <div class="f-container">
        <!-- Add custom logo here -->
        <span>MINT-Test Goetheschule Ilmenau</span>
        <span class="material-icons CloseIcon" @click="closetest()">
          close
        </span>
      </div>
    </header>

    <flow-form
      ref="flowform"
      v-on:complete="onComplete"
      v-on:submit="onQuizSubmit"
      v-on:timer="onTimer"
      v-bind:questions="questions"
      v-bind:language="language"
      v-bind:standalone="true"
      v-bind:timer="true"
      timer-start-step="Frage_1"
      @step="checkImage"
    >
      <!-- Custom content for the Complete/Submit screen slots in the FlowForm component -->
      <!-- We've overriden the default "complete" slot content -->
      <template v-slot:complete>
        <p>
          <span class="fh2">Geschafft!</span>
          <span v-if="!submitted" class="f-section-text">
            Lass dir nun dein Ergebnis anzeigen.
          </span>
        </p>
      </template>

      <!-- We've overriden the default "completeButton" slot content -->
      <template v-slot:completeButton>
        <div class="f-submit" v-if="!submitted">
          <button
            class="o-btn-action"
            ref="button"
            type="submit"
            href="#"
            v-on:click.prevent="onQuizSubmit()"
            aria-label="Press to submit"
          >
            <span>Dein Ergebnis</span>
          </button>
          <a
            class="f-enter-desc"
            href="#"
            v-on:click.prevent="onQuizSubmit()"
            v-html="language.formatString(language.pressEnter)"
          >
          </a>
        </div>
        <p class="text-success" v-if="submitted && time">
          Deine Zeit: {{ formattedTime }}
        </p>
        <p class="text-success" v-if="submitted && score < 10">
          "Du hast {{ score }} von {{ total }} Fragen richtig beantwortet."
        </p>
        <p class="text-success" v-else-if="submitted && score < 20">
          "Du hast {{ score }} von {{ total }} Fragen richtig beantwortet."
        </p>
        <p class="text-success" v-else-if="submitted && score <= total">
           "Du hast {{ score }} von {{ total }} Fragen richtig beantwortet."
        </p>
        <div v-if="submitted" class="result-container">
          <ul class="result-overview">
            <li v-for="(question, $index) in questions" :key="$index" class="result-block">
            <span class="question-title">  {{question.title}}</span>
            <div v-if="question.type === 'FlowFormMultipleChoiceType'">
            <span class="question-answer"  v-for="(answer, $index) in question.options" :key="$index"> 
              <span v-if="question.answer !== answer.value" class="answer-checkbox" :class="rightwrong(question.id, answer.value)">☐</span>
              <span v-else class="answer-checkbox" :class="rightwrong(question.id, answer.value)">☑</span>{{answer.label}}</span>
            </div>
            <div v-if="question.type === 'FlowFormLongTextType'">
<span class="question-answer" >{{question.answer}}</span>
<span class="question-answer" >{{answers[question.id]}}</span>

            </div>
            </li>
          </ul>
        </div>
      </template>
    </flow-form>
  </div>
</template>

<script>
import FlowForm, {
  QuestionModel,
  QuestionType,
  ChoiceOption,
  LanguageModel,
} from "@ditdot-dev/vue-flow-form";
export default {
  name: "Ilmenau",
  components: {
    FlowForm,
  },
  data() {
    return {
      bild1: require(`../../../../src/assets/Diagnostik/Ilmenau/Bild1.jpg`),
      bild2: require(`../../../../src/assets/Diagnostik/Ilmenau/Bild2.png`),
      bild3: require(`../../../../src/assets/Diagnostik/Ilmenau/Bild3.png`),
      bild4: require(`../../../../src/assets/Diagnostik/Ilmenau/Bild4.png`),
      bild5: require(`../../../../src/assets/Diagnostik/Ilmenau/Bild5.png`),
      submitted: false,
      completed: false,
      score: 0,
      total: 24,
      time: 0,
      formattedTime: "",
      answers: {
        Frage_1: "2",
        Frage_2: "3",
        Frage_3: "1",
        Frage_4: "3",
        Frage_5: "4",
        Frage_6: "3",
        Frage_7: "1",
        Frage_8: ["Luft", "Molekül", "Atom"],
        Frage_9: "2",
        Frage_10: "4",
        Frage_11: "4",
        Frage_12: "4",
        Frage_13: "3",
        Frage_14: "1",
        Frage_15: "2",
        Frage_16: "4",
        Frage_17: "2",
        Frage_18: "1",
        Frage_19: "4",
        Frage_20:  "4",
        Frage_21: "1",
        Frage_22: "2",
        Frage_23: "3",
        Frage_24: "2",
      },
      language: new LanguageModel({
        continue: "Starten",
        pressEnter: "oder ENTER drücken",
        multipleChoiceHelpText: "mehrere Antworten können abgegeben werden",
        multipleChoiceHelpTextSingle: "nur eine Antwort ist richtig",
        placeholder: "Antwort hier eingeben",
        submitText: "Antwort bestätigen",
        prev: "zurück",
        next: "vor",
        percentCompleted: ":percent% abgeschlossen",
        invalidPrompt: "ungültige Antwort",
        thankYouText: "Vielen Dank",
        successText: "Die Antworten wurden gespeichert",
        ariaOk: "Zum Fortfahren klicken",
        ariaRequired: "Diese Antwort muss gegeben werden",
        ariaPrev: "vorheriger Schritt",
        ariaNext: "nächster Schritt",
        ariaSubmitText: "Antwort per Klick absenden",
        ariaMultipleChoice: "Drücke :letter um zu wählen",
        ariaTypeAnswer: "Antwort hier eingeben",
        longTextHelpText: "':shiftKey + :enterKey um einen Zeilenumbruch zu erzeugen.'",
      }),
      // Create question list with QuestionModel instances
      questions: [
        new QuestionModel({
          id: "start",
          tagline: "24 Fragen",
          title: "MINT-Fragebogen Goetheschule Ilmenau",
          content:
            "Beantworte alle folgenden Fragen aus dem naturwissenschaftlichen Bereich. Ein direkte Auswertung erfolgt im Anschluss an die letzte Antwort",
          type: QuestionType.SectionBreak,
          required: true,
        }),

        new QuestionModel({
          id: "Frage_1",
          title:
            "Wenn ein Becherglas über eine brennende Kerze gestülpt wird, erlischt die Flamme, weil...",
          //    helpText: 'Choose all answers that apply.',
          helpTextShow: false,
          html: "<br>",
          type: QuestionType.MultipleChoice,
          required: true,
          multiple: false,
          options: [
            new ChoiceOption({
              label: "das kalte Becherglas die Flamme sehr stark kühlt",
              value: "1",
            }),
            new ChoiceOption({
              label:
                "Luftsauerstoff mit dem Kerzenwachs reagiert und Kohlenstoffdioxid entsteht",
              value: "2",
            }),
            new ChoiceOption({
              label:
                "Luftsauerstoff mit dem Kerzenwachs reagiert und Schwefeldioxid entsteht",
              value: "3",
            }),
            new ChoiceOption({
              label: "die Luftfeuchtigkeit sehr stark ansteigt",
              value: "4",
            }),
          ],
        }),
        new QuestionModel({
          id: "Frage_2",
          title:
            "Kohlendioxid ist als Wirkstoff in einigen Feuerlöschern enthalten. Das Feuer erlischt, weil...",
          helpTextShow: false,
          type: QuestionType.MultipleChoice,
          required: true,
          multiple: false,
          options: [
            new ChoiceOption({
              label:
                "durch das Kohlenstoffdioxid der brennbare Stoff unter seine Entzündungstemperatur abgekühlt wird",
              value: "1",
            }),
            new ChoiceOption({
              label:
                "das Kohlenstoffdioxid mit dem brennbaren Stoff zu einem nicht brennbaren Stoff reagiert",
              value: "2",
            }),
            new ChoiceOption({
              label: "das Kohlenstoffdioxid den Sauerstoff verdrängt",
              value: "3",
            }),
            new ChoiceOption({
              label: "das Kohlenstoffdioxid in der Hitze Kohlenstoff abgibt",
              value: "4",
            }),
          ],
        }),
        new QuestionModel({
          id: "Frage_3",
          title: "Wenn Erdöl brennt, dann wird die Reaktion",
          type: QuestionType.MultipleChoice,
          multiple: false,
          helpTextShow: false,
          helpText: "Select one or more correct answers.",
          required: true,
          options: [
            new ChoiceOption({
              label: "nur Energie freisetzen",
              value: "1",
            }),
            new ChoiceOption({
              label: "nur Energie aufnehmen",
              value: "2",
            }),
            new ChoiceOption({
              label: "Energie weder aufnehmen noch freisetzen",
              value: "3",
            }),
            new ChoiceOption({
              label:
                "mal Energie verbrauchen und mal freisetzen, je nach Art des Erdöles.",
              value: "4",
            }),
          ],
        }),
        new QuestionModel({
          id: "Frage_4",
          title: "Welche Stoffe lassen sich durch eine Filtration trennen?",
          type: QuestionType.MultipleChoice,
          multiple: false,
          helpText: "Select one or more correct answers.",
          helpTextShow: false,
          required: true,
          options: [
            new ChoiceOption({
              label: "Eine Lösung aus Kochsalz und Wasser.",
              value: "1",
            }),
            new ChoiceOption({
              label: "Ein Gemisch aus Alkohol und Wasser.",
              value: "2",
            }),
            new ChoiceOption({
              label: "Ein Gemisch aus Schlamm und Wasser.",
              value: "3",
            }),
            new ChoiceOption({
              label: "Ein Gemisch aus Sand und Sägespänen.",
              value: "4",
            }),
          ],
        }),
        new QuestionModel({
          id: "Frage_5",
          title:
            "Bei welchem der folgenden Stoffe handelt es sich NICHT um ein Stoffgemisch?",
          type: QuestionType.MultipleChoice,
          multiple: false,
          helpText: "Select one or more correct answers.",
          helpTextShow: false,
          required: true,
          options: [
            new ChoiceOption({
              label: "Luft",
              value: "1",
            }),
            new ChoiceOption({
              label: "Blut",
              value: "2",
            }),
            new ChoiceOption({
              label: "Mineralwasser",
              value: "3",
            }),
            new ChoiceOption({
              label: "Kochsalz",
              value: "4",
            }),
          ],
        }),
        new QuestionModel({
          id: "Frage_6",
          title:
            "Bei welchem der Beispiele handelt es sich um eine chemische Reaktion?",
          type: QuestionType.MultipleChoice,
          multiple: false,
          helpText: "Select one or more correct answers.",
          helpTextShow: false,
          required: true,
          options: [
            new ChoiceOption({
              label: "Eis schmilzt.",
              value: "1",
            }),
            new ChoiceOption({
              label: "Zuckerkristalle werden zu Pulver zermahlen.",
              value: "2",
            }),
            new ChoiceOption({
              label: "Holz brennt.",
              value: "3",
            }),
            new ChoiceOption({
              label: "Wasser verdunstet aus einer Kristallisierschale.",
              value: "4",
            }),
          ],
        }),
        new QuestionModel({
          id: "Frage_7",
          title:
            "Welches der genannten Beispiele ist KEIN Beispiel für eine chemische Reaktion?",
          type: QuestionType.MultipleChoice,
          multiple: false,
          helpTextShow: false,
          helpText: "Select one or more correct answers.",
          required: true,
          options: [
            new ChoiceOption({
              label: "Kochen von Wasser",
              value: "1",
            }),
            new ChoiceOption({
              label: "Rosten von Eisen",
              value: "2",
            }),
            new ChoiceOption({
              label: "Sauerwerden von Milch",
              value: "3",
            }),
            new ChoiceOption({
              label: "Backen von Kuchen",
              value: "4",
            }),
          ],
        }),
      
        new QuestionModel({
          id: "Frage_8",
          title:
            "Die Wörter Buch, Blätter und Zellstofffasern können zu folgendem Satz verbunden werden: Ein Buch besteht aus Blättern, die aus Zellstofffasern bestehen. Verwende die Wörter Moleküle, Atome und Luft, um den folgenden Satz zu vervollständigen:",
          type: QuestionType.LongText,
          helpTextShow: false,
          subtitle: "Atome, Moleküle, Luft",
          answer:
            "[..] besteht aus [..] , die aus [..] zusammengesetzt sind.",
        }),
        new QuestionModel({
          id: "Frage_9",
          title:
            "Eine Weißtanne besteht aus vielen Atomen. Was passiert mit den Atomen, nachdem sie abgestorben ist?",
          type: QuestionType.MultipleChoice,
          multiple: false,
          helpTextShow: false,
          helpText: "Select one or more correct answers.",
          required: true,
          options: [
            new ChoiceOption({
              label: "Die Atome hören auf, sich zu bewegen.",
              value: "1",
            }),
            new ChoiceOption({
              label: "Die Atome kehren in die Umwelt zurück.",
              value: "2",
            }),
            new ChoiceOption({
              label:
                "Die Atome spalten sich in kleinere Teilchen und verbinden sich zu neuen Atomen.",
              value: "3",
            }),
            new ChoiceOption({
              label:
                "Die Atome existieren nicht mehr, wenn die Weißtanne verwest ist.",
              value: "4",
            }),
          ],
        }),
        new QuestionModel({
          id: "Frage_10",
          title: "Fossile Brennstoffe sind entstanden aus",
          type: QuestionType.MultipleChoice,
          multiple: false,
          helpTextShow: false,
          helpText: "Select one or more correct answers.",
          required: true,
          options: [
            new ChoiceOption({
              label: "Plutonium.",
              value: "1",
            }),
            new ChoiceOption({
              label: "Granit und Hämatit.",
              value: "2",
            }),
            new ChoiceOption({
              label: "Kalk und Ton.",
              value: "3",
            }),
            new ChoiceOption({
              label: "abgestorbenen Pflanzen und Tieren.",
              value: "4",
            }),
          ],
        }),
        new QuestionModel({
          id: "Frage_11",
          title:
            "Die Zeichnung zeigt fünf verschiedene Celsius-Thermometer. Die Körpertemperatur von kranken Menschen liegt zwischen 36° C und 42° C. Welches Thermometer ist für die genaue Messung der Körpertemperatur am besten geeignet?",
          type: QuestionType.MultipleChoice,
          multiple: false,
          helpTextShow: false,
          helpText: "Select one or more correct answers.",
          required: true,
          options: [
            new ChoiceOption({
              label: "Thermometer A",
              value: "1",
            }),
            new ChoiceOption({
              label: "Thermometer B",
              value: "2",
            }),
            new ChoiceOption({
              label: "Thermometer C",
              value: "3",
            }),
            new ChoiceOption({
              label: "Thermometer D",
              value: "4",
            }),
          ],
        }),
        new QuestionModel({
          id: "Frage_12",
          title:
            "Wenn Wissenschaftler irgendeine Größe mehrere Male sorgfältig messen, erwarten sie, dass",
          type: QuestionType.MultipleChoice,
          multiple: false,
          helpTextShow: false,
          helpText: "Select one or more correct answers.",
          required: true,
          options: [
            new ChoiceOption({
              label: "alle Messwerte genau übereinstimmen",
              value: "1",
            }),
            new ChoiceOption({
              label: "nur zwei der Messwerte genau übereinstimmen",
              value: "2",
            }),
            new ChoiceOption({
              label: "alle Messwerte bis auf einen genau übereinstimmen",
              value: "3",
            }),
            new ChoiceOption({
              label:
                "die meisten Messwerte nahe beieinanderliegen, jedoch nicht genau übereinstimmen",
              value: "4",
            }),
          ],
        }),
        new QuestionModel({
          id: "Frage_13",
          title:
            "In jeweils 2 völlig identischen Bechergläsern befinden sich 50 ml Wasser(A) und 50 ml Benzin(B). Beide Bechergläser werden offen an ein von der Sonne beschienenes Fenster gestellt. Einige Zeit später kann man feststellen, dass das Flüssigkeitsvolumen in beiden Bechergläsern abgenommen hat, bei Benzin jedoch stärker als bei Wasser. Welche Schlussfolgerungen kannst Du ziehen?",
          type: QuestionType.MultipleChoice,
          multiple: false,
          helpTextShow: false,
          helpText: "Select one or more correct answers.",
          required: true,
          options: [
            new ChoiceOption({
              label: "Alle Flüssigkeiten verdunsten.",
              value: "1",
            }),
            new ChoiceOption({
              label: "Benzin wird heißer als Wasser.",
              value: "2",
            }),
            new ChoiceOption({
              label: "Einige Flüssigkeiten verdunsten schneller als andere.",
              value: "3",
            }),
            new ChoiceOption({
              label: "Flüssigkeiten verdunsten nur bei Sonnenschein.",
              value: "4",
            }),
          ],
        }),
        new QuestionModel({
          id: "Frage_14",
          title:
            'Helle hat 5 Tropfen Iod-Kaliumiodidlösung auf ein Stück Kartoffel getropft. In ihrem Protokoll steht: "Nachdem die Iod-Kaliumiodid-Lösung auf die Kartoffelscheibe getropft wurde, färbte sich die zuvor hellbraune Iod-Kaliumiodid-Lösung schwarz-violett. Diese Aussage ist ',
          type: QuestionType.MultipleChoice,
          multiple: false,
          helpTextShow: false,
          helpText: "Select one or more correct answers.",
          required: true,
          options: [
            new ChoiceOption({
              label: "eine Beobachtung.",
              value: "1",
            }),
            new ChoiceOption({
              label: "eine Schlussfolgerung.",
              value: "2",
            }),
            new ChoiceOption({
              label: "eine Verallgemeinerung.",
              value: "3",
            }),
            new ChoiceOption({
              label: "eine Voraussetzung für die Untersuchung.",
              value: "4",
            }),
          ],
        }),
        new QuestionModel({
          id: "Frage_15",
          title: "Der Zaunkönig singt hauptsächlich, um",
          type: QuestionType.MultipleChoice,
          multiple: false,
          helpTextShow: false,
          helpText: "Select one or more correct answers.",
          required: true,
          options: [
            new ChoiceOption({
              label: "Vögel anderer Arten zu vertreiben.",
              value: "1",
            }),
            new ChoiceOption({
              label: "sein Revier gegen Vögel der eigenen Art abzugrenzen.",
              value: "2",
            }),
            new ChoiceOption({
              label: "Insekten anzulocken.",
              value: "3",
            }),
            new ChoiceOption({
              label: "andere Tiere aufzuwecken.",
              value: "4",
            }),
          ],
        }),
        new QuestionModel({
          id: "Frage_16",
          title:
            "Ab Ende April kann man bereits 80 Minuten vor Sonnenaufgang den Gartenrotschwanz singen hören. Er ist schon bei niedrigen Temperaturen sehr aktiv. Die Zauneidechse kann man dagegen häufig an sonnigen, warmen Tagen um die Mittagszeit auf Steinen beim Insektenfang beobachten. Welche der folgenden Aussagen erklärt diese Beobachtungen am besten?",
          type: QuestionType.MultipleChoice,
          multiple: false,
          helpTextShow: false,
          helpText: "Select one or more correct answers.",
          required: true,
          options: [
            new ChoiceOption({
              label:
                "Beide Tiere sind Kaltblüter, aber ohne wärmende Federn ist es der Zauneidechse am frühen Morgen zu kalt, um aktiv zu sein.",
              value: "1",
            }),
            new ChoiceOption({
              label:
                "Im Unterschied zum Gartenrotschwanz ist Zauneidechse ein Warmblüter; die bei niedrigen Temperaturen in Kältestarre verfallen.",
              value: "2",
            }),
            new ChoiceOption({
              label:
                "Im Unterschied zu Reptilien sind Vögel Kaltblüter, die durch niedrige Temperaturen weniger beeinträchtigt sind",
              value: "3",
            }),
            new ChoiceOption({
              label:
                "Vögel sind im Unterschied zu Reptilien Warmblüter; die ihre Körpertemperatur unabhängig von der Außentemperatur konstant halten können.",
              value: "4",
            }),
          ],
        }),
        new QuestionModel({
          id: "Frage_17",
          title: "Welche der folgenden Aussagen triff für den Winterschlaf zu?",
          type: QuestionType.MultipleChoice,
          multiple: false,
          helpTextShow: false,
          helpText: "Select one or more correct answers.",
          required: true,
          options: [
            new ChoiceOption({
              label: "In keinem Körperteil ist noch Leben.",
              value: "1",
            }),
            new ChoiceOption({
              label:
                "Die Tiere verbrauchen weniger Energie, als im aktiven Zustand.",
              value: "2",
            }),
            new ChoiceOption({
              label: "Die Körpertemperatur ist höher, als im aktiven Zustand.",
              value: "3",
            }),
            new ChoiceOption({
              label:
                "Tiere nehmen Wärmenergie auf, um sie zu verbrauchen, wenn sich bewegen.",
              value: "4",
            }),
          ],
        }),
        new QuestionModel({
          id: "Frage_18",
          title: "Welche Aussage zur Funktion der Chloroplasten ist korrekt?",
          type: QuestionType.MultipleChoice,
          multiple: false,
          helpTextShow: false,
          helpText: "Select one or more correct answers.",
          required: true,
          options: [
            new ChoiceOption({
              label: "Lichtenergie aufnehmen und Traubenzucker herstellen.",
              value: "1",
            }),
            new ChoiceOption({
              label:
                "Lichtenergie aufnehmen und in mechanische Energie umwandeln",
              value: "2",
            }),
            new ChoiceOption({
              label: "Chemische Energie aus Stärke herstellen.",
              value: "3",
            }),
            new ChoiceOption({
              label: "Die grüne Farbe der Pflanzen verursachen.",
              value: "4",
            }),
          ],
        }),
        new QuestionModel({
          id: "Frage_19",
          title:
            "Constantin hat je 100 ml Wasser in jedes der dargestellten offenen Glasgefäße gefüllt und lässt sie einen Tag in der Sonne stehen. Aus welchem Glasgefäß verdunstet das meiste Wasser?",
          type: QuestionType.MultipleChoice,
          multiple: false,
          helpTextShow: false,
          helpText: "Select one or more correct answers.",
          required: true,
          options: [
            new ChoiceOption({
              label: "A",
              value: "1",
            }),
            new ChoiceOption({
              label: "B",
              value: "2",
            }),
            new ChoiceOption({
              label: "C",
              value: "3",
            }),
            new ChoiceOption({
              label: "D",
              value: "4",
            }),
          ],
        }),
        new QuestionModel({
          id: "Frage_20",
          title:
            "Theodora untersuchte mit Hilfe von Experimenten, die Bedingungen für das Keimen von Kressesamen. Sie fasst ihre Ergebnisse mit diesen beiden Aussagen zusammen: ",
          subtitle:
            "1. Feuchte Kressesamen keimen bei Licht und einer Temperatur von 20°C 2. Feuchte Kressesamen keimen im Dunkeln bei einer Temperatur von 15°C.",
            helpText: "Was kannst Du aus ihren Ergebnissen schließen?",
          type: QuestionType.MultipleChoice,
          multiple: false,
          helpTextShow: true,
         
          required: true,
          options: [
            new ChoiceOption({
              label:
                "Feuchte Kressesamen keimen bei Licht und einer Temperatur von 20°C",
              value: "1",
            }),
            new ChoiceOption({
              label: "Für das Keinem von Kressesamen ist Licht notwendig.",
              value: "2",
            }),
            new ChoiceOption({
              label:
                "Für das Keinem von Kressesamen sind Wasser und Temperaturen von mindestens 20°C notwendig.",
              value: "3",
            }),
            new ChoiceOption({
              label:
                "Für das Keinem von Kressesamen sind Wasser und Wärme notwendig.",
              value: "4",
            }),
          ],
        }),
        new QuestionModel({
          id: "Frage_21",
          title:
            "Wenn man seinen Arm im Ellbogengelenk beugt, wirken die Knochen und Muskeln im Arm als ein System. Welche einfache physikalische Anordnung stellt dieses System dar?",
          type: QuestionType.MultipleChoice,
          multiple: false,
          helpTextShow: false,
          helpText: "Select one or more correct answers.",
          required: true,
          options: [
            new ChoiceOption({
              label: "Hebel",
              value: "1",
            }),
            new ChoiceOption({
              label: "Flaschenzug",
              value: "2",
            }),
            new ChoiceOption({
              label: "hydraulische Anlage",
              value: "3",
            }),
            new ChoiceOption({
              label: "Schiefe Ebene ",
              value: "4",
            }),
          ],
        }),
        new QuestionModel({
          id: "Frage_22",
          tagline:
            "Das Diagramm zeigt die Fortbewegung einer Weinbergschnecke, die sich entlang einer geraden Linie bewegt.",
          title:
            "Welche Strecke wird die Weinbergschnecke nach 60 Sekunden zurückgelegt haben, wenn sie sich immer mit der gleichen Geschwindigkeit fortbewegt? ",
          type: QuestionType.MultipleChoice,
          multiple: false,
              helpTextShow: false,
          helpText: "Select one or more correct answers.",
          required: true,
          options: [
            new ChoiceOption({
              label: "73mm",
              value: "1",
            }),
            new ChoiceOption({
              label: "70mm",
              value: "2",
            }),
            new ChoiceOption({
              label: "75mm",
              value: "3",
            }),
            new ChoiceOption({
              label: "60mm",
              value: "4",
            }),
          ],
        }),
        new QuestionModel({
          id: "Frage_23",
          title:
            "Die Besatzungen zweier Schiffe auf dem Meer können sich durch lautes Rufen verständigen. Weshalb ist dies den Besatzungen zweier Raumschiffe bei gleichem Abstand voneinander im Weltraum nicht möglich?",
          type: QuestionType.MultipleChoice,
          multiple: false,
          helpTextShow: false,
          helpText: "Select one or more correct answers.",
          required: true,
          options: [
            new ChoiceOption({
              label: "Der Schall wird im Weltraum stärker reflektiert.",
              value: "1",
            }),
            new ChoiceOption({
              label: " Der Druck im Inneren der Raumschiffe ist zu groß.",
              value: "2",
            }),
            new ChoiceOption({
              label:
                "Es gibt keine Luft im Weltraum, in der sich der Schall fortbewegen kann.",
              value: "3",
            }),
            new ChoiceOption({
              label: "Die Raumschiffe bewegen sich schneller als der Schall.",
              value: "4",
            }),
          ],
        }),
        new QuestionModel({
          id: "Frage_24",
          title:
            "Ein festgeschraubter Metalldeckel auf einem Marmeladenglas lässt sich leichter lösen, wenn er unter heißes Wasser gehalten wird. Die Ursache dafür ist, dass das heiße Wasser",
          type: QuestionType.MultipleChoice,
          multiple: false,
          helpTextShow: false,
          helpText: "Select one or more correct answers.",
          required: true,
          options: [
            new ChoiceOption({
              label: "das Glas zusammenzieht.",
              value: "1",
            }),
            new ChoiceOption({
              label: "den Metalldeckel stärker ausdehnt als das Glas.",
              value: "2",
            }),
            new ChoiceOption({
              label: "das Glas stärker ausdehnt als den Metalldeckel.",
              value: "3",
            }),
            new ChoiceOption({
              label: "den Metalldeckel zusammenzieht.",
              value: "4",
            }),
          ],
        }),
      ],
    };
  },
  methods: {
    /* eslint-disable-next-line no-unused-vars */
    onComplete(completed, questionList) {
      // This method is called whenever the "completed" status is changed.
      this.completed = completed;
    },
    arrayEquals(a, b) {
      return (
        Array.isArray(a) &&
        Array.isArray(b) &&
        a.length === b.length &&
        a.every((val, index) => val === b[index])
      );
    },
    calculateScore() {
      this.questions.forEach((question) => {
        if (question.type !== QuestionType.SectionBreak && question.type  !== QuestionType.LongText) {
          let answer = question.answer;
          if (typeof answer === "object") {
            answer.sort((a, b) => a - b);
            if (this.arrayEquals(answer, this.answers[question.id])) {
              this.score++;
            }
          }
          if (answer == this.answers[question.id]) {
            this.score++;
          }
        }
        if (question.type  === QuestionType.Text) {
           let answer = question.answer;
           let indexes = []
this.answers[question.id].forEach(function(a) {
  indexes.push(answer.indexOf(a))
    console.log(answer.indexOf(a))})

    //check whether keywords in right direction
    if (indexes.every((num, i) => i === indexes.length - 1 || num < indexes[i + 1])) {
      this.score++
      console.log("right")
    }
        }
      });
    },
    rightwrong(id, value) {
      if (value == this.answers[id]) {
        return "right-answer"
      }
      else return "wrong-answer"
    },
    onQuizSubmit() {
      // Set `submitted` to true so the form knows not to allow back/forward
      // navigation anymore.
      this.$refs.flowform.submitted = true;

      this.submitted = true;
      this.calculateScore();
       this.submitResult()
    },
    onTimer(time, formattedTime) {
      this.time = formattedTime;
      this.formattedTime = formattedTime;
    },
    closetest() {
      this.$parent.$emit("closetest", true);
    },
    checkImage(payload) {
      console.log(payload);
      let bild;
      switch (true) {
        case payload == "Frage_1":
          bild = this.bild1;
          break;
        case payload == "Frage_11":
          bild = this.bild2;
          break;
        case payload == "Frage_13":
          bild = this.bild3;
          break;
        case payload == "Frage_19":
          bild = this.bild4;
          break;
        case payload == "Frage_22":
          bild = this.bild5;
          break;
        default:
          bild = "";
          break;
      }
      if (bild !== "") {
        let wrapper = document
          .getElementsByClassName("q-is-active")[0]
          .getElementsByClassName("f-radios-wrap")[0];
        wrapper.setAttribute(
          "style",
          "background-image: url(" +
            bild +
            "); background-repeat: no-repeat; background-size: contain; background-position-x: right;"
        );
        if (bild === this.bild5) {
          wrapper.setAttribute(
          "style",
          "background-image: url(" +
            bild +
            "); background-repeat: no-repeat; background-size: contain; background-position-x: right; height: 30vh"
        );
        }
      }
      if (payload === "Frage_20") {
        let content = document.getElementsByClassName("q-is-active")[0].getElementsByClassName("f-sub")[0]
        content.setAttribute(
          "style", "width: 59ch;"
        )
      }
    },
    submitResult() {
       let ergebnis = {
         Title: "Ilmenau",
         Author: {
          Id:  this.$store.getters.userId,
          Title: this.$store.getters.userTitle 
         },
         Modified: new Date().toISOString(),
         Created: new Date().toISOString(),
         RefDiagId: 1,
         Ergebnis: this.score + " von " + this.total,
         Id: this.$store.getters.diagnostik_ergebnisse.length + 10,
         RefPEPId: parseInt(this.$route.params.id),
         Antworten: this.questions,
         Antworten_Set: this.answers
        
       }
       console.log(ergebnis)
       this.$store.dispatch("addDiagnostikResult", ergebnis)
    },
  },
};
</script>

<style lang="css">
@import "~@ditdot-dev/vue-flow-form/dist/vue-flow-form.css";
@import "~@ditdot-dev/vue-flow-form/dist/vue-flow-form.theme-minimal.css";
:root {
--vff-bg-color: #313640;
--vff-main-text-color: #fff;
--vff-secondary-text-color: #aeb6bf;
--vff-tertiary-text-color: #41464f;
--vff-main-accent-color: #41b883;
--vff-secondary-accent-color: #a0dbc1;
}
.testContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-image: linear-gradient(to top, #141e30, #243b55);
  z-index: 999;
  overflow-y: auto;
}
header.vff-header {
  max-height: 50px;
  color: white;
  background-color: #313640 !important;
}
.f-container {
  font-family: "aileronblack";
}
.vff-footer .footer-inner-wrap {
  background-color: rgba(49,54,64,.75) !important;
}
.vff a, .vff a:active, .vff input, .vff select option, .vff textarea,
.vff, .vff.vff-not-standalone {
  color: #fff;
}
.CloseIcon {
  position: absolute;
  top: 14px;
  right: 14px;
  cursor: pointer;
}
.f-radios * span {
  font-family: "aileronregular";
  color: white;
  font-weight: 300;
}
.result-container {
  overflow-y: scroll;
max-height: 63vh;
padding-bottom: 115px;
}
.result-overview {
list-style: none;
column-count: 2;
column-gap: 20px;
padding-left: 0;
}
.result-block {
  margin-bottom: 10px;
margin-top: 10px;
display: grid;
grid-auto-rows: auto auto;
}
.question-answer {
  font-size: 80%;
  font-family: 'aileronregular';
  display: block;
  margin: 10px;
}
.answer-checkbox {
  margin-right: 12px;
}
.wrong-answer {
  color: tomato
}
.right-answer {
  color: green
}
.vff .fh2, .vff h2 {
      font-size: clamp(22px, 4vw, 42px);
}
.vff{
      padding: 0 6%;
}
</style>