/*global _spPageContextInfo*/
/*eslint no-undef: "error"*/
import api from '../../api/api.js'
import local from '../../api/local.js'
import { default as customLocalStorage }  from '../../api/localStorage.js'
import store from '../index.js'
import { connect } from "../../axiosPepApi"
import { encryptData, decryptData } from '../../crypto.js' 
import { getLocalStorageItem, setLocalStorageItem } from '../../api/ClientStorageManager'

const state = {
  api: null,
  token: null,
  tokenRenewInterval: null,
  user: null,
  myself: null,
  colGroup: null,
  colleagues: null,
  myselfIsLoading: false,
  colGroupIsLoading: false,
  colleaguesIsLoading: false,
  settingsIsLoading: false,
  logIsLoading: false,
  admin: null,
  admin_loading: false,
  diagnostik: null,
  diagnostik_loading: false,
  showResult: null,
  settings: null,
  gs: null,
  groups: null,
  groupadmin: null,
  log: null,
  loadingIndicator: false,
  showOverlay: false,
  isMobile: false,
  userSettings: [{
    Section: {
      headline: "Entwicklungspläne",
      children: {
        EP1:
        {
          setting: "Ansicht von Entwicklungsplänen möglich, wenn Nutzer:in nicht für den Entwicklungsplan verantwortlich ist.",
          helper: "Diese Einstellung ermöglicht es den Nutzer:innen der Schule bei Aktivierung, die Einsicht in alle Entwicklungspläne der Schule, ohne explizit für den Entwicklungsplan verantwortlich zu sein. Ist das Kästchen allerdings deaktiviert, so ist lediglich der Name der Schüler:in ersichtlich, der Zugang ist für nicht-verantwortliche Nutzer:innen dann nicht möglich. Um auf diesen Entwicklungsplan zugreifen zu können, bedarf es einer ausdrücklichen Zuordnung durch Verantwortliche des Plans. Bitte erkundigen Sie sich bei Ihrem zuständigen Landesdatenschutzbeauftragten, bevor Sie diese Option aktivieren.        ",
          type: "checkbox",
          default: false,
          val: false,
          ID: 'EP1'
        },
        EP2:
        {
          setting: "Ausblenden der Entwicklungspläne möglich.",
          helper: "Das Ausblenden von Entwicklungsplänen dient neben der Volltextsuche, dem schnellen Auffinden von Plänen. Außerdem ermöglicht die Gruppierung die visuelle Trennung von Entwicklungsplänen beispielsweise, wenn angelegte, aber ruhende Entwicklungspläne aus einem Massenimport die Nutzer:innenerfahrung nicht beeinträchtigen sollen.",
          default: false,
          type: "checkbox",
          val: false,
          ID: 'EP2'
        },

      }
    }
  },
  {
    Section: {
      headline: "Datenschutz",
      children: {
       DS1:
        {
          setting: "Nutze Chiffren",
          helper: "Statt den Vornamen und Nachnamen der Schüler:innen, werden Organisations- und Namenschiffren verwendet.",
          default: false,
          type: "checkbox",
          val: false,
          ID: 'DS1'
        }
      }
    }
  }],
  userSettingsshort: ['EP1', 'EP2', 'DS1'],
}

const actions = {

  async loadUserObject({ commit, state }) {
    // Überprüfen, ob 'userObjectLastUpdated' im localStorage vorhanden ist
    if (getLocalStorageItem('userObjectLastUpdated') !== null) {
      const lastUpdated = getLocalStorageItem('userObjectLastUpdated');
      const isDataExpired = !lastUpdated || Date.now() - lastUpdated > 24 * 60 * 60 * 1000; // 24 Stunden
  
      try {
        if (!isDataExpired && state.user && state.user.UserId && state.user.UserId.NameId) {
          const encryptedData = getLocalStorageItem('encryptedUserObject');
          if (encryptedData) { // Überprüfen, ob die verschlüsselten Daten vorhanden sind
            const decryptedData = decryptData(encryptedData, state.user.UserId.NameId);
            commit("setUser", decryptedData);
          } else {
            // Keine verschlüsselten Daten im localStorage gefunden
            throw new Error("Keine verschlüsselten Benutzerdaten im localStorage gefunden.");
          }
        } else {
          const userObject = await api.getUserObject();
          commit("setUser", userObject);
          const encryptedData = encryptData(userObject, userObject.UserId.NameId);
          setLocalStorageItem('encryptedUserObject', encryptedData);
          setLocalStorageItem('userObjectLastUpdated', Date.now());
        }
      } catch (err) {
        console.error("Error while loading user object: " + err);
        // Hier können Sie weitere Fehlerbehandlungen durchführen
      }
    } else {
      // 'userObjectLastUpdated' ist nicht im localStorage vorhanden
      try {
        const userObject = await api.getUserObject();
        commit("setUser", userObject);
        const encryptedData = encryptData(userObject, userObject.UserId.NameId);
        setLocalStorageItem('encryptedUserObject', encryptedData);
        setLocalStorageItem('userObjectLastUpdated', Date.now());
      } catch (err) {
        console.error("Error while loading user object: " + err);
        // Hier können Sie weitere Fehlerbehandlungen durchführen
      }
    }
  },
  
  


  async loadGroupObject({ commit, state }) {
    if (getLocalStorageItem('groupObjectLastUpdated') !== null) {
      const lastUpdated = getLocalStorageItem('groupObjectLastUpdated');
      const isDataExpired = !lastUpdated || Date.now() - lastUpdated > 24 * 60 * 60 * 1000; // 24 Stunden
  
      try {
        if (!isDataExpired && state.groups) {
          const encryptedData = getLocalStorageItem('encryptedGroupObject');
          if (encryptedData) {
            const decryptedData = decryptData(encryptedData, state.user.UserId.NameId);
            commit("setGroups", decryptedData);
          } else {
            throw new Error("Keine verschlüsselten Gruppendaten im localStorage gefunden.");
          }
        } else {
          const groupObject = await api.getGroupObject();
          commit("setGroups", groupObject);
          const encryptedData = encryptData(groupObject, state.user.UserId.NameId);
          setLocalStorageItem('encryptedGroupObject', encryptedData);
          setLocalStorageItem('groupObjectLastUpdated', Date.now());
        }
      } catch (err) {
        console.error("Error while loading group object: " + err);
      }
    } else {
      try {
        const groupObject = await api.getGroupObject();
        commit("setGroups", groupObject);
        const encryptedData = encryptData(groupObject, state.user.UserId.NameId);
        setLocalStorageItem('encryptedGroupObject', encryptedData);
        setLocalStorageItem('groupObjectLastUpdated', Date.now());
      } catch (err) {
        console.error("Error while loading group object: " + err);
      }
    }
  },
  
  

  async loadColleagues({ commit, state }) {
    if(state.colleagues === null && state.token && !state.colleaguesIsLoading) {
      commit("setColleaguesIsLoading", true);
      commit('setApiStatus', { module: 'config', key: 'colleaguesIsLoaded', value: false });
  
      if (getLocalStorageItem('colleaguesLastUpdated') !== null) {
        const lastUpdated = getLocalStorageItem('colleaguesLastUpdated');
        const isDataExpired = !lastUpdated || Date.now() - lastUpdated > 24 * 60 * 60 * 1000; // 24 Stunden
  
        try {
          if (!isDataExpired && state.colleagues) {
            const encryptedData = getLocalStorageItem('encryptedColleagues');
            if (encryptedData) {
              const decryptedData = decryptData(encryptedData, state.user.UserId.NameId);
              commit("setColleagues", decryptedData);
            } else {
              throw new Error("Keine verschlüsselten Kollegendaten im localStorage gefunden.");
            }
          } else {
            const colleagues = await api.getColleagues();
            commit("setColleagues", colleagues);
            const encryptedData = encryptData(colleagues, state.user.UserId.NameId);
            setLocalStorageItem('encryptedColleagues', encryptedData);
            setLocalStorageItem('colleaguesLastUpdated', Date.now());
          }
        } catch (err) {
          console.error("Error while loading colleagues: " + err);
        } finally {
          setTimeout(() => {
            commit("setColleaguesIsLoading", false);
          });
        }
      } else {
        try {
          const colleagues = await api.getColleagues();
          commit("setColleagues", colleagues);
          const encryptedData = encryptData(colleagues, state.user.UserId.NameId);
          setLocalStorageItem('encryptedColleagues', encryptedData);
          setLocalStorageItem('colleaguesLastUpdated', Date.now());
          commit('setApiStatus', { module: 'config', key: 'colleaguesIsLoaded', value: true });
        } catch (err) {
          console.error("Error while loading colleagues: " + err);
        } finally {
          setTimeout(() => {
            commit("setColleaguesIsLoading", false);
          });
        }
      }
    }
  },
  
  
  loadMyself({commit, state}) {
    if(state.myself === null && state.token && !state.myselfIsLoading) {
      commit("setMyselfIsLoading", true)
      commit('setApiStatus', { module: 'config', key: 'myselfIsLoaded', value: false });
      api.getMyself()
      .then(res => {
        if (res) {
          commit("setMyself", res)
          commit('setApiStatus', { module: 'config', key: 'myselfIsLoaded', value: true });
        }
      })
      .catch(err => {
        console.log("Error while loading myself: " + err)
      })
      .finally(() => {
        setTimeout(() => {
          commit("setMyselfIsLoading", false)
        })
      })
    }
  },
  loadColGroup({commit, state}) {
    if(state.colGroup === null  && state.token && !state.colGroupIsLoading) {
      commit("setColGroupIsLoading", true)
      commit('setApiStatus', { module: 'config', key: 'colGroupIsLoaded', value: false });
      api.getGroup()
      .then(res => {
        if (res) {
          commit("setColGroup", res)
          commit('setApiStatus', { module: 'config', key: 'colGroupIsLoaded', value: true });
        }
      })
      .catch(err => {
        console.log("Error while loading groups: " + err)
      })
      .finally(() => {
        setTimeout(() => {
          commit("setColGroupIsLoading", false)
        })
      })
    }
  },

  loadDiagnostik({commit}) {
    if(store.getters.api === "pepApi" && !store.getters.token) return

     // only load, if necessary
     if(state.diagnostik === null) {
      commit('setDiagnostikLoading', true)
    api.getDiagnostikObject()
    .then(res => {
      commit("setDiagnostik", res)
    })
    .catch(err => {
      console.log("Error while loading diagnostic: " + err)
    })
    .then(() => {
      setTimeout(() => {
        commit('setDiagnostikLoading', false)
      }, 1000)
    }

    )
  }
  },
  addDiagnostikResult({commit}, ergebnis){
    api.addDiaResult(ergebnis)
    .then(ergebnis => {
      commit('addResult', ergebnis)
    })
    .catch(err => {
      alert("Es trat ein Problem mit der Serververbindung auf. Das Ergebnis konnte leider nicht gespeichert werden.")
      console.log("Error while saving result: " +err)
    })
  },
  loadAdminObject({ commit, dispatch }) {
    if(state.admin === null) {
      commit('setAdminLoading', true)
      commit('setApiStatus', { module: 'config', key: 'admin_loaded', value: false });
      api.getAdministrationObject()
        .then(res => {
          if (res) {
            commit("setAdmin", res)
            commit('setApiStatus', { module: 'config', key: 'admin_loaded', value: true });
                        return dispatch("loadSettings").then(() => {
              commit("setAdminSettings", res.groupsSettings);
            });
          }
        })
        .catch(err => {
          console.log("Error while loading group object: " + err)
        })
        .finally(() => {
          setTimeout(() => {
            commit('setAdminLoading', false)
          })
        })
    }
  },

  loadSettings({ commit, state }) {
    return new Promise((resolve, reject) => {
      if (state.settings === null) {
        commit("setSettingsIsLoading", true)
        commit('setApiStatus', { module: 'config', key: 'settingsIsLoaded', value: false });
                if (state.userSettings) {
          commit("setSettings", state.userSettings)
          commit('setApiStatus', { module: 'config', key: 'settingsIsLoaded', value: true });
                    resolve()
        } else {
          console.error("state.userSettings is null in loadSettings")
          reject("state.userSettings is null in loadSettings")
        }
        setTimeout(() => {
          commit("setSettingsIsLoading", false)
        })
      } else {
        resolve()
      }
    })
  },
  loadLogData({ commit, state }) {
    if(store.getters.api === "pepApi" && !store.getters.token) return

    if(state.log === null && !state.logIsLoading) {
      commit("setLogIsLoading", true)
      commit('setApiStatus', { module: 'config', key: 'logIsLoaded', value: false });

      api.loadLogData()
        .then(res => {
          commit("setLog", res)
          commit('setApiStatus', { module: 'config', key: 'logIsLoaded', value: true });

        })
        .catch(err => {
          console.log("Error while loading log: " + err)
        })
        .then(() => {
          setTimeout(() => {
            commit("setLogIsLoading", false)
          })
        })
    }
  },
  createLog() {
    api.createLog()
      .catch(err => {
        console.log("Error while creating log: " + err)
      })
  },
  importLocalData({ dispatch }) {
    local.getData().then(data => {
      customLocalStorage.setLocalData(data)
      console.log(data)
      dispatch("resetData")
    })
  },
  resetData({ commit, dispatch }) {
    // force update of entpl data
    commit("setEntPl", null)
    // force update of svz data
    commit("setSVz", null)
    store.dispatch("loadSVz")
    //force update of admin data
    commit("setAdmin", null)
    // reload user data
    dispatch("loadUserObject", null)
    dispatch("loadGroupObject", null)
    dispatch("loadSettings")
    dispatch("loadAdminObject")
    //force update of admin data
    //commit("setAdmin",null)
    //force update of settings data
    // commit("setSettings",null)
    commit("modifySettings", null)
    commit("setAdminSettings", null)
    // force update of zykn data
    commit("setZyklen", null)
    commit("setZyklenStudentId", null)
    // force update of entZiele data
    commit("setEntZiele", null)
    commit("setEntZieleZyklusId", null)
    // force update of entMasznahmen
    commit("setEntMk", null)
    // force update of evaluation
    commit("setEvaluationGoals", null)
    commit("setEvaluationMeasures", null)
    commit("setEvaluationZyklusId", null)
    // force update of aur
    commit("setAurStudentId", null)
    commit("setAurList", null)
    // force update of notes
    commit("setNotesStudentId", null)
    commit("setNotes", null)
    // force update of log
    dispatch("loadLogData")
  },
  clearStore({ commit }) {
    commit("setToken", null)
    commit("setEntPl", null)
    commit("setSVz", null)
    commit("setZyklen", null)
    commit("setZyklenStudentId", null)
    commit("setEntZiele", null)
    commit("setEntZieleZyklusId", null)
    commit("setEntMk", null)
    commit("setEvaluationGoals", null)
    commit("setEvaluationMeasures", null)
    commit("setEvaluationZyklusId", null)
    commit("setAurStudentId", null)
    commit("setAurList", null)
    commit("setNotesStudentId", null)
    commit("setNotes", null)
    commit("setLog", null)
    commit("setMyself", null)
    commit("setColleagues", null)
    commit("setColGroup", null)
  },
  async UpdateSettings({ commit, dispatch, state }, payload) {
    commit('setLoadingIndi', true);  // Ladeindikator aktivieren
    try {
      console.log("action", payload);
      const r = await api.updateSettings(payload);
      commit("UpdateGroupsSettings", payload.Settings.results);
      commit("setupdateSettings", state.userSettings);
      commit("GroupSettingsNew", payload.Settings.results);
      commit("setAdminSettings", state.admin.groupsSettings);
      commit("updateSettings", true);
      await dispatch("updateEntPl", store.state.entPl.entPl);
      console.log(r);
    } catch (error) {
      alert("Es trat ein Problem mit der Serververbindung oder Session auf. Die Einstellungen konnte leider nicht aktualisiert werden. Sie können versuchen die Browserseite neu zu laden.");
      console.error("Ein Fehler ist aufgetreten:", error);
    } finally {
      commit('setLoadingIndi', false);  // Ladeindikator deaktivieren
    }
  }
  ,
     

  
  startTokenRenewInterval({ commit, state }) {
    if(state.tokenRenewInterval === null) {
      const interval = setInterval(async () => {
        if (state.token !== null) {
          const res = await connect(state.token).post('/auth/token/renew')
          if (res && res.data && res.data.access_token) {
            // set new token in store
            commit("setToken", res.data.access_token)
            // set new token in cookie
            document.cookie = "pep_token="+res.data.access_token+";SameSite=Strict; Secure"
          }
        }
      }, (9 *60 *1000) )
      commit("setTokenRenewInterval", interval)
    }
  },
  clearTokenRenewInterval({ commit, state }) {
    clearInterval(state.tokenRenewInterval)
    commit("setTokenRenewInterval", null)
  },
  updateShowOverlay({ commit }, payload) {
    commit('setShowOverlay', payload);
  },
}

const getters = {
  api: state => {
    return state.api
  },
  isMobile: state => {
    return state.isMobile
  },
  token: state=> {
    return state.token
  },
  user: state => {
    return state.user
  },
  getloadingIndi: state => {
    return state.loadingIndicator
  },
  settings: state => {
    if (state.admin.groupsSettings) {
      console.log(state.admin.groupsSettings.length)

    }

    return state.settings
  },
  selectedsettings: state => {
    if (state.settings) return state.settings.selectedsettings
    else return null
  },
  admin: state => {
    
    if(state.admin === null && !state.admin_loading ) {
      store.dispatch("loadAdminObject")
    }
    return state.admin
  },
  admingroupdata: state => {
    if (state.admin) return state.admin.groupsSet
    else return null
  },
  groupsAdmin: state => {
    if (state.admin) return state.admin.groupsAdmin
    else return null
  },
  adminsettings: state => {
    if (state.admin) return state.admin.groupsSettings
    else return null
  },
  groups: state => {
    
    if(state.groups === null ) {
      store.dispatch("loadGroupObject")
    }
    return state.groups
  },
  colleagues: state => {
    
    if(state.colleagues === null ) {
      store.dispatch("loadColleagues")
    }
    return state.colleagues
  },
  showOverlay: state => {
    return state.showOverlay
  },
  diagnostik: state => {
    
    if(state.diagnostik === null && !state.diagnostik_loading ) {
      store.dispatch("loadDiagnostik")
    }
    return state.diagnostik
  },
  diagnostik_test: state => {
    
    if(state.diagnostik === null && !state.diagnostik_loading ) {
      store.dispatch("loadDiagnostik")
      if(state.diagnostik) return state.diagnostik.test
      else return null
    }
    else if (state.diagnostik != null && !state.diagnostik_loading) return state.diagnostik.test
    else return null
  },
  diagnostik_ergebnisse: state => {
    if (state.diagnostik && !state.diagnostik_loading) return state.diagnostik.ergebnisse
    else return null
  },
   diagnostik_ergebnissebyId: state => {
    if (state.diagnostik && !state.diagnostik_loading)  { return state.diagnostik.ergebnissebyId}
    else return null
   },
  showResult: state => {
    return state.showResult
  },
  userGroups: state => {
    if (state.groups) return state.groups.userGroups
    else return null
  },
  userColleaguesId: state => {
    if (state.groups && state.groups.userColleaguesId) return state.groups.userColleaguesId
    else return null
  },
  userColleaguesFull: state => {
    if (state.groups && state.groups.userColleaguesFull) return [...state.groups.userColleaguesFull].sort((a, b) => (a.Title > b.Title) ? 1 : -1)
    else if (state.colleagues) return state.colleagues
    else return null
  },
  userColleaguesFullunique: state => {
    if (state.groups && state.groups.userColleaguesFull)
      return state.groups.userColleaguesFull
      else if (state.colleagues) return state.colleagues
    else return null
  },
  userColleaguesFullselected: state => {
    if (state.groups.length > 0) {
      if ( state.groups.userColleaguesFull.selected) {
        return state.groups.userColleaguesFull.selected
      }
      else return null
      }
      else return null
    },
  userColleaguesFullselectable: state => {
    if (state.groups.length > 0) {
    if ( state.groups.userColleaguesFull.selectable) {
      return state.groups.userColleaguesFull.selectable
    }
    else return null
    }
    else return null
  },
  userColleaguesOwner: state => {
    if (state.userColleaguesOwner)
      return state.groups.userColleaguesOwner

    else return null
  },
  userColleaguesCreated: state => {
    if (state.userColleaguesCreated) return state.groups.userColleaguesCreated
    else return null
  },
  groupsState: state => {
    if (state.groupsState) return state.groups.groupsState
    else return null
  },
  userId: state => {
    
    if(store.getters.api === "pepApi") {
      if (state.myself === null)  {
        store.dispatch("loadMyself")
        return null
      }
      return state.myself.Id
    }

    if (state.user) return state.user.userId
    else return null
  },
  userTitle: state => {
    
    if(store.getters.api === "pepApi") {
      if (state.myself === null)  {
        store.dispatch("loadMyself")
        return null
      }
      return state.myself.Title
    }

    if (state.user) return state.user.userTitle
    else return null
  },
  myself: state => {
    return state.myself
  },
  mygroup: state => {
    if(store.getters.api === "pepApi") {
      if (state.myself === null)  {
        store.dispatch("loadMyself")
        return null
      }
      return state.myself.GroupId 
    }
    if (state.groups) return state.groups.userGroupsIds
    else return null
  },
  logData: state => {
    if(state.log === null) {
      store.dispatch("loadLogData")
    }
    return state.log
  },
  colGroup: state => {
    return state.colGroup
  },
  hasSetting: state => setting => {
    if(state.colGroup && state.colGroup.Settings) {
      for (let set of state.colGroup.Settings) {
        if (set === setting) return true
      }
    }
    return false
  }
}

const mutations = {
  setisMobile: (state, isMobile) => {
    state.isMobile = isMobile
  },
  setApi: (state, api) => {
    console.log("setApi called with:", api);

    if (api === "sharepoint" && typeof _spPageContextInfo === "undefined") {
      console.log("api: sharepoint was not available, fallback to localStorage")
      state.api = "localStorage"
    }
    else state.api = api
  },
  setToken: (state, token) => {
    state.token = token
  },
  setUser: (state, user) => {
    state.user = user
    const userData = {
      data: user,
      timestamp: new Date().getTime() // Aktueller Zeitstempel
    };
    setLocalStorageItem('userData', JSON.stringify(userData));
  },
  setSettings: (state, settings) => {

    state.settings = settings
  },
  setSettingsIsLoading: (state, settings) => {

    state.settingsIsLoading = settings
  },
  setShowOverlay(state, payload) {
    state.showOverlay = payload;
  },
  setLoadingIndi: (state, loading) => {
    state.loadingIndicator = loading
  },
  GS: (state) => {
    state.gs = state.admin.groupsSettings
  },
  GroupSettingsNew: (state, payload) => {
 //   console.log("update", payload)
    state.admin.groupsSettings = payload
  },
  setupdateSettings: (state, settings) => {

    state.settings = settings
  },
  updateSettings: (state, payload) => {
    state.updateSettings = payload
  },
  setGroups: (state, groups) => {
    state.groups = groups
    const groupData = {
      data: groups,
      timestamp: new Date().getTime() // Aktueller Zeitstempel
    };
    setLocalStorageItem('userData', JSON.stringify(groupData));
  },
  setColleagues: (state, colleagues) => {
    state.colleagues = colleagues
    const colleaguesData = {
      data: colleagues,
      timestamp: new Date().getTime() // Aktueller Zeitstempel
    };
    setLocalStorageItem('userData', JSON.stringify(colleaguesData));
  },
  setMyself: (state, myself) => {
    state.myself = myself
  },
  setColGroup: (state, colGroup) => {
    state.colGroup = colGroup
  },
  setAdminLoading: (state, val) => {
    state.admin_loading = val
  },
  setAdmin: (state, admin) => {
    state.admin = admin
  },
  setGroupsAdmin: (state, admin) => {
    state.admin = admin.groupsAdmin
  },
  setDiagnostikLoading: (state, val) => {
    state.diagnostik_loading = val
  },
  setDiagnostik: (state, diagnostik) => {
    state.diagnostik = diagnostik
  },
  addResult: (state, ergebnis) => {
    if(ergebnis) state.diagnostik.ergebnisse.push(ergebnis)
  },
  diagnostikErgebnisseById: (state, id) => {
    if(state.diagnostik !== null && !state.diagnostik_loading ) {
if (id) {
  state.diagnostik.ergebnissebyId = state.diagnostik.ergebnisse.filter(e => e.RefPEPId === id) }
}
  },
  showResult: (state, showResult) => {
    if (showResult) {
      state.showResult = showResult
    }
  },
  setAdminSettings: (state, res) => {
    // Frühe Rückkehr, wenn 'state.settings' null ist
    if (!state.settings) {
      console.error("state.settings is null in setAdminSettings");
      return;
    }
  
    // Erstellt ein Array mit userSettingsshort aus dem state
    let shortSettings = state.userSettingsshort;
  
    // Hilfsfunktion zum Finden aller Werte in einem Objekt, die einen bestimmten Schlüssel haben
    function findAllByKey(obj, keyToFind) {
      return Object.entries(obj)
        .reduce((acc, [key, value]) => (key === keyToFind)
          ? acc.concat(value)
          : (typeof value === 'object')
            ? acc.concat(findAllByKey(value, keyToFind))
            : acc, []);
    }
  
    // Wenn 'res' nicht vorhanden ist, machen wir nichts weiter
    if (!res) {
      return;
    }
  
    // Filtert 'res' um null Werte zu entfernen
    let groupsSetting = res.filter(n => n);
  
    if (groupsSetting && Array.isArray(groupsSetting) && groupsSetting.length) {
      // Setzt 'val' auf true für alle Schlüssel in 'state.settings', die auch in 'groupsSetting' vorhanden sind
      groupsSetting.forEach((p) => {
        let a = findAllByKey(state.settings, p);
        if(a[0]) a[0].val = true;
      });
  
      // Findet alle Schlüssel in 'shortSettings', die nicht in 'groupsSetting' sind, und setzt 'val' auf false
      shortSettings.filter(val => !groupsSetting.includes(val)).forEach((p) => {
        let b = findAllByKey(state.settings, p);
        if(b[0]) b[0].val = false;
      });
    } else {
      // Wenn 'groupsSetting' leer oder nicht existent ist, setze 'val' auf false für alle Schlüssel in 'state.settings', die in 'shortSettings' sind
      shortSettings.forEach((p) => {
        let b = findAllByKey(state.settings, p);
        if(b[0]) b[0].val = false;
      });
    }
  },
  
  PiCfullNames: (state, id) => {
    if (state.groups && state.groups.userColleaguesFull) {
      let a = state.groups.userColleaguesFull.filter(i => id.includes(i.Id))
      return a
    }
    else return ""
  },
  setuserColleaguesFull: (state, payload) => {
    if(payload.length) {
      state.groups.userColleaguesFull.forEach((obj) => {
        if (obj.Title) { obj.text = obj.Title }
        if (obj.Id) { obj.key = obj.Id }
        if (payload.includes(obj.Id)) { obj.disabled = true } else { obj.disabled = false }
        if (payload.includes(obj.Id)) { obj.choosen = true } else { obj.choosen = false }
      })
    }
  },
  setuserColleaguesselected: (state, payload) => {
    if (state.groups.userColleaguesFull) {
      let a = state.groups.userColleaguesFull.filter(i => payload.includes(i.Id))
      state.groups.userColleaguesFull.selected = a
    }
    else {
      state.groups.userColleaguesFull.selected = ""
    }
  },
  setuserColleaguesselectable: (state, payload) => {
    if (state.groups.userColleaguesFull) {
      let a = state.groups.userColleaguesFull.filter(i => !payload.includes(i.Id))
      state.groups.userColleaguesFull.selectable = a
    }
    else {
      state.groups.userColleaguesFull.selectable = ""
    }
  },
  SettingsChange: (state, payload) => {
    if (payload && state.admin.groupsSet != null) {
      state.admin.groupsSet.forEach(gd => {
        if (gd.Id === payload.gdId) {
          gd.Settings =
            payload.Settings
        }
      })

    }
    let pl = {
      gdId: payload.gdId,
      Settings: payload.Settings
    }


    store.dispatch("UpdateSettings", pl)

  },
  UpdateGroupsSettings: (state, payload) => {
    console.log("UGS", payload)



  },
  setLog: (state, log) => {
    state.log = log
  },
  setTokenRenewInterval: (state, interval) => {
    state.tokenRenewInterval = interval
  },
  setMyselfIsLoading: (state, loading) => {
    state.myselfIsLoading = loading
  },
  setColGroupIsLoading: (state, loading) => {
    state.colGroupIsLoading = loading
  },
  setColleaguesIsLoading: (state, loading) => {
    state.colleaguesIsLoading = loading
  },
  setLogIsLoading: (state, loading) => {
    state.logIsLoading = loading
  },


}

export default {
  state,
  actions,
  mutations,
  getters
}
