<template>
<div id="pepfooter">
  <div id="branding">
    <div id="pepbrand"></div>
    <div id="partnerbrand">
      <div class="partnerwrap">
        <a href="https://www.uni-leipzig.de/">
          <div id="uni"> </div>
        </a>
      </div>
      <div class="partnerwrap">
        <a href="https://www.leistung-macht-schule.de/">
          <div id="lms"></div>
        </a>
      </div>
      <div class="partnerwrap">
        <a href="https://www.bmbf.de/">
          <div id="bmbf"></div>
        </a>
      </div>

    </div>
  </div>
  <div class="version-display">
    <span>App Version: </span>
    <span>{{ version }}</span>
  </div>
</div>
</template>
<script>
export default {
  name: 'PEPFooter',
  data() {
    return {
      version: process.env.PACKAGE_VERSION,
    };
  },
}
</script>

<style>
#pepfooter {
  /* enable scrolling in firefox. stick content to sharepoints overlay container  */
  position: sticky;
}
#pepfooter {
  width: 100%;
  position: relative;
  background: linear-gradient(0deg,rgba(253, 251, 251,  .7),rgba(255, 255, 255, 0.4));
	min-height: 350px;

}
#branding {
	display: inline-flex;
align-items: center;

}
#partnerbrand {
	display: inline-flex;
}
.partnerwrap {
	margin: 20px;
}
#pepbrand {
	background-image: url("../../../src/assets/optimiert-PEP-Logo_trans-400x200.png");
	height: 200px;
width: 400px;
margin: 50px;
display: inline-block;

}
#uni {
  background-image: url('../../../src/assets/optimiert-footer_partner_uni-150x100.png');
  max-width: 150px;
  height: 100px;
  width: 150px;
}

#lms {
  background-image: url('../../../src/assets/optimiert-footer_partner_lemas-150x100.png');
   background-image: url('../../../src/assets/optimiert-footer_partner_lemas-150x100.png');
  max-width: 150px;
  height: 100px;
  width: 150px;
}


#bmbf {
  background-image: url('../../../src/assets/optimiert-footer_partner_bmbf-150x100.png');
  max-width: 150px;
  height: 100px;
  width: 150px;
}
</style>
