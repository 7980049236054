// ClientStorageManager.js

export function determineApiValue() {
    // Logik zur Bestimmung des API-Wertes
     // Set API end point based on the environment
 //   this.$store.commit("setApi", typeof _spPageContextInfo !== "undefined" ? "sharepoint" : "pepApi");
  //  console.log("API set to:", this.$store.getters.api); // Überprüfen des gesetzten API-Wertes
  //let apiValue = typeof _spPageContextInfo !== "undefined" ? "sharepoint" : "pepApi";
    return typeof _spPageContextInfo !== "undefined" ? "localStorage" : "localStorage"; 
  }
  export function determineEnvironmentPrefix() {
    if (typeof _spPageContextInfo !== "undefined") {
      return "SP"; // SharePoint
    } else if (window.location.hostname === "localhost") {
      return "LH"; // Localhost
    } else {
      return "OP"; // Offener Webserver
    }
  }
  const apiValue = determineApiValue();
  const environmentPrefix = determineEnvironmentPrefix();

  function getLocalStorageKey(key) {
    return `${environmentPrefix}_${apiValue}_${key}`;
  }

export function getLocalStorageItem(key) {
  const fullKey = getLocalStorageKey(key);
  return JSON.parse(localStorage.getItem(fullKey));
}

export function setLocalStorageItem(key, value) {
  const fullKey = getLocalStorageKey(key);
  localStorage.setItem(fullKey, JSON.stringify(value));
}

export function removeLocalStorageItem(key) {
  const fullKey = getLocalStorageKey(key);
  localStorage.removeItem(fullKey);
}
