<style scoped>
.zyklus-evaluation {
  width: 100%;
  text-align: left;
  text-align: -webkit-left;
   text-align: -moz-left;
  cursor: default;
  display: grid;
}

.separator {
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 30, 0.36);
}

.zyklus-evaluation-footer-container {
  margin-top: 50px;
}

.zyklus-evaluation-header {
  margin-top: 50px;
  margin-bottom: 15px;
  font-size: 23px;
  font-weight: bold;
}

.zyklus-evaluation-footer-success {
  margin-top: 60px;
  padding: 15px;
  background: #f9fff2;
  font-size: 20px;
  text-align: center;
  text-align: -webkit-center;
   text-align: -moz-center;
  cursor: default;
}

.zyklus-evaluation-footer-button-container {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 25px;
  margin-top: 5px;
  display: grid;
  grid-template-rows: auto auto;
  grid-gap: 10px;
}
.zyklus-evaluation-footer-button {
  color: #2c3e50;
  max-width: 500px;
  font-size: 20px;
  margin: 5px;
  background-color: white;
  padding: 0.8em;
  line-height: 1em;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  justify-content: center;
  grid-gap: 15px;
}
.zyklus-evaluation-footer-button:hover {
  cursor: pointer;
  background-color: rgba(206,220,242,.53);
}


</style>

<template>
  <div class="zyklus-evaluation">
    <div class="separator" />
    <div v-if="hasMeasures" class="zyklus-evaluation-measures">
      <div class="zyklus-evaluation-header">
        Entwicklungsmaßnahmen
      </div>
      <EvaluationCard :items="measures" :zid="zid" @checkEvaluationState="checkEvaluationState"/>
    </div>
    <div v-if="hasGoals" class="zyklus-evaluation-goals">
      <div class="zyklus-evaluation-header">
        Entwicklungsziele
      </div>
      <EvaluationCard :items="goals" :zid="zid" @checkEvaluationState="checkEvaluationState"/>
    </div>


    <div v-if="evalIsCompleted" class="zyklus-evaluation-footer-container">
        <div class="zyklus-evaluation-footer-success">
          Herzlichen Glückwunsch! Die Evaluation des Zyklus ist erfolgreich abgeschlossen.<br />
        </div>

        <div class="zyklus-evaluation-footer-button-container">
          <div>
            Um den Entwicklungsplan fortzuschreiben, gehen Sie zurück zur Entwicklungsplanseite der {{$entgendern.pupil}} und
            legen Sie einen neuen Zyklus an.
          </div>
          <button class="zyklus-evaluation-footer-button" @click.stop.prevent="gotoEp">
            <i class="material-icons">account_box</i>
            zur Entwicklungsplanseite
          </button>
        </div>
    </div>
  </div>
</template>

<script>
import EvaluationCard from "../Cards/EvaluationCard"

export default {
  name: "ZyklusEvaluation",
  props: ["sid", "zid"],
  components: {
    EvaluationCard
  },
  computed: {
    goals() {
      return this.$store.getters.evaluationGoals(this.zid)
    },
    measures() {
      return this.$store.getters.evaluationMeasures(this.zid)
    },
    evalIsCompleted() {
      return this.$store.getters.zyklusEvalById({sid: this.sid, zid: this.zid})
    },
    hasGoals() {
      if(this.goals && Array.isArray(this.goals) && this.goals.length) return true
      else return false
    },
    hasMeasures() {
      if(this.measures && Array.isArray(this.measures) && this.measures.length) return true
      else return false
    }
  },
  methods: {
    gotoEp() {
      this.scrollToTop()
      this.$router.push({ name: "Profil", params: { sid: this.sid } })
    },
    scrollToTop() {
      // window does not work in sharepoint
      if (typeof _spPageContextInfo !== "undefined") {
        document.getElementById('s4-workspace').scrollTo(0, 0)
      } else {
        window.scrollTo(0, 0)
      }
    },
    checkEvaluationState() {
      let goalsCompleted = false
      let measuresCompleted = false
      let evaluationCompletedCheck = false
      if(this.goals) goalsCompleted = this.isCompleted(this.goals)
      if(this.measures) measuresCompleted = this.isCompleted(this.measures)
      if(goalsCompleted && measuresCompleted) {
          evaluationCompletedCheck = true
      }
      if(this.evalIsCompleted !== evaluationCompletedCheck) {
        // update vuex zyklus eval state
        this.$store.commit("setEval", {
          sid: this.sid,
          zid: this.zid,
          eval: evaluationCompletedCheck
        })
      }
    },
    isCompleted(itemList) {
      for(let item of itemList) {
        for(let kriterium in item.rejectState.Kriterien) {
          if(item.rejectState.Kriterien[kriterium] === null) return false
        }
      }
      return true
    }
  }
}
</script>
