<template>
  <div class="CardHeader" v-bind:class="HeaderClassObject" v-on:click="types.isEB ? {'click' : TriggerChecked()} : null">
    <div class="HeaderEditContainer">
      <span class="HeaderLabel" v-html="ChoseData" v-if="!types.isEB">{{ ChoseData }}</span>
      <span v-if="this.helperIcon" class="icon-container">
        <helper-icon-callout :Title="this.helperIcon.title" :Main="this.helperIcon.main"></helper-icon-callout>
      </span>
      <!-- <i
        v-if="types.isZC && !isNew || types.isEM"
        @click="editHeaderFields($event)"
        class="ms-Icon icon ms-Icon--Edit"
        style="color: rgb(0, 120, 212); cursor: pointer"
      ></i> -->
      <span class="material-icons" @click="editHeaderFields($event)" style="cursor: pointer" v-if="types.isZC && !isNew || types.isEM">
        edit
      </span>

    </div>
    <div v-bind:class="EBcontainer" style="width: 100%">
        <span
          class="HeaderTitle"
          :class="{ 'EPExtraHeader': types.isEP, 'title-container': true }"
          aria-multiline="false"
          role="textbox"
          contenteditable="false"
          v-html="HeaderTitle"
          v-if="!isEdit"
            @input="onInput($event)"
        ></span>

    <div v-if="types.isEP && cardextra !== null" class="cardextra-klasse">
      {{cardextra}}
      </div>
     <div class="round" v-if="types.isEB">
                            <input style="z-index: -9"  :id="'CB' + cid " ref="trigger" type="checkbox" @change="Checked($event)" :isChecked="false"  :value="this.HeaderTitle" :disabled="selectOff && !selected" >
                            <label style="z-index: -1" :for="'CB' + cid" ref="triggerlabel" v-bind:class="{ 'EBselected': this.selected }"  @click="toggleActive($event), toggleAlert()"></label>

                        </div>
    </div>
     <slot v-if="isEdit" name="headerslot" ></slot>
         <div class="selectalert " v-bind:class="{ 'hiddenalert': hidden }">Es können maximal drei Entwicklungsbereiche ausgewählt werden.</div>

  </div>
</template>
<script>
import editCardFieldMixin from "../../../Mixins/editCardFieldMixin";

export default {
  props: {
        isNew: {
          type: Boolean,
          default: false,
        },
    cardtitle:  {
          type: String,
          default: null,
        },
          cardextra:  {
          type: String,
          default: null,
        },
      isEdit:  {
          type: Boolean,
          default: false,
        },
    cid: Number,
    selectOff: {
      type: Boolean,
      default: false
    },
    selected: {
      type: Boolean,
      default: false
    },
    aktiviert: Boolean,
    types: {
      isEP: Boolean,
      isEB: Boolean,
      isZC: Boolean,
    },
    helperIcon: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
    aktivierte: !this.selected,
    hidden: true,
      HeaderTitle: "Der Titel der Karte",
      HeaderLabel: "sd",
      EP: "Entwicklungsplan",
      EB: "Entwicklungsbereich",
      ZC: "Bezeichnung",
      EM: "Bezeichnung",
     dataobject: {
       title: this.cardtitle,
       id: this.cid
     }
    };
  },
//   watch: {
// aktivierte: function() {

//   this.wow()
// }
 // },
  //  watch: {
  //   aktiviert: function () {
  //    this.$parent.$emit('clickinit', this.dataobject)   ;
  //   this.$parent.$parent.$emit('addinit', this.dataobject)
  //   },


  // },
  created: function () {
    this.aktivierte = this.aktiviert
    if (this.cardtitle !== null) {
      this.HeaderTitle = this.cardtitle
    }
 if (this.aktiviert == true ) {
   // console.log("aktivierte")
  // let pl = {title: this.HeaderTitle, id: this.cid}
this.$parent.$emit('clickinit', this.dataobject)   ;
this.$parent.$parent.$emit('addinit', this.dataobject)
   //  this.$parent.$emit('clickinit', this.HeaderTitle)   ;
   // this.$parent.$parent.$emit('addinit', this.HeaderTitle)
  }
   },
  methods: {
 onInput(e) {
        this.$parent.$emit('update:syncContentTitle', e.target.innerText)

      },
      TriggerChecked() {
//this.$refs.trigger.click()
this.$refs.triggerlabel.click()
      },
Checked(event) {
  if (!this.selected){

  //  if (event.target.checked != true){
 // console.log(event.target.checked);
event.target.removeAttribute("checked");
let pl = {title: event.target.value, id:this.cid}
this.$parent.$emit('click', pl)   ;

// prevent to select more than 3 Entwicklungsbereiche
let ebArray = this.$parent.$parent.selectedEb.filter(item => item.search(/Entwicklungsbereich (1|2|3)/) === -1)
if(ebArray.length > 2) {
  this.hidden = false
  setTimeout(() => {
    this.hidden = true
  },1500)
}
else this.$parent.$parent.$emit('add', pl)

 //   this.$parent.$emit('click', event.target.value)   ;
  //  this.$parent.$parent.$emit('add', event.target.value)
   //this.$parent.$parent.$emit('add', this.dataobject)
    }
     else {
       event.target.setAttribute("checked", "checked");
       this.$parent.$emit('unclick', event.target.value);
      this.$parent.$parent.$emit('unadd', event.target.value)
      // console.log("evtagre" + event.target.value)
    }
    // this.$parent.$emit('input');
},
toggleActive() {
  if (this.selectOff == false && this.selected == false) {
 this.aktivierte = !this.aktivierte}
   if (this.selectOff == false && this.selected == true) {
 this.aktivierte = !this.aktivierte}

 if (this.selectOff == true && this.selected == true) {
    this.aktivierte = !this.aktivierte}

 },
 closeAlert(event) {
var alertCard = event.target.closest('.selectalert')
alertCard.classList.toggle('hiddenalert');
 this.hidden = !this.hidden
 },
 toggleAlert() {
  if (this.selectOff == true && this.selected == false) {
    this.hidden = !this.hidden}
    else {!this.hidden == this.hidden}
 }
},

  computed: {
    ChoseData: function () {
      if (this.types.isEP == true) return "Entwicklungsplan";
      //  this.HeaderLabel = "sdsdsd";

      if (this.types.isEB == true) return "Entwicklungsbereich";
      //  this.HeaderLabel = "sdsdsd";

      if (this.types.isZC == true) return "Bezeichnung";
      //  this.HeaderLabel = "sdsdsd";

      if (this.types.isEM == true) return "Bezeichnung";
      //  this.HeaderLabel = "sdsdsd";

      return "Fehler";
    },
    ccheck: function () {
      return {
        checked: this.aktiviert,
        false: !this.aktiviert
      }
    },

    HeaderClassObject: function () {
      return {
        EPCHeader: this.types.isEP,
        ZCHeader: this.types.isZC,
        EBCHeader: this.types.isEB,
        EMHeader: this.types.isEM
      };
    },
    EBcontainer: function() {
      return {
        EBH: this.types.isEB
      }
    }

  },
  name: "GeneralCardHeader",
  mixins: [editCardFieldMixin],
};
</script>
<style>

.icon-container {
  margin-right: auto;
}

.EBH,
.HeaderEditContainer {
  display: flex;
  width: 95%;
  justify-content: space-between;
}
.EBH span {
  font-size: calc(
18px + (22 - 18) * ((100vh - 300px) / (1900 - 300))
) !important;
word-break: break-word;
white-space: brak-spaces;
}
.CardHeader {
  width: 92%;
  grid-area: CardHeader;
  margin-left: 5%;
}

.CardHeader.EMHeader {
  border-bottom: 1px solid #00000038;
  padding-bottom: 5px;
}

.EPCHeader {
  font-family: aileronblack, sans-serif;
  font-weight: 700;
  letter-spacing: 1.2px;
  color: rgb(68 68 68) !important;

}
.EBCHeader {
  width: 90%;
  height: 80%;
  max-height: 101px;
  min-height: 101px;
  display: grid;
   cursor: pointer;
}
.ZCHeader {
  width: 82%;
  min-height: 8vh;
  padding-bottom: 28px;
}
@media only screen and (min-width: 1370px) {
  .ZCHeader {
  width: 88%;
  min-height: 5vh;
}
}
.HeaderLabel {
  font-size: calc(16px + 3 * (100vh - 300px) / 1600) !important;
  font-family: "aileronsemibold", sans-serif;
  display: block;
  /* padding: 8px; */
}
span[contenteditable="true"] {
  border: 1px solid;
  border-radius: 5px;
}
.HeaderTitle {
  /* padding: 8px; */
  font-size: calc(19px + 6 * (100vh - 300px) / 1600) !important;
  width: 100%;
  hyphens: auto;
}
.round {
    position: relative;
    float: right;
    z-index: 410;
    top: -5px;
}
.round input[type="checkbox"] {
    visibility: hidden;
    height: 30px;
    width: 30px;
    position: relative;
}
/* .round input[type="checkbox"]:disabled > .round label,
 input[type="checkbox"]:disabled * label {
   pointer-events: none;
   background: red; }
    */
.selectalert {
    position: absolute;
    height: auto;
    width: 50%;
    right: 10%;
    background: rgb(255 255 255 / 80%);
    padding: 2rem;
    top: 40px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    border-radius: 20px;
    z-index: 999;
    font-size: calc(17px + 3 * (100vh - 300px) / 1600) !important;
}
.selectalert::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    z-index: -1;
    top: 0;
    left: 0;
    border-radius: 20px;
}
@supports not (backdrop-filter: blur(10px)) {
    .selectalert::after  {
        filter: url(#blur)
    }
}
.closealertbutton {
  position: absolute;
  top: 15px;
  right: 25px;
  font-family: 'aileronheavy', sans-serif;
  cursor: pointer;
  z-index: 1001;
}
.hiddenalert{
  opacity: 0;
  display: none;
}
.round label {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 50%;
    cursor: pointer;
    height: 28px;
    left: 4px;
    position: absolute;
    top: 5px;
    width: 28px;
}
.round label::after {
    border: 2px solid #fff;
        border-top-color: rgb(255, 255, 255);
        border-top-style: solid;
        border-top-width: 2px;
        border-right-color: rgb(255, 255, 255);
        border-right-style: solid;
        border-right-width: 2px;
    border-top: none;
    border-right: none;
    content: "";
    height: 12px;
    left: 2px;
    opacity: 0;
    position: absolute;
    top: 3px;
    transform: rotate(-45deg);
    width: 22px;
}
.EBselected {
      background-color: rgba(7, 113, 48, 0.8) !important;
    border-color: rgba(7, 113, 48, 0.8) !important;

}

.EBselected::after {
    opacity: 1 !important;

}
.EBCselected {
filter: saturate(1)
}
.EPExtraHeader {
 font-size: calc(30px + 6*(100vh - 300px)/1600)!important
}
.cardextra-klasse {
      position: absolute;
    right: 0%;
    top: -3%;
    font-size: calc(20px + 6*(100vh - 300px)/1600)!important;
    font-family: 'aileronthin';
    border: 1px solid;
    padding: 10px;
    background: #ffffff66;
}

.title-container {
  display: block;
}
</style>
