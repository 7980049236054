export default {
    methods: {
        editFields(event) {
            if (event){
            var textfield = event.target.parentElement.nextSibling;
            var textattr = textfield.getAttribute("contenteditable");
            // console.log(textattr);
            if (textattr === "false") {
            //console.log(textfield);
textfield.setAttribute("contenteditable", true)

          } else {
            textfield.setAttribute("contenteditable", false)
          }
      }
    },
    editFieldsOrg(event) {
      if (event){
      event.target.setAttribute("clicked", true)
      var textfield = event.target.parentElement.nextSibling;
      var nexttextfield = event.target.parentElement.nextSibling.nextSibling
      var textattr = textfield.getAttribute("style");
    //   console.log(textattr);
      if (textattr.indexOf("block") != -1) {
      //console.log(textfield);
textfield.style.display = 'none'
event.target.setAttribute("clicked", true)
nexttextfield.style.display = 'block'
    } else {
      textfield.style.display = 'block'
      nexttextfield.style.display = 'none'
      event.target.setAttribute("clicked", false)

    }
}
},
    editHeaderFields(event) {
      if (event){
      var textfield = event.target.parentElement.nextSibling.firstChild;
      var textattr = textfield.getAttribute("contenteditable");
      // console.log(textattr);
      if (textattr === "false") {
      //console.log(textfield);
textfield.setAttribute("contenteditable", true)

    } else {
      textfield.setAttribute("contenteditable", false)
    }
}
},

}
}
