<template>
<section class="bar-seperator" :class="[computedClass, isSpecial ? 'orgclass' : 'useclass']" :style="[computedStyle, styleObj]" >
      <header  id="headerleiste" v-if="!isScrolled" :class="[{schooladmin : isSpecial }, {headerleiste: !isSpecial}]" :style="isMobile? 'backdropFilter: blur(0px)' : ''" >
    <div class="titelleiste" >

      <div v-if="headline" :style="isMobileStyle">
        <div class="subtitletext-container">
          <span class="subtitletext">{{headline}}</span>
          <helper-icon-callout
            v-if="this.$route.name === 'Zyklus' || this.$route.name === 'Profil'"
            :Title="''"
            :Main="`Auf dieser Seite finden Sie alle wichtigen Informationen über den Entwicklungsplan der ausgewählten Schüler:in sowie die Übersicht zu den einzelnen Entwicklungszyklen.\nInnerhalb der personalisierten Entwicklungsplanung findet die Persönlichkeitsentwicklung schrittweise durch Zyklen statt.\nJeder Zyklus folgt einem festen Schema aus Diagnostik, Förderung und Evaluation und baut auf den Informationen des vorherigen auf.`">
          </helper-icon-callout>
        </div>
         <h1 v-if="name" class="titeltext">{{name}}</h1>
        <fv-Shimmer v-else>

          <div style="width: 100%; height: 100%; display: flex; flex-wrap: wrap;" class="title-shimmer">
            <div class="sample" style="width: 22%;height: 80px; margin-left: 0%;background-image: inherit;margin-top: 20px;background-color: rgb(178 178 178 / 59%);"></div>
          <div class="sample" style="width: 33%;height: 80px; margin-left: 1%;background-image: inherit;margin-top: 20px;background-color: rgb(228 228 228 / 63%);"></div>
        </div>
      </fv-Shimmer>
      </div>
      <div v-else>
        <h1 class="uebersicht">Übersicht der Entwicklungspläne</h1>
      </div>

    </div>
    <fv-Pivot v-if="isSpecial && isPivot" :items="items" @change="pivotChange" :style="isMobileStyle"></fv-Pivot>
  </header>
  <div class="header-fixed-bar" v-if="isScrolled && !$route.meta.hideHFB">
      <span class="smallbar-subtitletext">{{headline || 'Übersicht der Entwicklungspläne'}}</span>
      <span v-if="name" class="smallbar-titeltext">{{name}}</span>
  </div>
        <slot name="infozyklus" />
        <slot name="pic"/>
        <div :class="[isSpecial ? 'helpdesk-schooladmin' : 'helpdesk-container', this.$route.name !== 'Zyklus' ? 'helpdesk-container-not-zyklus' : '']" >
      <Helpdesk :routeName="$route.name" :brightIcon="isSpecial"/>
    </div>

 </section>
</template>

<script>
import Helpdesk from "../Helpdesk"

export default {
  props: {
    title: {
      type: String,
      default: ""
    },
    isSpecial: {
      type:Boolean,
      default: false
    },
    isPivot: {
      type:Boolean,
      default: false
    },
    bgImage: {
      type: String,
      default: ""
    },
    colorClass: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      isScrolled: false,
      pivot: "",
      items: [
        { name: "Übersicht", width: 150 },
        { name: "Schüler:innen", width: 250 },
        { name: "Lehrer:innen", width: 250 },
        { name: "Einstellungen", width: 230 },
        ],
        scrollContainer: ""
    }
  },
  components: {
    Helpdesk
  },
   created() {
     // sharepoint has its own scroll container called s4-workspace
     this.scrollContainer = (typeof _spPageContextInfo !== "undefined") ? document.getElementById('s4-workspace') : document;
    this.scrollContainer.addEventListener('scroll', this.handleScrollHeader,  { passive: true });
  },
  destroyed() {
    this.scrollContainer.removeEventListener('scroll', this.handleScrollHeader,  { passive: true });
  },
  methods: {
    handleScrollHeader() {
      this.isScrolled = (this.scrollContainer === document) ? window.scrollY > 190 : this.scrollContainer.scrollTop > 190;
    },
    pivotChange(payload) {
      this.pivot = payload.name;
      this.$emit('pivotChange', payload);
    }
  },
  name: 'HeaderLeiste',
  computed: {
    computedClass() {
      return {
        [this.colorClass]: this.colorClass ? true : false,
        'bar-seperator--scrolled': this.isScrolled,
        'bar-seperator--special': this.isSpecial,
        'bar-seperator-min-height': this.$route.name === "Zyklus" || this.$route.name === "Profil"
      };
    },
    computedStyle() {
      return this.bgImage ? { backgroundImage: `url(${this.bgImage})` } : '';
    },
     isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    isMobileStyle() {
      return this.isMobile ? { backdropFilter: 'blur(0px)' } : '';
    },
    headline() {
    if (this.$route.name === "Profil") {
      return "Entwicklungsplan";
    }
    if (this.$route.matched.some(({ name }) => name === 'Maßnahmenkatalog')) {
      return " ";
    }
    if (this.$route.name === "Schulverwaltung") {
      return "Zentrale Schulverwaltung";
    }
    const titlePrefix = this.$route.name === "Zyklus" ? "Entwicklungsplan" : "Evaluation";
    if (["Zyklus", "ZyklusEvaluation"].includes(this.$route.name)) {
      return this.zyklusTitle ? `${titlePrefix} - ${this.zyklusTitle}` : `${titlePrefix} - `;
    }
    return null;
  },
  name() {
    const studentId = this.$parent.sid;
    if (studentId) {
      return this.$store.getters.studentNameById(studentId);
    } else if (this.$route.name === "Schulverwaltung" || this.$route.matched.some(({ name }) => name === 'Maßnahmenkatalog')) {
      return this.title;
    }
    return null;
  },
  zyklusTitle() {
    const payload = { sid: this.$parent.sid, zid: this.$parent.zid };
    if (payload.sid && payload.zid) {
      return this.$store.getters.zyklusTitleById(payload);
    }
    return null;
  },
  styleObj() {
    if (!this.isPivot) {
      return;
    }

    let styleObj = {};

    switch (this.pivot) {
      case "Schüler:innen":
        styleObj = {
          backgroundPosition: "right bottom",
          backgroundSize: "100%"
        };
        break;
      case "Lehrer:innen":
        styleObj = {
          backgroundPosition: "left",
          backgroundSize: "120%"
        };
        break;
      case "Einstellungen":
        styleObj = {
          backgroundPosition: "center",
          backgroundSize: "130%"
        };
        break;
      case "Überblick":
        styleObj = {
          backgroundPosition: "center",
          backgroundSize: "100%"
        };
        break;
      default:
        break;
    }

    return styleObj;
  },
  }
}
</script>

<style scoped>
.headerleiste {
  height: auto;
  transition: width 1s, height 2s;
  width: 100%;
  top: 0;
  font-family: "aileronthin", sans-serif;
  margin-left: 33px;
  margin-right: 33px;
  flex: 0.8;
}
.title-shimmer {
  display: grid;

  grid-auto-flow: column;

  justify-content: start;

  grid-gap: 1%;
}
.schooladmin {
   height: auto;
  position: sticky;
  min-height: 48px;
  transition: width 1s, height 2s;
  width: max-content;
  top: 0;
      font-family: "aileronthin", sans-serif;
    padding-left: 33px;
    padding-right: 33px;
    background: rgba(0,0,0,.3);
    backdrop-filter: blur(10px);
    color: white;
        display: grid;
    align-content: space-between;
    padding-top: 5%;
}
.schooladmin * .titelleiste {
  padding-top: 20% }
.schooladmin h1 {
  color: white}
.titelleiste {
  display: block;
  padding-top: 19px;
  margin-bottom: 0px;
  padding-bottom: 8px;
  text-align: left;
  text-align: -webkit-left;
  text-align: -moz-left;
  line-height: normal;
  margin: 0 1.5% 0 1.5%;
  justify-self: left;
}
.schooladmin .titelleiste {
  padding-top: 10%;
}
.titelleiste.scroll {
  display: block;
  padding-top: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-bottom: 8px;
  height: 64px;
}

.titeltext {
  margin-top: 0px;
  margin-bottom: 14px;
  /* white-space: nowrap; */
  color: rgb(38, 38, 38);
  font-family: "AileronThin", sans-serif !important;
  font-weight: 100 !important;
  font-size: calc(45px + (80 - 60) * ((100vh - 300px) / (1900 - 300))) !important;

}

.subtitletext {
  font-size: calc(22px + (30 - 22) * ((100vh - 300px) / (1900 - 300))) !important;
  font-family: 'aileronsemibold';
}

.titelleiste {
  margin-block-start: 0px !important;
}

.titeltext.scroll {
  margin-top: 14px;
  /* font-size: 24px; */
  margin-left: 33px;
  margin-bottom: 14px;
  white-space: nowrap;
  font-family: "Segoe UI Light", "Segoe UI", "Segoe", Tahoma, Helvetica, Arial, sans-serif;
  color: rgb(38, 38, 38);
}

.uebersicht {
  margin: 0;
  margin-top: 10px;
  color: #777;
  font-size: calc(45px + (80 - 60) * ((100vh - 300px) / (1900 - 300))) !important;
}
.header-fixed-bar {
height: 38px;
position: fixed;
max-width: 100%;
top: 0;
background: #ffffffa8;
text-align: left;

    padding-top: 12px;
    padding-bottom: 0px;
    padding-left: 12px;
min-width: 100%;
z-index: 99;
backdrop-filter: blur(10px);
font-family: 'aileronsemibold';
    box-shadow: 0px 0px 20px 1px rgb(0 0 0 / 19%);
    animation: revealbar 300ms;
}
@keyframes revealbar {
  0% {
    transform: translateY(-100px);
  }
  100% {
    transform: translateY(0);
  }
}
.smallbar-subtitletext {
  font-family: 'aileronheavy';
    margin-right: 12px
;
}
.bar-seperator {
    display: grid;
    flex-wrap: wrap;
    top: 0;
    grid-auto-flow: column;
    /* TODO: check side effects but without this the date picker will be cut off */
    /* overflow: hidden; */
}

.bar-seperator-min-height {
    min-height: 250px;
}

.verantwortliche-header-bar {
    position: relative;
    top: 25px;
    right: 0px;
    display: flex;
    justify-content: flex-end;
}
.orgclass {
  grid-template-columns: max-content;
  height: 100%;
  background-position: center ;
  background-repeat: no-repeat;
  background-size: 100%;
  transition: background-position 300ms ease-in-out, background-size 500ms ease-in-out;
  width: 100%;
  max-height: 40vh;
  min-height: 30vh;
  display: grid;
}
.useclass {
  width: 100% !important;
  padding-right: 33px;
}

.helpdesk-container {
  display: grid;
  align-content: start;
  justify-content: end;
}

.helpdesk-container-not-zyklus {
  margin-left: auto;
}


.helpdesk-schooladmin {
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 52px;
}

.entwicklungsplan {
  background: rgb(203,162,255);
  background: linear-gradient(23deg, rgba(203,162,255,1) 0%, rgba(206,234,255,1) 26%, rgba(219,245,255,1) 80%, rgba(240,254,255,1) 89%);
  width: 100% !important;
  min-height: 225px;
}

.subtitletext-container {
  display: flex;
  align-items: center;
}
.bar-seperator .fv-pivot-s {
  height: 4px;
}
.bar-seperator .fv-pivot-s p {
  background: #8fcfff;
} 
</style>
