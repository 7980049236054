<style scoped>
.cardset {
    display: flex;
    flex-wrap: wrap;
    margin: 20px;
    margin-left: 0px;
}
.actionscard {
  min-height: 176px;
  width: 500px;
  box-shadow: var(--button-elevation-medium);
  display: grid;
  flex-grow: .3;
  padding: 20px;
  position: relative;
  margin: 1%;
    margin-left: 1%;
  backdrop-filter: blur(10px);
  font-size: var(--font-size-slightly-larger);
  margin-left: 0;
  background-blend-mode: multiply;
  background-position: center;
  overflow: hidden;
  background-image: linear-gradient(190deg, #ddd 0px, #eee 40px, #ddd 80px);
  animation: shine-lines-table-e7508fbc 1.6s infinite linear;
  place-content: inherit;
}
.subtitle {
  width: 30%;
  height: 30px;
  background-color: gray;
  margin-bottom: 1%;
}
.title {
  width: 100%;
  height: 30px;
  background-color: gray;

}
.card-color {
    place-self: end;
display: grid;
grid-auto-flow: column;
grid-gap: 10%;
margin: 14px 0;
padding-right: 20px
}
.skeleton-button {
  width: 96px;
  height: 30px;
  background: lightgray
}
.w3samples_table_loader {
    background-color: #FFF;
    color: #666;
    width: 100%;
    padding: 0;
}
.w3samples_table_loader th{
    background: #F7F9FA;
    border-top: 1px solid #E5E5E5;
    border-bottom: 1px solid #E5E5E5;
    padding: 12px 10px;
	text-align: left;
}
.w3samples_table_loader td{
      border-top: 0px solid #E5E5E5;
      border-bottom: 1px solid #E5E5E5;
      padding: 12px 10px;
  vertical-align: top;
}


@keyframes shine-lines-table {
  0% {
	opacity:0.4;
  }
  40%{
	opacity:0.8;
  }
  100% {
	opacity:0.4;
  }
}
</style>

<template>
       <div class="cardset">
        <div class="actionscard" >
          <div class="subtitle"></div>
          <div class="title"></div>
          <div class="card-color" >
            <div class="skeleton-button"></div>
                <div class="skeleton-button"></div>
                </div>

        </div>
        <div class="actionscard" >
          <div class="subtitle"></div>
          <div class="title"></div>
          <div class="card-color" >
            <div class="skeleton-button"></div>
                <div class="skeleton-button"></div>
                </div>
        </div>
        <div class="actionscard" >
          <div class="subtitle"></div>
          <div class="title"></div>
          <div class="card-color" >
            <div class="skeleton-button"></div>
                <div class="skeleton-button"></div>
                </div>
        </div>
       </div>
</template>
<script>
export default {
     name: "ActionCardSkeleton",
     data() {
    return { }
  },
}
</script>