<template>
  <div
    class="CardContentBlock"
    :class="{
      EBCshrunken: !shrunken && types.isEB,
      EBCnotshrunken: shrunken && types.isEB,
      EM: types.isEM,
      ZC: types.isZC && !isNew,
    }"
    v-on:click="types.isEB ? {'click' : shrinkIt()} : null"
  >
    <div v-for="(a, $index) in ChoseLabel" v-bind:key="$index">
      <div :class="a.class">
        <div class="ContentBlockContainer" >
          <div class="ContentEditContainer">
            <span :class="{ nopadding: types.isEP }"> {{ a.label }}</span>
            <div v-if="types.isEB">
              <span style="float: right" class="material-icons"
                >arrow_drop_down</span
              >
            </div>
            <span
              v-if="(types.isZC && !isNew) || (types.isEM)"
              class="material-icons"
              v-on:click="!a.type ? {'click' : editFields($event) } : editSelect($event) "

              style="cursor: pointer">
            edit
            </span>
            <div v-if="helperIcon && helperIcon[$index]" class="icon-container">
              <helper-icon-callout :Title="helperIcon[$index].title" :Main="helperIcon[$index].main"></helper-icon-callout>
            </div>
            <!-- <i
              v-if="
                (types.isZC && !isNew) || (types.isEM)
              "
               v-on:click="!a.type ? {'click' : editFields($event) } : editSelect($event) "
              class="ms-Icon icon ms-Icon--Edit"
              style="color: rgb(0, 120, 212); cursor: pointer"
            ></i> -->
          </div>
          <span
            v-if="!isEdit && !a.type"
            class="ContentField"
            aria-multiline="true"
            role="textbox"
            contenteditable="false"
            :class="{ EPExtra: types.isEP }"
            @input="onInput($event, a.label)"

            >{{ a.block }}</span
          >
          <fv-DropDown
            v-model="CZeit"
            :options="zeitumfang"
            :style="selector"
            placeholder="Zeitumfang bestimmen"
            v-if="!isEdit && a.type && a.label == 'Zeitumfang'"
            :value="CZeit"
         :data-label="a.label"
           v-on:change="zu"
           :disabled="guess_zeitumfang"
          >
          </fv-DropDown>
          <fv-DropDown
            :value="CSozial"
            v-model="CSozial"
            :options="sozialform"
            :style="selector"
            :data-label="a.label"

            placeholder="Sozialform wählen"
            v-if="!isEdit && a.type && a.label == 'Sozialform'"
            v-on:change="sf"
            :disabled="guess_sozialform"
          >
          </fv-DropDown>
        </div>
      </div>
      <slot v-if="isEdit" name="contentslot" :a="a" :aha="a.label"></slot>
    </div>
  </div>
</template>
<script>
import editCardFieldMixin from "../../../Mixins/editCardFieldMixin";

export default {
  props: {
    searchText: String,
    value: null,
    isNew: {
      type: Boolean,
      default: false,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    contentone: {
      type: String,
      default: null,
    },
    contenttwo: {
      type: String,
      default: null,
    },
    contentthree: {
      type: String,
      default: null,
    },
    contentfour: {
      type: String,
      default: null,
    },

    // shrunken: Boolean,
    cid: Number,
    types: {
      isEP: Boolean,
      isEB: Boolean,
      isZC: Boolean,
      isEM: Boolean,
    },
     helperIcon: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      shrunken: false,
      ready: false,
      toggleSelectDisplay: false,
      val: {
        Sozialform: "",
        Zeitumfang: "",
      },
      sozialform: [
        { key: "Einzelarbeit", text: "Einzelarbeit" },
        { key: "Gruppenarbeit", text: "Gruppenarbeit" },
        { key: "Lehrervortrag", text: "Lehrervortrag" },
      ],
      oldsozialform: [
        { key: "Einzelarbeit", text: "Einzelarbeit", choosen: false },
        { key: "Gruppenarbeit", text: "Gruppenarbeit", choosen: false },
        { key: "Lehrervortrag", text: "Lehrervortrag", choosen: false },
      ],
      CZeit: [],
      CSozial: [],
      zeitumfang: [
        { key: "1-4 UE", text: "1-4 UE" },
        { key: "4-8 Wochen", text: "4-8 Wochen" },
        { key: "6 Monate +", text: "6 Monate +" },
      ],
      oldzeitumfang: [
        { key: "1-4 UE", text: "1-4 UE", choosen: false },
        { key: "4-8 Wochen", text: "4-8 Wochen", choosen: false },
        { key: "6 Monate +", text: "6 Monate +", choosen: false },
      ],

      EPLabel: [
        {
          label: "Verantwortlich",
          block: "TextFeld für Verantwortliche Lehrperson",
        },
        { label: "Letzte Veränderung", block: "Ein Datum" },
      ],
      EBLabel: [
        {
          label: "Kurzbeschreibung:",
          block: "Eine Kurze Beschreibung des Entwicklungsbereiches",
        },
      ],
      ZCLabel: [
        {
          label: "Entwicklungsstand",
          block: "Der Zustand in der sich die " +this.$entgendern.pupil +" befindet.",
        },
        {
          label: "Entwicklungsziel",
          block: "Das zu erreichende Ziel der individuellen Entwicklung",
        },
      ],
      EMLabel: [
        {
          label: "Kurzbeschreibung",
          block: "Eine kurze Beschreibung der Maßnahme",
          class: "C1",
        },
        {
          label: "Sozialform",
          block: "Welche Sozialform hat die Maßnahme?",
          class: "C3",
          type: "select",
        },
        {
          label: "Voraussetzungen/Materialien",
          block:
            "Welche Voraussetzungen müssen erfüllt sein, welche Materialien müssen beschafft werden?",
          class: "C2",
        },
        {
          label: "Zeitumfang",
          block: "Welchen Zeitumfang hat die Maßnahme",
          class: "C1",
          type: "select",
        },
      ],
      guess_zeitumfang: true,
      guess_sozialform:  true,
    };
  },
  computed: {
    ChoseLabel: function () {
      if (this.types.isEP == true) return this.EPLabel;
      //  this.HeaderLabel = "sdsdsd";

      if (this.types.isEB == true) return this.EBLabel;
      //  this.HeaderLabel = "sdsdsd";

      if (this.types.isZC == true) return this.ZCLabel;
      //  this.HeaderLabel = "sdsdsd";
      if (this.types.isEM == true) return this.EMLabel;
      return "Fehler";
    },
    CClass: function () {
      let indiIndex = 0
      if (this.a) indiIndex = this.a.index

      if (this.types.isEM == true)
        return {
          indiIndex,
        };
      else {
        return {};
      }
    },
  },
  watch: {
    ready: function () {
    //  console.log("datachange");
      this.findchosen();
    },
  },
  mounted() {
    if (this.contentone !== null) {
      //  console.log(this.contentone)
      this.ChoseLabel[0].block = this.contentone;
    }
    if (this.contenttwo !== null) {
      //  console.log(this.contentone)
      this.ChoseLabel[1].block = this.contenttwo;
      this.ready = true;
    }
    if (this.contentthree !== null) {
      //  console.log(this.contentone)
      this.ChoseLabel[2].block = this.contentthree;
    }
    if (this.contentfour !== null) {
      //  console.log(this.contentone)
      this.ChoseLabel[3].block = this.contentfour;
    }
  },
  created: function () {
    if (this.contentone !== null) {
      //  console.log(this.contentone)
      this.ChoseLabel[0].block = this.contentone;
    }
    if (this.contenttwo !== null) {
      //  console.log(this.contentone)
      this.ChoseLabel[1].block = this.contenttwo;
      this.ready = true;
    }
    if (this.contentthree !== null) {
      //  console.log(this.contentone)
      this.ChoseLabel[2].block = this.contentthree;
    }
    if (this.contentfour !== null) {
      //  console.log(this.contentone)
      this.ChoseLabel[3].block = this.contentfour;
    }
       this.$on("guess_zeitumfang", function() {
        //   console.log("guess zeitumgang")
         if (this.guess_zeitumfang == true) {
this.guess_zeitumfang = false
         }
         else {
             this.guess_zeitumfang = true
         }

        }),
      this.$on("guess_sozialform", function() {
     //   console.log("guess sozialform")
        this.guess_sozialform = !this.guess_sozialform
        })
  },
  name: "GeneralCardContent",
  methods: {
    editSelect(event){
  if (event){
//    var textfield = event.target.parentElement.nextSibling;
    var selectfield = event.target.parentElement.parentElement.lastElementChild
    var datalabel = selectfield.getAttribute("data-label")
    if (datalabel == "Sozialform") {
    this.$emit("guess_sozialform", )}
    else if (datalabel == "Zeitumfang") {
      this.$emit("guess_zeitumfang", )}
  //  var selectattr = selectfield.getAttribute("disabled")

  }
  },
    zu(value) {
      let payload = value.option.text
      this.$parent.$emit("update:syncContentFour", payload);

    },
        sf(value) {
           let payload = value.option.text
                   this.$parent.$emit("update:syncContentTwo",payload);

    },
    onInput(e, label) {
      if (label === "Kurzbeschreibung")
        this.$parent.$emit("update:syncContentOne", e.target.innerText);
      else if (label === "Entwicklungsstand")
        this.$parent.$emit("update:syncContentOne", e.target.innerText);
      else if (label === "Entwicklungsziel")
        this.$parent.$emit("update:syncContentTwo", e.target.innerText);
      else if (label === "Sozialform")
        this.$parent.$emit("update:syncContentTwo",this.CSozial);
      else if (label === "Voraussetzungen/Materialien")
        this.$parent.$emit("update:syncContentThree", e.target.innerText);
      else if (label === "Zeitumfang")
        this.$parent.$emit("update:syncContentFour", this.CZeit);
      //  else this.$emit('update:syncContentTitle', e.target.innerText)
    },
    selector: function () {
      return {
        //  width: "0%",
        "font-size": "20px",
      };
    },
    findchosen: function () {
      if (this.types.isEM) {
        let a = this.EMLabel.find((l) => l.label === "Sozialform");
        let a1 = a.block;
        let b = this.EMLabel.find((l) => l.label === "Zeitumfang");
        let b1 = b.block;

        let a2 = this.oldsozialform;
        let b2 = this.oldzeitumfang;
        let finda = a2.find((element) => element.key == a1);

        let findb = b2.find((element) => element.key == b1);
    //    console.log("findb" + findb);
       if  (typeof finda != "undefined") {
        finda.choosen = true; }
         if  (typeof findb != "undefined") {
        findb.choosen = true;
         }
        this.Czeit();
        this.Csozial();
      }
    },
    shrinkIt() {
     // console.log("clickiderwas");
   //   console.log(this.shrunken)
      if (this.shrunken)
      {
      this.shrunken = false
      }
      else {
        this.shrunken = true
      }

    },
    Czeit: function () {
      let a = [];
      let b = this.oldzeitumfang;
//     console.log(b);
      let e = b.find((element) => element.choosen == true);
      let arr = [...a, e];
      if(e) this.CZeit = arr;
      else this.CZeit = []
    },
    Csozial: function () {
      let a = [];
      let b = this.oldsozialform;
 //     console.log(b);
      let e = b.find((element) => element.choosen == true);

      let arr = [...a, e];
      if(e) this.CSozial = arr;
      else this.CSozial = []
    },
  },
  mixins: [editCardFieldMixin],
};
</script>
<style>
.CardContentBlock {
  position: relative;

  min-height: 60%;

  /* max-height:calc(45vh - 15vh); */
  max-height: calc((100vh / 3));
  overflow-y: auto;
  /* width: 100%; */
  /* margin-bottom: 20px; */
  transition: max-height 300ms cubic-bezier(0, 0, 0, 1.02);
  grid-area: CardContentBlock;
  padding-left: 5%;
  overflow-x: hidden;
}

.icon-container {
  margin-right: auto;
}

/* .CardContentBlock::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

.CardContentBlock::-webkit-scrollbar-thumb {
  border-radius: 0px;
  background-image: linear-gradient(
    to bottom,
    #1e3c7217 0%,
    #1e3c7280 1%,
    #2a5298d9 100%
  );
} */
.ContentEditContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.ContentEditContainer .material-icons {
  width: 10%;
}


.EBCnotshrunken {
  max-height: max-content;
  cursor: pointer;
}

.EBCshrunken {
  padding-bottom: 4% !important;
  max-height: 50px;
  min-height: 50px;
  overflow: hidden;
  margin-bottom: 0px;
  cursor: pointer;
  transition: max-height 400ms cubic-bezier(0, 0, 0, 1.02);

}
.nopadding {
  padding: 0px !important;
}
.EPExtra {
  font-size: 120%;
  padding: 0px !important;
}
.ZC {
  box-shadow: inset 0px 0px 20px rgb(0 0 0 / 19%),
    inset 0px 0px 9px 0px rgb(0 0 0 / 0%);
  padding-bottom: 5%;
  max-height: 20vh;
}
.EM {
  display: grid;
  grid-template-columns: 1.9fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 0px 0px;
  grid-template-areas:
    "C1 C3"
    "C2 C4";
  /* width: 92%; */
}
.unsichtbar {
  display: none;
}
@media only screen and (min-width: 2000px) {
  .EM {
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 0px 0px;
    grid-template-areas:
      "C1 C3"
      "C2 C4";
  }
}

.C1 {
  grid-area: C1;
}
.C4 {
  grid-area: C4;
}
.C2 {
  grid-area: C2;
}
.C3 {
  grid-area: C3;
}

@media all and (-ms-high-contrast: none) {
  .EM {
    display: -ms-grid;
    -ms-grid-columns: 1fr 1fr;
    -ms-grid-rows: 1fr 1fr;
    gap: 0px 0px;
  }

  .C1 {
    -ms-grid-row: 1;
    grid-row: 1;
    -ms-grid-row-span: 1;
    -ms-grid-column: 1;
    grid-column: 1;
    -ms-grid-column-span: 1;
  }

  .C4 {
    -ms-grid-row: 2;
    grid-row: 2;
    -ms-grid-row-span: 1;
    -ms-grid-column: 2;
    grid-column: 2;
    -ms-grid-column-span: 1;
  }

  .C2 {
    -ms-grid-row: 2;
    grid-row: 2;
    -ms-grid-row-span: 1;
    -ms-grid-column: 1;
    grid-column: 1;
    -ms-grid-column-span: 0;
  }

  .C3 {
    -ms-grid-row: 1;
    grid-row: 1;
    -ms-grid-row-span: 1;
    -ms-grid-column: 2;
    grid-column: 2;
    -ms-grid-column-span: 2;
  }
}

</style>
<style scoped>
::v-deep .fv-light-DropDown {
  width: 200px;
}
::v-deep .fv-light-DropDown.disabled {
background-color: rgb(255 255 255);
}
::v-deep .fv-light-DropDown.disabled .drop-down-box * input {
color: #2c3e50
}
::v-deep .fv-light-DropDown p {
  font-size: calc(
    16px + (22 - 16) * ((100vh - 300px) / (1900 - 300))
  ) !important;
  min-height: 31px;
  font-family: "aileronsemibold", sans-serif;
}
::v-deep div.list-head.fv-custom-head > span.col,
::v-deep div.list-content.fv-custom-row > div > span.col,
::v-deep .col {
  max-width: 24% !important;
  display: flex !important;
}
::v-deep div.list-head.fv-custom-head > span:nth-child(2) {
  display: none !important;
}
::v-deep .fv-light-DropDown input {
  font-size: calc(
    16px + (22 - 16) * ((100vh - 300px) / (1900 - 300))
  ) !important;
  min-height: 31px;
  font-family: "aileronsemibold", sans-serif;
}

::v-deep p.default-title {
  font-size: calc(
    18px + (24 - 18) * ((100vh - 300px) / (1900 - 300))
  ) !important;
}
</style>
