<style scoped>

.modal-fade-enter,
 .modal-fade-leave-active {
   opacity: 0;
 }

 .modal-fade-enter-active,
 .modal-fade-leave-active {
   transition: opacity .5s ease
 }

.modal-backdrop {
   position: fixed;
   top: 0;
   bottom: 0;
   left: 0;
   right: 0;
   background-color: rgba(255, 255, 255, 0.7);
   display: grid;
   justify-content: center;
   align-content: center;
   z-index: 1000;
 }

.save-dialog-container {
  width: 500px;
  height: 180px;
  padding: 5px;
  border: 1px black solid;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  display: grid;
  grid-template-rows: auto max-content;
  grid-template-areas:
  "text"
  "buttons";
}

.save-dialog-text {
  grid-area: "text";
  text-align: start;
     text-align: -webkit-start;
   text-align: -moz-start;
  justify-self: center;
  align-self: center;
  margin: 20px;
}

.save-dialog-buttons {
  height: 35px;
  grid-area: "buttons";
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 10px;
}

.save-dialog-button {
  font-size: 16px;
}

.save-dialog-button-reject {
  color: white;
  background-color: rgb(250, 77, 77);
  border-color: rgb(250, 77, 77);
}
.save-dialog-button-reject:hover {
  background-color: rgb(237, 123, 123);
  border-color: rgb(237, 123, 123);
}
.save-dialog-button-reject:focus {
  background-color: rgb(227, 30, 30);
  border-color: rgb(227, 30, 30);
}

.aileron-black {
  font-family: 'aileronblack';
  font-size: 18px;
}

</style>

<template>
  <transition name="modal-fade">
     <div class="modal-backdrop">
       <div class="save-dialog-container">
         <div class="save-dialog-text aileron-black">
           Änderungen vor dem Schließen speichern?
         </div>
         <div class="save-dialog-buttons">
           <button class="save-dialog-button save-dialog-button-reject" @click.prevent="$emit('reject')">
             Nicht speichern
           </button>
           <button class="save-dialog-button" @click.prevent="$emit('cancel')">
             Abbrechen
           </button>
           <button class="save-dialog-button" @click.prevent="$emit('save')">
             Speichern
          </button>
         </div>
       </div>
     </div>
  </transition>
</template>

<script>
  export default {
    name: 'FormSaveDialog',
    methods: {
      close() {
        this.$emit('close');
      },
    },
  };
</script>
