import Startseite from './components/Home.vue';
import EP from './components/EP.vue'
const Student = () => import(/* webpackChunkName: "student-chunk" */ './components/StudentIndex.vue');
import Profil from './components/layout/Profil/ProfilAnsicht.vue';
import Zyklus from './components/layout/Zyklus/ZyklusAnsicht.vue';
import ZyklusEvaluation from './components/layout/Zyklus/ZyklusEvaluation.vue';
import Testpage from './pages/TestPage.vue'
const Dashboard = () => import(/* webpackChunkName: "Dashboard-chunk" */ './pages/Dashboard.vue');
const SchoolOrgPage = () => import(/* webpackChunkName: "schoolOrgPage-chunk" */ './pages/SchoolOrgPage.vue');
import Login from './components/Login.vue'
const ActionsDashboard = () => import(/* webpackChunkName: "actionsDashboard-chunk" */ './pages/ActionsDashboard.vue');
const DetailsModal = () => import(/* webpackChunkName: "DetailsModal-chunk" */ './components/layout/Reusable/DetailsModal.vue')
import FutureSuS from './pages/FutureSuS.vue'
import store from './store';


const validColors = ['bg-blue', 'bg-cyan', 'bg-indigo', 'bg-purple', 'bg-pink', 'bg-red', 'bg-orange', 'bg-yellow', 'bg-green', 'bg-peach', 'bg-misty', 'bg-iron', 'bg-sunset'];
const validTypes = ['MTKi', 'MTKg', 'MKi'];

const parseNumberParam = (param) => {
  return Number.parseInt(param, 10) || null;
}
const validateColorClass = (colorClass) => {
  return validColors.includes(colorClass) ? colorClass : null;
}

const validateType = (type) => {
  return validTypes.includes(type) ? type : null;
}

const routes = [{
  path: '/',
  redirect: '/Startseite'
},
{
  path: '/Login',
  component: Login,
  name: 'Login',
  meta: {
    hideMenu: true
  }
},
{
  path: '/Testpage',
  component: Testpage
},
{
  path: '/FutureSuS',
  component: FutureSuS,
  meta: {
    hideMenu: true
  }
},
{
  path: '/Dashboard',
  component: Dashboard,
  name: 'Dashboard',
  meta: {
    hidePerson: true
  }
},
{
  path: '/Startseite',
  component: Startseite,
  meta: {
    hideMenu: true
  }
},
{
  path: '/EP',
  component: EP,
  name: 'UebersichtPlaene',
  meta: {
    hidePerson: true
  },
  beforeEnter: (to, from, next) => {
    // Überprüfen, ob die notwendigen Daten im Store vorhanden sind
    if (!store.getters.entPl || store.getters.entPl.length === 0) {
      // Wenn nicht, lösen Sie die Aktion aus, um die Daten zu laden
      store.dispatch('loadEntPl').then(() => {
        // Nachdem die Daten geladen wurden, fahren Sie mit der Route fort
        next();
      }).catch(() => {
        // Im Fehlerfall können Sie eine Fehlermeldung anzeigen oder auf eine andere Seite umleiten
        next('/error'); // Beispiel für eine Umleitung zu einer Fehlerseite
      });
    } else {
      // Wenn die Daten bereits vorhanden sind, fahren Sie einfach fort
      next();
    }
  }
},
{
  path: '/Student/:sid',
  name: 'Student',
  component: Student,
  props: ({ params }) => ({
    sid: parseNumberParam(params.sid),
    colorClass: validateColorClass(params.colorClass),
    zid: parseNumberParam(params.zid)
  }),
  meta: {
    hideCB: true
  },
  children: [{
    path: 'Profil/',
    name: 'Profil',
    props: ({ params }) => ({
      sid: parseNumberParam(params.sid),
      colorClass: validateColorClass(params.colorClass)
    }),
    component: Profil,
    meta: {
      hideCB: true
    },
    beforeEnter: (to, from, next) => {
      console.log("beforeEnter triggered");
      console.log("To route:", to);
      console.log("From route:", from);
    
      if (from.params && from.params.colorClass && to.params && !to.params.colorClass) {
        console.log("Setting colorClass from previous route");
        store.commit('setColorClass', from.params.colorClass); // Setzen im Vuex Store
      } else {
        console.log("colorClass not set from previous route");
      }
      next();
    }
  },
  {
    path: 'Zyklus/:zid/',
    name: 'Zyklus',
    component: Zyklus,
    props: ({ params }) => ({
      sid: parseNumberParam(params.sid),
      zid: parseNumberParam(params.zid)
    }),
    
    meta: {
      hideCB: true
    },
    beforeEnter: (to, from, next) => {
      console.log("Zyklus beforeEnter triggered");
      console.log("To route:", to);
      console.log("From route:", from);
    
      if (to.params.sid && to.params.zid) {
        // Wenn alle Parameter vorhanden sind, fahren Sie fort
    
        // Wenn colorClass im vorherigen Routen-Parameter vorhanden ist und im aktuellen Routen-Parameter nicht, dann setzen im Vuex Store
        if (from.params.colorClass && !to.params.colorClass) {
          // Beachten Sie, dass `this` hier möglicherweise nicht auf die Vue-Instanz verweist, abhängig davon, wie Sie Ihre Routen konfiguriert haben. 
          // Wenn das der Fall ist, müssen Sie möglicherweise eine andere Methode verwenden, um Zugriff auf den Vuex-Store zu bekommen.
          store.commit('setColorClass', from.params.colorClass);
        }
    
        next();
      } else {
        console.error("Missing required parameters.");
        // Umleiten zu einer Fehlerseite oder der vorherigen Seite
        next(from.path);
      }
    }
    ,
    children: [
      {
        path: 'New',
        name: 'NewActionZ',
        component: DetailsModal,
        props: ({ params }) => ({
          sid: parseNumberParam(params.sid),
          colorClass: validateColorClass(params.colorClass),
          zid: parseNumberParam(params.zid),
          type: validateType(params.type)
        }),
        meta: {
          modalVisible: true,
          hidePerson: false,
          hideCB: true,
          hideHFB: true,
        }
      },
      {
        path: 'Maßnahme/:mid',
        name: 'Maßnahme',
        component: DetailsModal,
        props: (route) => {
          const [mid, type] = route.params.mid.split('_');
          return {
            sid: parseNumberParam(route.params.sid),
            colorClass: validateColorClass(route.params.colorClass),
            zid: parseNumberParam(route.params.zid),
            mid: parseNumberParam(mid),
            isTemplate: false,
            type: validateType(type),
            ActionsDetailsId: route.params.mid
          };
        },
        meta: {
          modalVisible: true,
          hidePerson: false,
          hideCB: true,
          hideHFB: true,
        }
      },
    ]
  },
  {
    path: 'Zyklus/:zid/evaluation',
    name: 'ZyklusEvaluation',
    component: ZyklusEvaluation,
    props: ({ params }) => ({
      sid: parseNumberParam(params.sid),
      colorClass: validateColorClass(params.colorClass),
      zid: parseNumberParam(params.zid)
    }),
    meta: {
      hideCB: true
    }
  }
  ]
},
{
  path: '/Schulverwaltung',
  component: SchoolOrgPage,
  name: 'Schulverwaltung',
  meta: {
    hidePerson: true
  }
},
{
  path: '/ActionsDashboard/',
  component: ActionsDashboard,
  name: 'Maßnahmenkatalog',
  props: ({ params }) => ({ type: validTypes.includes(params.type) ? params.type : null }),
  children: [
    {
      path: ':ActionsDetailsId',
      name: 'ActionDetailsModal',
      component: DetailsModal,
      props: true,
      meta: {
        modalVisible: true,
        hidePerson: true,
        hideHFB: true,
        check: false

      }
    },
    {
      path: 'New',
      name: 'NewAction',
      component: DetailsModal,
      props: ({ params }) => ({ type: validTypes.includes(params.type) ? params.type : null }),
      meta: {
        modalVisible: true,
        hidePerson: true,
        hideHFB: true,

      }
    },
  ],
  meta: {
    hidePerson: true,
    modalVisible: false
  }
}
];

export default routes;
