import store from "../index"
import api from '../../api/api.js'

const state = {
  entMk: null,
  loading: null,
  lastSavedMeasure: '', 
  lastSavedMeasureId: '', 
}

const actions = {
  loadEntMk({commit, state}) {
    return new Promise((resolve, reject) => {
      if(state.entMk === null) {
        commit('setEntMkLoading', true)
        api.loadEntMk()
        .then(res => {
          commit('setEntMk', res)
          commit('createEvaluationMeasures', res)
          commit('setEntMkLoading', false)
          resolve(res)
        })
        .catch(err => {
          setTimeout(() => {
            commit('setEntMkLoading', false)
          }, 1000)
          console.log("Error while getting library of measures: " +err)
          reject(err)
        })
      } else {
        if(state.entMk && Array.isArray(state.entMk)) resolve(state.entMk)
        else resolve([])
      }
    })
  },
  addEntMki({commit}, measure) {
    return new Promise((resolve, reject) => {
      // checks if element what should be added does not already exist
      if (!measureExists(measure.Id)) {
        commit('setEntMkLoading', true)
        api.addEntMki(measure)
        .then(res => {
          setTimeout(() => {
            commit("addEntMki", res)
            commit("addEvaluationMeasure", res)
            commit('setEntMkLoading', false)
commit('setMKSavedData', res)
commit('setMKSavedData2', res.Id) 
            resolve()
          }, 500)
        })
        .catch(err => {
          commit('setEntMkLoading', false)
          handleError(err, "Es trat ein Problem mit der Serververbindung oder Session auf. Es konnte leider keine neue Maßnahme angelegt werden.");
          reject(err)
        })
      }
    })
  },
  removeEntMki({commit}, payload) {
    // checks whether the measure to be deleted exists
    if (measureExists(payload.measureId)) {
            commit('setEntMkLoading', true)
      api.removeEntMki(payload)
      .then(() => {
        setTimeout(() => {
          commit("removeEntMki", payload)
          commit("removeEvaluationMeasure", payload)
          commit('setEntMkLoading', false)
        }, 100)
      })
      .catch(err => {
        commit('setEntMkLoading', false)
        handleError(err, "Es trat ein Problem mit der Serververbindung oder Session auf. Die Maßnahme konnte leider nicht gelöscht werden.");
      })
    }
  },
  updateEntMki({commit}, payload) {
    api.updateEntMki(payload)
    .then((res) => {
      commit("updateEvaluationMeasure", payload)
      commit('setMKSavedData', res)
    })
    .catch(err => {
      handleError(err, "Es trat ein Problem mit der Serververbindung oder Session auf. Die Entwicklungsmaßnahme konnte leider nicht aktualisiert werden.");
      
    })
  },
  saveMkiEvaluation({commit}, item) {
    api.saveMkiEvaluation(item)
    .then(() => {
      if(item && item.Kriterien && item.Kriterien.Vorgehen && typeof item.Kriterien.Vorgehen.Value === 'number' && Array.isArray(item.Kriterien.Vorgehen.Answers) && item.Kriterien.Vorgehen.Answers.length) {
        // check reuse state
        let reuse = false
        let vorgehen = item.Kriterien.Vorgehen.Answers[item.Kriterien.Vorgehen.Value]
        if(vorgehen === "Erneuter Versuch der Umsetzung" || vorgehen === "Weiterführung") reuse = true
        // apply reuse state
        let payload = {measureId: item.Id, reuse: reuse}
        commit("setEntMkiReuse", payload)
        api.saveEntMkiReuse(payload)
        .catch(err => {
          console.log("Error while saving reuse from measure: " +err)
        })
      }
    })
    .catch(err => {
      // reopen edit view
      commit("setPayload", {
        id: item.Id,
        type: item.Type,
        isEdited: true,
        task: "isEdited"
      })
      // show warning
      alert("Die Evaluation konnte leider nicht gespeichert werden und ist beim nächsten Laden der Seite leider verloren. Bitte überprüfen Sie Ihre Internetverbindung und versuchen Sie die Browserseite neu zu laden.")
      console.log("Error while saving evaluation measure: " +err)
    })
  },
  setEntMkiRefZie(_, payload) {
    api.setEntMkiRefZie(payload)
    .catch(err => {
      handleError(err, "Es trat ein Problem mit der Serververbindung oder Session auf. Die Entwicklungsmaßnahme konnte leider nicht aktualisiert werden.");
    })
  }
}

const getters = {
  entMkbyZid: state => zid => {
    
    // fetch data if no other data is available or there is no other fetching process.
    if(state.entMk === null && !state.loading) {
      store.dispatch("loadEntMk")
    }
    // return only measures matching to zyklus from users
    if(state.entMk && Array.isArray(state.entMk) && state.entMk.length) return state.entMk.filter(item => item.RefZId === zid)
    else return null
  },
  entMk: state => {
    
    // fetch data if no other data is available or there is no other fetching process.
    if(state.entMk === null && !state.loading) {
      store.dispatch("loadEntMk")
    }
    return state.entMk
  },
  entMkLoading: state => {
    return state.loading
  },
  entMKsavedData: state => {
    return state.lastSavedMeasure
  },
  nextEntMkiId: state => {
    let measures = state.entMk
    let id = 0
    if(measures) {
      for(let measure of measures) {
        if(measure.Id > id) id = measure.Id
      }
    }
    return ++id
  }
}

const mutations = {
  setEntMk: (state, entMk) => {
    state.entMk = entMk
  },
  setEntMkLoading: (state, loading) => {
    state.loading = loading
  },
  setMKSavedData: (state, data) => {
    console.log('setMKSavedData', data)
    state.lastSavedMeasure = data
  },
  setMKSavedData2: (state, data) => {
    console.log('setMKSavedData', data)
    state.lastSavedMeasureId = data
  },
  addEntMki: (state, measure) => {
    if(measure) state.entMk.push(measure)
  },
  removeEntMki: (state, payload) => {
    if(payload && payload.measureId) {
      state.entMk = state.entMk.filter(item => item.Id !== payload.measureId)
    }
  },
  updateEntMki: (state, payload) => {
    if(payload && payload.Id) {
      state.entMk.forEach(measure => {
        if(measure.Id === payload.Id) {
          // update measure
          measure.Title = payload.Title
          measure.Zeitumfang = payload.Zeitumfang
          measure.Sozialform = payload.Sozialform
          measure.Voraussetzungen_x002f_Materialie = payload.Voraussetzungen_x002f_Materialie
          measure.Kurzbeschreibung = payload.Kurzbeschreibung
          measure.Altersgruppe = payload.Altersgruppe
          measure.Evaluationskriterien_x0020__x002 = payload.Eval
          measure.Lernumgebung = payload.Lernumgebung
          measure.Plan_und_Durch = payload.Plan_und_Durch
          measure.Praxistipps = payload.Praxistipps
          measure.RefMethId = payload.RefMethId
          measure.RefTemplateGroupId = payload.RefTemplateGroupId
          measure.RefTemplateindiId = payload.RefTemplateindiId
          measure.RefZ = payload.RefZ
          measure.RefZId = payload.RefZ
          measure.RefMethBeschr = payload.RefMethBeschr
          measure.RefMethZiel = payload.RefMethZiel
        }
      })
      // fluent design list for already used measures has only shallow object update recognition, force component to update
      state.entMk.push({Id: "placeholder"})
      state.entMk = state.entMk.filter(item => item.Id !== "placeholder")
    }
    // update measure in data storage
    store.dispatch("updateEntMki", payload)
  },
  setEntMkiReuse: (state, payload) => {
    let measure = findMeasureById(payload.measureId);
      if(measure) measure.Reuse = payload.reuse
  },
  setEntMkiRefZie: (state, payload) => {
    let measure = findMeasureById(payload.measureId);
        if(measure && payload && JSON.stringify(measure.RefZie) !== JSON.stringify(payload.refZie)) {
      measure.RefZie = payload.refZie
      // save refZie in data storage
      store.dispatch("setEntMkiRefZie", payload)
    }
  }
}


function handleError(err, message) {
  console.log(message + err);
  alert(message + " Bitte versuchen Sie, die Browserseite neu zu laden.");
}

function measureExists(measureId) {
  return !state.loading && state.entMk.some(item => item.Id === measureId);
}

function findMeasureById(measureId) {
  return state.entMk.find(item => item.Id === measureId);
}

export default {
  state,
  mutations,
  getters,
  actions
}
