<template>
  <div
    class="GeneralCard"
    v-bind:class="[classObject, colorClass]"
    v-on:click="
      types.isEP ? { click: changebodyColor($event), click: EPClick() } : null
    "
   
  >
    <object
      type="image/svg+xml"
      v-if="!unlocked"
      class="locksvg"
      :data="locksvg"
    ></object>

  
    <div
      class="CardContentContainer"
      :style="!unlocked ? 'filter:blur(1px)' : ''"
     
      
    >
      <div class="vorderseite" :class="{ methodenvs: types.isEM }"  :style="!unlocked ? 'filter: url(#pixelate)' : ''">
        <div class="innenseite" :class="{ zinnen: types.isZC }">
          <div
            class="methodenslider"
            v-if="types.isEM && !this.helperhide"
            @click="showMethHelper()"
            :class="{ helperslider: this.isHelper, stylescroll: this.isHelper }"
          >
            <span
              class="DescriptionHeading"
              :class="{ metheadline: !this.isHelper }"
              >{{ this.methcontent.Title | keepmain }}</span
            >
            <span class="closehelper" v-if="this.isHelper">X</span>
            <div v-if="this.isHelper">
              <span class="DescriptionSubHeading">
                {{ this.methcontent.Title | showelse }}
              </span>
              <span class="ContentBlockContainer MethSub">Ziel</span>
              <span>{{ this.methcontent.Ziel }}</span>
              <span class="ContentBlockContainer MethSub">Beschreibung</span>
              <span class="lastmethblock">{{
                this.methcontent.Beschreibung
              }}</span>
            </div>
          </div>
          <GeneralCardHeader
            :types="types"
            :cid="cid"
            :cardtitle="cardtitle"
            :cardextra="cardextra"
            :selectOff="selectOff"
            :selected="aktivierte"
            :aktiviert="aktivierte"
            v-bind:syncContentTitle.sync="newContentTitle"
          >
          </GeneralCardHeader>
          <GeneralCardContent
            :types="types"
            :cid="cid"
            :contentone="contentone"
            :contenttwo="contenttwo"
            :contentthree="contentthree"
            :contentfour="contentfour"
            v-bind:syncContentOne.sync="newContentOne"
            v-bind:syncContentTwo.sync="newContentTwo"
            v-bind:syncContentThree.sync="newContentThree"
            v-bind:syncContentFour.sync="newContentFour"
          />

          <GeneralCardActionButtons
            :types="types"
            v-if="this.types.isZC"
            :cid="cid"
            :front="true"
            :isEdit="Edited"
            :EditSuccess="EditSuccess"
          />
          <GeneralCardActionButtons
            :types="types"
            v-if="this.types.isEM"
            :cid="cid"
            :front="true"
            :isEdit="Edited"
            :EditSuccess="EditSuccess"
          />
        </div>
      </div>
      <div class="rueckseite">
        <div class="innenseite">
          <h3 v-if="this.types.isZC">
            Sind sie sicher, dass Sie dieses Ziel unwiederbringlich löschen
            möchten?
          </h3>
          <h3 v-if="this.types.isEM">
            Sind sie sicher, dass Sie diese Methode unwiederbringlich löschen
            möchten?
          </h3>
          <GeneralCardActionButtons
            :types="types"
            v-if="this.types.isZC"
            :cid="cid"
            :back="true"
            :index="index"
          />
          <GeneralCardActionButtons
            :types="types"
            v-if="this.types.isEM"
            :cid="cid"
            :back="true"
            :index="index"
          />
        </div>
      </div>
    </div>
   
  </div>
</template>
<script>
import GeneralCardActionButtons from "./GeneralCardActionButtons";
import GeneralCardContent from "./GeneralCardContent";
import GeneralCardHeader from "./GeneralCardHeader";
import getBGcolor from "../../../Mixins/getBGcolor";
import changeBGcolor from "../../../Mixins/changeBGcolor";

// import ExMerge from '../../../ExMerge'

export default {
  props: {
    cardtitle: String,
    unlocked: {
      type: Boolean,
      default: true,
    },
    cardextra: {
      type: String,
      default: null,
    },
    contentone: {
      type: String,
      default: "",
    },
    contenttwo: {
      type: String,
      default: null,
    },
    contentthree: {
      type: String,
      default: null,
    },
    contentfour: {
      type: String,
      default: null,
    },
    methcontent: {
      type: Object,
      default: null,
    },
    cid: Number,
    zid: Number,
    shrunken: Boolean,
    selectOff: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
      default: 0,
    },
    aktivierte: {
      type: Boolean,
      default: false,
    },
    types: {
      isEP: Boolean,
      isEB: Boolean,
      isZC: Boolean,
      isEM: Boolean,
    },
  },
  mixins: [getBGcolor, changeBGcolor],
  data() {
    return {
      selected: false,
      aktivierte1: this.aktivierte,
      locksvg: require(`../../../assets/icons/lockperson.svg`),
      selectedvalue: "",
      datasets: null,
      isHelper: false,
      Edited: false,
      EditSuccess: false,
      newContentTitle: null,
      newContentOne: null,
      newContentTwo: null,
      newContentThree: null,
      newContentFour: null,
      helperhide: false,
      SV: {
        EBID: "",
        EBTitle: "",
      },
    };
  },
  computed: {
    classObject: function () {
      return {
        EntwicklungsplanCard: this.types.isEP,
        lockscreen: this.types.isEP && !this.unlocked,
        EBCardselected: this.types.isEB && this.selected,
        ZielCard: this.types.isZC,
        EntwicklungsbereichCard: this.types.isEB,
        MaszCard: this.types.isEM,
      };
    },
    colorClass: function () {
      if (this.types.isEP == true || this.types.isEB == true)
        return this.getBgColor(this.cid);
      return "nichts";
    },
  },
  filters: {
    keepmain: function (value) {
      if (value) {
        return value.split("(")[0];
      }
    },
    showelse: function (value) {
      if (value) {
        var regExp = /\(([^)]+)\)/;
        var val = value;
        if (val.indexOf("(") >= 1) {
          var short = regExp.exec(val);
          return short[1];
        } else "";
      }
    },
  },
  watch: {
    newContentTitle: function () {
      if (this.newContentTitle === this.cardtitle) {
        this.Edited = false;
      } else {
        this.Edited = true;
        this.EditSuccess = false;
      }
    },
    newContentOne: function () {
      if (
        this.newContentOne === this.contentone ||
        this.newContentOne === "Eine kurze Beschreibung der Maßnahme"
      ) {
        this.Edited = false;
      } else {
        this.Edited = true;
        this.EditSuccess = false;
      }
    },
    newContentTwo: function () {
      if (
        this.newContentTwo === this.contentwo ||
        this.newContentTwo === "Welche Sozialform hat die Maßnahme?"
      ) {
        this.Edited = false;
      } else {
        this.Edited = true;
        this.EditSuccess = false;
      }
    },
    newContentThree: function () {
      if (
        this.newContentThree === this.contenthree ||
        this.newContentThree ===
          "Welche Voraussetzungen müssen erfüllt sein, welche Materialien müssen beschafft werden?"
      ) {
        this.Edited = false;
      } else {
        this.Edited = true;
        this.EditSuccess = false;
      }
    },
    newContentFour: function () {
      if (
        (this.newContentFour === this.contenfour) |
        (this.newContentFour === "Welchen Zeitumfang hat die Maßnahme")
      ) {
        this.Edited = false;
      } else {
        this.Edited = true;
        this.EditSuccess = false;
      }
    },
  },
  created() {
    this.$on("click", function (selectedT) {
      this.selected = true;
      this.aktivierte1 = true;
      this.selectedvalue = selectedT;
      this.SV.EBTitle = selectedT.title;
      this.SV.EBID = selectedT.id;
    });
    this.$on("clickinit", function (selectedT) {
      this.selected = true;
      this.aktivierte1 = true;
      this.selectedvalue = selectedT;
      this.SV.EBTitle = selectedT.title;
      this.SV.EBID = selectedT.id;
    });
    this.$on("unclick", function (selectedT) {
      this.selected = false;
      this.aktivierte1 = false;
      this.selectedvalue = selectedT;
    });
    this.$on("flipped", function () {
      this.helperhide = true;
    });
    this.$on("backflipped", function () {
      this.helperhide = false;
    });

    this.$on("update:syncContentTitle", function (value) {
      this.newContentTitle = value;
    });
    this.$on("update:syncContentOne", function (value) {
      this.newContentOne = value;
    });
    this.$on("update:syncContentTwo", function (value) {
      this.newContentTwo = value;
    });
    this.$on("update:syncContentThree", function (value) {
      this.newContentThree = value;
    });
    this.$on("update:syncContentFour", function (value) {
      this.newContentFour = value;
    });

    this.$on("updateSave", function () {
      // update goal
      if (this.types.isZC) {
        this.$store.commit("updateEntZiel", {
          goalId: this.cid,
          zyklusId: this.zid,
          Title:
            this.newContentTitle === null
              ? this.cardtitle
              : this.newContentTitle,
          IstStand:
            this.newContentOne === null ? this.contentone : this.newContentOne,
          Entwicklungsziel:
            this.newContentTwo === null ? this.contenttwo : this.newContentTwo,
        });
        // reset editable state in goal
        this.ZupdateSuccess();
      }

      // update maeasures
      if (this.types.isEM) {
        this.$store.commit("updateEntMki", {
          measureId: this.cid,
          zyklusId: this.zid,
          Title:
            this.newContentTitle === null
              ? this.cardtitle
              : this.newContentTitle,
          Kurzbeschreibung:
            this.newContentOne === null ? this.contentone : this.newContentOne,
          Sozialform:
            this.newContentTwo === null ? this.contenttwo : this.newContentTwo,
          Voraussetzung:
            this.newContentThree === null
              ? this.contentthree
              : this.newContentThree,
          Zeitumfang:
            this.newContentFour === null
              ? this.contentfour
              : this.newContentFour,
        });
        // reset editable state in goal
        this.ZupdateSuccess();
      }

      // // Offline Version Entwicklungsziele
      // if (typeof _spPageContextInfo === "undefined" && this.types.isZC) {
      // this.$store.commit('updateGoal', {
      //              title: this.newContentTitle,
      //              Entwicklungsressourcen: this.newContentOne,
      //              Entwicklungsziel: this.newContentTwo,
      //              id: this.cid
      //              })
      //              this.ZupdateSuccess()
      //  }
      // Offline Version Entwicklungsmaßnahmen
      // if (typeof _spPageContextInfo === "undefined" && this.types.isEM) {
      // this.$store.commit('updateMnmn', {
      //              title: this.newContentTitle,
      //              Kurzbeschreibung: this.newContentOne,
      //              Sozialform: this.newContentTwo,
      //              Voraussetzung: this.newContentThree,
      //              Zeitumfang: this.newContentFour,
      //              id: this.cid
      //              })
      //                this.ZupdateSuccess()
      // }

      // Live Version Entwicklungsziele
      // else if (typeof _spPageContextInfo != "undefined" && this.types.isZC) {
      //   console.log("is live and triggered")
      //   let url = "/_api/web/lists/getbyTitle('EntZiele')/items(" + this.cid +")"
      //   let title
      //   if (  this.newContentTitle === null ) {
      //     title = this.cardtitle
      //   }
      //   else {
      //      title = this.newContentTitle
      //   }
      //   let c1
      //    if (  this.newContentOne === null ) {
      //     c1 = this.contentone
      //   }
      //   else {
      //      c1 = this.newContentOne
      //   }
      //   let c2
      //    if (  this.newContentTwo === null ) {
      //     c2 = this.contenttwo
      //   }
      //   else {
      //      c2 = this.newContentTwo
      //   }
      //          let goal = {
      //            __metadata: { type: "SP.Data.EntZieleListItem" },
      //          Title: title,
      //          IstStand: c1,
      //          Entwicklungsziel: c2,
      //          RefZId: this.zid,
      //          }
      //          console.log(goal)
      //          let entwz = JSON.stringify(goal);
      //          console.log(entwz)
      //          ExMerge.post(url, entwz)
      //            .then(response => {
      //              console.log(response)
      //              // update pdf
      //              this.$root.$emit("updateZyklusPdf")
      //              this.ZupdateSuccess()
      //            })
      //            .catch(error => {
      //              console.log("Error while merging note: " +error)
      //            })
      //        }

      // Live Version Entwicklungsmaßnahmen
      //  else if (typeof _spPageContextInfo != "undefined" && this.types.isEM) {
      //   console.log("is live and triggered")
      // let url = "/_api/web/lists/getbyTitle('MKi')/items(" + this.cid +")"
      //   let title
      //   if (  this.newContentTitle === null ) {
      //     title = this.cardtitle
      //   }
      //   else {
      //      title = this.newContentTitle
      //   }
      //   let c1
      //    if (  this.newContentOne === null ) {
      //     c1 = this.contentone
      //   }
      //   else {
      //      c1 = this.newContentOne
      //   }
      //   let c2
      //    if (  this.newContentTwo === null ) {
      //     c2 = this.contenttwo
      //   }
      //   else {
      //      c2 = this.newContentTwo
      //   }
      //    let c3
      //    if (  this.newContentThree === null ) {
      //     c3 = this.contentthree
      //   }
      //   else {
      //      c3 = this.newContentThree
      //   }
      //       let c4
      //    if (  this.newContentFour === null ) {
      //     c4 = this.contentfour
      //   }
      //   else {
      //      c4 = this.newContentFour
      //   }
      //          let action = {
      //            __metadata: { type: "SP.Data.MKiListItem" },
      //          Title: title,
      //          Kurzbeschreibung: c1,
      //          Sozialform: c2,
      //          Voraussetzungen_x002f_Materialie: c3,
      //          Zeitumfang: c4,
      //          RefZId: this.zid,
      //          }
      //
      //          console.log(action)
      //          let act = JSON.stringify(action);
      //          console.log(act)
      //          ExMerge.post(url, act)
      //            .then(response => {
      //              console.log(response)
      //              // update pdf
      //              this.$root.$emit("updateZyklusPdf")
      //              this.ZupdateSuccess()
      //            })
      //            .catch(error => {
      //              console.log("Error while merging note: " +error)
      //            })
      //        }
    });
  },

  methods: {
    EPClick: function () {
      let id = this.$props.cid;
      let cc = this.colorClass;
      this.$parent.$parent.$parent.$parent.$emit("EPClick", { id, cc });
    },
    KtC: function () {
      // console.log(this.index)
      this.$parent.zielkaertchen.splice(this.index, 1);
    },
    showMethHelper: function () {
      this.isHelper = !this.isHelper;
    },
    ZupdateSuccess: function () {
      this.EditSuccess = true;
      this.Edited = false;
      let q = this.$el.querySelectorAll("[contenteditable = true]");
      q.forEach((q1) => q1.setAttribute("contenteditable", "false"));
    },
  },

  name: "CardComponent",
  components: {
    GeneralCardActionButtons,
    GeneralCardContent,
    GeneralCardHeader,
  },
};
</script>
<style>
.GeneralCard {
  float: left;
  position: relative;
  margin: 1%;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.7);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}
.EntwicklungsplanCard:hover,
.ZielCard:hover {
  box-shadow: 0 10px 20px 20px rgba(0, 0, 0, 0.19),
    0 6px 20px 0px rgba(0, 0, 0, 0.23);
  transition: box-shadow ease-in-out 0.4s,
    padding-bottom 1s cubic-bezier(0, 0, 0, 1.02);
}
.CardContentContainer {
  font-family: "aileronregular", sans-serif;
  height: auto;
  text-align: left;
  text-align: -webkit-left;
  text-align: -moz-left;
  width: -webkit-fill-available;
}
body {
  font-size: calc(
    16px + (22 - 16) * ((100vh - 300px) / (1900 - 300))
  ) !important;
  line-height: calc(1.3em + (1.5 - 1.2) * ((100vh - 300px) / (1600 - 300)));
  letter-spacing: 0.7px;
}
.ZielCard {
  width: calc((100% - 10vw) / 1);
  min-height: 25vh;
  max-height: 55vh;
  border-radius: 20px;
  background: rgba(255, 255, 255, 0 70);
  transition: box-shadow ease-in-out 0.4s,
    padding-bottom 1s cubic-bezier(0, 0, 0, 1.02);
}
.MaszCard {
  width: calc((100% - 7vw) / 1);
}
.EntwicklungsplanCard {
  width: calc(
    340px + (250 - 140) * ((100vw - 300px) / (1900 - 300))
  ) !important;
  border-radius: 2px;
  transition: box-shadow ease-in-out 0.4s,
    padding-bottom 1s cubic-bezier(0, 0, 0, 1.02);
  padding: 18px;
  flex-grow: 1;
  max-width: 40%;
}
@media only screen and (max-width: 1370px) {
  .EntwicklungsplanCard {
    width: calc( 350px + (300 - 300) * ((100vw - 300px) / (1900 - 300)) ) !important;
  }}
.EntwicklungsbereichCard {
  max-width: 100%;
  border-radius: 20px;
  transition: box-shadow ease-in-out 0.4s,
    padding-bottom 1s cubic-bezier(0, 0, 0, 1.02), filter 1.2s;
  filter: saturate(0.4);
}
@media only screen and (min-width: 1370px) {
  .EntwicklungsbereichCard {
    min-width: calc(
      300px + (250 - 140) * ((100vw - 300px) / (1900 - 300))
    ) !important;
  }
  .MaszCard {
    width: calc((100% - 2.5vw) / 2);
    margin: 1.5% 0 0 1.5%;
  }
  .ZielCard {
    width: calc((100% - 2.5vw) / 2);
    max-height: 45vh;
    margin: 1.5% 0 0 1.5%;
  }
}
.EntwicklungsbereichCard:hover {
  box-shadow: 0 10px 20px 20px rgba(0, 0, 0, 0.19),
    0 6px 20px 0px rgba(0, 0, 0, 0.23);
  transition: box-shadow ease-in-out 0.4s,
    padding-bottom 1s cubic-bezier(0, 0, 0, 1.02), filter 1.2s;
}
.EBCardselected {
  filter: saturate(1);
  transition: box-shadow ease-in-out 0.4s,
    padding-bottom 1s cubic-bezier(0, 0, 0, 1.02), filter 1.2s;
}
.CardContentContainer {
  /* -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d; */
  -webkit-perspective: 1000px;
  perspective: 1000px;
}
.CardContentContainer.DarkSideoftheMoon .vorderseite,
.CardContentContainer.DarkSideoftheMoon .rueckseite {
  -webkit-transition: -webkit-transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
  transition: -webkit-transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
  -o-transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
  transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
  transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1),
    -webkit-transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
}
.CardContentContainer.DarkSideoftheMoon .rueckseite {
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
  /* -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d; */
  -webkit-transform: translate3d(0, 0, 0);
  -webkit-perspective: 0;
  perspective: 0;
-webkit-backface-visibility: hidden;
-webkit-transform: translate3d(0,0,0);
visibility: visible;
backface-visibility: hidden;
position: absolute;
}

.CardContentContainer.DarkSideoftheMoon .vorderseite {
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
  /* -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d; */
}
.vorderseite,
.rueckseite {
  background-size: cover;
  background-position: center;
  -webkit-transition: -webkit-transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
  transition: -webkit-transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
  -o-transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
  transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
  transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1),
    -webkit-transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 0;
  perspective: 0;
-webkit-backface-visibility: hidden;
-webkit-transform: translate3d(0,0,0);
transform: translate3d(0,0,0);
visibility: visible;
backface-visibility: hidden;
}

.rueckseite:after {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  content: "";
  display: block;
  opacity: 0.6;
  background-color: rgb(255, 97, 97);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 0px;
}
.rueckseite {
  position: absolute;
  top: 0px;
  height: 100%;
  left: 0;
  width: 100%;
}
.rueckseite > .innenseite {
  top: 10%;
  position: absolute;
  left: 0;
  width: 100%;
  padding: 2rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  outline: 1px solid rgb(0 0 0 / 0%);
  -webkit-perspective: inherit;
  perspective: inherit;
  z-index: 2;
  height: 100%;
  text-align: left;
  text-align: -webkit-left;
  text-align: -moz-left;
}
.CardContentContainer .rueckseite {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
  /* -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d; */
}

.CardContentContainer .vorderseite {
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
  /* -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d; */
  padding-top: 5%;
  padding-bottom: 5%;
  /* padding-left: 5%; */
  -webkit-transform: translate3d(0, 0, 0);
  -webkit-perspective: 0;
  perspective: 0;
-webkit-backface-visibility: hidden;
-webkit-transform: translate3d(0,0,0);
visibility: visible;
backface-visibility: hidden;
}
.rueckseite .innenseite h3 {
  color: rgb(0 0 0 / 70%);
  font-family: "aileronsemibold";
  font-weight: 300;
  position: relative;
  right: 50%;
  -webkit-transform: translate(50%, -50%);
  transform: translate(50%, -50%);
  padding: 50px;
  font-size: calc(18px + 10 * (100vh - 300px) / 1600) !important;
  top: 20%;
  width: fit-content;
  text-align: center;
  text-align: -webkit-center;
  text-align: -moz-center;
  /* min-height: calc(35vh - 210px); */
  /* transform: translateY(-50%) translateX(50%) translateZ(100px) scale(0.94); */
  /* top: 35%; */
  /* position: absolute; */
  /* left: 5%; */
}

.rueckseite .innenseite .CardActionButtonContainer {
  top: 70%;
}
.DescriptionHeading {
  font-size: calc(
    20px + (34 - 20) * ((100vh - 300px) / (1900 - 300))
  ) !important;
  line-height: calc(1.3em + (1.5 - 1.2) * ((100vh - 300px) / (1600 - 300)));
  width: 100%;
  text-align: start !important;
  font-weight: 700;
  color: rgb(36 50 64);
  font-family: "aileronheavy", sans-serif, sans-serif;
  margin-bottom: 12px;
  display: block;
}
.metheadline {
  writing-mode: vertical-lr;
  transform: rotate(-180deg);
  font-size: calc(
    16px + (22 - 16) * ((100vh - 300px) / (1900 - 300))
  ) !important;
  cursor: pointer;
}

.methodenslider {
  position: absolute;
  transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1);
  width: 30px;
  background-color: rgba(255, 227, 126, 0.7);
  box-shadow: 4px 10px 14px 0px #000000;
  height: 100%;
  padding: 18px 0 0 18px;
  left: 0;
  z-index: 99;
  top: 0;
}
.methodenslider {
  background: rgba(255, 227, 126, 0.7);
}

.methodenvs {
  padding-left: 3em !important;
  padding-top: 1.5em !important;
}

.helperslider {
  width: 30%;
  position: absolute;
  z-index: 3;
  width: calc(100% / 2) !important;
  transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1);
  /* background: rgb(255 255 255 / 73%); */
  background-color: rgba(255, 227, 126, 0.7);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  overflow-y: auto;
  height: calc(100% - 56px);
  padding: 28px;
}

@supports not (backdrop-filter: blur(10px)) {
  .helperslider {
    background-color: rgba(255, 227, 126, 0.99);
  }
}

.lastmethblock {
  margin-bottom: 40px;
}

.closehelper {
  position: absolute;
  top: 14px;
  right: 14px;
  z-index: 9;
  cursor: pointer;
}

.save-maßnahmen-card {
  margin-left: 10px;
}
.zinnen {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 0.4fr 2.1fr 0.5fr;
  gap: 0px 0px;
  grid-template-areas: "CardHeader" "CardContentBlock" "CardActionButtonContainer";
  max-height: 45vh;
}
.ZCABC, .MNC {
     transition: opacity 0.3s cubic-bezier(0.4, 0.2, 0.2, 1) 0.2s, -webkit-opacitiy 0.3s cubic-bezier(0.4, 0.2, 0.2, 1) 0.2s;

}
.DarkSideoftheMoon > .vorderseite .ZCABC {
  opacity: 0;
}
.DarkSideoftheMoon > .rueckseite .ZCABC {
  opacity: 1;
}
.rueckseite .ZCABC {
  opacity: 0;
}
.DarkSideoftheMoon > .vorderseite .MNC {
  opacity: 0;
}
.DarkSideoftheMoon > .rueckseite .MNC {
  opacity: 1;
}
.rueckseite .MNC {
  opacity: 0;
}
.DarkSideoftheMoon > .rueckseite .CardActionButtonContainer {
  width: 100%;
}
.lockscreen {
  pointer-events: none;
  filter: grayscale(0.5);
}
.locksvg {
  position: absolute;
  height: 70%;
  filter: drop-shadow(0px 11px 3px grey);
  mix-blend-mode: soft-light;
}
.bg-blue {
  background-image: linear-gradient(
    120deg,
    rgb(137 247 254 / 40%),
    rgb(102 166 255 / 40%)
  );
}
.bg-cyan {
  background-image: linear-gradient(
    90deg,
    rgb(79 172 254 / 40%) 0,
    rgb(0 242 254 / 40%)
  );
}
.bg-indigo {
  background-image: linear-gradient(
    90deg,
    rgb(10 207 254 / 40%) 0,
    rgb(73 90 255 / 40%)
  );
}

.bg-purple {
  background-image: linear-gradient(
    0deg,
    rgb(205 156 242 / 40%) 0,
    rgb(246 243 255 / 40%)
  );
}

.bg-pink {
  background-image: linear-gradient(
    0deg,
    rgb(247 112 98 / 40%) 0,
    rgb(254 81 150 / 40%)
  );
}

.bg-red {
  background-image: linear-gradient(
    -225deg,
    rgb(255 226 159 / 40%),
    rgb(255 169 159 / 40%) 48%,
    rgb(255 113 154 / 40%)
  );
}

.bg-orange {
  background-image: linear-gradient(
    -60deg,
    rgb(255 88 88 / 40%),
    rgb(240 152 25 / 40%)
  );
}

.bg-yellow {
  background-image: linear-gradient(
    0deg,
    rgb(230 185 128 / 40%) 0,
    rgb(234 205 163 / 40%)
  );
}

.bg-green {
  background-image: linear-gradient(
    15deg,
    rgb(19 84 122 / 40%),
    rgb(128 208 199 / 40%)
  );
}

.bg-blue {
  background-image: linear-gradient(
    120deg,
    rgb(137 247 254 / 40%),
    rgb(102 166 255 / 40%)
  );
}
.bg-cyan {
  background-image: linear-gradient(
    90deg,
    rgb(79 172 254 / 40%) 0,
    rgb(0 242 254 / 40%)
  );
}
.bg-indigo {
  background-image: linear-gradient(
    90deg,
    rgb(10 207 254 / 40%) 0,
    rgb(73 90 255 / 40%)
  );
}

.bg-purple {
  background-image: linear-gradient(
    0deg,
    rgb(205 156 242 / 40%) 0,
    rgb(246 243 255 / 40%)
  );
}

.bg-pink {
  background-image: linear-gradient(
    0deg,
    rgb(247 112 98 / 40%) 0,
    rgb(254 81 150 / 40%)
  );
}

.bg-red {
  background-image: linear-gradient(
    -225deg,
    rgb(255 226 159 / 40%),
    rgb(255 169 159 / 40%) 48%,
    rgb(255 113 154 / 40%)
  );
}

.bg-orange {
  background-image: linear-gradient(
    -60deg,
    rgb(255 88 88 / 40%),
    rgb(240 152 25 / 40%)
  );
}

.bg-yellow {
  background-image: linear-gradient(
    0deg,
    rgb(230 185 128 / 40%) 0,
    rgb(234 205 163 / 40%)
  );
}

.bg-green {
  background-image: linear-gradient(
    15deg,
    rgb(19 84 122 / 40%),
    rgb(128 208 199 / 40%)
  );
}
.bg-peach {
  background-image: linear-gradient(
    0deg,
    rgb(254 173 166 / 40%) 0%,
    rgb(245 239 239 /40%) 100%
  );
}
.bg-misty {
  background-image: linear-gradient(
    60deg,
    rgba(171, 236, 214, 0.4) 0%,
    rgba(251, 237, 150, 0.4) 100%
  );
}
.bg-iron {
  background-image: linear-gradient(
    to right,
    rgba(215, 210, 204, 0.4) 0%,
    rgba(48, 67, 82, 0.4) 100%
  );
}
.bg-sunset {
  background-image: linear-gradient(
    109.6deg,
    rgb(247 202 201 / 81%) 20.6%,
    rgb(146 168 209 / 61%) 85.9%
  );
}
svg {
 position: absolute;
}
</style>
