<template>
    <div>
        <div class="formContainer">
        <div class="formContainerlinks">
        <h2 style="margin-bottom: 24px">Entwicklungsziele</h2>
        <span class="divider"></span>
         <CardCollection
         :zid="this.zid"
          :types="{isZC: true}"
          

  />

         </div>
        </div>
    </div>
</template>
<script>

import CardCollection from '../../../components/layout/Cards/CardCollection'
export default {
    name: 'ZyklusZiele',
    props: {
        zid: {types: Number},
        },
    components: { CardCollection},
}
</script>
<style scoped>
.divider {
    margin-top: 0px;
}
.formContainer {
    width: 100%;
}
.formContainerlinks {
    width: 100%;
}
#EZ {
    display: flex
}
</style>
