<style>
body.modal-open {
  overflow: hidden;
}
#s4-workspace.modal-open {
  overflow: hidden;
}

.content-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(385px, 1fr));
    grid-gap: 20px;
    padding: 30px 55px 60px 55px;
}

#kopfkoerper.collapsed {
    width: calc(100vw - 50px - 100vw + 100%);
    margin-left: 50px;
   background: rgba(255, 255, 255, 0.1)
}
.verantwortliche-container {
  display: grid;
  grid-template-rows: min-content auto;
  grid-template-areas:
  "header"
  "preview";
  padding: 8px 16px 16px 16px;
  text-align: left;
     text-align: -webkit-left;
   text-align: -moz-left;
  background-color: rgba(255,255,255,0.0);
}
.verantwortliche-header {
  grid-area: header;
  justify-self: start;
  margin: 0 1.5% 0 1.5%;

}
.verantwortliche-header-title {
  margin: 0;
  padding: 0;
}

.verantwortliche-preview-container {
  grid-area: preview;
}
.verantwortliche-header-bar {
  position: relative;
  top: 25px;
  right: 0px;
  max-width: 100%;
}
.verantwortliche-header-bar * .verantwortliche-preview-container {
  width: 100%;
}
hr {
  color: lightgray
}

.note-top-container {
    padding: 30px 55px 60px 55px;
}
.filter-container {
  position: relative;
height: 100%;
z-index: 2;
background-image: linear-gradient(180deg, transparent 2%, hsla(0,0%,100%,.4) 3%);
}
.filter-container::before {
content: "";
position: absolute;
height: calc(100% - 220px);
width: calc(100% - 66px);
background: hsla(0,0%,100%,.2);
left: 0;
opacity: 0.8;
margin-top: 220px;
z-index: -4;
top: 0;
margin-left: 33px;
  margin-right: 35px;
}
.filter-container::after {
content: "";
position: absolute;
height: calc(100% - 21px);
width: 100%;
left: 0;
opacity: 0.6;
margin: 82px;
margin-top: 271px;
z-index: -1;
top: 0;
margin-left: 0px;
margin-right: 0px;

}
</style>

<template>
  <div>
    <FormLockscreen v-if="!hasDatenschutz" @agree="agreeLockscreen" @delete="deleteStudent"/>
      <ilmenau v-if="showTest" />
      <TestResult v-if="showResult" />
      <div class="filter-container">
    <ProfilZyklen v-if="sid" :sid="sid" :componentLoaded="componentLoaded" @updateZyklusId="updateZyklusId" :colorClass="colorClass" :routeNow="routeNow" :zyklen="hasDatenschutz ? zyklen : null"> </ProfilZyklen>
      <div class="content-container">
          <ProfilDokumente :showDate="true" />
          <ProfilVerantwortliche :refZid="refZid" :sid="sid"  :verantwortliche="hasDatenschutz ? verantwortliche : null" />
          <ProfilAktivitaeten />
          <ProfilDiagnostik :id="sid" v-if="diagnostik === true"/>
      </div>
      <div class="note-top-container">
        <ProfilNotizen :studentId="sid" :refZid="refZid" :zyklen="hasDatenschutz ? zyklen : null"/>
      </div>
    </div>
  </div>
</template>

<script>
import ProfilZyklen from './ProfilZyklen'
import ProfilNotizen from './ProfilNotizen'
import ProfilDokumente from './ProfilDokumente'
import ProfilVerantwortliche from './ProfilVerantwortliche'
import ProfilAktivitaeten from './ProfilAktivitaeten'
import ProfilDiagnostik from './ProfilDiagnostik.vue'
import FormLockscreen from '../Forms/FormLockscreen'
import Ilmenau from '../IndiForms/Ilmenau.vue'
import TestResult from '../IndiForms/TestResult.vue'
// import ResponsiblePersonDropdown from "../Reusable/ResponsiblePersonDropdown.vue";


export default {
    name: 'ProfilAnsicht',
    props: ["sid", "colorClass", "set", "zid", 'routeNow', "componentLoaded"],
    components: {
      ProfilZyklen,
      ProfilNotizen,
      ProfilDokumente,
      ProfilVerantwortliche,
      ProfilAktivitaeten,
      ProfilDiagnostik,
      FormLockscreen,
      Ilmenau,
      TestResult
      //  ResponsiblePersonDropdown,
    },
    data() {
      return {
        refZid: null,
        showTest: false,
        showResult: false,
        
      }
    },
    methods: {
      updateZyklusId(id) {
  console.log(id);
  if (id || id === null) {
    this.$store.commit('setZid', id);
    this.refZid = id;
  }
},

      agreeLockscreen() {
        this.$store.dispatch("agreeDatenschutz", this.sid)
      },
      deleteStudent() {
        // delete entpl from user
        this.$store.dispatch("deleteUser", this.sid)
        // delete user in svz
        if (this.refsId) this.$store.dispatch("deleteUserSVz", this.refsId)
        this.$router.push({name: 'UebersichtPlaene'})
      }
    },
    created() {
    this.$on("opentest", function() {
      this.showTest = true
        document.body.classList.add("modal-open")
        if (typeof _spPageContextInfo !== "undefined") {
        document.getElementById("s4-workspace").classList.add("modal-open")
        }
        }),
         this.$on("closetest", function() {
      this.showTest = false
        document.body.classList.remove("modal-open")
          if (typeof _spPageContextInfo !== "undefined") {
        document.getElementById("s4-workspace").classList.remove("modal-open")
        }
           this.$store.commit("diagnostikErgebnisseById" , this.sid)
        }),
      this.$on("showresult", function() {
      this.showResult = true
        document.body.classList.add("modal-open")
        if (typeof _spPageContextInfo !== "undefined") {
        document.getElementById("s4-workspace").classList.add("modal-open")
        }
        }),
         this.$on("closeresult", function() {
      this.showResult = false
         document.body.classList.remove("modal-open")
         if (typeof _spPageContextInfo !== "undefined") {
        document.getElementById("s4-workspace").classList.remove("modal-open")
        }
          this.$store.commit("showResult", null)
    })},
    computed: {
      zyklen() {
  if(typeof this.sid === 'number' && this.$store.getters.entPlbyId(this.sid) !== null) {
    return this.$store.getters.zyklenStudent(this.sid)
  }
  else return null
},
      hasDatenschutz() {
        // init loading chain if entpl not already loaded
        let plan = this.$store.getters.entPlbyId(this.sid)
        if(plan) return plan.DSchutzE
        // do not show lockscreen until plan is loaded
        else return true
      },
      verantwortliche() {
        if(this.zyklen === null || this.zyklen.length == 0) return null
        // if zyklus is selected: return selected author title; else: return all titles
        let verantwortliche = []
        for(let zyklus of this.zyklen) {
          let name = zyklus.Author.Title
          if(this.refZid && zyklus.Id === this.refZid) {
            verantwortliche.push(name)
          } else if(this.refZid === null) {
            if(verantwortliche.findIndex(element => element === name))
            verantwortliche.push(name)
          }
        }
        return verantwortliche
      },
      diagnostik() {
      if (this.$store.getters.diagnostik_test && this.$store.getters.diagnostik_test.length >= 1)
      return true
      else return false
      },
      refsId() {
        let plan = this.$store.getters.entPlbyId(this.sid)
        if(plan) return plan.RefSId
        return null
      }
    },
    mounted () {
  if(typeof this.sid === 'number' && this.$store.getters.entPlbyId(this.sid) !== null) {
    this.$store.getters.zyklen(this.sid)
  }
}

}

</script>
