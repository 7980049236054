// dataUtils.js
import store from "../store";

export async function changeEntPl(val) {
    try {
        if (!val) return "";

        let colleagues = await waitForColleagues();  // Warten auf Kolleg:innendaten
        if (!colleagues || !Array.isArray(colleagues) || colleagues.length === 0) {
            console.warn('Colleagues data not available.');
            return;
        }

        const processObj = (obj) => {
        //    console.log("Initial object:", JSON.stringify(obj));

            let newObj = JSON.parse(JSON.stringify(obj));

            if (newObj.Author && newObj.Author.Id) {
                let id = parseInt(newObj.Author.Id);
                let metadata = { "__metadata": { "type": "Collection(Edm.Int32)" } };
                newObj.PiC = newObj.PiC || { ...metadata, "results": [{ "Title": newObj.Author.Title, "Id": id }] };
                newObj.PiCId = newObj.PiCId || { ...metadata, "results": [id] };
            } else {
                console.warn('Author or Author.Id is missing.');
            }

            if (newObj.PiC && newObj.PiC.results && newObj.PiCId && newObj.PiCId.results) {
                if (newObj.PiC.results.length !== newObj.PiCId.results.length) {
                    console.warn('Inkonsistenz zwischen PiC und PiCId:', newObj.PiC.results, newObj.PiCId.results);
                }
            } else {
                console.warn('PiC, PiC.results, PiCId, or PiCId.results is missing.');
            }

            if (!newObj.Verantwortlich && newObj.PiC && newObj.PiC.results) {
                newObj.Verantwortlich = newObj.PiC.results.map(t => t.Title).toString();
                console.log("Verantwortlich:", newObj.Verantwortlich);
            }

            let PiCIds = newObj.PiCId ? newObj.PiCId.results : [];
            let PiCfull = colleagues.filter((i) => PiCIds.includes(i.Id));
         //   console.log("PiCfull:", PiCfull);

            if (PiCIds.length !== PiCfull.length) {
                console.warn('Nicht alle Verantwortlichen gefunden:', PiCIds, PiCfull);
            }

            if (PiCfull.length > 0) {
                newObj.PiC.results = PiCfull.map(i => ({ "Id": i.Id, "Title": i.Title }));
                newObj.Verantwortlich = PiCfull.map(t => t.Title).toString();
            }

            // EP2 Setting
            if (store.getters.adminsettings && store.getters.adminsettings.includes("EP2")) {
                let GA = store.getters.groupsAdmin;
                let OP = newObj.PiC ? newObj.PiC.results : [];
                newObj.isAdminImport = OP.length === 1 && OP.some(r => GA.includes(r.Id));
            }

            // EP1 Setting
            let lockstatus = false;  // Standardwert auf 'false' setzen (gesperrt)

            // Überprüfen, ob der aktuelle Benutzer in der PiC-Liste ist
            if (newObj.PiC && newObj.PiC.results.some(r => r.Id === store.getters.userId)) {
                lockstatus = true;  // Entsperren, wenn der Benutzer in der PiC-Liste ist
            }
            
            // Überprüfen, ob EP1 in den adminsettings ist
            if (store.getters.adminsettings && store.getters.adminsettings.includes("EP1")) {
                lockstatus = true;  // Entsperren, wenn EP1 im Array ist
            }
            
            newObj.unlocked = lockstatus;  // 'unlocked' auf den Wert von 'lockstatus' setzen
            



         //   console.log("Final object:", JSON.stringify(newObj));
            return newObj;
        };

        if (Array.isArray(val)) {
            return val.map(processObj);  // Ein neues Array mit veränderten Objekten zurückgeben
        } else {
            return processObj(val);  // Ein verändertes Objekt zurückgeben
        }
    } catch (error) {
        console.error('Ein Fehler ist aufgetreten:', error);
        throw error;
    }
}

export const waitForColleagues = () => {
    return new Promise((resolve, reject) => {
        let timeoutId = setTimeout(() => {
            reject(new Error('Timed out waiting for colleagues data.'));
        }, 5000); // Timeout nach 5000 Millisekunden (5 Sekunden)

        let checkData = () => {
            let colleagues = store.getters.userColleaguesFull || store.getters.colleagues;
            if (colleagues) {
                clearTimeout(timeoutId); // Löschen des Timeouts, wenn die Daten verfügbar sind
                resolve(colleagues);
            } else {
                setTimeout(checkData, 100); // Überprüfen Sie die Daten alle 100 Millise
            }
        }
        checkData();
    })
}