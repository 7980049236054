<template>
    <div id="EM">
        <div class="formContainer">
        <div class="formContainerlinks">
        <div class="headline-container">
            <h2 >Entwicklungsmaßnahmen</h2>
            <helper-icon-callout
                :Title="'Info'"
                :Main="'Legen Sie mindestens 1 Entwicklungsmaßnahme an.'"
            ></helper-icon-callout>
        </div>
        <span class="divider"></span>
        <div class="ActionsContainer">
        
         <ZyklusActionsMiniDashboad :showActions="false"></ZyklusActionsMiniDashboad>
        </div>

         </div>
        </div>
    
    </div>
</template>
<script>
import ZyklusActionsMiniDashboad from './ZyklusActionsMiniDashboad.vue';
export default {
    name: 'ZyklusMaßnahmen',
    props: {
        zid: Number,
        },
        methods: {
            createNewAction() {
      this.$router.push({ name: `NewAction`, params: { type: 'personalisierte Maßnahme' } });
    },
        },
    components: {  ZyklusActionsMiniDashboad},
}
</script>
<style scoped>
.divider {
    margin-top: 0px;
}
.formContainer {
    width: 100%;
}
.formContainerlinks {
    width: 100%;
}
.ActionsContainer {
    position: relative;
  min-height: 50vh
}
.headline-container {
    display: flex;
    align-items: center;
    display: grid;

  grid-auto-flow: column;

  place-content: flex-start;

  margin-bottom: 24px;
}
</style>
