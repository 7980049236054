<style>
html {
  background: white;
  height: 100rem;
  width: 100%;
  box-sizing: border-box;
  margin: 0;
}
.svg-container,
#blur {
  position: fixed;
  z-index: -999;
}

body {
  margin: 0;
  overflow-y: auto;
  /* overflow: hidden; */
}

#hintergrund {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  /* background-image: url('../assets/bgg2comp.jpg'); */

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  width: calc(100vw - (100vw - 100%));
  position: fixed;
  height: 100vh;
  opacity: 1;
}

.dashboard-link-container {
  cursor: pointer;
  background-color: white;
  display: grid;
  grid-template-columns: min-content auto;
  justify-content: start;
  align-items: center;
}
.dashboard-link-text {
  width: 200px;
  font-size: calc(14.875px + .375vh) !important;
}
.hidden-text {
  visibility: hidden;
}
.PEP {
  width: 50px;
  height: 50px;
  background: white;
}

#exclusionoverlay {
  content: "";
  width: 100vw;
  height: 100vh;
  top: 0;
  position: absolute;
  /* opacity: .4; */
  background: rgb(255, 255, 255);
  z-index: 1;
  left: 0;
  opacity: 0.2;
  mix-blend-mode: exclusion;
}

#tintoverlay {
  content: "";
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 3;
  /* background: linear-gradient(to bottom, rgba(255, 251, 213, .4), rgba(178, 10, 44, .4)); */
  top: 0;
  /* opacity: .6; */
  left: 0;
  /* filter: saturate(.2); */
  opacity: 0.7;
  mix-blend-mode: normal;
  background: #ffffffa3;
}
#colorhighlight {
  content: "";
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 4;
  filter: saturate(0.8);
  opacity: 0.4;
  mix-blend-mode: lighten;
}
.collapsed {
  width: 300px;
  transition: 0.3s width ease;
}

#menucont .vsm--list {
  position: absolute;
  bottom: 0px;
  width: 50px;
  font-family: "Aileronregular", sans-serif;
}

#menucont .v-sidebar-menu {
  background-color: rgb(42 42 46 / 90%);
  overflow: hidden;
}

#menucont > div > div > div.vsm--list > a > span {
  color: rgb(255 255 255);
}

#menucont .vsm--header,
#menucont .vsm--title:not(a.persongroup > span.vsm--title),
#menucont .vsm--icon:not(a.persongroup > span.vsm--icon) {
  background-color: rgba(255, 255, 255, 0) !important;
  color: rgb(255, 255, 255) !important;
}

#menucont .vsm--title,
#menucont .vsm--icon {
  background-color: rgba(255, 255, 255, 0) !important;
  color: rgb(0, 0, 0) !important;
}
#menucont .vsm--title {
  white-space: nowrap;
  font-size: calc(15.25px + 0.25vh) !important;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased !important;
  -moz-font-smoothing: antialiased !important;
  text-rendering: optimizelegibility !important;
  letter-spacing: 0.03em;
}
#menucont .lastperson {
  margin-bottom: 32px;
}
#menucont {
  z-index: 99 !important;
}

#menucont .vsm--link:hover {
  width: 350px;
  background-color: rgb(255 255 255 / 40%) !important;
}

#menucont .v-sidebar-menu.vsm_expanded::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  z-index: -1;
}
.blur-svg {
  display: none;
}
@supports not (backdrop-filter: blur(10px)) {
  .blur-svg {
  display: block;
  }
  #menucont .v-sidebar-menu.vsm_expanded::after {
    filter: url(#blur);
  }
}
.rotate-icon {
  transform: rotate(-90deg);
}
</style>
<style scoped>
::v-deep a.persongroup > span.vsm--title,
::v-deep a.persongroup > span.vsm--icon {
  color: black !important;
  opacity: 1;
}
::v-deep a.persongroup > span.vsm--title {
  white-space: break-spaces !important;
  word-break: normal;
}
::v-deep .persongroup {
  width: 350px;
  background: ghostwhite;
  opacity: 1;
  border-bottom: 1px solid black;
}
::v-deep .orga,
::v-deep .pwchange {
  background: rgba(248, 248, 255, 0.8);
  box-shadow: inset 0px 1px 5px 0px #0000009c,
    inset 5px -2px 10px 3px rgb(248 248 255 / 30%),
    inset -8px -3px 20px 0px rgb(248 248 255 / 30%);
}
::v-deep .orga > span:first-child,
::v-deep .pwchange > span:first-child {
  margin-left: 10px;
}
::v-deep #menucont .vsm--title {
  text-align: left;
}
::v-deep .orga:hover,
::v-deep .pwchange:hover {
  background-color: rgb(255 255 255 / 70%) !important;
}
::v-deep .vsm--link_active.apptab {
  width: 350px;
  background: linear-gradient(90deg, #4285f4, 10%, transparent);
  opacity: 1;
}
::v-deep .vsm--link_active.apptab span {
  font-weight: 400;
}
::v-deep #menucont .v-sidebar-menu .vsm--link_disabled {
  opacity: 1 !important;
}
::v-deep .vsm--toggle-btn {
  display: none !important;
}
::v-deep .tglbtn {
  color: #fff;
  background-color: #1e1e21;
  text-align: center;
  text-align: -webkit-center;
  text-align: -moz-center;
  font-style: normal;
  font-weight: 900;
  height: 50px;
  cursor: pointer;
  border: none;
  width: 349px;
}
::v-deep .vsm_collapsed * hr {
  opacity: 0;
}
</style>
<template>
  <div>
    <div
      id="hintergrund"
      :style="{ backgroundImage: 'url(' + BG2 + ')', width: conditionalWidth }"
      v-shared-element:hallo
    >
      <div id="exclusionoverlay"></div>
      <div id="tintoverlay"></div>
      <div
        id="colorhighlight"
        :style="{
          backgroundImage:
            $route.name === 'UebersichtPlaene'
              ? 'linear-gradient(    15deg,    rgb(255 255 255 / 80%),    rgb(255 255 255 / 82%)  )'
              : '',
        }"
      ></div>
<div class="svg-container">
      <svg xmlns="http://www.w3.org/2000/svg" version="1.1" class="blur-svg" style="position: fixed; z-index: -999;" >
        <defs>
          <filter id="blur" width="130%" height="140%">
            <feGaussianBlur stdDeviation="8"></feGaussianBlur>
          </filter>
          <filter id="superblur" width="130%" height="140%">
            <feGaussianBlur stdDeviation="20"></feGaussianBlur>
          </filter>
        </defs>
      </svg>
    </div>
    </div>
    <div id="menucont" v-shared-element:structure>
      <sidebar-menu
        :menu="menu"
        :collapsed="collapsed"
        :disableHover="true"
        to="href"
        @toggle-collapse="onToggleCollapse"
        @item-click="onItemClick"
        v-if="!$route.meta.hideMenu"
        v-click-outside="onClickOutside"
      >
        <!-- link to dashboard only shown for meinPEP user -->
        <div
          v-if="isTeamMember"
          slot="header"
          class="dashboard-link-container"
          @click.stop.prevent="gotoDashboard"
        >
          <img class="PEP" :src="PEP" />
          <div
            class="dashboard-link-text"
            :class="{ 'hidden-text': this.collapsed }"
          >
            interner Bereich
          </div>
        </div>
      </sidebar-menu>
    </div>

    <xmodal v-model="isModalOpen" :params="modaloptions" />
    <LogoutDialog v-if="showModal" @cancel="cancelModal" @leave="leavePEP" />
    <xmodal v-model="PWModal" :params="PWmoodaloptions" />
  </div>
</template>

<script>
/*global _spPageContextInfo*/
/*eslint no-undef: "error"*/
import LogoutDialog from "./layout/Forms/LogoutDialog";
import { entgendern } from "../variables.js";
import vClickOutside from "v-click-outside";

const separator = {
  render(h) {
    return h("hr", {
      style: {
        borderColor: "rgba(255, 255, 255, 0.4)",
        margin: "00px",
        width: "350px",
      },
      class: "sidebar-seperator",
    });
  },
};

export default {
  name: "Aussehen",
  data() {
    return {
      isModalOpen: false,
      modaloptions: {
        component: require("../components/layout/Forms/FormFeedback").default,
        backgroundColor: " #4444448a",
        animation: "scaleIn",
      },
      PWmoodaloptions: {
        component: require("../components/layout/Forms/PWChange").default,
        backgroundColor: " #4444448a",
        animation: "scaleIn",
      },
      PWModal: false,
      BG2: require(`../assets/bgg2compblur.jpg`),
      PEP: require(`../assets/icons/logo_symbol.svg`),
      menu: [
        {
          header: true,
          value: this.UserId,
          title: `${entgendern.user} Info`,
          hiddenOnCollapse: true,
          class: "persongroup",
        },
        {
          title: "",
          class: "persongroup",
          hiddenOnCollapse: true,
          disabled: true,
          icon: {
            element: "span",
            class: "material-icons",
            text: "account_circle",
          },
        },
        {
          title: "Passwort ändern",
          class: "persongroup pwchange",
          hiddenOnCollapse: true,
          disabled: false,
          icon: {
            element: "span",
            class: "material-icons",
            text: "security",
          },
        },
        {
          title: "",
          class: "persongroup ",
          hiddenOnCollapse: true,
          disabled: true,
          icon: {
            element: "span",
            class: "material-icons",
            text: "domain",
          },
        },
        {
          title: "Schulverwaltung",
          hidden: true,
          hiddenOnCollapse: true,
          href: {
            path: "/Schulverwaltung",
            params: { groups: this.UserGroups },
          },
          class: "persongroup lastperson orga",
          icon: {
            element: "span",
            class: "material-icons",
            text: "dashboard_customize",
          },
        },
        {
          component: separator,
        },
        {
          header: true,
          title: "Navigation",
          hiddenOnCollapse: true,
        },
        {
          href: "/Startseite",
          title: "Startseite",
          class: "apptab",
          icon: {
            element: "span",
            class: "material-icons",
            text: "home",
          },
        },
        {
          href: "/ActionsDashboard",
          title: "Maßnahmenkatalog",
          class: "apptab",
          icon: {
            element: "span",
            class: "material-icons rotate-icon",
            text: "line_style",
          },
        },
        {
          href: "/EP",
          title: "Entwicklungspläne",
          class: "apptab",
          icon: {
            element: "span",
            class: "material-icons",
            // attributes: {}
            text: "list",
          },
        },
        {
          href: {
            name: "Profil",
          },
          title: "Person",
          class: "apptab",
          hidden: false,
          icon: {
            element: "span",
            class: "material-icons",
            // attributes: {}
            text: "account_box",
          },
        },
        {
          header: "true",
          title: " ",
        },
        {
          component: separator,
        },
        {
          title: "Feedback",
          icon: {
            element: "span",
            class: "material-icons",
            text: "feedback",
          },
        },
        {
          title: "Ausloggen",
          icon: {
            element: "span",
            class: "material-icons logout",
            text: "power_settings_new",
          },
        },
        {
          title: "Einklappen",
          class: "tglbtn",
          icon: {
            element: "span",
            class: "material-icons",
            text: "settings",
          },
        },
      ],
      collapsed: true,
      showModal: false,
    };
  },

  directives: {
    clickOutside: vClickOutside.directive,
  },
  watch: {
    "$route.meta.hidePerson": function () {
    this.ProfileRoute()
      if (this.$route.meta.hidePerson == true) {
        this.menu[10].hidden = true;
      } else {
        this.menu[10].hidden = false;
      }
    },
    "$route.params": function () {
    this.ProfileRoute();
    if (this.$route.params.sid) {
      this.menu[10].title = this.$store.getters.studentNameById(
        this.$route.params.sid
      );
    } else this.menu[10].title = "";
  },
    // UserId() {
    //   this.menu[2].title = this.UserId
    // },
    UserGroups() {
      this.menu[3].title = this.UserGroups;
    },
    GroupAdministration() {
      if (this.GroupAdministration === false) {
        this.menu[4].hidden = true;
      } else {
        this.menu[4].hidden = false;
      }
    },
    UserName() {
      this.menu[1].title = this.UserName;
    },
    Person() {
      this.menu[10].title = this.Person;
    },
    $route(to, from) {
      //  console.log(to + from)
      if (to.path !== from.path) this.collapsed = true;
      if (this.$store.getters.entPlQuery != "") {
        this.$store.commit("setEntPlQuery", "");
      }
    },
  },
  computed: {
    UserGroups: function () {
      if (this.$store.getters.api === "pepApi") {
        let myself = this.$store.getters.myself;
        if (myself && myself.Group) return myself.Group;
        return "";
      }

      let groups = this.$store.getters.groups;
      if (groups && !Array.isArray(groups)) {
        //  return groups.userGroups.toString()
        return groups.userGroups.join(", ");
      } else return null;
    },
    UserName() {
      if (this.$store.getters.api === "pepApi") {
        // wait until login before fetching data to avoid errors
        if (this.$store.getters.token) return this.$store.getters.userTitle;
        else return "";
      }
      return this.$store.getters.userTitle;
    },
    UserId() {
      if (this.$store.getters.api === "pepApi") {
        // wait until login before fetching data to avoid errors
        if (this.$store.getters.token) return this.$store.getters.userId;
        else return "";
      }
      return this.$store.getters.userId;
    },
    isTeamMember() {
      if (this.$store.getters.api === "pepApi") {
        let colGroup = this.$store.getters.colGroup;
        if (colGroup && colGroup.Title === "TeamPEP") return true;
        return false;
      }

      if (this.UserGroups && this.UserGroups.search("TeamPEP") !== -1)
        return true;
      else return false;
    },
    isMobile() {
  const isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  const isSmallScreen = window.matchMedia('(max-width: 1374px)').matches;
  const isMobile = isMobileDevice || isSmallScreen;
  this.$store.commit("setisMobile", isMobile);
  return isMobile;
},


    isSharePoint() {
      if (this.$store.getters.api === "sharepoint") {
        return true;
      } else return false;
    },
  GroupAdministration() {
  // Wenn die API auf 'localStorage' gesetzt ist, wird automatisch 'true' zurückgegeben.
  if (this.$store.getters.api === "localStorage") {
    return true;
  }

  // Andernfalls werden relevante Informationen aus dem Store abgerufen.
  let colleagues = this.$store.getters.colleagues;
  let groupsAdmin = this.$store.getters.groupsAdmin;
  let userId = this.$store.getters.userId;

  // Initial wird 'isAdmin' auf 'false' gesetzt.
  let isAdmin = false;

  // Überprüfe, ob 'groupsAdmin' definiert ist.
  if (groupsAdmin) {
    // Wenn 'colleagues' definiert ist, suche nach einem Kollegen, der ein Gruppenadministrator ist.
    if (colleagues) {
      let matchedColleague = colleagues.find(colleague => groupsAdmin.includes(colleague.Title));

      // Wenn ein passender Kollege gefunden wurde, überprüfe, ob dessen 'Id' mit der 'userId' übereinstimmt.
      if (matchedColleague) {
        isAdmin = (matchedColleague.Id === userId);
      }
    }
  }

  // Gibt 'true' zurück, wenn der Benutzer ein Administrator ist, ansonsten 'false'.
  return isAdmin;
},

  

    Person() {


      if (typeof this.$route.params.sid !== "undefined") {
        //if Person with id detected set href
       
        let id = Number.parseInt(this.$route.params.sid);
        console.log(id);
        return this.$store.getters.studentNameById(id);
      } else return "Person";
    },
    conditionalWidth() {
      if (this.isMobile && this.isSharePoint) {
     //   return "calc(100vw - (100vw - 100%) - 8px)";
     return "calc(100vw - (100vw - 100%)"
      }
      if (this.isSharePoint && !this.isMobile) {
        return "calc(100vw - (100vw - 100%)"
     //   return "calc(100vw - (100vw - 100%) - 16px)";
      } else return "calc(100vw - (100vw - 100%)";
    },
  },
  filters: {
    school(value) {
      if (value.match(/^\d+/)) return value.split("- ")[1];
    },
  },
  methods: {
    gotoDashboard() {
      this.onToggleCollapse(true);
      if (this.$route.name !== "Dashboard")
        this.$router.push({ name: "Dashboard" });
    },
    cancelModal() {
      this.showModal = false;
    },
    leavePEP() {
      if (this.$store.getters.api === "pepApi") {
        // delete auth token cookie
        document.cookie =
          "pep_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        // clear store
        this.$store.dispatch("clearStore");
        // go to login page
        this.showModal = false;
        location.href = window.location.origin + "#/Login";
      } else if (typeof _spPageContextInfo === "undefined") {
        location.href = "https://www.google.de";
      } else {
        let subURL =
          _spPageContextInfo.siteAbsoluteUrl +
          _spPageContextInfo.webServerRelativeUrl +
          "/_layouts/closeConnection.aspx?loginasanotheruser=true&Source=" +
          _spPageContextInfo.siteAbsoluteUrl +
          _spPageContextInfo.webServerRelativeUrl;
        location.href = subURL;
      }
    },
    TG() {
      this.collapsed = !this.collapsed;
    },
    onClickOutside() {
      this.collapsed = true;
    },
    hide: function () {
      this.collapsed = true;
    },
    onToggleCollapse(collapsed) {
      this.collapsed = collapsed;
    },
    onItemClick(event, item) {
      // open feedback modal
      if (item.title === "Feedback") {
        this.isModalOpen = !this.isModalOpen;
      }
      if (item.title === "Ausloggen") {
        this.showModal = true;
      }
      if (item.title === "Einklappen") {
        this.collapsed = !this.collapsed;
      }
      if (item.title === "Passwort ändern") {
        this.PWModal = !this.PWModal;
      }
    },
    ProfileRoute() {
      if (typeof this.$route.params.sid !== "undefined") {
  let personMenuItem = this.menu.find((x) => x.title === "Person");
  if (typeof personMenuItem !== "undefined") {
    // Setzen Sie die Route auf die "Person"-Seite mit der aktuellen 'sid'
    personMenuItem.href = { name: "Profil", params: { sid: this.$route.params.sid } };
  }
}
},

  },
  mounted() {
    this.ProfileRoute();
    // hide Schulverwaltung
 //   if (this.$store.getters.api === "pepApi") this.menu[4].hidden = true;
 if (this.GroupAdministration == false) {
        this.menu[4].hidden = true;
      } else {
        this.menu[4].hidden = false;
      }
  },
  components: {
    LogoutDialog,
  },
  created() {
    //this.ProfileRoute()
    if (this.$route.meta.hidePerson == true) {
      this.menu[10].hidden = true;
    } else {
      this.menu[10].hidden = false;
      this.menu[10].title = this.Person;
    }
    (this.menu[3].title = this.UserGroups),
      (this.menu[1].title = this.UserName);
  },
};
</script>
