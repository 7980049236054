<template>
  <!-- Creates an action button with an icon and text.
       The button can be in an active or disabled state based on the active and disabled props. -->
       <div style="position: relative">
  <div
    class="action-button"
    :class="[
      active ? 'action-button-active' : '',
      disabled ? 'action-button-disabled' : '',
      size === 'small' ? 'small-button' : '',
      animationClass,
    ]"
     :style="{ 'z-index': buttonZIndex }"
    @click="handleClick"
  >
  <svg class="progress-circle" viewBox="0 0 50 50">
  <circle class="path" cx="25" cy="25" r="25" fill="none" stroke-width="5"></circle>
</svg>

    <!-- Displays the icon passed as a prop -->
    <div class="action-button-icon">
      <i :class="`ms-Icon ms-Icon--${icon}`"></i>
    </div>
    <!-- Displays the text passed as a prop -->
    <div class="action-button-text">{{ buttonText }}</div>
  </div>
</div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
export default {
  props: {
    active: {
      type: Boolean,
      default: true,
    },
    isSaved: {
      type: Boolean,
      default: true,
    },
    isEditable: {
      type: Boolean,
      default: true,
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'normal',
    },
    action: {
      type: String,
      default: 'edit',
      validator: value => ['edit', 'save', 'open', 'check', 'delete', 'cancel', 'freigabe'].includes(value), // Add 'cancel' to the list of possible actions
        },
    hasChanged: {
      type: Boolean,
      default: false,
    },
  },
  name: 'ActionButton',
  computed: {
    ...mapState(['showOverlay']),
    buttonZIndex() {
      return this.showOverlay ? 10000 : 'auto';
    },
    // Determines if the button should be disabled based on its state and action
    disabled() {
      if (this.action === 'edit') {
        return !this.isEditable;
      } else if (this.action === 'save') {
        return this.saved || !this.hasChanged || !this.isDisabled;
      } else if (this.action === 'open') {
        return false; 
      }
     else if (this.action === 'check') {
        return this.isDisabled; 
      }
      else if (this.action === 'delete') {
        return false; 
      }
      else if (this.action === 'cancel') {
    return false;
  }
  else if (this.action === 'freigabe') {
        return this.isDisabled; 
      }
      return false;
    },
    // Sets the icon based on the action prop
    icon() {
  if (this.action === 'edit') {
    return 'Edit';
  } else if (this.action === 'save') {
    return this.saved ? 'CheckMark' : 'Save';
  } else if (this.action === 'open') {
    return 'OpenInNewWindow';
  } else if (this.action === 'check') {
    return this.saved ? 'Accept' : 'ProgressRingDots'; 
  } else if (this.action === 'delete') {
    return 'Delete';
  } else if (this.action === 'cancel') {
    return this.saved ? 'Undo' : 'Cancel';
  } else if (this.action === 'freigabe') {
    return this.saved ? 'Lock' : 'Unlock'; 
  }
  
  return ''; // Default value
},
    // Sets the button text based on the action prop
    buttonText() {
  let statusChanged = {
    'save': 'Gespeichert',
    'check': 'Überprüft',
    'cancel': 'Nein',
    'freigabe': 'Sperren'
  };

  if (this.action === 'edit') {
    return 'Bearbeiten';
  } else if (this.action === 'save') {
    return this.saved ? statusChanged[this.action] : 'Speichern';
  } else if (this.action === 'open') {
    return 'Öffnen';
  } else if (this.action === 'check') {
    return this.saved ? statusChanged[this.action] : 'Überprüfen'; 
  } else if (this.action === 'delete') {
    return 'Löschen'; // Set the text for the delete action
  } else if (this.action === 'cancel') {
    return this.saved ? statusChanged[this.action] : 'Abbrechen'; 
  } else if (this.action === 'freigabe') {
    return this.saved ? statusChanged[this.action] : 'Freigeben'; 
  }
  return '';
}
  },
  data() {
    return {
      animationClass: '',
      saved: this.isSaved, // Initialize the saved data property with the isSaved prop value
    };
  },
  methods: {
    ...mapActions(['updateShowOverlay']),
    // Emits a custom event 'click' when the button is clicked
    handleClick() {
  if (!this.disabled) {
    if (this.action === 'check') {
      this.startProgress();
    } else {
      this.emitClick();
    }
    this.triggerAnimation();
  //  this.updateShowOverlay(true); // Zeige das Overlay an, wenn der Button geklickt wird
  }
},
    startProgress() {
  // Zeigt den Fortschrittskreis
  setTimeout(() => {
  this.$el.querySelector('.progress-circle').style.display = 'block';
  }, 400)

  // Wartet auf die Dauer der Animation, dann emittiert den Klick
  setTimeout(() => {
    this.emitClick();
    this.$el.querySelector('.progress-circle').style.display = 'none';
  }, 2000);
},
    // Emits the appropriate event based on the action prop
    emitClick() {
  const eventName = this.action === 'edit' ? 'editClick' : 
    this.action === 'save' ? 'saveClick' : 
    this.action === 'check' ? 'checkClick' : 
    this.action === 'delete' ? 'deleteClick' :     
    this.action === 'freigabe' ? 'releaseClick' :
    this.action === 'cancel' ? 'cancelClick' : 'openClick'; // Add a case for the cancel action

  this.$emit(eventName);
  
  if (['save', 'check', 'cancel', 'freigabe'].includes(this.action)) {
    this.saved = !this.saved;
  }
},
hideOverlay() {
      this.updateShowOverlay(false); // Verstecke das Overlay
    },
    // Triggers the animation when the save button is clicked
    triggerAnimation() {
      if (this.action === 'save') {
        this.animationClass = 'saved-animation';
        setTimeout(() => {
          this.animationClass = '';
          this.saved = true;
        }, 1000);
      }
      if (this.action === 'check') {
        this.animationClass = 'check-animation';
        setTimeout(() => {
          this.animationClass = 'animation-checked';
          this.saved = true;
        }, 2500);
      }
    },
  },
  watch: {
    // Updates the saved data property when the hasChanged prop changes
    hasChanged(newVal) {
      if (newVal) {
        this.saved = false;
      }
    },
    // Updates the saved data property when the isSaved prop changes
    isSaved(newVal) {
      this.saved = newVal;
    },
  },
};
</script>
<style >
:root {
  --button-shadow-color: 0deg 0% 0%;
  --button-elevation-low:
    0px 1px 1.2px hsl(var(--button-shadow-color) / 0.14),
    0px 1.9px 2.2px -1.2px hsl(var(--button-shadow-color) / 0.15),
    0.1px 5px 5.8px -2.3px hsl(var(--button-shadow-color) / 0.16);
  --button-elevation-medium:
    0px 1px 1.2px hsl(var(--button-shadow-color) / 0.15),
    0.1px 4px 4.6px -0.8px hsl(var(--button-shadow-color) / 0.16),
    0.2px 10.3px 11.9px -1.5px hsl(var(--button-shadow-color) / 0.16),
    0.4px 25px 28.9px -2.3px hsl(var(--button-shadow-color) / 0.17);
  --button-elevation-high:
    0px 1px 1.2px hsl(var(--button-shadow-color) / 0.16),
    0.2px 10.1px 11.7px -0.4px hsl(var(--button-shadow-color) / 0.16),
    0.3px 19.9px 23px -0.8px hsl(var(--button-shadow-color) / 0.17),
    0.6px 34.7px 40.1px -1.2px hsl(var(--button-shadow-color) / 0.17),
    1px 58.6px 67.7px -1.5px hsl(var(--button-shadow-color) / 0.18),
    1.6px 95.6px 110.4px -1.9px hsl(var(--button-shadow-color) / 0.18),
    2.6px 150px 173.3px -2.3px hsl(var(--button-shadow-color) / 0.18);
}
</style>

<style scoped>

.action-button {
  display: grid;
  grid-auto-flow: column;
  place-items: center;
  grid-gap: 8%;
  cursor: pointer;
  padding: 0px 21px;
  max-height: 50px;
  place-content: center;
  min-width: 64px;
  font-size: 16px;
  overflow: hidden;
  position: relative;

}



.small-button {
  max-height: 30px;
  padding: 0px 13px;
}
.action-button.action-button-active:hover {
  background: white;
  transition: all 0.3s ease;
}
.action-button-active {
  border: 1px solid black;
  box-shadow: var(--button-elevation-medium);
  transition: box-shadow .15s ease;
  background-color: rgba(255, 255, 255, 0.37);
  backdrop-filter: blur(10px);
}
.action-button-disabled {
  background: #ccc;
  color: #5b5b5b;
  pointer-events: none;
  border: 1px solid #aaa;
  box-shadow: var(--button-elevation-low);
  transition: box-shadow .15s ease
}
.action-button-icon {
  padding: 8px 0 8px 8px;
}
.action-button-text {
  padding: 8px 8px 8px 0;
}
.saved-animation {
  animation: savedFlash 1s ease-in-out;
}
@keyframes savedFlash {
  0% {
    background: transparent;
  }
  50% {
    background: rgba(0, 255, 0, 0.2);
  }
  100% {
    background: transparent;
  }
}
.check-animation {
  width: 50px;
height: 50px;
padding: 0;
border-radius: 90px;
border: none;
min-width: 50px;
transition: all .3s ease-in-out
}
.animation-checked {
  transition: all .3s ease-in-out
}
.check-animation > div {
  display: none
}
.progress-circle {
  display: none; /* Default zu hidden */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  transform: rotate(-90deg);
}

.path {
  stroke: #97C02F;
  stroke-linecap: round;
  animation: progress 2s cubic-bezier(0, 0, 0.2, 1) forwards .7s;
    stroke-dasharray: 314;
  stroke-dashoffset: 314;
}
@keyframes progress {
  0% {
    stroke-dashoffset: 314;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
</style>
