<style scoped>

.evaluation-card-container {
  display: flex;
  flex-wrap: wrap;
  row-gap: 2.5rem;
  column-gap: 2.5rem;
}

.evaluation-card {
  background-color: white;
  margin: 5px;
  padding: 20px;
  border-radius: 20px;
  line-height: 1em !important;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  display: grid;
  grid-template-columns: 200px 180px;
  transition: all 1s;
}
.yellow-border-left {
  border-left: 20px solid #FFE37E;
  padding-left:  0px;
  border-radius: 0px;
}
.edit {
  grid-template-columns: 200px 650px;
  transition: none;
}
/* move edit field down instead of next to evaluation card */
@media only screen and (max-width: 1200px ) {
  /* on a medium screen */
  .edit {
    grid-template-columns: minmax(380px, 700px);
    grid-template-rows: min-content min-content;
  }
}
@media only screen and (max-width: 900px ) {
  /* on small screen */
  .edit {
    grid-template-columns: minmax(380px, 500px);
    grid-template-rows: min-content min-content;
  }
}


.evaluation-status-container {
  margin: 0px 5px 0px 10px;
  display: grid;
  grid-template-rows: min-content 50px;
}

.evaluation-status-header {
  margin-top: 5px;
  display: grid;
  grid-template-rows: min-content minmax(60px, min-content);
}
@media only screen and (max-width: 1200px ) {
  /* headline has more space on smaller screens  */
  .edit .evaluation-status-header {
    grid-template-rows: min-content minmax(30px, min-content);
  }
}
.evaluation-status-header-headline {
  margin-bottom: 5px;
  font-size: 20px;
  font-family: "aileronthin", sans-serif !important;
  line-height: 1em !important;
}
.evaluation-status-header-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 320px;
  margin-top: -2px;
  margin-right: 30px;
  margin-bottom: 10px;
  font-size: 22px;
  font-family: 'aileronregular' !important;
  line-height: 1em !important;
}
.evaluation-status-header-title-open{
  width: 800px;
}

.evaluation-status-icons-container {
  display: grid;
  grid-template-columns: min-content min-content min-content;
  align-content: end;
  justify-content: space-between;
  width: 140px;
}
.evaluation-status-icons {
  height: 38px;
  width: 38px;
}
.dot {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #bbb;
}


.evaluation-edit-closed {
  cursor: pointer;
  display: grid;
  align-items: end;
}
.evaluation-edit-closed:hover .evaluation-edit-closed-hint {
  background-color: #0d4fc4;
  color: white;
  border-radius: 3px;
}
.evaluation-edit-closed-hint {
  padding: 3px;
  font-size: 18px;
  font-weight: bold;
  font-family: aileronthin !important;
  line-height: 1em !important;
  color: #0d4fc4;
  max-width: 180px;
}
/* evaluation questionnaire */
.evaluation-edit-open {

}
@media only screen and (max-width: 1200px ) {
  /* adjust the position of the questionnaire */
  .evaluation-edit-open {
    margin-top: 30px;
    margin-left: 15px;
  }
}

.evaluation-edit-button-container {
  margin-top: -5px;
  margin-bottom: 40px;
  display: grid;
  justify-content: end;
}
.evaluation-edit-button {
  padding: 5px;
}
@media only screen and (max-width: 1200px ) {
  /* move the close button upwards */
  .evaluation-edit-button {
    margin-top: -145px;
    height: 25px;
  }
  .evaluation-status-header-title-open{
    width: 600px;
  }
}

.evaluation-edit-container {
  display: grid;
  grid-template-columns: 90px auto;
  grid-template-areas:
  "icon criteria"
  "separator separator";
}

.evaluation-edit-icon {
  grid-area: icon;
  display: grid;
  align-content: center;
  padding: 5px 10px 5px 10px;
}

.evaluation-edit-kriterium-container {
  margin: 0 15px 0 30px;
  grid-area: criteria;
  display: grid;
  grid-template-rows: min-content auto;
}
.separator {
  grid-area: separator;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 30, 0.36);
}

.evaluation-edit-kriterium-headline {
  margin: 5px 0 10px 0;
  font-size: 18px;
  font-family: aileronthin !important;
  line-height: 1em !important;
}
.evaluation-edit-kriterium-checkbox-container {
  margin-bottom: 10px;
  display: grid;
  justify-content: space-between;
}
.three {
  grid-template-columns: min-content min-content min-content;
}
.four {
  grid-template-columns: min-content min-content min-content min-content;
}
.five {
  grid-template-columns: min-content min-content min-content min-content min-content;
}
@media only screen and (max-width: 900px ) {
  /* small: show boxes among each other  */
  .evaluation-edit-kriterium-checkbox-container {
    margin-bottom: 10px;
    display: grid;
    grid-template-columns: auto;
  }
  .evaluation-status-header-title-open{
    width: 300px;
  }
}

.evaluation-edit-kriterium-checkbox {
  display: grid;
  grid-template-rows: min-content min-content;
  grid-template-columns: 100px;
}
.evaluation-edit-kriterium-checkbox-input {
  justify-self: center;
}
.evaluation-edit-kriterium-checkbox-label {
  margin-top: 5px;
  font-size: 15px;
  text-align: center;
   text-align: -webkit-center;
   text-align: -moz-center;
  font-family: aileronregular !important;
  line-height: 1em !important;
}
@media only screen and (max-width: 900px ) {
  /* small: show boxes among each other with labels beside  */
  .evaluation-edit-kriterium-checkbox {
    display: grid;
    grid-template-columns: min-content auto;
    justify-content: start;
    margin-bottom: 15px;
  }
  .evaluation-edit-kriterium-checkbox-label {
    margin-left: 15px;
    align-self: center;
    text-align: left;
     text-align: -webkit-left;
   text-align: -moz-left;
  }
}

.evaluation-edit-notes-container {
  display: grid;
  grid-template-columns: 90px auto;
  grid-template-rows: min-content min-content;
  grid-template-areas:
  "placeholder headline"
  "placeholder textarea"
  "placeholder button";
}
.evaluation-edit-notes-headline {
  margin: 5px 15px 10px 15px;
  grid-area: headline;
}
.evaluation-edit-notes-textarea {
  margin: 0 15px 10px 15px;
  grid-area: textarea;
  height: 80px;
  resize: none;
  border: 1px #a1a0ab solid;
  padding: 12px 20px;
  font-size: 18px;
  font-family: aileronregular !important;
  line-height: 1em !important;
  word-wrap: anywhere;
}
.evaluation-edit-save-button-container {
  margin: 20px 15px 20px 15px;
  grid-area: button;
}
.evaluation-edit-save-button {
  z-index: 2;
}
.remove-margin {
  margin: 0;
}

/* status color for icons */
.filter-green {
  filter: invert(50%) sepia(11%) saturate(1875%) hue-rotate(62deg) brightness(101%) contrast(88%);
}
.filter-lightgreen {
  filter: invert(93%) sepia(15%) saturate(918%) hue-rotate(35deg) brightness(90%) contrast(86%);
}
.filter-blue {
  filter: invert(69%) sepia(6%) saturate(4500%) hue-rotate(182deg) brightness(86%) contrast(90%);
}
.filter-yellow {
  filter: invert(81%) sepia(61%) saturate(1022%) hue-rotate(357deg) brightness(105%) contrast(98%);
}
.filter-red {
  filter: invert(49%) sepia(45%) saturate(766%) hue-rotate(320deg) brightness(82%) contrast(109%);
}
.filter-opacity {
  opacity: 0.6;
}

/* input style */
input[type="radio"]{
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  background: white;
  border: 1px solid black;
  width: 23px;
  height: 23px;
  transition: all .3s linear;
}
input[type="radio"]:checked{
  background-color: #ADB3AF;
}
</style>

<template>
  <div>
    <ol class="evaluation-card-container">
      <li v-for="item in items" :key="item.Id">
        <div class="evaluation-card" :class="{'edit': item.isEdited, 'yellow-border-left': item.Type === 'measures'}">

          <!-- the left half of the evaluation card: title, status icons  -->
          <div class="evaluation-status-container">
            <div class="evaluation-status-header">
              <!-- headline -->
              <div v-if="item.Type === 'goals'" class="evaluation-status-header-headline">Bezeichnung</div>
              <div v-else class="evaluation-status-header-headline">Bezeichnung</div>
              <!-- title -->
              <div class="evaluation-status-header-title" :class="{'evaluation-status-header-title-open': item.isEdited}">
                {{item.Title}}
              </div>
            </div>
            <div class="evaluation-status-icons-container">
              <div v-for="(icon, iconName) in item.Kriterien" :key="icon.Title" class="evaluation-status-icons">
                <div v-if="icon.Value ===  null" class="dot" />
                <img v-else :src="getIcon(icon)" :class="getIconColor(icon, iconName)" />
              </div>
            </div>
          </div>

          <!-- the right half of the evaluation card: evaluation questionnaire  -->
          <div v-if="item.isEdited" class="evaluation-edit-open">
            <!-- if questionnaire is visible -->
            <div class="evaluation-edit-button-container">
              <button class="removeButtonStyle" @click.stop.prevent="rejectEvaluation(item)">
                <i class="evaluation-edit-button material-icons">close</i>
              </button>
            </div>
            <!-- for each criterion -->
            <div v-for="(evalItem, evalItemName) in item.Kriterien" :key="evalItemName" class="evaluation-edit-container">
              <!-- criterion icon -->
              <div class="evaluation-edit-icon">
                <img :src="getIcon(evalItem)" :class="getIconColor(evalItem, evalItemName)" />
              </div>
              <!-- criterion checkbox container -->
              <div class="evaluation-edit-kriterium-container">
                <div class="evaluation-edit-kriterium-headline">
                  {{evalItem.Title}}
                </div>
                <div class="evaluation-edit-kriterium-checkbox-container" :class="getNumberOfAnswers(evalItem.Answers.length)">
                  <!-- create checkbox for every answer inside the questionnaire -->
                  <div v-for="(answer, index) in evalItem.Answers" :key="index" class="evaluation-edit-kriterium-checkbox">
                    <input type="radio" class="evaluation-edit-kriterium-checkbox-input"
                          :value="index" :checked="evalItem.Value === index"
                          @change="setCheckboxValue(item.Id, item.Type, evalItemName, index)"
                          :id="`${item.Id}-${answer}`">
                    <label class="evaluation-edit-kriterium-checkbox-label" :for="`${item.Id}-${answer}`">{{answer}}</label>
                  </div>
                </div>
              </div>
              <!-- criterion separator -->
              <div class="separator" />
            </div>
            <div class="evaluation-edit-notes-container">
              <div class="evaluation-edit-notes-headline evaluation-edit-kriterium-headline">
                Hinweise
              </div>
              <textarea :value="item.Hinweise" @input="setTextareaValue(item.Id, item.Type, $event.target.value)" class="evaluation-edit-notes-textarea" />
              <p class="evaluation-edit-save-button-container formactionbutton formactionbuttonspeichern">
                <input class="evaluation-edit-save-button save-button remove-margin removeButtonStyle" @click.stop.prevent="saveEvaluation(item)" value="Speichern" />
              </p>
            </div>
          </div>
          <div v-else class="evaluation-edit-closed" @click.stop.prevent="setEditState(item.Id, item.Type, true)">
            <!-- if questionnaire is hidden -->
            <div class="evaluation-edit-closed-hint" v-if="isCompleted(item)">
              Evaluation bearbeiten
            </div>
            <div class="evaluation-edit-closed-hint" v-else>
              Noch nicht evaluiert jetzt evaluieren
            </div>
          </div>

        </div>
      </li>
    </ol>
    <FormSaveDialog v-if="showSaveModal" @cancel="modalCancel" @reject="modalReject" @save="modalSave" />
  </div>
</template>

<script>
import FormSaveDialog from "../Forms/FormSaveDialog"
// load icons
let zielerreichungIcon = require('../../../assets/icons/zielerreichung.svg')
let entwicklungszielIcon = require('../../../assets/icons/entwicklungsziel.svg')
let planumsetzungIcon = require('../../../assets/icons/planumsetzung.svg')
let entwicklungsmasznahmeIcon = require('../../../assets/icons/entwicklungsmasznahme.svg')
let abschlussIcon = require('../../../assets/icons/abschluss.svg')
let erneutIcon = require('../../../assets/icons/erneut.svg')
let weiterfuehrungIcon = require('../../../assets/icons/weiterfuehrung.svg')
let alternativeIcon = require('../../../assets/icons/alternative.svg')

export default {
  name: "EvaluationCard",
  props: ["items", "zid"],
  components: {
      FormSaveDialog
  },
  data() {
    return {
      showSaveModal: false,
      saveModalItem: null
    }
  },
  methods: {
    setEditState(id, type, value) {
      this.$store.commit("setPayload", {
        id: id,
        type: type,
        isEdited: value,
        task: "isEdited"
      })
    },
    setCheckboxValue(id, type, kriterium, value){
      this.$store.commit("setPayload", {
        id: id,
        type: type,
        kriterium: kriterium,
        checkboxValue: value,
        task: "checkboxValue"
      })
    },
    setTextareaValue(id, type, value) {
      this.$store.commit("setPayload", {
        id: id,
        type: type,
        hinweise: value,
        task: "notes"
      })
    },
    setRejectState(id, type, value) {
      this.$store.commit("setPayload", {
        id: id,
        type: type,
        rejectState: value,
        task: "rejectState"
      })
    },
    isCompleted(item) {
      for(let kriterium in item.Kriterien) {
        if(item.Kriterien[kriterium].Value === null) return false
      }
      return true
    },
    rejectEvaluation(item) {
      if(this.stateChanged(item)) {
        this.saveModalItem = item
        this.showSaveModal = true
      } else {
        this.closeEditView(item)
      }
    },
    stateChanged(item) {
      if( ((item.Type === "goals" && item.Kriterien.Zielerreichung.Value === item.rejectState.Kriterien.Zielerreichung) ||
          (item.Type === "measures" && item.Kriterien.Planumsetzung.Value === item.rejectState.Kriterien.Planumsetzung)) &&
        item.Kriterien.Erfolg.Value === item.rejectState.Kriterien.Erfolg &&
        item.Kriterien.Vorgehen.Value === item.rejectState.Kriterien.Vorgehen &&
        item.Hinweise === item.rejectState.Hinweise
      )  return false
      else return true
    },
    modalSave() {
      this.showSaveModal = false
      this.saveEvaluation(this.saveModalItem)
    },
    modalReject() {
      this.showSaveModal = false
      this.closeEditView(this.saveModalItem)
      this.resetValues(this.saveModalItem)
    },
    modalCancel() {
      this.showSaveModal = false
    },
    saveEvaluation(item) {
      // update local data
      this.closeEditView(item)
      this.updateRejectState(item)

      // check if evaluation is completed
      this.$emit("checkEvaluationState")

      // create data object
      let evalObj = {
        Id: item.Id,
        Kriterien: item.Kriterien,
        Hinweise: item.Hinweise,
        RefZ: this.zid
      }
      // update storage data
      if(item.Type === "goals")  this.$store.dispatch("saveZielEvaluation", evalObj)
      else if(item.Type === "measures") this.$store.dispatch("saveMkiEvaluation", evalObj)
    },
    closeEditView(item) {
      this.setEditState(item.Id, item.Type, false)
    },
    // set evaluation card values to reject state
    resetValues(item) {
      // reset checkboxes
      for(let kriterium in item.Kriterien) {
        this.setCheckboxValue(item.Id, item.Type, kriterium, item.rejectState.Kriterien[kriterium])
      }
      // reset Hinweise
      this.setTextareaValue(item.Id, item.Type, item.rejectState.Hinweise)
    },
    // set reject state to evaluation card values
    updateRejectState(item) {
      let rejectState = {Kriterien: {}, Hinweise: ""}
      for(let kriterium in item.Kriterien) {
        rejectState.Kriterien[kriterium] = item.Kriterien[kriterium].Value
      }
      rejectState.Hinweise = item.Hinweise
      this.setRejectState(item.Id, item.Type, rejectState)
    },
    getIconColor(item, itemName) {
      let value = item.Value

      // checkbox icons
      if(value === null) return "filter-opacity"
      // no color added
      if(itemName === "Vorgehen") return
      // stretch number of answers to color array length
      if(item.Answers.length === 3) value=value*2

      if(value === 0) return "filter-green"
      else if(value === 1) return "filter-lightgreen"
      else if(value === 2) return "filter-blue"
      else if(value === 3) return "filter-yellow"
      else if(value === 4) return "filter-red"
    },
    getIcon(item) {
      let name = item.Title
      let value = item.Value
      if(name === "Grad der Zielerreichung") return zielerreichungIcon
      else if(name === "Entwicklungsziel") return entwicklungszielIcon
      else if(name === "Umsetzung der Entwicklungsmaßnahme") return planumsetzungIcon
      else if(name === "Entwicklungsmaßnahme") return entwicklungsmasznahmeIcon
      else if(name === "Weiteres Vorgehen") {
        // icons to procedure
        if(value === null) return erneutIcon
        else if(value === 0) return abschlussIcon
        else if(value === 1) return erneutIcon
        else if(value === 2) return weiterfuehrungIcon
        else if(value === 3) return alternativeIcon
        else return ""
      }
      else return ""
    },
    getNumberOfAnswers(length) {
      if(length === 3) return "three"
      if(length === 4) return "four"
      if(length === 5) return "five"
      else return null
    }
  }
}
</script>
