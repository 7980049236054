//ScrollHeader.vue

<template>
  <div :class="['scrollheader', !isScrolled ? 'hidden-scrollheader' : 'scrollheader-fixed']"
    :style="{ backgroundColor: backgroundcolor }">
    <!-- Renders the title and subtitle texts -->
    <div>
      <span class="smallbar-titletext">{{ title }}</span>
      <span class="smallbar-subtitletext">{{ subtitle }}</span>
    </div>
        <!-- Renders the slot content passed in the component -->
    <div class="action-button-area">
      <slot></slot>
    </div>
        <!-- Renders the close icon button -->
    <div style="margin-right: 60px">
      <i class="ms-Icon ms-Icon--ChromeClose" style="cursor: pointer" @click="handleCloseClick"></i>
    </div>
  </div>
</template>
  
<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
    backgroundcolor: {
      type: [String, Number],
      default: '#ffffffa8'
    },
    containerIDs: {
      type: Array,
      default() {
        return []
      }
    },
    backRoute: {
      type: String,
      default: ""
    }
  },
  name: 'ScrollHeader',
  data() {
    return {
      isScrolled: false,
    };
  },
  watch: {
    '$route': function (to) {
      if (to.meta.hideMenu) {
        this.updateStyles('100%');
      } else {
        this.updateStyles('calc(100vw - 50px)');
      }
    }
  },
  beforeDestroy() {
    for (let element of this.containerIDs) {
      const container = document.getElementById(element);
      if (container) {
        container.removeEventListener("scroll", this.handleScrollHeader);
      }
    }
  },
  methods: {
  // handleScrollHeader is responsible for determining whether the header should
  // be shown or hidden, depending on the scroll position of the specified containers or window.
  // It updates the isScrolled data property accordingly.
      handleScrollHeader() {
      let containerExists = false;
      for (let element of this.containerIDs) {
        const container = document.getElementById(element);
        if (container) {
          containerExists = true;
          this.isScrolled = container.scrollTop > 200;
          break;
        }
      }
      if (!containerExists) {
        this.isScrolled = window.scrollY > 200;
      }
    },
  // handleCloseClick is the event handler for the close button click event.
  // When clicked, it either navigates to a custom route defined by the 'backRoute' prop
  // or goes back to the previous page in the browser's history. If there is no history,
  // it redirects to the root route.
      handleCloseClick() {
      if (this.backRoute !== '') {
        this.$router.push({ name: this.backRoute });
      } else {
        window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/');
      }
    },
    updateStyles(width) {
  let scrollheaderElement = document.querySelector('.scrollheader');
  if(scrollheaderElement) {
    scrollheaderElement.style.minWidth = width;
    scrollheaderElement.style.maxWidth = width;
  }
    },
  },
    // Attaches scroll event listener based on the available containers to check for scrolling
  mounted() {
    let containerExists = false;
    for (let element of this.containerIDs) {
      const container = document.getElementById(element);
      if (container) {
        container.addEventListener("scroll", this.handleScrollHeader, { passive: true });
        containerExists = true;
      }
    } 
       // Fallback to window scroll event if no container is found
    if (!containerExists) {
      window.addEventListener("scroll", this.handleScrollHeader, { passive: true });
    }
     // Adjust min-width and max-width based on the route meta property
  
    if(this.$route.meta.hideMenu) {
      this.updateStyles('100%');
    } else {
      this.updateStyles('calc(100vw - 50px)');
    }
  },

};
</script>
  
<style scoped>
.scrollheader-fixed {
  height: 38px;
  position: fixed;
  max-width: calc(100vw - 50px);
  top: 0;
  background: transparent;
  text-align: left;
  padding-top: 12px;
  padding-bottom: 0px;
  padding-left: 12px;
  min-width: calc(100vw - 50px);
  z-index: 99;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  font-family: 'aileronsemibold';
  box-shadow: 0px 0px 20px 1px rgb(0 0 0 / 19%);
  animation: revealbar 300ms ease;
  display: grid;
  grid-auto-flow: column;
  place-content: space-between;
  grid-template-columns: 1fr auto auto;
  grid-gap: 5%;

}

@keyframes revealbar {
  0% {
    transform: translateY(-100px);
  }

  100% {
    transform: translateY(0);
  }
}

.smallbar-titletext {
  font-family: 'aileronheavy';
  margin-right: 12px;
}

.smallbar-subtitletext {
  text-align: left;
  font-family: 'aileronsemibold';
}

.action-button-area {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 5%;
}

.hidden-scrollheader {
  animation: hidebar 300ms ease forwards;
  height: 38px;
position: fixed;
max-width: calc(100vw - 50px);
top: 0;
text-align: left;
padding-top: 12px;
padding-bottom: 0px;
padding-left: 12px;
min-width: calc(100vw - 50px);
  background: transparent;
  z-index: 99;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  box-shadow: 0px 0px 20px 1px rgb(0 0 0 / 19%);
  display: grid;
  grid-auto-flow: column;
  place-content: space-between;
  grid-template-columns: 1fr auto auto;
  grid-gap: 5%;
}
@keyframes hidebar {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-1000px);

  }
  
}
</style>
  