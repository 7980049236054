
 <template>
   <transition name="modal-fade">
      <div class="modal-backdrop">
        <div class="form-container" >
          <div class="form-header-container">
            <div class="form-header">
              {{$entgendern.pupil}} neu anlegen
            </div>
            <button class="form-header-button removeButtonStyle" @click.stop.prevent="$emit('close')">
              <i class="material-icons">close</i>
            </button>
          </div>
          <form class="form-newstudent-container" @submit.stop.prevent="createNewStudent">
            <div class="field">
              <label v-if="showChiffre" for="firstnameField" class="field-label">Chiffre der Organisation</label>
              <label v-else for="firstnameField" class="field-label">Vorname</label>
              <input required type="input" v-model="firstname" class="field-input" name="firstnameField" autocomplete="off" />
            </div>
            <div class="field">
              <label v-if="showChiffre" for="lastnameField" class="field-label">Chiffre der {{$entgendern.pupil}}</label>
              <label v-else for="lastnameField" class="field-label">Nachname</label>
              <input required type="input" v-model="lastname" class="field-input" name="lastnameField" autocomplete="off" />
            </div>
            <div class="form-newstudent-button-container">
              <button v-if="loading" disabled class="loading"></button>
              <input v-else class="form-newstudent-button" type="submit" value="OK" />
              <button class="form-newstudent-button form-newstudent-button-ok" @click.prevent="$emit('close')">Abbrechen</button>
            </div>
          </form>
        </div>
      </div>
   </transition>
 </template>

 <script>
 export default {
   name: 'FormNewStudent',
   props: ['showChiffre'],
   data() {
     return {
       firstname: null,
       lastname: null,
       loading: false
     }
   },
   computed: {
    userTitle() {
      return this.$store.getters.userTitle
    },
    userId() {
      return this.$store.getters.userId
    }
   },
   methods: {
     createNewStudent() {
       let student = {
         Title: "Entwicklungsplan " + this.lastname + "; " + this.firstname,
         Author: {
          Id: "1",
          Title: "Lokale " +this.$entgendern.author
         },
         RefS: {
           FirstName: this.firstname,
           Title: this.lastname,
           Klasse: null,
         },
         PiC: [{
          Id: this.userId,
          Title: this.userTitle
        }],
         PiCId: {"__metadata":{"type":"Collection(Edm.Int32)"},"results":[this.$store.getters.userId]},
         Modified: new Date().toISOString(),
         DSchutzE: false,
         Id: this.$store.getters.entPl.length+1
       }
       this.$store.dispatch("createNewEntPl", student)
       this.showSavePopup("" +this.firstname + this.lastname)
       this.$emit("close")
     },
     showSavePopup(name) {
       let popup = document.getElementById("popup-notification")
       if(popup) {
         popup.textContent = "Die " +this.$entgendern.pupil +" " +name +" wurde erfolgreich hinzugefügt"
         popup.style.display = "grid";
         setTimeout(() => {
           popup.style.display = "none";
         }, 2000)
       }
     }
   }
 }
 </script>
<style scoped>

.modal-fade-enter,
 .modal-fade-leave-active {
   opacity: 0;
 }

 .modal-fade-enter-active,
 .modal-fade-leave-active {
   transition: opacity .5s ease
 }

.modal-backdrop {
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   /* background: #ffffffb0; */
   background: #4444448a;
   z-index: 1000;
   margin-left: 50px;
   display: grid;
   align-items: center;
   justify-items: center;
   backdrop-filter: blur(10px);
   -webkit-backdrop-filter: blur(10px);
 }
 /* if no backdrop support*/
 @supports not (backdrop-filter: blur(10px)) {
   .modal-backdrop::after {
     filter: url(#blur);
   }
 }

 .form-container {
   padding: 20px 30px 20px 30px;
   width: 500px;
   font-size: 16px;
   text-align: left;
      text-align: -webkit-left;
   text-align: -moz-left;
   cursor: default;
   background-color: white;
   /* border: 2px solid #838383; */
   display: grid;
   grid-template-rows: 80px auto;
 }

.form-header-container {
  display: grid;
  grid-template-columns: auto 50px;
  align-content: center;
}

.form-header {
  padding-left: 25px;
  padding-top: 25px;
  font-size: 30px;
  color: #777;
  font-family: aileronThin,sans-serif !important;
}

.form-header-button {
  background-color: white;
}

.form-newstudent-container {
  margin: 20px 30px 30px 30px;
  display: grid;
  grid-gap: 5px;
  grid-template-rows: auto auto auto;
}

.field {
  display: grid;
  grid-template-rows: auto auto;
  grid-gap: 5px;
  padding: 5px;
}
.field-label {
  font-weight: bold;
}
.field-input {
    font-size: 22px;
}

.form-newstudent-button-container {
  margin-top: 25px;
  display: grid;
  grid-template-columns: min-content min-content;
  grid-gap: 10px;
}

.form-newstudent-button {
  font-size: 16px;
  padding: 10px;
  cursor: pointer;
  margin-left: 0;
}
.form-newstudent-button:hover {
  background-color: #fcfcfc;
}
.form-newstudent-button-ok {
  padding-left: 15px;
  padding-right: 15px;
}

.loading {
  padding-left: 15px;
  padding-right: 25px;
  margin-left: 0;
}
.loading:after {
  content: '.';
  animation: dots 2s steps(5, end) infinite;
}

@keyframes dots {
  0%, 20% {
    color: rgba(0,0,0,0);
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);}
  40% {
    color: grey;
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);}
  60% {
    text-shadow:
      .25em 0 0 grey,
      .5em 0 0 rgba(0,0,0,0);}
  80%, 100% {
    text-shadow:
      .25em 0 0 grey,
      .5em 0 0 grey;}}

</style>
