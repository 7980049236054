import { getLocalStorageItem, setLocalStorageItem } from "./ClientStorageManager.js"

export default {
  loadEntPl() {
    let entPl = JSON.parse(getLocalStorageItem('entPl'))
    return promise(entPl)
  },
  loadSVz() {
    let svz = JSON.parse(getLocalStorageItem('svz'))
    return promise(svz)
  },
  updateSVz(payload) {
    let svz = getItem('svz')
     if(svz && payload) {
       svz.forEach(svz => {
         if(svz.Id === payload.svzId) {
           console.log(svz)
           // update svz
           svz.Title = payload.Title
           svz.FirstName = payload.FirstName
           svz.ImportId = payload.ImportId
           svz.Klasse = payload.Klasse
         }
       })
    console.log(payload)
      setItem('svz', svz)
      return promise(true)
    }
  },
  getDiagnostikObject() {
    let diagnostik = JSON.parse(getLocalStorageItem('diagnostik'))
    return promise(diagnostik)
  },
  addEntPl(student) {
    return this.loadEntPl()
    .then(entPl => {
      entPl.push(student)
      setLocalStorageItem('entPl', JSON.stringify(entPl))
      return promise(student)
    })
  },
  updateSettings(data) {
    let administrationObject = getItem('administrationObject')
    let GroupAdministration = getItem('GroupAdministration')
     if(administrationObject && data) {
      
      administrationObject.groupsSet.forEach(gs => {
        if(gs.Id === data.gdId) {
           
           // update svz
           if (gs.Settings === null)
           {
             gs.Settings = {"__metadata":{"type":"Collection(Edm.String)"},"results":data.Settings.results}
           }
           else {
           // update svz
           gs.Settings.results =  data.Settings.results
           }
         }
       })
       administrationObject.groupsSettings = data.Settings.results
       GroupAdministration.forEach(gs => {
        if(gs.Id === data.gdId) {
          
           if (gs.Settings === null)
           {
             gs.Settings = {"__metadata":{"type":"Collection(Edm.String)"},"results":data.Settings.results}
           }
           else {
           // update svz
           gs.Settings.results =  data.Settings.results
           }
         }
       })
       administrationObject.updateSettings = true
      setItem('administrationObject', administrationObject)
      setItem('GroupAdministration', GroupAdministration)
      return promise(true)
    }
  },
  getGroupObject() {
    let groupObject = JSON.parse(getLocalStorageItem('groupObject'))
    return promise(groupObject)
  },
  getGroupAdministration() {

  let GroupAdministration = JSON.parse(getLocalStorageItem('GroupAdministration'))
  return promise(GroupAdministration)
  
  },
  getUserObject() {
    let userObject = JSON.parse(getLocalStorageItem('userObject'))
    return promise(userObject)
  },
  getAdministrationObject() {
    let administrationObject = JSON.parse(getLocalStorageItem('administrationObject'))
return promise(administrationObject)
  },
  loadZyklen(id) {
    let zyklen = getItem('zyklen')
    if(zyklen) zyklen = zyklen.filter(item => id === item.RefPEPId).reverse()
    else zyklen = []
    return promise(zyklen)
  },
  addZyklus(zyklus) {
    let zyklen = getItem('zyklen')
    if(zyklen) zyklen.push(zyklus)
    else zyklen = [zyklus]

    setItem('zyklen', zyklen)
    return promise(zyklus)
  },
  agreeDatenschutz(id) {
    let entPl = getItem('entPl')
    if(id && entPl) {
      entPl.find(item => item.Id === id).DSchutzE = true
      setItem('entPl', entPl)
      return promise(true)
    }
    else return new Promise((resolve, reject) => reject(new Error('no entPl available')))
  },
  changePiC() {
    return promise(true)
  },
  changeZyknPiC() {
    return promise(true)
  },
  deleteUser(id) {
    let entPl = getItem('entPl')
    if(id && entPl) {
      entPl = entPl.filter(item => item.Id !== id)
      setItem('entPl', entPl)
      return promise(true)
    }
    else return new Promise((resolve, reject) => reject(new Error('no entPl available')))
  },
  deleteUserSVz(id) {
    let svz = getItem('SVz')
    if(id && svz) {
      svz = svz.filter(item => item.Id !== id)
      setItem('entPl', svz)
      return promise(true)
    }
    else return new Promise((resolve, reject) => reject(new Error('no svz available')))
  },
  deleteCycl(id) {
    let cycl = getItem('zyklen')
    if(id && cycl) {
      cycl = cycl.filter(item => item.Id !== id)
      setItem('zyklen', cycl)
      return promise(true)
    }
    else return new Promise((resolve, reject) => reject(new Error('no zyklen available')))
  },

  saveZyklusEb(payload) {
    let zyklen = getItem('zyklen')
    if(zyklen && payload.zid && payload.eb) {
      zyklen.find(item => item.Id === payload.zid).EB = JSON.stringify(payload.eb)
      setItem('zyklen', zyklen)
      return promise(true)
    }
    else return new Promise((resolve, reject) => reject(new Error('no zyklen available')))
  },
  saveZyklusAbschluss(payload) {
    let zyklen = getItem('zyklen')
    if(zyklen && payload.zid && payload.abschluss) {
      zyklen.find(item => item.Id === payload.zid).Abschluss = payload.abschluss
      setItem('zyklen', zyklen)
      return promise(true)
    }
    else return new Promise((resolve, reject) => reject(new Error('no zyklen available')))
  },
  saveZyklusEval(payload) {
    let zyklen = getItem('zyklen')
    if(zyklen && payload.zid) {
      zyklen.find(item => item.Id === payload.zid).Eval = payload.eval
      setItem('zyklen', zyklen)
      return promise(true)
    }
    else return new Promise((resolve, reject) => reject(new Error('no zyklen available')))
  },
  loadEntZiele(zid) {
    let entZiele = getItem('entZiele')
    if(entZiele) entZiele = entZiele.filter(item => zid === item.RefZ)
    else entZiele = []
    return promise(entZiele)
  },
  addEntZiel(goal) {
    let goals = getItem('entZiele')
    if(goals) goals.push(goal)
    else goals = [goal]

    setItem('entZiele', goals)
    return promise(goal)
  },
  removeEntZiel(payload) {
    let goals = getItem('entZiele')
    if(goals && payload.goalId) {
      goals = goals.filter(item => item.Id !== payload.goalId)
      setItem('entZiele', goals)
      return promise(true)
    }
    else return new Promise((resolve, reject) => reject(new Error('no goals available')))
  },
  updateEntZiel(payload) {
    let goals = getItem('entZiele')
    if(goals && payload.goalId && payload.zyklusId) {
      goals.forEach(goal => {
        if(goal.Id === payload.goalId && goal.RefZ === payload.zyklusId) {
          // update goal
          goal.Title = payload.Title
          goal.IstStand = payload.IstStand
          goal.Entwicklungsziel = payload.Entwicklungsziel
        }
      })
      setItem('entZiele', goals)
      return promise(true)
    }
  },
  saveEntZielReuse(payload) {
    let goals = getItem('entZiele')
    if(goals && payload.goalId) {
      goals.find(item => item.Id === payload.goalId && item.RefZ === payload.zyklusId).Reuse = payload.reuse
      setItem('entZiele', goals)
      return promise(true)
    }
    else return new Promise((resolve, reject) => reject(new Error('no goals available')))
  },
  saveZielEvaluation(evalItem) {
    let goals = getItem('entZiele')
    if(goals && evalItem && evalItem.Id) {
      goals.find(item => item.Id === evalItem.Id && item.RefZ === evalItem.RefZ).EvalObj = JSON.stringify(evalItem)
      setItem('entZiele', goals)
      return promise(true)
    }
    else return new Promise((resolve, reject) => reject(new Error('no entZiele available')))
  },
  setEntZielEBereiche(payload) {
    let goals = getItem('entZiele')
    if(goals && payload && payload.goalId) {
      goals.find(item => item.Id === payload.goalId && item.RefZ === payload.zyklusId).EBereiche = payload.eBereiche
      setItem('entZiele', goals)
      return promise(true)
    }
    else return new Promise((resolve, reject) => reject(new Error('no entZiele available')))
  },
  loadEntMk() {
    let entMk = getItem('entMk')
    return promise(entMk)
  },
  loadEntMTki() {
    let entMTKi = getItem('entMTKi')
    return promise(entMTKi)
  },
  loadEntMTkg() {
    let entMTKg = getItem('entMTKg')
    return promise(entMTKg)
  },
  addEntMki(entMki) {
    let entMk = getItem('entMk')
    if(entMk) entMk.push(entMki)
    else entMk = [entMki]

    setItem('entMk', entMk)
    return promise(entMki)
  },
  addEntMTki(entMTkii) {
    let entMTKi = getItem('entMTki')
    if(entMTKi) entMTKi.push(entMTkii)
    else entMTKi = [entMTkii]

    setItem('entMk', entMTKi)
    return promise(entMTKi)
  },
  addEntMTkg(entMTkgg) {
    let entMTkg = getItem('entMk')
    if(entMTkg) entMTkg.push(entMTkgg)
    else entMTkg = [entMTkgg]

    setItem('entMk', entMTkg)
    return promise(entMTkgg)
  },
  removeEntMki(payload) {
    let entMk = getItem('entMk')
    if(entMk && payload.measureId) {
      entMk = entMk.filter(item => item.Id !== payload.measureId)
      setItem('entMk', entMk)
      return promise(true)
    }
    else return new Promise((resolve, reject) => reject(new Error('no measures available')))
  },
   updateEntMki(payload) {
    let entMk = getItem('entMk');
    if (entMk && payload.measureId && payload.zyklusId) {
      entMk.forEach(entMki => {
        if (entMki.Id === payload.measureId && entMki.RefZ === payload.zyklusId) {
          // Update measure, nur wenn das entsprechende Feld im payload vorhanden ist
          if (payload.Title !== undefined) entMki.Title = payload.Title;
          if (payload.Kurzbeschreibung !== undefined) entMki.Kurzbeschreibung = payload.Kurzbeschreibung;
          if (payload.Sozialform !== undefined) entMki.Sozialform = payload.Sozialform;
          if (payload.Voraussetzung !== undefined) entMki.Voraussetzungen_x002f_Materialie = payload.Voraussetzung;
          if (payload.Zeitumfang !== undefined) entMki.Zeitumfang = payload.Zeitumfang;
          if (payload.Altersgruppe !== undefined) entMki.Altersgruppe = payload.Altersgruppe;
          if (payload.Lernumgebung !== undefined) entMki.Lernumgebung = payload.Lernumgebung;
          if (payload.Plan_und_Durch !== undefined) entMki.Plan_und_Durch = payload.Plan_und_Durch;
          if (payload.Praxistipps !== undefined) entMki.Praxistipps = payload.Praxistipps;
          if (payload.Evaluationskriterien_x0020__x002 !== undefined) entMki.Evaluationskriterien_x0020__x002 = payload.Evaluationskriterien_x0020__x002;
          if (payload.Evaluationskriterien_x0020__x0020 !== undefined) entMki.Evaluationskriterien_x0020__x0020 = payload.Evaluationskriterien_x0020__x0020;
          if (payload.RefMeth !== undefined) entMki.RefMeth = payload.RefMeth;
          if (payload.RefMethBeschr !== undefined) entMki.RefMethBeschr = payload.RefMethBeschr;
          if (payload.RefMethZiel !== undefined) entMki.RefMethZiel = payload.RefMethZiel;
          if (payload.RefZie !== undefined) entMki.RefZie = payload.RefZie;
          if (payload.Reuse !== undefined) entMki.Reuse = payload.Reuse;
          if (payload.EvalObj !== undefined) entMki.EvalObj = payload.EvalObj;
        }
      });
      setItem('entMk', entMk);
      return promise(true);
    }
  },
  
  updateEntMTki(payload) {
    let entMTKi = getItem('entMTKi');
    if (entMTKi && payload.Id) {
      entMTKi.forEach(entMTkii => {
        if (entMTkii.Id === payload.Id) {
          // Update fields only if they exist in payload
          if (payload.Title !== undefined) entMTkii.Title = payload.Title;
          if (payload.RefMethZiel !== undefined) entMTkii.RefMethZiel = payload.RefMethZiel;
          if (payload.RefMethBeschr !== undefined) entMTkii.RefMethBeschr = payload.RefMethBeschr;
          if (payload.Zeitumfang !== undefined) entMTkii.Zeitumfang = payload.Zeitumfang;
          if (payload.Sozialform !== undefined) entMTkii.Sozialform = payload.Sozialform;
          if (payload.Voraussetzungen_x002f_Materialie !== undefined) entMTkii.Voraussetzungen_x002f_Materialie = payload.Voraussetzungen_x002f_Materialie;
          if (payload.Kurzbeschreibung !== undefined) entMTkii.Kurzbeschreibung = payload.Kurzbeschreibung;
          if (payload.Eval !== undefined) entMTkii.Evaluationskriterien_x0020__x002 = payload.Eval;
          if (payload.RefMethId !== undefined) entMTkii.RefMethId = payload.RefMethId;
          if (payload.Lernumgebung !== undefined) entMTkii.Lernumgebung = payload.Lernumgebung;
          if (payload.Altersgruppe !== undefined) entMTkii.Altersgruppe = payload.Altersgruppe;
          if (payload.Praxistipps !== undefined) entMTkii.Praxistipps = payload.Praxistipps;
          if (payload.EntwicklBereiche !== undefined) entMTkii.EntwicklBereiche = payload.EntwicklBereiche;
          if (payload.Plan_und_Durch !== undefined) entMTkii.Plan_und_Durch = payload.Plan_und_Durch;
          if (payload.Beispiel !== undefined) entMTkii.Beispiel = payload.Beispiel;
          if (payload.RefTemplategroup !== undefined) entMTkii.RefTemplategroupId = payload.RefTemplategroup;
        }
      });
      setItem('entMTKi', entMTKi);
      return promise(true);
    }
  },

   updateEntMTkg(payload) {
    let entMTKg = getItem('entMTKg');
    if (entMTKg && payload.Id) {
      entMTKg.forEach(entMTkgg => {
        if (entMTkgg.Id === payload.Id) {
          // Update fields only if they exist in payload
          if (payload.Title !== undefined) entMTkgg.Title = payload.Title;
          if (payload.RefMethZiel !== undefined) entMTkgg.RefMethZiel = payload.RefMethZiel;
          if (payload.RefMethBeschr !== undefined) entMTkgg.RefMethBeschr = payload.RefMethBeschr;
          if (payload.Zeitumfang !== undefined) entMTkgg.Zeitumfang = payload.Zeitumfang;
          if (payload.Sozialform !== undefined) entMTkgg.Sozialform = payload.Sozialform;
          if (payload.Voraussetzungen_x002f_Materialie !== undefined) entMTkgg.Voraussetzungen_x002f_Materialie = payload.Voraussetzungen_x002f_Materialie;
          if (payload.Kurzbeschreibung !== undefined) entMTkgg.Kurzbeschreibung = payload.Kurzbeschreibung;
          if (payload.Eval !== undefined) entMTkgg.Evaluationskriterien_x0020__x002 = payload.Eval;
          if (payload.RefMethId !== undefined) entMTkgg.RefMethId = payload.RefMethId;
          if (payload.Lernumgebung !== undefined) entMTkgg.Lernumgebung = payload.Lernumgebung;
          if (payload.Altersgruppe !== undefined) entMTkgg.Altersgruppe = payload.Altersgruppe;
          if (payload.Praxistipps !== undefined) entMTkgg.Praxistipps = payload.Praxistipps;
          if (payload.EntwicklBereiche !== undefined) entMTkgg.EntwicklBereiche = payload.EntwicklBereiche;
          if (payload.Plan_und_Durch !== undefined) entMTkgg.Plan_und_Durch = payload.Plan_und_Durch;
          if (payload.Beispiel !== undefined) entMTkgg.Beispiel = payload.Beispiel;
          if (payload.AdminChecked !== undefined) entMTkgg.AdminChecked = payload.AdminChecked;
        }
      });
      setItem('entMTKg', entMTKg);
      return promise(true);
    }
  }
  ,
   
  saveEntMkiReuse(payload) {
    let entMk = getItem('entMk')
    if(entMk && payload.measureId) {
      entMk.find(item => item.Id === payload.measureId).Reuse = payload.reuse
      setItem('entMk', entMk)
      return promise(true)
    }
    else return new Promise((resolve, reject) => reject(new Error('no entMk available')))
  },
  saveMkiEvaluation(evalItem) {
    let entMk = getItem('entMk')
    if(entMk && evalItem && evalItem.Id) {
      entMk.find(item => item.Id === evalItem.Id).EvalObj = JSON.stringify(evalItem)
      setItem('entMk', entMk)
      return promise(true)
    }
    else return new Promise((resolve, reject) => reject(new Error('no entMk available')))
  },
  setEntMkiRefZie(payload) {
    let entMk = getItem('entMk')
    if(entMk && payload.measureId) {
      entMk.find(item => item.Id === payload.measureId).RefZie = payload.refZie
      setItem('entMk', entMk)
      return promise(true)
    }
    else return new Promise((resolve, reject) => reject(new Error('no entMk available')))
  },
  loadAurList(studentId) {
    let aurList = getItem('aurList')
    if(aurList) aurList = aurList.filter(item => studentId === item.RefPEPId)
    else aurList = null
    return promise(aurList)
  },
  addAur(aur) {
    let aurList = getItem('aurList')
    if(aur && aurList) aurList.push(aur)
    else aurList = [aur]
    setItem('aurList', aurList)
    return promise(aur)
  },
  saveAur(aur) {
    let aurList = getItem('aurList')
    if(aurList && aur && aur.Id) {
      // the aur is uniquely identified by the combination of id, studentId and zyklusId
      aurList.find(item => item.Id === aur.Id && item.RefPEPId === aur.RefPEPId && item.RefZId === aur.RefZId).AuRF = aur.AuRF
      setItem('aurList', aurList)
      return promise(true)
    }
    else return new Promise((resolve, reject) => reject(new Error('no aurList available')))
  },
  loadNotes(studentId) {
    let notes = getItem('notes')
    if(notes) notes = notes.filter(item => studentId === item.RefPEPId)
    else notes = null
    return promise(notes)
  },
  addNote(note) {
    let notes = getItem('notes')
    if(note && notes) notes.push(note)
    else notes = [note]
    setItem('notes', notes)
    return promise(note)
  },
  removeNote(payload) {
    let notes = getItem('notes')
    if(notes && payload.noteId && payload.RefPEPId) {
      notes = notes.filter(item => {
        // the note is uniquely identified by the combination of id and studentid
        if(item.Id === payload.noteId && item.RefPEPId === payload.RefPEPId) return false
        else return true
      })
      setItem('notes', notes)
      return promise(true)
    }
    else return new Promise((resolve, reject) => reject(new Error('no notes available')))
  },
  updateNote(payload) {
    let notes = getItem('notes')
    if(notes && payload.noteId && payload.RefPEPId) {
      notes.forEach(note => {
        if(note.Id === payload.noteId && note.RefPEPId === payload.RefPEPId) {
          // update note
          note.Title = payload.Title
          note.OData__Comments = payload.OData__Comments
          note.RefZId = payload.RefZId
        }
      })
      setItem('notes', notes)
      return promise(true)
    }
  },
  setLocalData(data) {
    // replace localStorage with local data
    if(data && Array.isArray(data.entPl) && data.entPl.length) setLocalStorageItem('entPl', JSON.stringify(data.entPl))
    if(data && Array.isArray(data.svz) && data.svz.length) setLocalStorageItem('svz', JSON.stringify(data.svz))
    if(data && Array.isArray(data.zyklen) && data.zyklen.length) setLocalStorageItem('zyklen', JSON.stringify(data.zyklen))
    if(data && Array.isArray(data.entZiele) && data.entZiele.length) setLocalStorageItem('entZiele', JSON.stringify(data.entZiele))
    if(data && Array.isArray(data.entMk) && data.entMk.length) setLocalStorageItem('entMk', JSON.stringify(data.entMk))
    if(data && Array.isArray(data.entMTKi) && data.entMTKi.length) setLocalStorageItem('entMTKi', JSON.stringify(data.entMTKi))
    if(data && Array.isArray(data.entMTKg) && data.entMTKg.length) setLocalStorageItem('entMTKg', JSON.stringify(data.entMTKg))
    if(data && Array.isArray(data.aurList) && data.aurList.length) setLocalStorageItem('aurList', JSON.stringify(data.aurList))
    if(data && Array.isArray(data.notes) && data.notes.length) setLocalStorageItem('notes', JSON.stringify(data.notes))
    if(data && data.userObject) setLocalStorageItem('userObject', JSON.stringify(data.userObject))
    if(data && data.groupObject) setLocalStorageItem('groupObject', JSON.stringify(data.groupObject))
    if(data && Array.isArray(data.GroupAdministration) && data.GroupAdministration.length) setLocalStorageItem('GroupAdministration', JSON.stringify(data.GroupAdministration))
    if(data && data.administrationObject) setLocalStorageItem('administrationObject', JSON.stringify(data.administrationObject))
    if(data && data.diagnostik) setLocalStorageItem('diagnostik', JSON.stringify(data.diagnostik))
    if(data && Array.isArray(data.documents) && data.documents.length) setLocalStorageItem('documents', JSON.stringify(data.documents))

    return promise(true)
  },
  createLog() {
    return promise(true)
  },
  loadLogData() {
    return promise(null)
  },
  loadDocuments(payload) {
    let documents = JSON.parse(getLocalStorageItem('documents'))
    if(documents && Array.isArray(documents) && documents.length) documents = documents.filter(item => item.chiffre === payload.chiffre)
    return promise(documents)
  },
}

function promise(data) {
  return new Promise( resolve => {
    setTimeout(() => {
      if(data === "undefined" || data === null) resolve([])
      else resolve(data)
    }, 200)
  })
}

function getItem(itemName) {
  return JSON.parse(getLocalStorageItem(itemName))
}
function setItem(itemName, item) {
  setLocalStorageItem(itemName, JSON.stringify(item))
}
