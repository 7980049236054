var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"delete-button-container"},[_c('div',{staticClass:"delete-button",class:[
    _vm.confirmationVisible ? 'confirmation' : '',
    _vm.size === 'small' ? 'small-button' : '',
    _vm.isActive ? 'delete-button-active' : '',
    _vm.isDisabled ? 'delete-button-disabled' : ''
  ],on:{"click":_vm.handleClick}},[(!_vm.confirmationVisible)?_c('div',{staticClass:"delete-button-icon"},[_c('i',{staticClass:"ms-Icon ms-Icon--Delete"})]):_vm._e(),(_vm.confirmationVisible)?_c('div',{staticClass:"confirmation-container"},[_c('div',{staticClass:"confirmation-text-container"},[_c('div',{staticClass:"confirmation-text"},[_vm._v(_vm._s(_vm.confirmationText))])]),_c('div',{staticClass:"cancel-icon-container",on:{"click":function($event){$event.stopPropagation();return _vm.cancelDeletion.apply(null, arguments)}}},[_vm._m(0)])]):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cancel-icon"},[_c('i',{staticClass:"ms-Icon ms-Icon--Cancel"})])
}]
render._withStripped = true
export { render, staticRenderFns }