// set in which form gender is represented linguistically
export const entgendern = {
  pupil: "Schüler:in",
  pupils: "Schüler:innen",
  author: "Autor:in",
  they: "sie:er",
  possessiv_akkusativ_sg: "ihren:seinen",
  possessiv_akkusativ_pl: "ihre:seine",
  possessiv_genitiv_pl: "ihrer:seiner",
  class_spokesperson: "Klassensprecher:in",
  user: "Nutzer:in",
  users: "Nutzer:innen",
  classmates: "Mitschüler:innen",
  article_nominativ: "Der:die",
  article_dativ: "der:dem",
}
