import store from "../index"

const state = {
  zyklusId: null,
  goals: null,
  measures: null
}

const actions = {
  loadEvaluationGoals(_, zid) {
      store.dispatch('loadEntZiele', zid)
  },
  loadEvaluationMeasures(_, zid) {
    store.dispatch('loadEntMk', zid)
  }
}

function buildEaluationObj(data, type) {
  let criteria = null
  let notes = ""
  let rejectState = null
  let evalObj = data.EvalObj
  // if evalObj already in sharepoint exist load data
  if(typeof evalObj === 'string' && evalObj !== null) {
    evalObj = JSON.parse(evalObj)
    criteria = evalObj.Kriterien
    notes = evalObj.Hinweise
    // create reject state
    let criteriaValues = {}
    for(let kriterium in evalObj.Kriterien) {
      criteriaValues[kriterium] =  evalObj.Kriterien[kriterium].Value
    }
    rejectState = {Kriterien: criteriaValues, Hinweise: evalObj.Hinweise}
  // initalize goals critera if evalObject do not exist
  } else if(type === "goals") {
    criteria = {
      Zielerreichung: {Title: "Grad der Zielerreichung", Value: null, Answers: ["Vollkommen erreicht", "Überwiegend erreicht", "Teilweise erreicht", "Etwas erreicht", "Nicht erreicht"]},
      Vorgehen: {Title: "Weiteres Vorgehen", Value: null, Answers:["Abschluss", "Weiterführung",  "Alternative", "Erneuter Versuch der Umsetzung"]}
    }
    rejectState = {Kriterien: {Zielerreichung: null, Erfolg: null, Vorgehen: null}, Hinweise: notes}
  // initalize measure critera if evalObject do not exist
  } else if(type === "measures") {
    criteria = {
      Planumsetzung: {Title: "Umsetzung der Entwicklungsmaßnahme", Value: null, Answers:["Wie geplant", "Modifiziert bzw. inkonsequent", "Nicht umgesetzt"]},
      Erfolg: {Title: "Entwicklungsmaßnahme", Value: null, Answers:["Erfolgreich", "Teilweise erfolgreich", "Nicht erfolgreich"]},
      Vorgehen: {Title: "Weiteres Vorgehen", Value: null, Answers:["Abschluss", "Erneuter Versuch der Umsetzung", "Weiterführung", "Alternative"]}
    }
    rejectState = {Kriterien: {Planumsetzung: null, Erfolg: null, Vorgehen: null}, Hinweise: notes}
  } else {
    console.log("error: there exist no sharepoint data and type is neither goals nor measures")
  }

  let obj = {
    Id: data.Id,
    ZyklusId: data.RefZ,
    Title: data.Title,
    Kriterien: criteria,
    rejectState: rejectState,
    Hinweise: notes,
    isEdited: false,
    Type: type
  }
  return obj
}

const getters = {
  evaluationGoals: state => zid => {
    // load goals for each change of cycle, as the goals are reloaded each time
    if(state.zyklusId !== zid && !state.loading) {
      store.dispatch("loadEvaluationGoals", zid)
    }
    return state.goals
  },
  evaluationMeasures: state => zid => {

    // only needs to be loaded once, as measures are all loaded at once
    if(state.measures === null) store.dispatch('loadEvaluationMeasures', zid)
    if(Array.isArray(state.measures) && state.measures.length) return state.measures.filter(item => item.ZyklusId === zid)
    else null
  }
}

const mutations = {
  setEvaluationGoals: (state, goals) => {
    state.goals = goals
  },
  setEvaluationMeasures: (state, measures) => {
    state.measures = measures
  },
  setEvaluationZyklusId: (state, zyklusId) => {
    state.zyklusId = zyklusId
  },
  addEvaluationGoal: (state, goal) => {
    if(goal) state.goals.push( buildEaluationObj(goal, "goals") )
  },
  removeEvaluationGoal: (state, payload) => {
    if(payload && payload.goalId && payload.zyklusId === state.zyklusId) {
      state.goals = state.goals.filter(item => item.Id !== payload.goalId)
    }
  },
  updateEvaluationGoal: (state, payload)  => {
    if(payload && payload.goalId && payload.zyklusId === state.zyklusId) {
      for(let goal of state.goals) {
        if(goal.Id === payload.goalId) goal.Title = payload.Title
      }
    }
  },
  createEvaluationGoals: (state, goals) => {
    let evaluationGoals = []
    if(Array.isArray(goals) && goals.length) {
      for(let dataItem of goals) {
        let goal = buildEaluationObj(dataItem, "goals")
        if(goal) evaluationGoals.push(goal)
      }
    }
    state.goals = evaluationGoals
  },
  addEvaluationMeasure: (state, measure) => {
    if(measure) state.measures.push( buildEaluationObj(measure, "measures") )
  },
  removeEvaluationMeasure: (state, payload) => {
    if(payload && payload.measureId && payload.zyklusId === state.zyklusId) {
      state.measures = state.measures.filter(item => item.Id !== payload.measureId)
    }
  },
  updateEvaluationMeasure: (state, payload)  => {
    if(payload && payload.measureId && payload.zyklusId === state.zyklusId) {
      for(let measure of state.measures) {
        if(measure.Id === payload.measureId) measure.Title = payload.Title
      }
    }
  },
  createEvaluationMeasures: (state, measures) => {
    let evaluationMeasures = []
    if(Array.isArray(measures) && measures.length) {
      for(let dataItem of measures) {
        let measure = buildEaluationObj(dataItem, "measures")
        if(measure) evaluationMeasures.push(measure)
      }
    }
    state.measures = evaluationMeasures
  },
  setPayload: (state, payload) => {
    if(payload.type === "goals") {
      state.goals.forEach(item => {
        setValues(item, payload)
      })
    } else if(payload.type === "measures") {
      state.measures.forEach(item => {
        setValues(item, payload)
      })
    }
  }
}

function setValues(item, payload) {
  if(item.Id === payload.id) {
    if(payload.task === "checkboxValue") {
      for(let kriterium in item.Kriterien) {
        if(kriterium === payload.kriterium) {
          item.Kriterien[kriterium].Value = payload.checkboxValue
        }
      }
    }
    else if(payload.task === "isEdited") {
      item.isEdited = payload.isEdited
    }
    else if(payload.task === "rejectState") {
      item.rejectState = payload.rejectState
    }
    else if(payload.task === "notes") {
      item.Hinweise = payload.hinweise
    }
  }
}


export default {
  state,
  actions,
  getters,
  mutations
}
