<style scoped>
.tests-container {
  height: 425px;
  display: grid;
  grid-template-rows: min-content auto;
  grid-template-areas:
  "header"
  "preview";
  padding: 33px 16px 10px 16px;
  text-align: left;
     text-align: -webkit-left;
   text-align: -moz-left;
  background-color: rgba(255,255,255,0.7);
}

.tests-header {
  grid-area: header;
  justify-self: start;
  margin-left: 33px;
  margin-bottom: 15px;
}

.tests-header-title {
  margin: 0;
  padding: 0;
}

.tests-preview-container {
  grid-area: preview;
  display: grid;
}

.tests-preview-list,
.ergebnis-preview-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow-y: auto;
}

.tests-preview-item {
    line-height: 29px;
   min-height: 78px;
  font-size: 16px;
  display: grid;
     grid-template-columns: auto;
    grid-template-rows: auto auto;
    cursor: pointer;
    border: 1px solid rgba(255,255,255,0.4);
}
.no-results {
  grid-template-rows: auto;
  min-height: 48px
}
.ergebnis-preview-item {
    line-height: 29px;
   height: 48px;
  font-size: 16px;
  display: grid;
    grid-template-columns: auto auto ;
    cursor: pointer;
    border: 1px solid rgba(255,255,255,0.4);
     background-color:  rgba(255,255,255,0.2);
}
.tests-preview-item-container {
  height: 48px;
  display: inline-flex;
  font-family: 'aileronsemibold';
  justify-content: space-between;
}
.ergebnis-preview-item:hover {
    background-color: rgb(255 255 255);
}
.tests-preview-item-container:hover {
  background-color: rgb(255 255 255);
}
.tests-preview-item:hover {

  border: 1px solid lightgrey;
}
.tests-test-title {
  justify-self: start;
    align-self: center;
    margin: 10px 10px 10px 10px;
    overflow-wrap: anywhere;
}
.test-start {
 justify-self: end;
    align-self: center;
    margin: 10px 10px 10px 10px;
    overflow-wrap: anywhere;
}
.test-value {
  justify-self: start;
    align-self: center;
    margin: 10px 10px 10px 10px;
    overflow-wrap: anywhere;
}
.test-date {
  justify-self: end;
    align-self: center;
    margin: 10px 10px 10px 10px;
    overflow-wrap: anywhere;
}

</style>

<template>
  <div class="tests-container">
    <div class="tests-header">
      <h2 class="tests-header-title">
        Diagnostik/Test
      </h2>
    </div>
    <div class="tests-preview-container">
      <div v-if="!this.diagnostiktests">
       <span>Es wurde noch kein Test durchgeführt</span>
       <br>
       <span @click="opentest()">Test jetzt durchführen</span>
     </div>
       
        <ul v-else class="tests-preview-list">
 <li v-for="(test, $index) in diagnostiktests" :key="$index" class="tests-preview-item" :class="diagnostikergebnisse.length == 0 ? 'no-results' : ''">
   <div class="tests-preview-item-container" @click="test.Title === 'Ilmenau MINT-Test' ? opentest() : ''"> 
 <span class="tests-test-title" >  {{test.Title}} </span>
  <span class="test-start">Test starten</span>
   </div>
  
    <ul  class="ergebnis-preview-list" v-if="diagnostikergebnisse.length >= 1">
 <li v-for="(ergebnis, $index) in diagnostikergebnisse.filter(a => a.RefDiagId === test.Id)" :key="$index" class="ergebnis-preview-item" @click="showResult(ergebnis)">
<span class="test-value"> {{ergebnis.Ergebnis}}</span>
<span class="test-date"> {{ergebnis.Created | dateformer}}</span>
 </li>
  </ul>
 </li>
       
         </ul>
    </div>
  </div>
</template>
<script >
/*global _spPageContextInfo*/
/*eslint no-undef: "error"*/
export default {
  name: "ProfilDiagnostik",
   props: ["id"],
  data() {
    return {
      documents: null
    }
  },
   methods: {
     opentest() {
          this.scrollToTop() 
        this.$parent.$emit('opentest', true)
     },
      showResult(ergebnis) {
          this.scrollToTop() 
          this.$store.commit("showResult", ergebnis)
        this.$parent.$emit('showresult', true)
     },
      scrollToTop() {
      // window does not work in sharepoint
      if (typeof _spPageContextInfo !== "undefined") {
        document.getElementById('s4-workspace').scrollTo(0, 0)
      } else {
        window.scrollTo(0, 0)
      }
    },
   },
   computed: {
     diagnostiktests() {
       return this.$store.getters.diagnostik_test
     },
     diagnostikergebnisse() {
        let ergebnisse = this.$store.getters.diagnostik_ergebnisse
        if (ergebnisse !== null){
          this.$store.commit("diagnostikErgebnisseById" , this.id)
           return this.$store.getters.diagnostik_ergebnissebyId}
           else 
          return null
        
     }
   },
   filters: {
    dateformer(value) {
      if (value) {
        //  let options = { year: "numeric", month: "numeric", day: "numeric" };      
        //  let date = value.toLocaleDateString("de-DE", options);
        return new Date(value).toLocaleString("de-DE", {year: 'numeric', month: 'numeric', day: 'numeric'})
        
      }
    },}
}
</script>
