<style scoped>

.modal-fade-enter,
 .modal-fade-leave-active {
   opacity: 0;
 }

 .modal-fade-enter-active,
 .modal-fade-leave-active {
   transition: opacity .5s ease
 }

.modal-backdrop {
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background-color: rgba(255, 159, 159, 0.4);
   z-index: 1000;
   margin-left: 50px;
   display: grid;
   align-items: center;
   justify-items: center;
   backdrop-filter: blur(10px);
   -webkit-backdrop-filter: blur(10px);
 }
 /* if no backdrop support*/
 @supports not (backdrop-filter: blur(10px)) {
   .modal-backdrop::after {
     filter: url(#blur);
   }
 }

 .form-container {
   max-width: 1500px;
   overflow: auto;
   margin-left: 7px;
   margin-right: 77px;
   font-size: 16px;
   background-color: #fdf8f8;
   cursor: default;
   display: grid;
   grid-template-columns: 5fr 4fr;
   grid-template-rows: auto;
 }

 @media only screen and (max-height: 700px) {
   .form-container {
     height: 90%;
   }
 }
  @media only screen and (max-width: 1200px) {
   .form-container {
    grid-template-columns: 75% 25%;
    max-height: 90%;
    max-width: 92%;   }
    .form-left-container-button-container {
display: flex;
    }
 }


 .form-documents-container {
   padding: 24px 57px 57px 0;
   display: grid;
   grid-template-rows: 100px auto;
 }

.form-left-container {
  padding: 57px;
  display: grid;
  grid-template-rows: min-content min-content min-content min-content;
}

.form-left-container-headline {
  max-width: 600px;
  font-family: aileronblack, sans-serif;
  font-size: calc(32px + (36 - 32) * ((100vh - 300px) / (1900 - 300))) !important;
  line-height: calc(1.3em + (1.5 - 1.2) * ((100vh - 300px)/(1600 - 300)));
  letter-spacing: .7px;
  text-align: left;
   text-align: -webkit-left;
   text-align: -moz-left;
  margin-bottom: 70px;
}

.form-left-container-text {
  font-size: calc(16px + (22 - 16) * ((100vh - 300px) / (1900 - 300))) !important;
  line-height: calc(1.3em + (1.5 - 1.2) * ((100vh - 300px)/(1600 - 300)));
  letter-spacing: .7px;
  text-align: left;
     text-align: -webkit-left;
   text-align: -moz-left;
  margin-bottom: 40px;
}
.margin-bottom {
  margin-bottom: 10px;
}

.form-left-container-selection {
  font-size: calc(16px + (22 - 16) * ((100vh - 300px) / (1900 - 300)));
  margin-bottom: 60px;
}
.form-left-selection {
  margin-bottom: 12px;
  display: grid;
  justify-content: start;
  grid-template-columns: auto auto;
}
.form-left-selection-label {
  text-align: left;
     text-align: -webkit-left;
   text-align: -moz-left;
  margin-left: 40px;
}

.form-left-container-button-container {
 display: grid;
 grid-template-columns: auto auto auto;
}

.form-button {
 justify-content: center;
 background-color: #ffffffb3;
 color: rgb(68,68,68);
 width: 200px;
 height: 48px;
 line-height: 48px;
 text-align: center;
    text-align: -webkit-center;
   text-align: -moz-center;
 border: 1px solid black;
 margin-right: 10px;
}
.form-button:hover {
 background-color: white;
 border: 1px solid rgba(0, 0, 30, 0.56)
}
.radio-field {
  position: relative;
}
.radio-field > input[type='radio']{ height: 0; width: 0; opacity: 0 }

.radio-field > input[type='radio'] + label{
   position: absolute;
  display: flex;
  margin: 0; 
  align-items: center;
  /* color: #9e9e9e; */
  transition: color 250ms cubic-bezier(.4,.0,.23,1);
  top: 0;
  left: 0;
}
.radio-field > input[type='radio'] + label > ins{
  position: absolute;
  display: block;
  bottom: 0;
  left: 2em;
  height: 0;
  width: 100%;
  overflow: hidden;
  text-decoration: none;
  transition: height 300ms cubic-bezier(.4,.0,.23,1);
}
.radio-field > input[type='radio'] + label > ins > i{
  position: absolute;
  bottom: 0;
  font-style: normal;
  color: #4FC3F7;
}
.radio-field > input[type='radio'] + label > span{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0;
  width: 24px;
  height: 24px;
  background: transparent;
  border: 2px solid #9E9E9E;
  border-radius: 2px;
  cursor: pointer;  
  transition: all 250ms cubic-bezier(.4,.0,.23,1);
}

.radio-field > input[type='radio'] + label:hover, input[type='radio']:focus + label{
  color: black;
}
.radio-field > input[type='radio'] + label:hover > span, input[type='radio']:focus + label > span{
  background: rgba(255,255,255,.1);
}
.radio-field > input[type='radio'][vchecked="true"] + label > ins{ height: 100%; }

.radio-field > input[type='radio'][vchecked="true"] + label > span{
  border: 2.1px solid #282825;
  animation: shrink-bounce 200ms cubic-bezier(.4,.0,.23,1);
  background: white
}
.radio-field > input[type='radio'][vchecked="true"] + label > span:before{
  content: "";
  position: absolute;
  top: 40%;
  left: 28%;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  transform: rotate(45deg);
  transform-origin: 0% 100%;
  animation: checkbox-check 125ms 250ms cubic-bezier(.4,.0,.23,1) forwards;
}

@keyframes shrink-bounce{
  0%{
    transform: scale(1);
  }
  33%{    
    transform: scale(.85);
  }
  100%{
    transform: scale(1);    
  }
}
@keyframes checkbox-check{
  0%{
    width: 0;
    height: 0;
    border-color: #212121;
    transform: translate3d(0,0,0) rotate(45deg);
  }
  33%{
    width: .2em;
    height: 0;
    transform: translate3d(0,0,0) rotate(45deg);
  }
  100%{    
    width: .2em;
    height: .5em;    
    border-color: #212121;
    transform: translate3d(0,-.5em,0) rotate(45deg);
  }
}
</style>


 <template>
   <transition name="modal-fade">
      <div class="modal-backdrop">
        <div class="form-container">
          <div class="form-left-container">
            <div class="form-left-container-headline">
              Dieser Entwicklungsplan ist noch gesperrt
            </div>
            <div class="form-left-container-text">
              <div class="margin-bottom">
                Ohne die Einstimmung der {{$entgendern.pupil}} bzw. deren gesetzlichen Vertreters, kann dieser Entwicklungsplan nicht bearbeitet werden.
              </div>
              <div>
                Die Bearbeitung des Entwicklungsplans kann erst mit der Vorlage der Datenschutzerklärung in unterzeichneter Form erfolgen.
              </div>
            </div>
            <div class="form-left-container-selection">
              <div class="form-left-selection radio-field">
                <input type="radio" id="save" value="save" name="agreement" :vchecked="vchecked('save')" v-model="checkboxStatus"   class="checkbox-groups" >
                <label class="form-left-selection-label" for="save">
    <span></span>
    
    <ins><i></i></ins>
  </label>
  <span class="form-left-selection-label">Mir liegt eine unterzeichnete Datenschutzerklärung vor.</span>
                <!-- <label class="form-left-selection-label" for="save">Mir liegt eine unterzeichnete Datenschutzerklärung vor.</label> -->
              </div>
              <div class="form-left-selection radio-field">
                <input type="radio" id="delete" value="delete" :vchecked="vchecked('delete')" name="agreement" v-model="checkboxStatus" class="checkbox-groups">
                <label class="form-left-selection-label" for="delete">
    <span></span>
    
    <ins><i></i></ins>
  </label>
  <span class="form-left-selection-label">Mir liegt <b>keine</b> unterzeichnete Datenschutzerklärung vor. {{$entgendern.pupil}} entgültig löschen.</span>
                <!-- <label class="form-left-selection-label" for="delete">Mir liegt <b>keine</b> unterzeichnete Datenschutzerklärung vor. {{$entgendern.pupil}} entgültig löschen.</label> -->
              </div>
            </div>
            <div class="form-left-container-button-container">
              <button class="form-button removeButtonStyle" @click.prevent="saveAgreement">Jetzt freischalten</button>
              <button class="form-button removeButtonStyle" @click.prevent="deleteStudent">Endgültig löschen</button>
               <button class="form-button removeButtonStyle" @click.prevent="backtoEP">Zurück zur Übersicht</button>
            </div>
          </div>
          <div class="form-documents-container">
            <div> </div>
            <ProfilDokumente :showDate="false" />
          </div>
        </div>
      </div>
   </transition>
 </template>

 <script>
 import ProfilDokumente from '../Profil/ProfilDokumente'
 export default {
   name: 'FormLockscreen',
   components: {
     ProfilDokumente
   },
   data() {
     return {
       checkboxStatus: null
     }
   },
   mounted() {
     // hide footer in lockscreen
     let footer = document.getElementById("pepfooter")
     if(footer) {
       footer.style.zIndex = "-1"
     }
   },
   methods: {
     saveAgreement() {
       if(this.checkboxStatus === "save") {
         // show footer
         let footer = document.getElementById("pepfooter")
         if(footer) {
           footer.style.zIndex = "1"
         }
         this.$emit('agree')
       } else {
         alert("Um diesen Entwicklungsplan freizugeben, bestätigen Sie bitte, dass die entsprechenden Einwilligungen erteilt wurden.")
       }
     },
     deleteStudent() {
       if(this.checkboxStatus === "delete") {
         if(window.confirm("Sind Sie sicher, dass Sie die " +this.$entgendern.pupil +" endgültig löschen möchten?")) {
           this.$emit('delete')
         }
       } else {
         alert("Um diesen Entwicklungsplan zu löschen, bestätigen Sie dies bitte.")
       }
     },
     vchecked(val) {
       if (val === this.checkboxStatus) {
         return true
       }
       else return false
     },
     backtoEP() {
        this.$router.push({
          name: "UebersichtPlaene",
         
    })},
     }
   
 }
 </script>
