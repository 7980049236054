<style scoped>
.note-wrapper {
  margin-top: 50px;
  margin-right: 33px;
}

.note-container {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-areas:
    "preview detail";
}

.note-header {
  justify-self: start;
  margin-left: 33px;
  margin-bottom: 25px;
}
.note-header-title {
  margin: 0;
  padding: 0;
}

.note-preview-container {
  grid-area: preview;
  padding: 33px 16px 33px 16px;
  background-color: rgba(255, 255, 255, 0.3);
  display: grid;
  grid-template-rows: min-content min-content auto;
}

.note-detail-container {
  grid-area: detail;
  padding: 0px 16px 0px 16px;
  background-color: rgba(255, 255, 255, 0.8);
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: min-content auto min-content;
}

.separator {
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 30, 0.36);
  margin: 0;
}

.note-preview-command-bar {
  margin-bottom: 10px;
  background-color: transparent;
  z-index: 1;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr auto;
  background: #f2f2f2;

}

.note-preview-command-bar-search {
  margin-right: 50px;
  margin-top: 2px;
}

.note-preview-command-bar-search-input {
  margin: 5px 0px 5px 5px;
  padding: 5px;
  padding-right: 35px;
  font-size: 16px;
  background: inherit;
  outline: none;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 30, 0.36);
}

.note-preview-command-bar-search-input:hover {
  outline: none;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 30, 0.36);
}

.note-preview-command-bar-search-input:focus {
  outline: none;
  border: none;
  border-bottom: 1px solid rgb(0, 120, 212);
}

.note-preview-command-bar-close {
  font-size: 16px;
  margin-left: -25px;
  align-self: center;
  cursor: pointer;

}

.note-preview-command-bar-close:hover {
  color: rgb(0, 120, 212);
}

.note-preview-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  height: 400px;
  overflow-y: auto;
}

.note-preview-item {
  height: 79px;
  display: grid;
  grid-template-columns: 70px minmax(200px, auto) auto;
  grid-template-areas:
    "person content modified";
  cursor: pointer;
      box-shadow: var(--shadow-elevation-low);
      transition: all .2s ease;
}
@media only screen and (max-width: 1200px) {
  .note-preview-item {
    grid-template-columns: 15% minmax(55%, auto)  37%; 
  }
  .note-preview-item-modified {
    font-size: calc(14px + (11 - 14) * ((100vh - 300px) / (1900 - 300))) !important;
  }

}
@media only screen and (max-width: 1400px) {
  .s1-container {
  font-size: 14px !important;
}
}
.note-preview-item:hover {
  background-color: rgba(255, 255, 255, 0.4);
  box-shadow: var(--shadow-elevation-medium);
  transition: all .2s ease;
}
.active {
  background-color: rgba(255, 255, 255, 0.7);
}

.note-preview-item-person {
  grid-area: person;
  justify-self: center;
  align-self: center;
  margin: 10px 15px 10px 15px;
}

.note-preview-item-content {
  grid-area: content;
  justify-self: start;
  margin: 10px 10px 10px 10px;
  display: grid;
}

.note-preview-item-content-title {
  text-align: start;
     text-align: -webkit-start;
   text-align: -moz-start;
  font-family: 'aileronsemibold';
  font-size: calc(17px + (22 - 18) * ((100vh - 300px) / (1900 - 300))) !important;
}

.note-preview-item-content-message {
  text-align: start;
     text-align: -webkit-start;
   text-align: -moz-start;
  font-weight: normal;
  color: #4c586de0;
  font-size: calc(13px + (22 - 16) * ((100vh - 300px) / (1900 - 300))) !important;
}

.note-preview-item-modified {
  grid-area: modified;
  justify-self: end;
  align-self: start;
  color: grey;
  font-size: calc(11px + (22 - 16) * ((100vh - 300px) / (1900 - 300))) !important;
  margin: 10px 20px 10px 20px;
}

.text-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.aileron-thin {
  font-family: 'aileronthin';
  font-size: calc(16px + (22 - 16) * ((100vh - 300px) / (1900 - 300))) !important;
}

.note-detail-header-container {
  margin-left: 33px;
  display: grid;
  grid-template-columns: auto min-content;
}

.note-detail-header {
  margin-top: 50px;
  padding: 2px 14px;
  display: grid;
  grid-template-rows: auto auto;
}

.note-detail-header-status-container {
  margin-left: 2px;
  text-align: start;
     text-align: -webkit-start;
   text-align: -moz-start;
  font-size: 16px;
  color: #676767;
  display: grid;
  grid-template-columns: max-content max-content;
  grid-gap: 7px;
}
.note-detail-header-status-person {
  font-family: aileronsemibold;
  color: #2a5298d9;
}

.note-detail-header-headline {
  background-color: transparent;
  outline: none;
  border-top: 1px solid #ffffff;
  border-left: 1px solid #ffffff;
  border-right: 1px solid #ffffff;
  border-bottom: 1px solid rgba(0, 0, 30, 0.36);
  font-size: calc(30px + (22 - 16) * ((100vh - 300px) / (1900 - 300))) !important;
  font-family: aileronsemibold;
}
.note-detail-header-headline:hover {
  border-top: 1px solid transparent;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
}
.note-detail-header-headline:focus {
  border-top: 1px solid transparent;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
}

.note-detail-header-close {
  color: #80808080;
  margin-top: 15px;
  margin-right: 25px;
}

.note-detail-textbox {
  display: grid;
  margin: 15px 33px 15px 33px;
}

.note-detail-textbox-textarea {
  font-family: 'aileronregular';
  font-size: calc(18px + (22 - 16) * ((100vh - 300px) / (1900 - 300))) !important;
  resize: none;
  border: 1px #a1a0ab solid;
  padding: 12px 15px;
}

.hideEditMode {
  border: 1px solid transparent;
  background: transparent;
  outline: transparent;
  cursor: default;
}
.hideEditMode:focus {
  border: 1px solid transparent;
}
.hideEditMode:hover {
  border: 1px solid transparent;
}

.note-detail-footer {
  display: grid;
  grid-template-columns: auto min-content min-content;
  grid-gap: 20px;
  align-content: center;
  margin: 10px 33px 33px 33px;
  grid-template-areas:
  "select button button";
}
/* put selection upwards if window is small */
@media only screen and (max-width: 1450px ) {
  /* on a medium screen */
  .note-detail-footer {
    display: grid;
    grid-template-columns: auto min-content;
    grid-template-rows: min-content min-content;
    grid-template-areas:
    "select select"
    "button button";
    justify-content: start;
  }
}

.note-detail-header-dropdown {
  margin-left: 15px;
  margin-bottom: 5px;
  grid-area: select;
  display: grid;
  justify-content: start;
  align-items: center;
  grid-template-columns: auto auto;
  grid-gap: 20px;
  color: #676767;;
}

.note-detail-header-dropdown-select {
  min-width: 280px;
  padding: 8px;
  font-size: calc(16px + (22 - 16) * ((100vh - 300px) / (1900 - 300))) !important;
  background-color: white;
}

.note-detail-footer-button {
  z-index: 2;
}

.removeButtonStyle {
  all: inherit;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.notes-details-placeholder-container {
  justify-content: center;
  align-content: center;
}

.notes-details-placeholder-text {
  color: #2a5298d9;
  opacity: 0.3;
  cursor: default;
}

.notes-details-placeholder-icon {
  justify-self: center;
  width: 30%;
  opacity: 0.3;
}

.notes-add-icon-container {
  cursor: pointer;
}
.add-icon {
  width: 50%;
}

</style>

<template>
<div class="note-wrapper">
  <div class="note-container">
    <div class="note-preview-container">
      <div class="note-header">
        <h2 class="note-header-title">Notizen</h2>
      </div>
      <fv-CommandBar class="note-preview-command-bar" :options="options">
        <template v-if="searchbar" v-slot:right-space>
          <div class="note-preview-command-bar-search">
            <input id="searchBar" class="note-preview-command-bar-search-input" type="text" @keyup.enter.prevent v-model="searchNoteQuery" placeholder="Suche" />
            <i class="material-icons note-preview-command-bar-close" @click.stop.prevent="hideSearchbar">close</i>
          </div>
        </template>
      </fv-CommandBar>
      <ul class="note-preview-list">
        <li v-for="note in noteQuery" :key="note.Id" @click.stop.prevent="setactiveNoteId(note.Id)">
          <div class="note-preview-item" :class="{active: activeNoteId === note.Id}">
            <div class="note-preview-item-person">
              <fv-Persona size="40" :name="note.Author.Title" :showInfo="false" />
            </div>
            <div class="note-preview-item-content">
              <div class="note-preview-item-content-title text-overflow">
                <template v-if="note.Title">
                  {{note.Title}}
                </template>
                <template v-else>
                  Kein Titel
                </template>
              </div>
              <div class="note-preview-item-content-message text-overflow">
                {{note.OData__Comments}}
              </div>
            </div>
            <div class="note-preview-item-modified">
              {{note.Created | dateformer}}
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div v-if="hideEditMode === true && activeNoteId === null" class="note-detail-container notes-details-placeholder-container">
      <div v-if="!this.notesLength" class="notes-add-icon-container" @click.stop.prevent="createNewNote">
          <img :src="newnote" class="notes-details-placeholder-icon add-icon">
          <div class="notes-details-placeholder-text">Schreiben Sie Ihre erste Notiz.</div>
      </div>
      <div v-else>
        <object type="image/svg+xml" class="notes-details-placeholder-icon" :data="stickynote"></object>
        <div class="notes-details-placeholder-text">Um eine Notiz anzuzeigen, wählen Sie diese links aus.</div>
      </div>
    </div>
    <form v-if="!hideEditMode || activeNoteId !== null" class="form" @submit.stop.prevent="onSubmit">
      <div class="note-detail-container">
        <div class="note-detail-header-container">
          <!-- title container -->
          <div class="note-detail-header">
            <!-- name -->
            <div class="note-detail-header-status-container">
              Erstellt von
              <div class="note-detail-header-status-person">
                {{activeNoteUser}}
              </div>
            </div>
            <!-- title  -->
            <input v-model="activeNoteTitle" v-bind:class="{hideEditMode: hideEditMode}" :readonly="hideEditMode" class="note-detail-header-headline text-overflow" type="text" :placeholder="[hideEditMode ? '' : 'Betreff']" />
          </div>
          <!-- close button -->
          <button class="removeButtonStyle" @click.stop.prevent="setactiveNoteId(null)">
            <i class="material-icons note-detail-header-close">close</i>
          </button>
        </div>
        <!-- textbox -->
        <div class="note-detail-textbox">
          <textarea v-model="activeNoteMessage" v-bind:class="{hideEditMode: hideEditMode}" :readonly="hideEditMode" class="note-detail-textbox-textarea" :placeholder="[hideEditMode ? '' : 'Schreiben Sie hier ihre Notiz...']" />
        </div>
        <!-- footer -->
        <div class="note-detail-footer">
          <!-- dropdown -->
          <div class="note-detail-header-dropdown">
            Zuordnung
            <select v-model="activeNoteRefZId" :disabled="hideEditMode" class="note-detail-header-dropdown-select">
              <option :value="null" key="allCycles">Sichtbar in allen Zyklen</option>
              <option v-for="option in zyklen" :value="option.Id" :key="option.Id">{{option.Title}}</option>
            </select>
          </div>
          <!-- save button -->
          <div v-if="activeNoteAuthorId === userId" class="note-detail-footer-button formactionbutton formactionbuttonspeichern">
            <input class="removeButtonStyle" type="submit" :value="submitButtonLabel">
          </div>
          <!-- delete button -->
          <div v-if="activeNoteAuthorId === userId" class="note-detail-footer-button formactionbutton formactionbuttonspeichern">
            <button class="removeButtonStyle" @click.stop.prevent="deleteNote">Löschen</button>
          </div>
          <div class="note-detail-footer-spacer" v-else />
        </div>
      </div>
    </form>
  </div>
  <FormSaveDialog v-if="showModal" @cancel="cancelModal" @reject="rejectModal" @save="saveModal" />
</div>
</template>

<script>
import FormSaveDialog from "../Forms/FormSaveDialog"

export default {
  name: "ProfilNotizen",
  data() {
    return {
      /*
        hideEditMode = true && activeNoteId = null : default view
        hideEditMode = true && activeNoteId = id   : show active note
        hideEditMode = false && activeNoteId = id    : edit active note
        hideEditMode = false && activeNoteId = null  : new note
      */
      newnote: require(`../../../assets/note_add.svg`),
      stickynote: require(`../../../assets/sticky_note2.svg`),
      hideEditMode: true,
      activeNoteId: null,
      activeNoteRejectState: null,
      searchbar: false,
      searchNoteQuery: null,
      showModal: false,
      modalAction: null,
       isMobile: false, // Initialwert
      options: this.getOptions(false), // Initialisiere mit Desktop-Optionen
      
    }
  },
  props: ["studentId", "refZid", "zyklen"],
  components: {
    FormSaveDialog
  },

  methods: {
    checkIsMobile() {
      this.isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
      this.options = this.getOptions(this.isMobile);
    },
    getOptions(isMobile) {
      if (isMobile) {
        return[{
        name: "",
        icon: "Sort",
        iconColor: "rgba(0, 120, 212, 1)",
        secondary: [{
          name: "alphabetisch",
          icon: "FontColorA",
          iconColor: "rgba(0, 153, 204, 1)",
          func: this.alphasort,
        }, {
          type: "divider"
        }, {
          name: "Erstelldatum",
          icon: "EventDate",
          iconColor: "rgba(0, 153, 204, 1)",
          func: this.datesort
        }, ]
      }, {
        name: "",
        icon: "Add",
        iconColor: "rgba(0, 120, 212, 1)",
        func: this.openNewNote
      }, {
        name: "",
        icon: "Search",
        iconColor: "rgba(0, 120, 212, 1)",
        func: this.showSearchbar
      }];
      } else {
        return[{
        name: "Sortieren",
        icon: "Sort",
        iconColor: "rgba(0, 120, 212, 1)",
        secondary: [{
          name: "alphabetisch",
          icon: "FontColorA",
          iconColor: "rgba(0, 153, 204, 1)",
          func: this.alphasort,
        }, {
          type: "divider"
        }, {
          name: "Erstelldatum",
          icon: "EventDate",
          iconColor: "rgba(0, 153, 204, 1)",
          func: this.datesort
        }, ]
      }, {
        name: "Neu",
        icon: "Add",
        iconColor: "rgba(0, 120, 212, 1)",
        func: this.openNewNote
      }, {
        name: "Suche",
        icon: "Search",
        iconColor: "rgba(0, 120, 212, 1)",
        func: this.showSearchbar
      }];
      }
    },
    alphasort() {
      this.$store.commit('notealphasort')
    },
    datesort() {
      this.$store.commit('notedatesort')
    },
    cancelModal() {
      this.showModal = false
      this.modalAction = null
    },
    rejectModal() {
      // restore state from old note
      this.$store.commit('updateNote', {
        title: this.activeNoteRejectState.title,
        message: this.activeNoteRejectState.message,
        refZid: this.activeNoteRejectState.refZid,
        id: this.activeNoteId
      })

      if (this.modalAction && this.modalAction.type === "setactiveNoteId") {
        this.activeNoteId = this.modalAction.id
        this.hideEditMode = true
      } else if (this.modalAction && this.modalAction.type === "setNewNoteId") {
        this.createNewNote()
      }
      this.showModal = false
      this.modalAction = null
    },
    saveModal() {
      if (this.modalAction && this.modalAction.type === "setactiveNoteId") {
          this.submit(this.modalAction, modalAction => {
            this.activeNoteId = modalAction.id
            this.hideEditMode = true
          })
      } else if (this.modalAction && this.modalAction.type === "setNewNoteId") {
          this.submit(this.modalAction, () => {
            this.createNewNote()
          })
      }
      this.showModal = false
      this.modalAction = null
    },
    setactiveNoteId(id) {
      if (this.activeNoteId !== id) {
        // check if save dialog is needed
        if (!this.hideEditMode && this.activeNoteId && this.isNoteChanged) {
          this.showModal = true
          this.modalAction = {
            type: "setactiveNoteId",
            id: id
          }
        } else {
          this.activeNoteId = id
          this.hideEditMode = true
        }
      }
    },
    openNewNote() {
      // check if save dialog is needed
      if (!this.hideEditMode && this.activeNoteId && this.isNoteChanged) {
        this.showModal = true
        this.modalAction = {
          type: "setNewNoteId"
        }
      } else {
        this.createNewNote()
      }
    },
    createNewNote() {
      let note = {
        Id: this.$store.getters.nextNoteId,
        Title: "",
        OData__Comments: "",
        Created: new Date(Date.now()).toISOString(),
        Author: {
          Title: this.userTitle,
          Id: this.userId
        },
        AuthorId: this.userId,
        RefPEPId: this.studentId,
        RefZId: this.refZid
      }
      this.$store.dispatch("addNote", note)
      .then(noteId => {
        this.hideEditMode = false
        // set reject state for new note
        this.activeNoteRejectState = {
          title: "",
          message: "",
          refZid: this.refZid
        }
        // set new note as active note
        if(noteId)  this.activeNoteId = noteId
      })
    },
    onSubmit() {
      if (this.hideEditMode) {
        // button status 'Bearbeiten'
        this.hideEditMode = false
        this.activeNoteRejectState = {
          title: this.activeNoteTitle,
          message: this.activeNoteMessage,
          refZid: this.activeNoteRefZId
        }
      } else {
        // button status Speichern'
        if (this.isNoteChanged) {
          this.submit(this.modalAction, () => {
            this.hideEditMode = true
          })
        } else {
          this.hideEditMode = true
        }
      }
    },
    deleteNote() {
      if(window.confirm("Sind Sie sicher, dass Sie diese Notiz endgültig löschen möchten?")) {
        let deleteNoteId = this.activeNoteId
        this.hideEditMode = true
        this.activeNoteId = null
        this.$store.dispatch('removeNote', {
            noteId: deleteNoteId,
            RefPEPId: this.studentId
        })
      }
    },
    submit(modalAction, successfulReply) {
      this.$store.dispatch("updateNote", {
        noteId: this.activeNoteId,
        Title: this.activeNoteTitle,
        OData__Comments: this.activeNoteMessage,
        RefPEPId: this.studentId,
        RefZId: this.activeNoteRefZId
      })
      successfulReply(modalAction)
    },
    showSearchbar() {
      this.searchbar = true
      this.options.pop()
      // wait until searchbar is rendered
      this.$nextTick(function() {
        document.getElementById("searchBar").focus()
        document.getElementById("searchBar").select()
      })

    },
    hideSearchbar() {
      this.searchNoteQuery = ""
      this.searchbar = false
      this.options.push({
        name: "Suche",
        icon: "Search",
        iconColor: "rgba(0, 120, 212, 1)",
        func: this.showSearchbar
      })
    }
  },
  computed: {
    notes() {
      let notes = this.$store.getters.notes(this.studentId)
      // get notes for focused zyklus card
      if (this.refZid !== null && Array.isArray(notes) && notes.length) {
        notes = notes.filter(note => {
          return note.RefZId === this.refZid || note.RefZId === null
        })
      }
      return notes
    },
    notesLength() {
      if(this.notes) return this.notes.length
      else return null
    },
    activeNote() {
      if (this.activeNoteId) return this.$store.getters.getNoteById(this.activeNoteId)
      else return null
    },
    /* two-way data binding with vuex for input and textarea */
    activeNoteTitle: {
      get() {
        if (this.activeNote) return this.activeNote.Title
        else return null
      },
      set(value) {
        this.$store.commit('updateNote', {
          title: value,
          id: this.activeNoteId
        })
      }
    },
    activeNoteMessage: {
      get() {
        if (this.activeNote) return this.activeNote.OData__Comments
        else return null
      },
      set(value) {
        this.$store.commit('updateNote', {
          message: value,
          id: this.activeNoteId
        })
      }
    },
    activeNoteRefZId: {
      get() {
        if (this.activeNote) return this.activeNote.RefZId
        else return null
      },
      set(value) {
        this.$store.commit('updateNote', {
          refZid: value,
          id: this.activeNoteId
        })
      }
    },
    activeNoteDate() {
      let isoString = new Date(Date.now()).toISOString();
      if (this.activeNote) return this.activeNote.Created
     

      else return  isoString
    },
    activeNoteDate2() {
      if (this.activeNote) return this.activeNote.Created
      else return  this.$dayjs(new Date(), "DD.MM.YYYY", true).format()
    },
    activeNoteUser() {
      if (this.activeNote && this.activeNote.Author) return this.activeNote.Author.Title
      else return null
    },
    activeNoteAuthorId() {
      if (this.activeNote && this.activeNote.AuthorId) return this.activeNote.AuthorId
      else return null
    },
    submitButtonLabel() {
      if (this.hideEditMode) return "Bearbeiten"
      else return "Speichern"
    },
    noteQuery() {
      if (this.searchNoteQuery && Array.isArray(this.notes) && this.notes.length) {
        return this.notes.filter(item => {
          return this.searchNoteQuery.toLowerCase().split(' ').every(v => item.Title.toLowerCase().includes(v) || item.OData__Comments.toLowerCase().includes(v) || item.Author.Title.toLowerCase().includes(v))
        })
      } else {
        return this.notes;
      }
    },
    isNoteChanged() {
      if (this.activeNoteRejectState && this.activeNote.Title === this.activeNoteRejectState.title && this.activeNoteMessage === this.activeNoteRejectState.message && this.activeNoteRefZId === this.activeNoteRejectState.refZid) {
        return false
      } else {
        return true
      }
    },
    userTitle() {
      return this.$store.getters.userTitle
    },
    userId() {
      return this.$store.getters.userId
    },
  },
  created() {
    // Überprüfe die Bildschirmgröße beim Laden der Komponente
    this.checkIsMobile();

    // Füge einen Event-Listener hinzu, um die Größe des Fensters zu überwachen
    window.addEventListener('resize', this.checkIsMobile);
  },
  beforeDestroy() {
    // Entferne den Event-Listener, wenn die Komponente zerstört wird
    window.removeEventListener('resize', this.checkIsMobile);
  },
}
</script>
