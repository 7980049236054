//local data diagnostik
let diagnostik = {
    test: [],
    test1: [
      {
        __metadata: {
          id: "Web/Lists(guid'7197d5cb-8cd8-43ab-9d57-a6a93e264c85')/Items(1)",
          uri: "https://pep.orga-cloud.de/dev/_api/Web/Lists(guid'7197d5cb-8cd8-43ab-9d57-a6a93e264c85')/Items(1)",
          etag: '"1"',
          type: "SP.Data.DiagnostikListItem",
        },
        FirstUniqueAncestorSecurableObject: {
          __deferred: {
            uri: "https://pep.orga-cloud.de/dev/_api/Web/Lists(guid'7197d5cb-8cd8-43ab-9d57-a6a93e264c85')/Items(1)/FirstUniqueAncestorSecurableObject",
          },
        },
        RoleAssignments: {
          __deferred: {
            uri: "https://pep.orga-cloud.de/dev/_api/Web/Lists(guid'7197d5cb-8cd8-43ab-9d57-a6a93e264c85')/Items(1)/RoleAssignments",
          },
        },
        AttachmentFiles: {
          __deferred: {
            uri: "https://pep.orga-cloud.de/dev/_api/Web/Lists(guid'7197d5cb-8cd8-43ab-9d57-a6a93e264c85')/Items(1)/AttachmentFiles",
          },
        },
        ContentType: {
          __deferred: {
            uri: "https://pep.orga-cloud.de/dev/_api/Web/Lists(guid'7197d5cb-8cd8-43ab-9d57-a6a93e264c85')/Items(1)/ContentType",
          },
        },
        FieldValuesAsHtml: {
          __deferred: {
            uri: "https://pep.orga-cloud.de/dev/_api/Web/Lists(guid'7197d5cb-8cd8-43ab-9d57-a6a93e264c85')/Items(1)/FieldValuesAsHtml",
          },
        },
        FieldValuesAsText: {
          __deferred: {
            uri: "https://pep.orga-cloud.de/dev/_api/Web/Lists(guid'7197d5cb-8cd8-43ab-9d57-a6a93e264c85')/Items(1)/FieldValuesAsText",
          },
        },
        FieldValuesForEdit: {
          __deferred: {
            uri: "https://pep.orga-cloud.de/dev/_api/Web/Lists(guid'7197d5cb-8cd8-43ab-9d57-a6a93e264c85')/Items(1)/FieldValuesForEdit",
          },
        },
        File: {
          __deferred: {
            uri: "https://pep.orga-cloud.de/dev/_api/Web/Lists(guid'7197d5cb-8cd8-43ab-9d57-a6a93e264c85')/Items(1)/File",
          },
        },
        Folder: {
          __deferred: {
            uri: "https://pep.orga-cloud.de/dev/_api/Web/Lists(guid'7197d5cb-8cd8-43ab-9d57-a6a93e264c85')/Items(1)/Folder",
          },
        },
        ParentList: {
          __deferred: {
            uri: "https://pep.orga-cloud.de/dev/_api/Web/Lists(guid'7197d5cb-8cd8-43ab-9d57-a6a93e264c85')/Items(1)/ParentList",
          },
        },
        FileSystemObjectType: 0,
        Id: 1,
        ContentTypeId: "0x0100A3CD651426219D45A8CB2B873E627C97",
        Title: "Ilmenau MINT-Test",
        RefGroupId: null,
        Schreibschutz: false,
        Scope: "Gruppe",
        ID: 1,
        Modified: "2022-03-18T12:59:30Z",
        Created: "2022-03-18T12:59:30Z",
        AuthorId: 120,
        EditorId: 120,
        OData__UIVersionString: "1.0",
        Attachments: false,
        GUID: "8907716d-f09e-4042-8189-818e338fdce5",
      },
      {
        __metadata: {
          id: "Web/Lists(guid'7197d5cb-8cd8-43ab-9d57-a6a93e264c85')/Items(1)",
          uri: "https://pep.orga-cloud.de/dev/_api/Web/Lists(guid'7197d5cb-8cd8-43ab-9d57-a6a93e264c85')/Items(1)",
          etag: '"1"',
          type: "SP.Data.DiagnostikListItem",
        },
        FirstUniqueAncestorSecurableObject: {
          __deferred: {
            uri: "https://pep.orga-cloud.de/dev/_api/Web/Lists(guid'7197d5cb-8cd8-43ab-9d57-a6a93e264c85')/Items(1)/FirstUniqueAncestorSecurableObject",
          },
        },
        RoleAssignments: {
          __deferred: {
            uri: "https://pep.orga-cloud.de/dev/_api/Web/Lists(guid'7197d5cb-8cd8-43ab-9d57-a6a93e264c85')/Items(1)/RoleAssignments",
          },
        },
        AttachmentFiles: {
          __deferred: {
            uri: "https://pep.orga-cloud.de/dev/_api/Web/Lists(guid'7197d5cb-8cd8-43ab-9d57-a6a93e264c85')/Items(1)/AttachmentFiles",
          },
        },
        ContentType: {
          __deferred: {
            uri: "https://pep.orga-cloud.de/dev/_api/Web/Lists(guid'7197d5cb-8cd8-43ab-9d57-a6a93e264c85')/Items(1)/ContentType",
          },
        },
        FieldValuesAsHtml: {
          __deferred: {
            uri: "https://pep.orga-cloud.de/dev/_api/Web/Lists(guid'7197d5cb-8cd8-43ab-9d57-a6a93e264c85')/Items(1)/FieldValuesAsHtml",
          },
        },
        FieldValuesAsText: {
          __deferred: {
            uri: "https://pep.orga-cloud.de/dev/_api/Web/Lists(guid'7197d5cb-8cd8-43ab-9d57-a6a93e264c85')/Items(1)/FieldValuesAsText",
          },
        },
        FieldValuesForEdit: {
          __deferred: {
            uri: "https://pep.orga-cloud.de/dev/_api/Web/Lists(guid'7197d5cb-8cd8-43ab-9d57-a6a93e264c85')/Items(1)/FieldValuesForEdit",
          },
        },
        File: {
          __deferred: {
            uri: "https://pep.orga-cloud.de/dev/_api/Web/Lists(guid'7197d5cb-8cd8-43ab-9d57-a6a93e264c85')/Items(1)/File",
          },
        },
        Folder: {
          __deferred: {
            uri: "https://pep.orga-cloud.de/dev/_api/Web/Lists(guid'7197d5cb-8cd8-43ab-9d57-a6a93e264c85')/Items(1)/Folder",
          },
        },
        ParentList: {
          __deferred: {
            uri: "https://pep.orga-cloud.de/dev/_api/Web/Lists(guid'7197d5cb-8cd8-43ab-9d57-a6a93e264c85')/Items(1)/ParentList",
          },
        },
        FileSystemObjectType: 0,
        Id: 2,
        ContentTypeId: "0x0100A3CD651426219D45A8CB2B873E627C97",
        Title: "Recklinghausen Sport Test",
        RefGroupId: null,
        Schreibschutz: false,
        Scope: "Gruppe",
        ID: 2,
        Modified: "2022-03-18T12:59:30Z",
        Created: "2022-03-18T12:59:30Z",
        AuthorId: 120,
        EditorId: 120,
        OData__UIVersionString: "1.0",
        Attachments: false,
        GUID: "8907716d-f09e-4042-8189-818e338fdce5",
      },
    ],
    ergebnissebyId: [],
    ergebnisse: [
      {
        __metadata: {
          id: "Web/Lists(guid'6d4ee3ea-92d4-4573-aced-db817edbfc0d')/Items(1)",
          uri: "https://pep.orga-cloud.de/dev/_api/Web/Lists(guid'6d4ee3ea-92d4-4573-aced-db817edbfc0d')/Items(1)",
          etag: '"1"',
          type: "SP.Data.Diagnostik_x005f_ErgebnisseListItem",
        },
        FirstUniqueAncestorSecurableObject: {
          __deferred: {
            uri: "https://pep.orga-cloud.de/dev/_api/Web/Lists(guid'6d4ee3ea-92d4-4573-aced-db817edbfc0d')/Items(1)/FirstUniqueAncestorSecurableObject",
          },
        },
        RoleAssignments: {
          __deferred: {
            uri: "https://pep.orga-cloud.de/dev/_api/Web/Lists(guid'6d4ee3ea-92d4-4573-aced-db817edbfc0d')/Items(1)/RoleAssignments",
          },
        },
        AttachmentFiles: {
          __deferred: {
            uri: "https://pep.orga-cloud.de/dev/_api/Web/Lists(guid'6d4ee3ea-92d4-4573-aced-db817edbfc0d')/Items(1)/AttachmentFiles",
          },
        },
        ContentType: {
          __deferred: {
            uri: "https://pep.orga-cloud.de/dev/_api/Web/Lists(guid'6d4ee3ea-92d4-4573-aced-db817edbfc0d')/Items(1)/ContentType",
          },
        },
        FieldValuesAsHtml: {
          __deferred: {
            uri: "https://pep.orga-cloud.de/dev/_api/Web/Lists(guid'6d4ee3ea-92d4-4573-aced-db817edbfc0d')/Items(1)/FieldValuesAsHtml",
          },
        },
        FieldValuesAsText: {
          __deferred: {
            uri: "https://pep.orga-cloud.de/dev/_api/Web/Lists(guid'6d4ee3ea-92d4-4573-aced-db817edbfc0d')/Items(1)/FieldValuesAsText",
          },
        },
        FieldValuesForEdit: {
          __deferred: {
            uri: "https://pep.orga-cloud.de/dev/_api/Web/Lists(guid'6d4ee3ea-92d4-4573-aced-db817edbfc0d')/Items(1)/FieldValuesForEdit",
          },
        },
        File: {
          __deferred: {
            uri: "https://pep.orga-cloud.de/dev/_api/Web/Lists(guid'6d4ee3ea-92d4-4573-aced-db817edbfc0d')/Items(1)/File",
          },
        },
        Folder: {
          __deferred: {
            uri: "https://pep.orga-cloud.de/dev/_api/Web/Lists(guid'6d4ee3ea-92d4-4573-aced-db817edbfc0d')/Items(1)/Folder",
          },
        },
        ParentList: {
          __deferred: {
            uri: "https://pep.orga-cloud.de/dev/_api/Web/Lists(guid'6d4ee3ea-92d4-4573-aced-db817edbfc0d')/Items(1)/ParentList",
          },
        },
        FileSystemObjectType: 0,
        Id: 1,
        ContentTypeId: "0x0100CD39520B55DB7742A4BE83349A9BE740",
        Title: "Ilmenau",
        RefPEPId: 42,
        Antworten_Set: {
          Frage_1: "2",
          Frage_2: "3",
          Frage_3: "1",
          Frage_4: "3",
          Frage_5: "4",
          Frage_6: "3",
          Frage_7: "1",
          Frage_8: ["Luft", "Molekül", "Atom"],
          Frage_9: "2",
          Frage_10: "4",
          Frage_11: "4",
          Frage_12: "4",
          Frage_13: "3",
          Frage_14: "1",
          Frage_15: "2",
          Frage_16: "4",
          Frage_17: "2",
          Frage_18: "1",
          Frage_19: "4",
          Frage_20:  "4",
          Frage_21: "1",
          Frage_22: "2",
          Frage_23: "3",
          Frage_24: "2",
        },
        Antworten: [
          {
            id: "start",
            answer: null,
            answered: true,
            index: 0,
            options: [],
            description: "",
            className: "",
            type: "FlowFormSectionBreakType",
            html: null,
            required: true,
            jump: null,
            placeholder: null,
            mask: "",
            multiple: false,
            allowOther: false,
            other: null,
            language: {
              enterKey: "Enter",
              shiftKey: "Shift",
              ok: "OK",
              continue: "Starten",
              skip: "Skip",
              pressEnter: "oder ENTER drücken",
              multipleChoiceHelpText: "mehrere Antworten können abgegeben werden",
              multipleChoiceHelpTextSingle: "nur eine Antwort ist richtig",
              otherPrompt: "Other",
              placeholder: "Antwort hier eingeben",
              submitText: "Antwort bestätigen",
              longTextHelpText:
                "':shiftKey + :enterKey um einen Zeilenumbruch zu erzeugen.'",
              prev: "zurück",
              next: "vor",
              percentCompleted: ":percent% abgeschlossen",
              invalidPrompt: "ungültige Antwort",
              thankYouText: "Vielen Dank",
              successText: "Die Antworten wurden gespeichert",
              ariaOk: "Zum Fortfahren klicken",
              ariaRequired: "Diese Antwort muss gegeben werden",
              ariaPrev: "vorheriger Schritt",
              ariaNext: "nächster Schritt",
              ariaSubmitText: "Antwort per Klick absenden",
              ariaMultipleChoice: "Drücke :letter um zu wählen",
              ariaTypeAnswer: "Antwort hier eingeben",
            },
            tagline: "24 Fragen",
            title: "MINT-Fragebogen Goetheschule Ilmenau",
            subtitle: null,
            content:
              "Beantworte alle folgenden Fragen aus dem naturwissenschaftlichen Bereich. Ein direkte Auswertung erfolgt im Anschluss an die letzte Antwort",
            inline: false,
            helpText: null,
            helpTextShow: true,
            descriptionLink: [],
            min: null,
            max: null,
            nextStepOnAnswer: false,
          },
          {
            id: "Frage_1",
            answer: "1",
            answered: true,
            index: 1,
            options: [
              {
                label: "das kalte Becherglas die Flamme sehr stark kühlt",
                value: "1",
                selected: true,
                imageSrc: null,
                imageAlt: null,
              },
              {
                label:
                  "Luftsauerstoff mit dem Kerzenwachs reagiert und Kohlenstoffdioxid entsteht",
                value: "2",
                selected: false,
                imageSrc: null,
                imageAlt: null,
              },
              {
                label:
                  "Luftsauerstoff mit dem Kerzenwachs reagiert und Schwefeldioxid entsteht",
                value: "3",
                selected: false,
                imageSrc: null,
                imageAlt: null,
              },
              {
                label: "die Luftfeuchtigkeit sehr stark ansteigt",
                value: "4",
                selected: false,
                imageSrc: null,
                imageAlt: null,
              },
            ],
            description: "",
            className: "",
            type: "FlowFormMultipleChoiceType",
            html: "<br>",
            required: true,
            jump: null,
            placeholder: null,
            mask: "",
            multiple: false,
            allowOther: false,
            other: null,
            language: {
              enterKey: "Enter",
              shiftKey: "Shift",
              ok: "OK",
              continue: "Starten",
              skip: "Skip",
              pressEnter: "oder ENTER drücken",
              multipleChoiceHelpText: "mehrere Antworten können abgegeben werden",
              multipleChoiceHelpTextSingle: "nur eine Antwort ist richtig",
              otherPrompt: "Other",
              placeholder: "Antwort hier eingeben",
              submitText: "Antwort bestätigen",
              longTextHelpText:
                "':shiftKey + :enterKey um einen Zeilenumbruch zu erzeugen.'",
              prev: "zurück",
              next: "vor",
              percentCompleted: ":percent% abgeschlossen",
              invalidPrompt: "ungültige Antwort",
              thankYouText: "Vielen Dank",
              successText: "Die Antworten wurden gespeichert",
              ariaOk: "Zum Fortfahren klicken",
              ariaRequired: "Diese Antwort muss gegeben werden",
              ariaPrev: "vorheriger Schritt",
              ariaNext: "nächster Schritt",
              ariaSubmitText: "Antwort per Klick absenden",
              ariaMultipleChoice: "Drücke :letter um zu wählen",
              ariaTypeAnswer: "Antwort hier eingeben",
            },
            tagline: null,
            title:
              "Wenn ein Becherglas über eine brennende Kerze gestülpt wird, erlischt die Flamme, weil...",
            subtitle: null,
            content: null,
            inline: false,
            helpText: null,
            helpTextShow: false,
            descriptionLink: [],
            min: null,
            max: null,
            nextStepOnAnswer: false,
          },
          {
            id: "Frage_2",
            answer: "1",
            answered: true,
            index: 2,
            options: [
              {
                label:
                  "durch das Kohlenstoffdioxid der brennbare Stoff unter seine Entzündungstemperatur abgekühlt wird",
                value: "1",
                selected: true,
                imageSrc: null,
                imageAlt: null,
              },
              {
                label:
                  "das Kohlenstoffdioxid mit dem brennbaren Stoff zu einem nicht brennbaren Stoff reagiert",
                value: "2",
                selected: false,
                imageSrc: null,
                imageAlt: null,
              },
              {
                label: "das Kohlenstoffdioxid den Sauerstoff verdrängt",
                value: "3",
                selected: false,
                imageSrc: null,
                imageAlt: null,
              },
              {
                label: "das Kohlenstoffdioxid in der Hitze Kohlenstoff abgibt",
                value: "4",
                selected: false,
                imageSrc: null,
                imageAlt: null,
              },
            ],
            description: "",
            className: "",
            type: "FlowFormMultipleChoiceType",
            html: null,
            required: true,
            jump: null,
            placeholder: null,
            mask: "",
            multiple: false,
            allowOther: false,
            other: null,
            language: {
              enterKey: "Enter",
              shiftKey: "Shift",
              ok: "OK",
              continue: "Starten",
              skip: "Skip",
              pressEnter: "oder ENTER drücken",
              multipleChoiceHelpText: "mehrere Antworten können abgegeben werden",
              multipleChoiceHelpTextSingle: "nur eine Antwort ist richtig",
              otherPrompt: "Other",
              placeholder: "Antwort hier eingeben",
              submitText: "Antwort bestätigen",
              longTextHelpText:
                "':shiftKey + :enterKey um einen Zeilenumbruch zu erzeugen.'",
              prev: "zurück",
              next: "vor",
              percentCompleted: ":percent% abgeschlossen",
              invalidPrompt: "ungültige Antwort",
              thankYouText: "Vielen Dank",
              successText: "Die Antworten wurden gespeichert",
              ariaOk: "Zum Fortfahren klicken",
              ariaRequired: "Diese Antwort muss gegeben werden",
              ariaPrev: "vorheriger Schritt",
              ariaNext: "nächster Schritt",
              ariaSubmitText: "Antwort per Klick absenden",
              ariaMultipleChoice: "Drücke :letter um zu wählen",
              ariaTypeAnswer: "Antwort hier eingeben",
            },
            tagline: null,
            title:
              "Kohlendioxid ist als Wirkstoff in einigen Feuerlöschern enthalten. Das Feuer erlischt, weil...",
            subtitle: null,
            content: null,
            inline: false,
            helpText: null,
            helpTextShow: false,
            descriptionLink: [],
            min: null,
            max: null,
            nextStepOnAnswer: false,
          },
          {
            id: "Frage_3",
            answer: "1",
            answered: true,
            index: 3,
            options: [
              {
                label: "nur Energie freisetzen",
                value: "1",
                selected: true,
                imageSrc: null,
                imageAlt: null,
              },
              {
                label: "nur Energie aufnehmen",
                value: "2",
                selected: false,
                imageSrc: null,
                imageAlt: null,
              },
              {
                label: "Energie weder aufnehmen noch freisetzen",
                value: "3",
                selected: false,
                imageSrc: null,
                imageAlt: null,
              },
              {
                label:
                  "mal Energie verbrauchen und mal freisetzen, je nach Art des Erdöles.",
                value: "4",
                selected: false,
                imageSrc: null,
                imageAlt: null,
              },
            ],
            description: "",
            className: "",
            type: "FlowFormMultipleChoiceType",
            html: null,
            required: true,
            jump: null,
            placeholder: null,
            mask: "",
            multiple: false,
            allowOther: false,
            other: null,
            language: {
              enterKey: "Enter",
              shiftKey: "Shift",
              ok: "OK",
              continue: "Starten",
              skip: "Skip",
              pressEnter: "oder ENTER drücken",
              multipleChoiceHelpText: "mehrere Antworten können abgegeben werden",
              multipleChoiceHelpTextSingle: "nur eine Antwort ist richtig",
              otherPrompt: "Other",
              placeholder: "Antwort hier eingeben",
              submitText: "Antwort bestätigen",
              longTextHelpText:
                "':shiftKey + :enterKey um einen Zeilenumbruch zu erzeugen.'",
              prev: "zurück",
              next: "vor",
              percentCompleted: ":percent% abgeschlossen",
              invalidPrompt: "ungültige Antwort",
              thankYouText: "Vielen Dank",
              successText: "Die Antworten wurden gespeichert",
              ariaOk: "Zum Fortfahren klicken",
              ariaRequired: "Diese Antwort muss gegeben werden",
              ariaPrev: "vorheriger Schritt",
              ariaNext: "nächster Schritt",
              ariaSubmitText: "Antwort per Klick absenden",
              ariaMultipleChoice: "Drücke :letter um zu wählen",
              ariaTypeAnswer: "Antwort hier eingeben",
            },
            tagline: null,
            title: "Wenn Erdöl brennt, dann wird die Reaktion",
            subtitle: null,
            content: null,
            inline: false,
            helpText: "Select one or more correct answers.",
            helpTextShow: false,
            descriptionLink: [],
            min: null,
            max: null,
            nextStepOnAnswer: false,
          },
          {
            id: "Frage_4",
            answer: "1",
            answered: true,
            index: 4,
            options: [
              {
                label: "Eine Lösung aus Kochsalz und Wasser.",
                value: "1",
                selected: true,
                imageSrc: null,
                imageAlt: null,
              },
              {
                label: "Ein Gemisch aus Alkohol und Wasser.",
                value: "2",
                selected: false,
                imageSrc: null,
                imageAlt: null,
              },
              {
                label: "Ein Gemisch aus Schlamm und Wasser.",
                value: "3",
                selected: false,
                imageSrc: null,
                imageAlt: null,
              },
              {
                label: "Ein Gemisch aus Sand und Sägespänen.",
                value: "4",
                selected: false,
                imageSrc: null,
                imageAlt: null,
              },
            ],
            description: "",
            className: "",
            type: "FlowFormMultipleChoiceType",
            html: null,
            required: true,
            jump: null,
            placeholder: null,
            mask: "",
            multiple: false,
            allowOther: false,
            other: null,
            language: {
              enterKey: "Enter",
              shiftKey: "Shift",
              ok: "OK",
              continue: "Starten",
              skip: "Skip",
              pressEnter: "oder ENTER drücken",
              multipleChoiceHelpText: "mehrere Antworten können abgegeben werden",
              multipleChoiceHelpTextSingle: "nur eine Antwort ist richtig",
              otherPrompt: "Other",
              placeholder: "Antwort hier eingeben",
              submitText: "Antwort bestätigen",
              longTextHelpText:
                "':shiftKey + :enterKey um einen Zeilenumbruch zu erzeugen.'",
              prev: "zurück",
              next: "vor",
              percentCompleted: ":percent% abgeschlossen",
              invalidPrompt: "ungültige Antwort",
              thankYouText: "Vielen Dank",
              successText: "Die Antworten wurden gespeichert",
              ariaOk: "Zum Fortfahren klicken",
              ariaRequired: "Diese Antwort muss gegeben werden",
              ariaPrev: "vorheriger Schritt",
              ariaNext: "nächster Schritt",
              ariaSubmitText: "Antwort per Klick absenden",
              ariaMultipleChoice: "Drücke :letter um zu wählen",
              ariaTypeAnswer: "Antwort hier eingeben",
            },
            tagline: null,
            title: "Welche Stoffe lassen sich durch eine Filtration trennen?",
            subtitle: null,
            content: null,
            inline: false,
            helpText: "Select one or more correct answers.",
            helpTextShow: false,
            descriptionLink: [],
            min: null,
            max: null,
            nextStepOnAnswer: false,
          },
          {
            id: "Frage_5",
            answer: "1",
            answered: true,
            index: 5,
            options: [
              {
                label: "Luft",
                value: "1",
                selected: true,
                imageSrc: null,
                imageAlt: null,
              },
              {
                label: "Blut",
                value: "2",
                selected: false,
                imageSrc: null,
                imageAlt: null,
              },
              {
                label: "Mineralwasser",
                value: "3",
                selected: false,
                imageSrc: null,
                imageAlt: null,
              },
              {
                label: "Kochsalz",
                value: "4",
                selected: false,
                imageSrc: null,
                imageAlt: null,
              },
            ],
            description: "",
            className: "",
            type: "FlowFormMultipleChoiceType",
            html: null,
            required: true,
            jump: null,
            placeholder: null,
            mask: "",
            multiple: false,
            allowOther: false,
            other: null,
            language: {
              enterKey: "Enter",
              shiftKey: "Shift",
              ok: "OK",
              continue: "Starten",
              skip: "Skip",
              pressEnter: "oder ENTER drücken",
              multipleChoiceHelpText: "mehrere Antworten können abgegeben werden",
              multipleChoiceHelpTextSingle: "nur eine Antwort ist richtig",
              otherPrompt: "Other",
              placeholder: "Antwort hier eingeben",
              submitText: "Antwort bestätigen",
              longTextHelpText:
                "':shiftKey + :enterKey um einen Zeilenumbruch zu erzeugen.'",
              prev: "zurück",
              next: "vor",
              percentCompleted: ":percent% abgeschlossen",
              invalidPrompt: "ungültige Antwort",
              thankYouText: "Vielen Dank",
              successText: "Die Antworten wurden gespeichert",
              ariaOk: "Zum Fortfahren klicken",
              ariaRequired: "Diese Antwort muss gegeben werden",
              ariaPrev: "vorheriger Schritt",
              ariaNext: "nächster Schritt",
              ariaSubmitText: "Antwort per Klick absenden",
              ariaMultipleChoice: "Drücke :letter um zu wählen",
              ariaTypeAnswer: "Antwort hier eingeben",
            },
            tagline: null,
            title:
              "Bei welchem der folgenden Stoffe handelt es sich NICHT um ein Stoffgemisch?",
            subtitle: null,
            content: null,
            inline: false,
            helpText: "Select one or more correct answers.",
            helpTextShow: false,
            descriptionLink: [],
            min: null,
            max: null,
            nextStepOnAnswer: false,
          },
          {
            id: "Frage_6",
            answer: "1",
            answered: true,
            index: 6,
            options: [
              {
                label: "Eis schmilzt.",
                value: "1",
                selected: true,
                imageSrc: null,
                imageAlt: null,
              },
              {
                label: "Zuckerkristalle werden zu Pulver zermahlen.",
                value: "2",
                selected: false,
                imageSrc: null,
                imageAlt: null,
              },
              {
                label: "Holz brennt.",
                value: "3",
                selected: false,
                imageSrc: null,
                imageAlt: null,
              },
              {
                label: "Wasser verdunstet aus einer Kristallisierschale.",
                value: "4",
                selected: false,
                imageSrc: null,
                imageAlt: null,
              },
            ],
            description: "",
            className: "",
            type: "FlowFormMultipleChoiceType",
            html: null,
            required: true,
            jump: null,
            placeholder: null,
            mask: "",
            multiple: false,
            allowOther: false,
            other: null,
            language: {
              enterKey: "Enter",
              shiftKey: "Shift",
              ok: "OK",
              continue: "Starten",
              skip: "Skip",
              pressEnter: "oder ENTER drücken",
              multipleChoiceHelpText: "mehrere Antworten können abgegeben werden",
              multipleChoiceHelpTextSingle: "nur eine Antwort ist richtig",
              otherPrompt: "Other",
              placeholder: "Antwort hier eingeben",
              submitText: "Antwort bestätigen",
              longTextHelpText:
                "':shiftKey + :enterKey um einen Zeilenumbruch zu erzeugen.'",
              prev: "zurück",
              next: "vor",
              percentCompleted: ":percent% abgeschlossen",
              invalidPrompt: "ungültige Antwort",
              thankYouText: "Vielen Dank",
              successText: "Die Antworten wurden gespeichert",
              ariaOk: "Zum Fortfahren klicken",
              ariaRequired: "Diese Antwort muss gegeben werden",
              ariaPrev: "vorheriger Schritt",
              ariaNext: "nächster Schritt",
              ariaSubmitText: "Antwort per Klick absenden",
              ariaMultipleChoice: "Drücke :letter um zu wählen",
              ariaTypeAnswer: "Antwort hier eingeben",
            },
            tagline: null,
            title:
              "Bei welchem der Beispiele handelt es sich um eine chemische Reaktion?",
            subtitle: null,
            content: null,
            inline: false,
            helpText: "Select one or more correct answers.",
            helpTextShow: false,
            descriptionLink: [],
            min: null,
            max: null,
            nextStepOnAnswer: false,
          },
          {
            id: "Frage_7",
            answer: "1",
            answered: true,
            index: 7,
            options: [
              {
                label: "Kochen von Wasser",
                value: "1",
                selected: true,
                imageSrc: null,
                imageAlt: null,
              },
              {
                label: "Rosten von Eisen",
                value: "2",
                selected: false,
                imageSrc: null,
                imageAlt: null,
              },
              {
                label: "Sauerwerden von Milch",
                value: "3",
                selected: false,
                imageSrc: null,
                imageAlt: null,
              },
              {
                label: "Backen von Kuchen",
                value: "4",
                selected: false,
                imageSrc: null,
                imageAlt: null,
              },
            ],
            description: "",
            className: "",
            type: "FlowFormMultipleChoiceType",
            html: null,
            required: true,
            jump: null,
            placeholder: null,
            mask: "",
            multiple: false,
            allowOther: false,
            other: null,
            language: {
              enterKey: "Enter",
              shiftKey: "Shift",
              ok: "OK",
              continue: "Starten",
              skip: "Skip",
              pressEnter: "oder ENTER drücken",
              multipleChoiceHelpText: "mehrere Antworten können abgegeben werden",
              multipleChoiceHelpTextSingle: "nur eine Antwort ist richtig",
              otherPrompt: "Other",
              placeholder: "Antwort hier eingeben",
              submitText: "Antwort bestätigen",
              longTextHelpText:
                "':shiftKey + :enterKey um einen Zeilenumbruch zu erzeugen.'",
              prev: "zurück",
              next: "vor",
              percentCompleted: ":percent% abgeschlossen",
              invalidPrompt: "ungültige Antwort",
              thankYouText: "Vielen Dank",
              successText: "Die Antworten wurden gespeichert",
              ariaOk: "Zum Fortfahren klicken",
              ariaRequired: "Diese Antwort muss gegeben werden",
              ariaPrev: "vorheriger Schritt",
              ariaNext: "nächster Schritt",
              ariaSubmitText: "Antwort per Klick absenden",
              ariaMultipleChoice: "Drücke :letter um zu wählen",
              ariaTypeAnswer: "Antwort hier eingeben",
            },
            tagline: null,
            title:
              "Welches der genannten Beispiele ist KEIN Beispiel für eine chemische Reaktion?",
            subtitle: null,
            content: null,
            inline: false,
            helpText: "Select one or more correct answers.",
            helpTextShow: false,
            descriptionLink: [],
            min: null,
            max: null,
            nextStepOnAnswer: false,
          },
          {
            id: "Frage_8",
            answer: "[..] besteht aus [..] , die aus [..] zusammengesetzt sind.",
            answered: true,
            index: 8,
            options: [],
            description: "",
            className: "",
            type: "FlowFormLongTextType",
            html: null,
            required: false,
            jump: null,
            placeholder: null,
            mask: "",
            multiple: false,
            allowOther: false,
            other: null,
            language: {
              enterKey: "Enter",
              shiftKey: "Shift",
              ok: "OK",
              continue: "Starten",
              skip: "Skip",
              pressEnter: "oder ENTER drücken",
              multipleChoiceHelpText: "mehrere Antworten können abgegeben werden",
              multipleChoiceHelpTextSingle: "nur eine Antwort ist richtig",
              otherPrompt: "Other",
              placeholder: "Antwort hier eingeben",
              submitText: "Antwort bestätigen",
              longTextHelpText:
                "':shiftKey + :enterKey um einen Zeilenumbruch zu erzeugen.'",
              prev: "zurück",
              next: "vor",
              percentCompleted: ":percent% abgeschlossen",
              invalidPrompt: "ungültige Antwort",
              thankYouText: "Vielen Dank",
              successText: "Die Antworten wurden gespeichert",
              ariaOk: "Zum Fortfahren klicken",
              ariaRequired: "Diese Antwort muss gegeben werden",
              ariaPrev: "vorheriger Schritt",
              ariaNext: "nächster Schritt",
              ariaSubmitText: "Antwort per Klick absenden",
              ariaMultipleChoice: "Drücke :letter um zu wählen",
              ariaTypeAnswer: "Antwort hier eingeben",
            },
            tagline: null,
            title:
              "Die Wörter Buch, Blätter und Zellstofffasern können zu folgendem Satz verbunden werden: Ein Buch besteht aus Blättern, die aus Zellstofffasern bestehen. Verwende die Wörter Moleküle, Atome und Luft, um den folgenden Satz zu vervollständigen:",
            subtitle: "Atome, Moleküle, Luft",
            content: null,
            inline: false,
            helpText: null,
            helpTextShow: false,
            descriptionLink: [],
            min: null,
            max: null,
            nextStepOnAnswer: false,
          },
          {
            id: "Frage_9",
            answer: "1",
            answered: true,
            index: 9,
            options: [
              {
                label: "Die Atome hören auf, sich zu bewegen.",
                value: "1",
                selected: true,
                imageSrc: null,
                imageAlt: null,
              },
              {
                label: "Die Atome kehren in die Umwelt zurück.",
                value: "2",
                selected: false,
                imageSrc: null,
                imageAlt: null,
              },
              {
                label:
                  "Die Atome spalten sich in kleinere Teilchen und verbinden sich zu neuen Atomen.",
                value: "3",
                selected: false,
                imageSrc: null,
                imageAlt: null,
              },
              {
                label:
                  "Die Atome existieren nicht mehr, wenn die Weißtanne verwest ist.",
                value: "4",
                selected: false,
                imageSrc: null,
                imageAlt: null,
              },
            ],
            description: "",
            className: "",
            type: "FlowFormMultipleChoiceType",
            html: null,
            required: true,
            jump: null,
            placeholder: null,
            mask: "",
            multiple: false,
            allowOther: false,
            other: null,
            language: {
              enterKey: "Enter",
              shiftKey: "Shift",
              ok: "OK",
              continue: "Starten",
              skip: "Skip",
              pressEnter: "oder ENTER drücken",
              multipleChoiceHelpText: "mehrere Antworten können abgegeben werden",
              multipleChoiceHelpTextSingle: "nur eine Antwort ist richtig",
              otherPrompt: "Other",
              placeholder: "Antwort hier eingeben",
              submitText: "Antwort bestätigen",
              longTextHelpText:
                "':shiftKey + :enterKey um einen Zeilenumbruch zu erzeugen.'",
              prev: "zurück",
              next: "vor",
              percentCompleted: ":percent% abgeschlossen",
              invalidPrompt: "ungültige Antwort",
              thankYouText: "Vielen Dank",
              successText: "Die Antworten wurden gespeichert",
              ariaOk: "Zum Fortfahren klicken",
              ariaRequired: "Diese Antwort muss gegeben werden",
              ariaPrev: "vorheriger Schritt",
              ariaNext: "nächster Schritt",
              ariaSubmitText: "Antwort per Klick absenden",
              ariaMultipleChoice: "Drücke :letter um zu wählen",
              ariaTypeAnswer: "Antwort hier eingeben",
            },
            tagline: null,
            title:
              "Eine Weißtanne besteht aus vielen Atomen. Was passiert mit den Atomen, nachdem sie abgestorben ist?",
            subtitle: null,
            content: null,
            inline: false,
            helpText: "Select one or more correct answers.",
            helpTextShow: false,
            descriptionLink: [],
            min: null,
            max: null,
            nextStepOnAnswer: false,
          },
          {
            id: "Frage_10",
            answer: "1",
            answered: true,
            index: 10,
            options: [
              {
                label: "Plutonium.",
                value: "1",
                selected: true,
                imageSrc: null,
                imageAlt: null,
              },
              {
                label: "Granit und Hämatit.",
                value: "2",
                selected: false,
                imageSrc: null,
                imageAlt: null,
              },
              {
                label: "Kalk und Ton.",
                value: "3",
                selected: false,
                imageSrc: null,
                imageAlt: null,
              },
              {
                label: "abgestorbenen Pflanzen und Tieren.",
                value: "4",
                selected: false,
                imageSrc: null,
                imageAlt: null,
              },
            ],
            description: "",
            className: "",
            type: "FlowFormMultipleChoiceType",
            html: null,
            required: true,
            jump: null,
            placeholder: null,
            mask: "",
            multiple: false,
            allowOther: false,
            other: null,
            language: {
              enterKey: "Enter",
              shiftKey: "Shift",
              ok: "OK",
              continue: "Starten",
              skip: "Skip",
              pressEnter: "oder ENTER drücken",
              multipleChoiceHelpText: "mehrere Antworten können abgegeben werden",
              multipleChoiceHelpTextSingle: "nur eine Antwort ist richtig",
              otherPrompt: "Other",
              placeholder: "Antwort hier eingeben",
              submitText: "Antwort bestätigen",
              longTextHelpText:
                "':shiftKey + :enterKey um einen Zeilenumbruch zu erzeugen.'",
              prev: "zurück",
              next: "vor",
              percentCompleted: ":percent% abgeschlossen",
              invalidPrompt: "ungültige Antwort",
              thankYouText: "Vielen Dank",
              successText: "Die Antworten wurden gespeichert",
              ariaOk: "Zum Fortfahren klicken",
              ariaRequired: "Diese Antwort muss gegeben werden",
              ariaPrev: "vorheriger Schritt",
              ariaNext: "nächster Schritt",
              ariaSubmitText: "Antwort per Klick absenden",
              ariaMultipleChoice: "Drücke :letter um zu wählen",
              ariaTypeAnswer: "Antwort hier eingeben",
            },
            tagline: null,
            title: "Fossile Brennstoffe sind entstanden aus",
            subtitle: null,
            content: null,
            inline: false,
            helpText: "Select one or more correct answers.",
            helpTextShow: false,
            descriptionLink: [],
            min: null,
            max: null,
            nextStepOnAnswer: false,
          },
          {
            id: "Frage_11",
            answer: "1",
            answered: true,
            index: 11,
            options: [
              {
                label: "Thermometer A",
                value: "1",
                selected: true,
                imageSrc: null,
                imageAlt: null,
              },
              {
                label: "Thermometer B",
                value: "2",
                selected: false,
                imageSrc: null,
                imageAlt: null,
              },
              {
                label: "Thermometer C",
                value: "3",
                selected: false,
                imageSrc: null,
                imageAlt: null,
              },
              {
                label: "Thermometer D",
                value: "4",
                selected: false,
                imageSrc: null,
                imageAlt: null,
              },
            ],
            description: "",
            className: "",
            type: "FlowFormMultipleChoiceType",
            html: null,
            required: true,
            jump: null,
            placeholder: null,
            mask: "",
            multiple: false,
            allowOther: false,
            other: null,
            language: {
              enterKey: "Enter",
              shiftKey: "Shift",
              ok: "OK",
              continue: "Starten",
              skip: "Skip",
              pressEnter: "oder ENTER drücken",
              multipleChoiceHelpText: "mehrere Antworten können abgegeben werden",
              multipleChoiceHelpTextSingle: "nur eine Antwort ist richtig",
              otherPrompt: "Other",
              placeholder: "Antwort hier eingeben",
              submitText: "Antwort bestätigen",
              longTextHelpText:
                "':shiftKey + :enterKey um einen Zeilenumbruch zu erzeugen.'",
              prev: "zurück",
              next: "vor",
              percentCompleted: ":percent% abgeschlossen",
              invalidPrompt: "ungültige Antwort",
              thankYouText: "Vielen Dank",
              successText: "Die Antworten wurden gespeichert",
              ariaOk: "Zum Fortfahren klicken",
              ariaRequired: "Diese Antwort muss gegeben werden",
              ariaPrev: "vorheriger Schritt",
              ariaNext: "nächster Schritt",
              ariaSubmitText: "Antwort per Klick absenden",
              ariaMultipleChoice: "Drücke :letter um zu wählen",
              ariaTypeAnswer: "Antwort hier eingeben",
            },
            tagline: null,
            title:
              "Die Zeichnung zeigt fünf verschiedene Celsius-Thermometer. Die Körpertemperatur von kranken Menschen liegt zwischen 36° C und 42° C. Welches Thermometer ist für die genaue Messung der Körpertemperatur am besten geeignet?",
            subtitle: null,
            content: null,
            inline: false,
            helpText: "Select one or more correct answers.",
            helpTextShow: false,
            descriptionLink: [],
            min: null,
            max: null,
            nextStepOnAnswer: false,
          },
          {
            id: "Frage_12",
            answer: "1",
            answered: true,
            index: 12,
            options: [
              {
                label: "alle Messwerte genau übereinstimmen",
                value: "1",
                selected: true,
                imageSrc: null,
                imageAlt: null,
              },
              {
                label: "nur zwei der Messwerte genau übereinstimmen",
                value: "2",
                selected: false,
                imageSrc: null,
                imageAlt: null,
              },
              {
                label: "alle Messwerte bis auf einen genau übereinstimmen",
                value: "3",
                selected: false,
                imageSrc: null,
                imageAlt: null,
              },
              {
                label:
                  "die meisten Messwerte nahe beieinanderliegen, jedoch nicht genau übereinstimmen",
                value: "4",
                selected: false,
                imageSrc: null,
                imageAlt: null,
              },
            ],
            description: "",
            className: "",
            type: "FlowFormMultipleChoiceType",
            html: null,
            required: true,
            jump: null,
            placeholder: null,
            mask: "",
            multiple: false,
            allowOther: false,
            other: null,
            language: {
              enterKey: "Enter",
              shiftKey: "Shift",
              ok: "OK",
              continue: "Starten",
              skip: "Skip",
              pressEnter: "oder ENTER drücken",
              multipleChoiceHelpText: "mehrere Antworten können abgegeben werden",
              multipleChoiceHelpTextSingle: "nur eine Antwort ist richtig",
              otherPrompt: "Other",
              placeholder: "Antwort hier eingeben",
              submitText: "Antwort bestätigen",
              longTextHelpText:
                "':shiftKey + :enterKey um einen Zeilenumbruch zu erzeugen.'",
              prev: "zurück",
              next: "vor",
              percentCompleted: ":percent% abgeschlossen",
              invalidPrompt: "ungültige Antwort",
              thankYouText: "Vielen Dank",
              successText: "Die Antworten wurden gespeichert",
              ariaOk: "Zum Fortfahren klicken",
              ariaRequired: "Diese Antwort muss gegeben werden",
              ariaPrev: "vorheriger Schritt",
              ariaNext: "nächster Schritt",
              ariaSubmitText: "Antwort per Klick absenden",
              ariaMultipleChoice: "Drücke :letter um zu wählen",
              ariaTypeAnswer: "Antwort hier eingeben",
            },
            tagline: null,
            title:
              "Wenn Wissenschaftler irgendeine Größe mehrere Male sorgfältig messen, erwarten sie, dass",
            subtitle: null,
            content: null,
            inline: false,
            helpText: "Select one or more correct answers.",
            helpTextShow: false,
            descriptionLink: [],
            min: null,
            max: null,
            nextStepOnAnswer: false,
          },
          {
            id: "Frage_13",
            answer: "1",
            answered: true,
            index: 13,
            options: [
              {
                label: "Alle Flüssigkeiten verdunsten.",
                value: "1",
                selected: true,
                imageSrc: null,
                imageAlt: null,
              },
              {
                label: "Benzin wird heißer als Wasser.",
                value: "2",
                selected: false,
                imageSrc: null,
                imageAlt: null,
              },
              {
                label: "Einige Flüssigkeiten verdunsten schneller als andere.",
                value: "3",
                selected: false,
                imageSrc: null,
                imageAlt: null,
              },
              {
                label: "Flüssigkeiten verdunsten nur bei Sonnenschein.",
                value: "4",
                selected: false,
                imageSrc: null,
                imageAlt: null,
              },
            ],
            description: "",
            className: "",
            type: "FlowFormMultipleChoiceType",
            html: null,
            required: true,
            jump: null,
            placeholder: null,
            mask: "",
            multiple: false,
            allowOther: false,
            other: null,
            language: {
              enterKey: "Enter",
              shiftKey: "Shift",
              ok: "OK",
              continue: "Starten",
              skip: "Skip",
              pressEnter: "oder ENTER drücken",
              multipleChoiceHelpText: "mehrere Antworten können abgegeben werden",
              multipleChoiceHelpTextSingle: "nur eine Antwort ist richtig",
              otherPrompt: "Other",
              placeholder: "Antwort hier eingeben",
              submitText: "Antwort bestätigen",
              longTextHelpText:
                "':shiftKey + :enterKey um einen Zeilenumbruch zu erzeugen.'",
              prev: "zurück",
              next: "vor",
              percentCompleted: ":percent% abgeschlossen",
              invalidPrompt: "ungültige Antwort",
              thankYouText: "Vielen Dank",
              successText: "Die Antworten wurden gespeichert",
              ariaOk: "Zum Fortfahren klicken",
              ariaRequired: "Diese Antwort muss gegeben werden",
              ariaPrev: "vorheriger Schritt",
              ariaNext: "nächster Schritt",
              ariaSubmitText: "Antwort per Klick absenden",
              ariaMultipleChoice: "Drücke :letter um zu wählen",
              ariaTypeAnswer: "Antwort hier eingeben",
            },
            tagline: null,
            title:
              "In jeweils 2 völlig identischen Bechergläsern befinden sich 50 ml Wasser(A) und 50 ml Benzin(B). Beide Bechergläser werden offen an ein von der Sonne beschienenes Fenster gestellt. Einige Zeit später kann man feststellen, dass das Flüssigkeitsvolumen in beiden Bechergläsern abgenommen hat, bei Benzin jedoch stärker als bei Wasser. Welche Schlussfolgerungen kannst Du ziehen?",
            subtitle: null,
            content: null,
            inline: false,
            helpText: "Select one or more correct answers.",
            helpTextShow: false,
            descriptionLink: [],
            min: null,
            max: null,
            nextStepOnAnswer: false,
          },
          {
            id: "Frage_14",
            answer: "1",
            answered: true,
            index: 14,
            options: [
              {
                label: "eine Beobachtung.",
                value: "1",
                selected: true,
                imageSrc: null,
                imageAlt: null,
              },
              {
                label: "eine Schlussfolgerung.",
                value: "2",
                selected: false,
                imageSrc: null,
                imageAlt: null,
              },
              {
                label: "eine Verallgemeinerung.",
                value: "3",
                selected: false,
                imageSrc: null,
                imageAlt: null,
              },
              {
                label: "eine Voraussetzung für die Untersuchung.",
                value: "4",
                selected: false,
                imageSrc: null,
                imageAlt: null,
              },
            ],
            description: "",
            className: "",
            type: "FlowFormMultipleChoiceType",
            html: null,
            required: true,
            jump: null,
            placeholder: null,
            mask: "",
            multiple: false,
            allowOther: false,
            other: null,
            language: {
              enterKey: "Enter",
              shiftKey: "Shift",
              ok: "OK",
              continue: "Starten",
              skip: "Skip",
              pressEnter: "oder ENTER drücken",
              multipleChoiceHelpText: "mehrere Antworten können abgegeben werden",
              multipleChoiceHelpTextSingle: "nur eine Antwort ist richtig",
              otherPrompt: "Other",
              placeholder: "Antwort hier eingeben",
              submitText: "Antwort bestätigen",
              longTextHelpText:
                "':shiftKey + :enterKey um einen Zeilenumbruch zu erzeugen.'",
              prev: "zurück",
              next: "vor",
              percentCompleted: ":percent% abgeschlossen",
              invalidPrompt: "ungültige Antwort",
              thankYouText: "Vielen Dank",
              successText: "Die Antworten wurden gespeichert",
              ariaOk: "Zum Fortfahren klicken",
              ariaRequired: "Diese Antwort muss gegeben werden",
              ariaPrev: "vorheriger Schritt",
              ariaNext: "nächster Schritt",
              ariaSubmitText: "Antwort per Klick absenden",
              ariaMultipleChoice: "Drücke :letter um zu wählen",
              ariaTypeAnswer: "Antwort hier eingeben",
            },
            tagline: null,
            title:
              'Helle hat 5 Tropfen Iod-Kaliumiodidlösung auf ein Stück Kartoffel getropft. In ihrem Protokoll steht: "Nachdem die Iod-Kaliumiodid-Lösung auf die Kartoffelscheibe getropft wurde, färbte sich die zuvor hellbraune Iod-Kaliumiodid-Lösung schwarz-violett. Diese Aussage ist ',
            subtitle: null,
            content: null,
            inline: false,
            helpText: "Select one or more correct answers.",
            helpTextShow: false,
            descriptionLink: [],
            min: null,
            max: null,
            nextStepOnAnswer: false,
          },
          {
            id: "Frage_15",
            answer: "1",
            answered: true,
            index: 15,
            options: [
              {
                label: "Vögel anderer Arten zu vertreiben.",
                value: "1",
                selected: true,
                imageSrc: null,
                imageAlt: null,
              },
              {
                label: "sein Revier gegen Vögel der eigenen Art abzugrenzen.",
                value: "2",
                selected: false,
                imageSrc: null,
                imageAlt: null,
              },
              {
                label: "Insekten anzulocken.",
                value: "3",
                selected: false,
                imageSrc: null,
                imageAlt: null,
              },
              {
                label: "andere Tiere aufzuwecken.",
                value: "4",
                selected: false,
                imageSrc: null,
                imageAlt: null,
              },
            ],
            description: "",
            className: "",
            type: "FlowFormMultipleChoiceType",
            html: null,
            required: true,
            jump: null,
            placeholder: null,
            mask: "",
            multiple: false,
            allowOther: false,
            other: null,
            language: {
              enterKey: "Enter",
              shiftKey: "Shift",
              ok: "OK",
              continue: "Starten",
              skip: "Skip",
              pressEnter: "oder ENTER drücken",
              multipleChoiceHelpText: "mehrere Antworten können abgegeben werden",
              multipleChoiceHelpTextSingle: "nur eine Antwort ist richtig",
              otherPrompt: "Other",
              placeholder: "Antwort hier eingeben",
              submitText: "Antwort bestätigen",
              longTextHelpText:
                "':shiftKey + :enterKey um einen Zeilenumbruch zu erzeugen.'",
              prev: "zurück",
              next: "vor",
              percentCompleted: ":percent% abgeschlossen",
              invalidPrompt: "ungültige Antwort",
              thankYouText: "Vielen Dank",
              successText: "Die Antworten wurden gespeichert",
              ariaOk: "Zum Fortfahren klicken",
              ariaRequired: "Diese Antwort muss gegeben werden",
              ariaPrev: "vorheriger Schritt",
              ariaNext: "nächster Schritt",
              ariaSubmitText: "Antwort per Klick absenden",
              ariaMultipleChoice: "Drücke :letter um zu wählen",
              ariaTypeAnswer: "Antwort hier eingeben",
            },
            tagline: null,
            title: "Der Zaunkönig singt hauptsächlich, um",
            subtitle: null,
            content: null,
            inline: false,
            helpText: "Select one or more correct answers.",
            helpTextShow: false,
            descriptionLink: [],
            min: null,
            max: null,
            nextStepOnAnswer: false,
          },
          {
            id: "Frage_16",
            answer: "1",
            answered: true,
            index: 16,
            options: [
              {
                label:
                  "Beide Tiere sind Kaltblüter, aber ohne wärmende Federn ist es der Zauneidechse am frühen Morgen zu kalt, um aktiv zu sein.",
                value: "1",
                selected: true,
                imageSrc: null,
                imageAlt: null,
              },
              {
                label:
                  "Im Unterschied zum Gartenrotschwanz ist Zauneidechse ein Warmblüter; die bei niedrigen Temperaturen in Kältestarre verfallen.",
                value: "2",
                selected: false,
                imageSrc: null,
                imageAlt: null,
              },
              {
                label:
                  "Im Unterschied zu Reptilien sind Vögel Kaltblüter, die durch niedrige Temperaturen weniger beeinträchtigt sind",
                value: "3",
                selected: false,
                imageSrc: null,
                imageAlt: null,
              },
              {
                label:
                  "Vögel sind im Unterschied zu Reptilien Warmblüter; die ihre Körpertemperatur unabhängig von der Außentemperatur konstant halten können.",
                value: "4",
                selected: false,
                imageSrc: null,
                imageAlt: null,
              },
            ],
            description: "",
            className: "",
            type: "FlowFormMultipleChoiceType",
            html: null,
            required: true,
            jump: null,
            placeholder: null,
            mask: "",
            multiple: false,
            allowOther: false,
            other: null,
            language: {
              enterKey: "Enter",
              shiftKey: "Shift",
              ok: "OK",
              continue: "Starten",
              skip: "Skip",
              pressEnter: "oder ENTER drücken",
              multipleChoiceHelpText: "mehrere Antworten können abgegeben werden",
              multipleChoiceHelpTextSingle: "nur eine Antwort ist richtig",
              otherPrompt: "Other",
              placeholder: "Antwort hier eingeben",
              submitText: "Antwort bestätigen",
              longTextHelpText:
                "':shiftKey + :enterKey um einen Zeilenumbruch zu erzeugen.'",
              prev: "zurück",
              next: "vor",
              percentCompleted: ":percent% abgeschlossen",
              invalidPrompt: "ungültige Antwort",
              thankYouText: "Vielen Dank",
              successText: "Die Antworten wurden gespeichert",
              ariaOk: "Zum Fortfahren klicken",
              ariaRequired: "Diese Antwort muss gegeben werden",
              ariaPrev: "vorheriger Schritt",
              ariaNext: "nächster Schritt",
              ariaSubmitText: "Antwort per Klick absenden",
              ariaMultipleChoice: "Drücke :letter um zu wählen",
              ariaTypeAnswer: "Antwort hier eingeben",
            },
            tagline: null,
            title:
              "Ab Ende April kann man bereits 80 Minuten vor Sonnenaufgang den Gartenrotschwanz singen hören. Er ist schon bei niedrigen Temperaturen sehr aktiv. Die Zauneidechse kann man dagegen häufig an sonnigen, warmen Tagen um die Mittagszeit auf Steinen beim Insektenfang beobachten. Welche der folgenden Aussagen erklärt diese Beobachtungen am besten?",
            subtitle: null,
            content: null,
            inline: false,
            helpText: "Select one or more correct answers.",
            helpTextShow: false,
            descriptionLink: [],
            min: null,
            max: null,
            nextStepOnAnswer: false,
          },
          {
            id: "Frage_17",
            answer: "1",
            answered: true,
            index: 17,
            options: [
              {
                label: "In keinem Körperteil ist noch Leben.",
                value: "1",
                selected: true,
                imageSrc: null,
                imageAlt: null,
              },
              {
                label:
                  "Die Tiere verbrauchen weniger Energie, als im aktiven Zustand.",
                value: "2",
                selected: false,
                imageSrc: null,
                imageAlt: null,
              },
              {
                label: "Die Körpertemperatur ist höher, als im aktiven Zustand.",
                value: "3",
                selected: false,
                imageSrc: null,
                imageAlt: null,
              },
              {
                label:
                  "Tiere nehmen Wärmenergie auf, um sie zu verbrauchen, wenn sich bewegen.",
                value: "4",
                selected: false,
                imageSrc: null,
                imageAlt: null,
              },
            ],
            description: "",
            className: "",
            type: "FlowFormMultipleChoiceType",
            html: null,
            required: true,
            jump: null,
            placeholder: null,
            mask: "",
            multiple: false,
            allowOther: false,
            other: null,
            language: {
              enterKey: "Enter",
              shiftKey: "Shift",
              ok: "OK",
              continue: "Starten",
              skip: "Skip",
              pressEnter: "oder ENTER drücken",
              multipleChoiceHelpText: "mehrere Antworten können abgegeben werden",
              multipleChoiceHelpTextSingle: "nur eine Antwort ist richtig",
              otherPrompt: "Other",
              placeholder: "Antwort hier eingeben",
              submitText: "Antwort bestätigen",
              longTextHelpText:
                "':shiftKey + :enterKey um einen Zeilenumbruch zu erzeugen.'",
              prev: "zurück",
              next: "vor",
              percentCompleted: ":percent% abgeschlossen",
              invalidPrompt: "ungültige Antwort",
              thankYouText: "Vielen Dank",
              successText: "Die Antworten wurden gespeichert",
              ariaOk: "Zum Fortfahren klicken",
              ariaRequired: "Diese Antwort muss gegeben werden",
              ariaPrev: "vorheriger Schritt",
              ariaNext: "nächster Schritt",
              ariaSubmitText: "Antwort per Klick absenden",
              ariaMultipleChoice: "Drücke :letter um zu wählen",
              ariaTypeAnswer: "Antwort hier eingeben",
            },
            tagline: null,
            title: "Welche der folgenden Aussagen triff für den Winterschlaf zu?",
            subtitle: null,
            content: null,
            inline: false,
            helpText: "Select one or more correct answers.",
            helpTextShow: false,
            descriptionLink: [],
            min: null,
            max: null,
            nextStepOnAnswer: false,
          },
          {
            id: "Frage_18",
            answer: "1",
            answered: true,
            index: 18,
            options: [
              {
                label: "Lichtenergie aufnehmen und Traubenzucker herstellen.",
                value: "1",
                selected: true,
                imageSrc: null,
                imageAlt: null,
              },
              {
                label:
                  "Lichtenergie aufnehmen und in mechanische Energie umwandeln",
                value: "2",
                selected: false,
                imageSrc: null,
                imageAlt: null,
              },
              {
                label: "Chemische Energie aus Stärke herstellen.",
                value: "3",
                selected: false,
                imageSrc: null,
                imageAlt: null,
              },
              {
                label: "Die grüne Farbe der Pflanzen verursachen.",
                value: "4",
                selected: false,
                imageSrc: null,
                imageAlt: null,
              },
            ],
            description: "",
            className: "",
            type: "FlowFormMultipleChoiceType",
            html: null,
            required: true,
            jump: null,
            placeholder: null,
            mask: "",
            multiple: false,
            allowOther: false,
            other: null,
            language: {
              enterKey: "Enter",
              shiftKey: "Shift",
              ok: "OK",
              continue: "Starten",
              skip: "Skip",
              pressEnter: "oder ENTER drücken",
              multipleChoiceHelpText: "mehrere Antworten können abgegeben werden",
              multipleChoiceHelpTextSingle: "nur eine Antwort ist richtig",
              otherPrompt: "Other",
              placeholder: "Antwort hier eingeben",
              submitText: "Antwort bestätigen",
              longTextHelpText:
                "':shiftKey + :enterKey um einen Zeilenumbruch zu erzeugen.'",
              prev: "zurück",
              next: "vor",
              percentCompleted: ":percent% abgeschlossen",
              invalidPrompt: "ungültige Antwort",
              thankYouText: "Vielen Dank",
              successText: "Die Antworten wurden gespeichert",
              ariaOk: "Zum Fortfahren klicken",
              ariaRequired: "Diese Antwort muss gegeben werden",
              ariaPrev: "vorheriger Schritt",
              ariaNext: "nächster Schritt",
              ariaSubmitText: "Antwort per Klick absenden",
              ariaMultipleChoice: "Drücke :letter um zu wählen",
              ariaTypeAnswer: "Antwort hier eingeben",
            },
            tagline: null,
            title: "Welche Aussage zur Funktion der Chloroplasten ist korrekt?",
            subtitle: null,
            content: null,
            inline: false,
            helpText: "Select one or more correct answers.",
            helpTextShow: false,
            descriptionLink: [],
            min: null,
            max: null,
            nextStepOnAnswer: false,
          },
          {
            id: "Frage_19",
            answer: "1",
            answered: true,
            index: 19,
            options: [
              {
                label: "A",
                value: "1",
                selected: true,
                imageSrc: null,
                imageAlt: null,
              },
              {
                label: "B",
                value: "2",
                selected: false,
                imageSrc: null,
                imageAlt: null,
              },
              {
                label: "C",
                value: "3",
                selected: false,
                imageSrc: null,
                imageAlt: null,
              },
              {
                label: "D",
                value: "4",
                selected: false,
                imageSrc: null,
                imageAlt: null,
              },
            ],
            description: "",
            className: "",
            type: "FlowFormMultipleChoiceType",
            html: null,
            required: true,
            jump: null,
            placeholder: null,
            mask: "",
            multiple: false,
            allowOther: false,
            other: null,
            language: {
              enterKey: "Enter",
              shiftKey: "Shift",
              ok: "OK",
              continue: "Starten",
              skip: "Skip",
              pressEnter: "oder ENTER drücken",
              multipleChoiceHelpText: "mehrere Antworten können abgegeben werden",
              multipleChoiceHelpTextSingle: "nur eine Antwort ist richtig",
              otherPrompt: "Other",
              placeholder: "Antwort hier eingeben",
              submitText: "Antwort bestätigen",
              longTextHelpText:
                "':shiftKey + :enterKey um einen Zeilenumbruch zu erzeugen.'",
              prev: "zurück",
              next: "vor",
              percentCompleted: ":percent% abgeschlossen",
              invalidPrompt: "ungültige Antwort",
              thankYouText: "Vielen Dank",
              successText: "Die Antworten wurden gespeichert",
              ariaOk: "Zum Fortfahren klicken",
              ariaRequired: "Diese Antwort muss gegeben werden",
              ariaPrev: "vorheriger Schritt",
              ariaNext: "nächster Schritt",
              ariaSubmitText: "Antwort per Klick absenden",
              ariaMultipleChoice: "Drücke :letter um zu wählen",
              ariaTypeAnswer: "Antwort hier eingeben",
            },
            tagline: null,
            title:
              "Constantin hat je 100 ml Wasser in jedes der dargestellten offenen Glasgefäße gefüllt und lässt sie einen Tag in der Sonne stehen. Aus welchem Glasgefäß verdunstet das meiste Wasser?",
            subtitle: null,
            content: null,
            inline: false,
            helpText: "Select one or more correct answers.",
            helpTextShow: false,
            descriptionLink: [],
            min: null,
            max: null,
            nextStepOnAnswer: false,
          },
          {
            id: "Frage_20",
            answer: "1",
            answered: true,
            index: 20,
            options: [
              {
                label:
                  "Feuchte Kressesamen keimen bei Licht und einer Temperatur von 20°C",
                value: "1",
                selected: true,
                imageSrc: null,
                imageAlt: null,
              },
              {
                label: "Für das Keinem von Kressesamen ist Licht notwendig.",
                value: "2",
                selected: false,
                imageSrc: null,
                imageAlt: null,
              },
              {
                label:
                  "Für das Keinem von Kressesamen sind Wasser und Temperaturen von mindestens 20°C notwendig.",
                value: "3",
                selected: false,
                imageSrc: null,
                imageAlt: null,
              },
              {
                label:
                  "Für das Keinem von Kressesamen sind Wasser und Wärme notwendig.",
                value: "4",
                selected: false,
                imageSrc: null,
                imageAlt: null,
              },
            ],
            description: "Was kannst Du aus ihren Ergebnissen schließen?",
            className: "",
            type: "FlowFormMultipleChoiceType",
            html: null,
            required: true,
            jump: null,
            placeholder: null,
            mask: "",
            multiple: false,
            allowOther: false,
            other: null,
            language: {
              enterKey: "Enter",
              shiftKey: "Shift",
              ok: "OK",
              continue: "Starten",
              skip: "Skip",
              pressEnter: "oder ENTER drücken",
              multipleChoiceHelpText: "mehrere Antworten können abgegeben werden",
              multipleChoiceHelpTextSingle: "nur eine Antwort ist richtig",
              otherPrompt: "Other",
              placeholder: "Antwort hier eingeben",
              submitText: "Antwort bestätigen",
              longTextHelpText:
                "':shiftKey + :enterKey um einen Zeilenumbruch zu erzeugen.'",
              prev: "zurück",
              next: "vor",
              percentCompleted: ":percent% abgeschlossen",
              invalidPrompt: "ungültige Antwort",
              thankYouText: "Vielen Dank",
              successText: "Die Antworten wurden gespeichert",
              ariaOk: "Zum Fortfahren klicken",
              ariaRequired: "Diese Antwort muss gegeben werden",
              ariaPrev: "vorheriger Schritt",
              ariaNext: "nächster Schritt",
              ariaSubmitText: "Antwort per Klick absenden",
              ariaMultipleChoice: "Drücke :letter um zu wählen",
              ariaTypeAnswer: "Antwort hier eingeben",
            },
            tagline: null,
            title:
              "Theodora untersuchte mit Hilfe von Experimenten, die Bedingungen für das Keimen von Kressesamen. Sie fasst ihre Ergebnisse mit diesen beiden Aussagen zusammen: ",
            subtitle:
              "1. Feuchte Kressesamen keimen bei Licht und einer Temperatur von 20°C",
            content: null,
            inline: false,
            helpText:
              " 2. Feuchte Kressesamen keimen im Dunkeln bei einer Temperatur von 15°C.",
            helpTextShow: true,
            descriptionLink: [],
            min: null,
            max: null,
            nextStepOnAnswer: false,
          },
          {
            id: "Frage_21",
            answer: "1",
            answered: true,
            index: 21,
            options: [
              {
                label: "Hebel",
                value: "1",
                selected: true,
                imageSrc: null,
                imageAlt: null,
              },
              {
                label: "Flaschenzug",
                value: "2",
                selected: false,
                imageSrc: null,
                imageAlt: null,
              },
              {
                label: "hydraulische Anlage",
                value: "3",
                selected: false,
                imageSrc: null,
                imageAlt: null,
              },
              {
                label: "Schiefe Ebene ",
                value: "4",
                selected: false,
                imageSrc: null,
                imageAlt: null,
              },
            ],
            description: "",
            className: "",
            type: "FlowFormMultipleChoiceType",
            html: null,
            required: true,
            jump: null,
            placeholder: null,
            mask: "",
            multiple: false,
            allowOther: false,
            other: null,
            language: {
              enterKey: "Enter",
              shiftKey: "Shift",
              ok: "OK",
              continue: "Starten",
              skip: "Skip",
              pressEnter: "oder ENTER drücken",
              multipleChoiceHelpText: "mehrere Antworten können abgegeben werden",
              multipleChoiceHelpTextSingle: "nur eine Antwort ist richtig",
              otherPrompt: "Other",
              placeholder: "Antwort hier eingeben",
              submitText: "Antwort bestätigen",
              longTextHelpText:
                "':shiftKey + :enterKey um einen Zeilenumbruch zu erzeugen.'",
              prev: "zurück",
              next: "vor",
              percentCompleted: ":percent% abgeschlossen",
              invalidPrompt: "ungültige Antwort",
              thankYouText: "Vielen Dank",
              successText: "Die Antworten wurden gespeichert",
              ariaOk: "Zum Fortfahren klicken",
              ariaRequired: "Diese Antwort muss gegeben werden",
              ariaPrev: "vorheriger Schritt",
              ariaNext: "nächster Schritt",
              ariaSubmitText: "Antwort per Klick absenden",
              ariaMultipleChoice: "Drücke :letter um zu wählen",
              ariaTypeAnswer: "Antwort hier eingeben",
            },
            tagline: null,
            title:
              "Wenn man seinen Arm im Ellbogengelenk beugt, wirken die Knochen und Muskeln im Arm als ein System. Welche einfache physikalische Anordnung stellt dieses System dar?",
            subtitle: null,
            content: null,
            inline: false,
            helpText: "Select one or more correct answers.",
            helpTextShow: false,
            descriptionLink: [],
            min: null,
            max: null,
            nextStepOnAnswer: false,
          },
          {
            id: "Frage_22",
            answer: "1",
            answered: true,
            index: 22,
            options: [
              {
                label: "73mm",
                value: "1",
                selected: true,
                imageSrc: null,
                imageAlt: null,
              },
              {
                label: "70mm",
                value: "2",
                selected: false,
                imageSrc: null,
                imageAlt: null,
              },
              {
                label: "75mm",
                value: "3",
                selected: false,
                imageSrc: null,
                imageAlt: null,
              },
              {
                label: "60mm",
                value: "4",
                selected: false,
                imageSrc: null,
                imageAlt: null,
              },
            ],
            description: "",
            className: "",
            type: "FlowFormMultipleChoiceType",
            html: null,
            required: true,
            jump: null,
            placeholder: null,
            mask: "",
            multiple: false,
            allowOther: false,
            other: null,
            language: {
              enterKey: "Enter",
              shiftKey: "Shift",
              ok: "OK",
              continue: "Starten",
              skip: "Skip",
              pressEnter: "oder ENTER drücken",
              multipleChoiceHelpText: "mehrere Antworten können abgegeben werden",
              multipleChoiceHelpTextSingle: "nur eine Antwort ist richtig",
              otherPrompt: "Other",
              placeholder: "Antwort hier eingeben",
              submitText: "Antwort bestätigen",
              longTextHelpText:
                "':shiftKey + :enterKey um einen Zeilenumbruch zu erzeugen.'",
              prev: "zurück",
              next: "vor",
              percentCompleted: ":percent% abgeschlossen",
              invalidPrompt: "ungültige Antwort",
              thankYouText: "Vielen Dank",
              successText: "Die Antworten wurden gespeichert",
              ariaOk: "Zum Fortfahren klicken",
              ariaRequired: "Diese Antwort muss gegeben werden",
              ariaPrev: "vorheriger Schritt",
              ariaNext: "nächster Schritt",
              ariaSubmitText: "Antwort per Klick absenden",
              ariaMultipleChoice: "Drücke :letter um zu wählen",
              ariaTypeAnswer: "Antwort hier eingeben",
            },
            tagline:
              "Das Diagramm zeigt die Fortbewegung einer Weinbergschnecke, die sich entlang einer geraden Linie bewegt.",
            title:
              "Welche Strecke wird die Weinbergschnecke nach 60 Sekunden zurückgelegt haben, wenn sie sich immer mit der gleichen Geschwindigkeit fortbewegt? ",
            subtitle: null,
            content: null,
            inline: false,
            helpText: "Select one or more correct answers.",
            helpTextShow: true,
            descriptionLink: [],
            min: null,
            max: null,
            nextStepOnAnswer: false,
          },
          {
            id: "Frage_23",
            answer: "1",
            answered: true,
            index: 23,
            options: [
              {
                label: "Der Schall wird im Weltraum stärker reflektiert.",
                value: "1",
                selected: true,
                imageSrc: null,
                imageAlt: null,
              },
              {
                label: " Der Druck im Inneren der Raumschiffe ist zu groß.",
                value: "2",
                selected: false,
                imageSrc: null,
                imageAlt: null,
              },
              {
                label:
                  "Es gibt keine Luft im Weltraum, in der sich der Schall fortbewegen kann.",
                value: "3",
                selected: false,
                imageSrc: null,
                imageAlt: null,
              },
              {
                label: "Die Raumschiffe bewegen sich schneller als der Schall.",
                value: "4",
                selected: false,
                imageSrc: null,
                imageAlt: null,
              },
            ],
            description: "",
            className: "",
            type: "FlowFormMultipleChoiceType",
            html: null,
            required: true,
            jump: null,
            placeholder: null,
            mask: "",
            multiple: false,
            allowOther: false,
            other: null,
            language: {
              enterKey: "Enter",
              shiftKey: "Shift",
              ok: "OK",
              continue: "Starten",
              skip: "Skip",
              pressEnter: "oder ENTER drücken",
              multipleChoiceHelpText: "mehrere Antworten können abgegeben werden",
              multipleChoiceHelpTextSingle: "nur eine Antwort ist richtig",
              otherPrompt: "Other",
              placeholder: "Antwort hier eingeben",
              submitText: "Antwort bestätigen",
              longTextHelpText:
                "':shiftKey + :enterKey um einen Zeilenumbruch zu erzeugen.'",
              prev: "zurück",
              next: "vor",
              percentCompleted: ":percent% abgeschlossen",
              invalidPrompt: "ungültige Antwort",
              thankYouText: "Vielen Dank",
              successText: "Die Antworten wurden gespeichert",
              ariaOk: "Zum Fortfahren klicken",
              ariaRequired: "Diese Antwort muss gegeben werden",
              ariaPrev: "vorheriger Schritt",
              ariaNext: "nächster Schritt",
              ariaSubmitText: "Antwort per Klick absenden",
              ariaMultipleChoice: "Drücke :letter um zu wählen",
              ariaTypeAnswer: "Antwort hier eingeben",
            },
            tagline: null,
            title:
              "Die Besatzungen zweier Schiffe auf dem Meer können sich durch lautes Rufen verständigen. Weshalb ist dies den Besatzungen zweier Raumschiffe bei gleichem Abstand voneinander im Weltraum nicht möglich?",
            subtitle: null,
            content: null,
            inline: false,
            helpText: "Select one or more correct answers.",
            helpTextShow: false,
            descriptionLink: [],
            min: null,
            max: null,
            nextStepOnAnswer: false,
          },
          {
            id: "Frage_23",
            answer: "1",
            answered: true,
            index: 24,
            options: [
              {
                label: "das Glas zusammenzieht.",
                value: "1",
                selected: true,
                imageSrc: null,
                imageAlt: null,
              },
              {
                label: "den Metalldeckel stärker ausdehnt als das Glas.",
                value: "2",
                selected: false,
                imageSrc: null,
                imageAlt: null,
              },
              {
                label: "das Glas stärker ausdehnt als den Metalldeckel.",
                value: "3",
                selected: false,
                imageSrc: null,
                imageAlt: null,
              },
              {
                label: "den Metalldeckel zusammenzieht.",
                value: "4",
                selected: false,
                imageSrc: null,
                imageAlt: null,
              },
            ],
            description: "",
            className: "",
            type: "FlowFormMultipleChoiceType",
            html: null,
            required: true,
            jump: null,
            placeholder: null,
            mask: "",
            multiple: false,
            allowOther: false,
            other: null,
            language: {
              enterKey: "Enter",
              shiftKey: "Shift",
              ok: "OK",
              continue: "Starten",
              skip: "Skip",
              pressEnter: "oder ENTER drücken",
              multipleChoiceHelpText: "mehrere Antworten können abgegeben werden",
              multipleChoiceHelpTextSingle: "nur eine Antwort ist richtig",
              otherPrompt: "Other",
              placeholder: "Antwort hier eingeben",
              submitText: "Antwort bestätigen",
              longTextHelpText:
                "':shiftKey + :enterKey um einen Zeilenumbruch zu erzeugen.'",
              prev: "zurück",
              next: "vor",
              percentCompleted: ":percent% abgeschlossen",
              invalidPrompt: "ungültige Antwort",
              thankYouText: "Vielen Dank",
              successText: "Die Antworten wurden gespeichert",
              ariaOk: "Zum Fortfahren klicken",
              ariaRequired: "Diese Antwort muss gegeben werden",
              ariaPrev: "vorheriger Schritt",
              ariaNext: "nächster Schritt",
              ariaSubmitText: "Antwort per Klick absenden",
              ariaMultipleChoice: "Drücke :letter um zu wählen",
              ariaTypeAnswer: "Antwort hier eingeben",
            },
            tagline: null,
            title:
              "Ein festgeschraubter Metalldeckel auf einem Marmeladenglas lässt sich leichter lösen, wenn er unter heißes Wasser gehalten wird. Die Ursache dafür ist, dass das heiße Wasser",
            subtitle: null,
            content: null,
            inline: false,
            helpText: "Select one or more correct answers.",
            helpTextShow: false,
            descriptionLink: [],
            min: null,
            max: null,
            nextStepOnAnswer: false,
          },
        ],
        Ergebnis: "1 von 20 ",
        RefDiagId: 1,
        ID: 1,
        Modified: "2022-03-21T12:38:18Z",
        Created: "2022-03-21T12:38:18Z",
        AuthorId: 120,
        EditorId: 120,
        OData__UIVersionString: "1.0",
        Attachments: false,
        GUID: "8209e170-5cac-4b97-aae9-f11aeed80b18",
      },
      {
        __metadata: {
          id: "Web/Lists(guid'6d4ee3ea-92d4-4573-aced-db817edbfc0d')/Items(1)",
          uri: "https://pep.orga-cloud.de/dev/_api/Web/Lists(guid'6d4ee3ea-92d4-4573-aced-db817edbfc0d')/Items(1)",
          etag: '"1"',
          type: "SP.Data.Diagnostik_x005f_ErgebnisseListItem",
        },
        FirstUniqueAncestorSecurableObject: {
          __deferred: {
            uri: "https://pep.orga-cloud.de/dev/_api/Web/Lists(guid'6d4ee3ea-92d4-4573-aced-db817edbfc0d')/Items(1)/FirstUniqueAncestorSecurableObject",
          },
        },
        RoleAssignments: {
          __deferred: {
            uri: "https://pep.orga-cloud.de/dev/_api/Web/Lists(guid'6d4ee3ea-92d4-4573-aced-db817edbfc0d')/Items(1)/RoleAssignments",
          },
        },
        AttachmentFiles: {
          __deferred: {
            uri: "https://pep.orga-cloud.de/dev/_api/Web/Lists(guid'6d4ee3ea-92d4-4573-aced-db817edbfc0d')/Items(1)/AttachmentFiles",
          },
        },
        ContentType: {
          __deferred: {
            uri: "https://pep.orga-cloud.de/dev/_api/Web/Lists(guid'6d4ee3ea-92d4-4573-aced-db817edbfc0d')/Items(1)/ContentType",
          },
        },
        FieldValuesAsHtml: {
          __deferred: {
            uri: "https://pep.orga-cloud.de/dev/_api/Web/Lists(guid'6d4ee3ea-92d4-4573-aced-db817edbfc0d')/Items(1)/FieldValuesAsHtml",
          },
        },
        FieldValuesAsText: {
          __deferred: {
            uri: "https://pep.orga-cloud.de/dev/_api/Web/Lists(guid'6d4ee3ea-92d4-4573-aced-db817edbfc0d')/Items(1)/FieldValuesAsText",
          },
        },
        FieldValuesForEdit: {
          __deferred: {
            uri: "https://pep.orga-cloud.de/dev/_api/Web/Lists(guid'6d4ee3ea-92d4-4573-aced-db817edbfc0d')/Items(1)/FieldValuesForEdit",
          },
        },
        File: {
          __deferred: {
            uri: "https://pep.orga-cloud.de/dev/_api/Web/Lists(guid'6d4ee3ea-92d4-4573-aced-db817edbfc0d')/Items(1)/File",
          },
        },
        Folder: {
          __deferred: {
            uri: "https://pep.orga-cloud.de/dev/_api/Web/Lists(guid'6d4ee3ea-92d4-4573-aced-db817edbfc0d')/Items(1)/Folder",
          },
        },
        ParentList: {
          __deferred: {
            uri: "https://pep.orga-cloud.de/dev/_api/Web/Lists(guid'6d4ee3ea-92d4-4573-aced-db817edbfc0d')/Items(1)/ParentList",
          },
        },
        FileSystemObjectType: 0,
        Id: 12,
        ContentTypeId: "0x0100CD39520B55DB7742A4BE83349A9BE740",
        Title: "Ilmenau 12",
        RefPEPId: 42,
        Antworten: null,
        Ergebnis: "1 von 20 ",
        RefDiagId: 2,
        ID: 1,
        Modified: "2022-03-21T12:38:18Z",
        Created: "2022-03-21T12:38:18Z",
        AuthorId: 120,
        EditorId: 120,
        OData__UIVersionString: "1.0",
        Attachments: false,
        GUID: "8209e170-5cac-4b97-aae9-f11aeed80b18",
      },
    ],
  };
  export default {
    diagnostik
  }