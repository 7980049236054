// ActionsList
<template >
  <fv-DetailsList :value="filteredValue" :head="head" :compact="compact" >
    <template v-slot:row_expand="x">
      <div class="expanded-row" :ref="x.item.Id" v-shared-element:[x.item.Id]="{
        easing: 'ease',
        duration: '1s',
      }"></div>
    </template>

    <template v-slot:column_0="x">
      <div v-if="x.RefZ !== 0" class="Table-Icon">
        <i class="ms-Icon ms-Icon--ViewDashboard"
          :class="x.item.Type === 'MKi' ? 'own-action' : x.item.Type === 'MTKi' ? 'own-template-action' : 'group-template-action'"
          aria-hidden="true"></i>
      </div>
    </template>
    <template v-slot:column_1="x">
      <p class="title-main">{{ x.item.Title }}</p>

      <p class="title-sec sec">{{ x.item.RefMeth.Title }}</p>
    </template>
    <template v-slot:column_2="x">
      <p class="sec">
        <i class="ms-Icon ms-Icon--ArrangeBringToFront"></i>{{ x.item.Lernumgebung }}
      </p>
      <p class="sec">
        <i class="ms-Icon ms-Icon--PeopleAdd"></i>{{ x.item.Altersgruppe }}
      </p>
    </template>
    <template v-slot:column_3="x">
      <p class="sec">
        <i class="ms-Icon ms-Icon--DateTime2"></i>{{ x.item.Zeitumfang }}
      </p>
      <p class="sec">
        <i class="ms-Icon ms-Icon--PeopleAdd"></i>{{ x.item.Sozialform }}
      </p>
    </template>
    <template v-slot:column_4="x">
      <div class="action-icons">
        <action-button v-if="x.item.hasOwnProperty('approveDisabled')   && !x.item.approveDisabled"
          :isSaved="(x.item.AdminChecked === null || x.item.AdminChecked === false)" :size="'small'" :action="'freigabe'"
          v-on:releaseClick="checkactiondetails(x.item, item)"></action-button>
        <action-button :size="'small'" :action="'open'" v-on:openClick="setactiondetails(x.item, x.item)"></action-button>

      </div>
    </template>
  </fv-DetailsList>
</template>

<script>
import ActionButton from '../Buttons/ActionButton.vue'
export default {

  name: 'ActionsTable',
  props: {
    value: {
      type: Array,
      default() {
        return []
      },
    },
    masznahmen: {
      type: Array,
      default() {
        return []
      },
    },
    masznahmentemplateI: {
      type: Array,
      default() {
        return []
      },
    },
    masznahmentemplateG: {
      type: Array,
      default() {
        return []
      },
    },
    head: {
      type: Array,
      default() {
        return []
      },
    },
    compact: {
      type: Boolean,
      default: false
    },
    Main: {
      type: String,
      require: true,
      default: "keine Information verfügbar"
    },
    searchText: {
      type: String,
      default: "",
    },
    mkiVisible: {
      type: Boolean,
      default: true
    },
    mtkiVisible: {
      type: Boolean,
      default: true
    },
    mtkgVisible: {
      type: Boolean,
      default: true
    },
    Height: {
      type: String,
      default: '100%'
    },
    isMobile: {
      type: Boolean,
      default: false
    },
    masznahmenUpdatedAt: {
      type: Date,
      default: () => new Date()
    }


  },
  data() {
    let combinedData = [];

    const addAdditionalData = item => {
      const createdDate = new Date(item.Created);
      const currentDate = new Date();
      const oneDay = 24 * 60 * 60 * 1000; // Stunden * Minuten * Sekunden * Millisekunden
      const daysDifference = Math.round(Math.abs((createdDate - currentDate) / oneDay));

      let newItem = {
        ...item,
        Id3: item.Type + '-' + item.Id,
        Neu: daysDifference <= 1,
        AdminChecked: null,
        clickDisabled: null,

      };

      if (newItem.Type === "MTKg") {
        newItem.AdminChecked = newItem.AdminChecked || null;
        if (newItem.AdminChecked === false || newItem.AdminChecked === null) {
          newItem.clickDisabled = !(
            this.$store.getters.groupsAdmin &&
            this.$store.getters.groupsAdmin.includes(this.$store.getters.userId) ||
            this.$store.getters.userTitle === newItem.Autor
          );
        } else {
          newItem.clickDisabled = false;
        }
        newItem.approveDisabled = !(this.$store.getters.groupsAdmin && this.$store.getters.groupsAdmin.includes(this.$store.getters.userId));
      }
      newItem.Lernumgebung = newItem.Lernumgebung === true ? 'innerhalb des Unterrichts' : 'außerhalb des Unterrichts';
      
      return newItem;
    }

    if (this.masznahmen && this.masznahmen.length) {
      combinedData = [...combinedData, ...this.masznahmen.map((item) => addAdditionalData({ ...item, Type: 'MKi' }))];
    }

    if (this.masznahmentemplateI && this.masznahmentemplateI.length) {
      combinedData = [...combinedData, ...this.masznahmentemplateI.map((item) => addAdditionalData({ ...item, Type: 'MTKi' }))];
    }

    if (this.masznahmentemplateG && this.masznahmentemplateG.length) {
      combinedData = [...combinedData, ...this.masznahmentemplateG.map((item) => addAdditionalData({ ...item, Type: 'MTKg' }))];
    }

    return {
      combinedData,
    };
  }

  ,
  watch: {
    searchText: {
      handler() {
        this.checkDataAvailabilityAndInitializeTree();
      },
    },
    mkiVisible: {
      deep: true,
      handler() {
        this.checkDataAvailabilityAndInitializeTree();
        this.toogleText('personalisierte Maßnahmen', '̶̶p̶e̶r̶s̶o̶n̶a̶l̶i̶s̶i̶e̶r̶t̶e̶ ̶M̶a̶ß̶n̶a̶h̶m̶e̶n̶', 0, this.mkiVisible)
      },
    },
    mtkiVisible: {
      deep: true,
      handler() {
        this.checkDataAvailabilityAndInitializeTree();
        this.toogleText('eigene Vorlage', '̶e̶i̶g̶e̶n̶e̶ ̶V̶o̶r̶l̶a̶g̶e̶', 2, this.mtkiVisible)
      },
    },
    mtkgVisible: {
      deep: true,
      handler() {
        this.checkDataAvailabilityAndInitializeTree();
        this.toogleText('Schulkatalog', '̶̶̶S̶c̶h̶u̶l̶k̶a̶t̶a̶l̶o̶g̶', 4, this.mtkgVisible)

      },
    },
    isMobile: function (newVal) {
      if (newVal) {
        document.documentElement.style.setProperty('--font-size-slightly-larger', '110%', 'important');
        document.documentElement.style.setProperty('--font-size-averege', 'calc(16px + (17 - 16) * ((100vw - 300px) / (1900 - 300)))', 'important');
      } else {
        document.documentElement.style.setProperty('--font-size-slightly-larger', '120%');
        document.documentElement.style.setProperty('--font-size-averege', 'calc(16px + (18 - 16) * ((100vw - 300px) / (1900 - 300)))', 'important');
      }
    }
  },
  methods: {
    checkDataAvailabilityAndInitializeTree() {
      if (
        !this.masznahmenLoading &&
        !this.masznahmentemplateILoading &&
        !this.masznahmentemplateGLoading
      )
      return true
    },
    setactiondetails(payload) {
      const clickedColumn = event.target;
      this.$emit('setaction', [payload, payload, clickedColumn])
    },
    checkactiondetails(payload) {
      const clickedColumn = event.target.parentNode.parentNode.parentNode;

      const originalRecord = this.getOriginalRecordById(payload.Type, payload.Id);
      this.$emit('checkaction', [originalRecord, payload, clickedColumn]);
    }
  },
  mounted() {
    if (this.isMobile) {
      document.documentElement.style.setProperty('--font-size-slightly-larger', '100%', 'important');
      document.documentElement.style.setProperty('--font-size-averege', 'calc(16px + (17 - 16) * ((100vw - 300px) / (1900 - 300)))', 'important');
    } else {
      document.documentElement.style.setProperty('--font-size-slightly-larger', '120%');
      document.documentElement.style.setProperty('--font-size-averege', 'calc(16px + (18 - 16) * ((100vw - 300px) / (1900 - 300)))', 'important');
    }
  },
  computed: {
    filteredValue() {
      return this.combinedData.filter((item) => {
        const isVisible = (this.mtkgVisible && item.Type === "MTKg") || (this.mtkiVisible && item.Type === "MTKi") || (this.mkiVisible && item.Type === "MKi");
        const matchesSearch = item.Title ? item.Title.toLowerCase().includes(this.searchText.toLowerCase()) : false;
        return isVisible && matchesSearch;
      });
    },
  },

  components: {
    ActionButton
  }
}

</script>

<style scoped>
:root {
  --font-size: calc(16px + (22 - 16) * ((100vw - 300px) / (1900 - 300)));

}

.expanded-row {
  position: absolute;
  width: 100%;
  height: 100%;
  background: white;
  z-index: -1;
}

:deep(.content-row) {
  min-height: 80px;
  height: auto !important;
  max-height: 180px !important;
  overflow: hidden;
  font-size: var(--font-size-averege) !important;
}

:deep(.fv-details-list-head) {
  background: hsla(0, 0%, 100%, 0.6);

}

:deep(.fv-light-DetailsList .fv-details-list-content .content-row .col *),
:deep(.fv-light-DetailsList .list-head .col .col-content p.default-title),
:deep(.col p),
:deep(.col i) {
  font-size: var(--font-size-averege) !important;
}

:deep(.fv-details-list-head > span:not(:nth-child(2)):not(:nth-child(3))),
:deep(div.content-row > span:not(:nth-child(2)):not(:nth-child(3)))

/* ,
:deep(.col:not(:nth-child(1)):not(:nth-child(3)))  */
  {
  max-width: 25% !important;
  width: 21% !important;
  min-width: 20% !important;
  /* display: flex !important; */
}

:deep(span.col:nth-child(7), div.content-row > .col:nth-child(6)) {
  justify-content: flex-end;
}

:deep(.fv-details-list-head > span:nth-child(3)) {
  width: 10% !important
}

/*bleibt*/
:deep(.fv-details-list-head > span:nth-child(4)) {
  max-width: 25% !important;
  width: 21% !important;
  min-width: 20% !important;
}

:deep(.fv-details-list-head > span:nth-child(2)),
:deep(div.content-row > span:nth-child(3))

/* ,
:deep(.col:nth-child(3)) */
  {
  max-width: 24% !important;
  width: 20% !important;
  min-width: 20% !important;
  /* display: flex !important; */
}

:deep(.fv-details-list-head > span:nth-child(1)),
:deep(div.content-row > span:nth-child(2))

/* ,
:deep(.col:nth-child(3)) */
  {
  max-width: 10% !important;
  width: 10% !important;
  padding: 20px 40px !important;
  /* display: flex !important; */
}

:deep(.fv-details-list-head > span:nth-child(2)) {
  display: none !important;
}

/* :deep(.col:nth-child(3)){
  width: 20% !important;
   max-width: 24% !important;
} */
:deep(.fv-details-list-head p) {
  font-size: var(--font-size-averege) !important;
}

:deep(.fv-light-DetailsList p),
:deep(.fv-details-list-content span p) {

  min-height: 31px;
  font-family: "aileronsemibold", sans-serif;
}

:deep(.fv-light-DetailsList .fv-details-list-content span p) {
  /* font-size: calc(
    16px + (22 - 16) * ((100vw - 300px) / (1900 - 300))
  ) !important; */
  min-height: 31px;
  font-family: "aileronregular", sans-serif;
}

:deep(.fv-details-list-head > span:nth-child(4)) {
  min-width: 20% !important;
  max-width: 25%;
  min-width: 20% !important;
}

:deep(.fv-details-list-head > span:nth-child(3)) {
  width: 10% !important;
}

.fv-light-DetailsList {
  width: 100%;
  height: 100% !important;
}

:deep(.fv-light-DetailsList) {
  height: auto;
  height: 80% !important;
  overflow-y: auto;
}

:deep(.fv-custom-row) {
  background: rgba(243, 242, 241, 0.4);
  cursor: pointer;
}

:deep(.fv-details-list-content) {
  background: rgba(243, 242, 241, 0) !important;
}

:deep(.fv-details-list-content::-webkit-scrollbar) {
  width: 10px;
  background-color: #f5f5f5;
}

:deep(.fv-details-list-content::-webkit-scrollbar-thumb) {
  border-radius: 0px;
  background-image: linear-gradient(to bottom,
      #1e3c7217 0%,
      #1e3c7280 1%,
      #2a5298d9 100%);
}

:deep(.fv-light-DetailsList .fv-details-list-content .content-row .col *),
:deep(.fv-light-DetailsList .list-head .col .col-content p.default-title) {
  /* font-size: calc(
    16px + (22 - 16) * ((100vw - 300px) / (1900 - 300))
  ) !important; */
  font-size: 80%;
  font-family: "aileronregular";
}

/* :deep(p.default-title) {
 font-size: calc(
    18px + (24 - 18) * ((100vw - 300px) / (1900 - 300))
  ) !important;

}*/
:deep(div.content-row .col) {
  white-space: break-spaces;
  margin: 0;
  padding: 12px !important;
  display: grid !important;
}

:deep(div.content-row .col *) {
  white-space: break-spaces;
  margin: 0;
}

:deep(.fv-light-DetailsList .fv-details-list-content .content-row),
:deep(div.content-row) {
  min-height: 80px;
  height: auto !important;
  max-height: 180px !important;
  overflow: hidden;
}

:deep(.title-main) {
  font-size: var(--font-size-slightly-larger);
  font-weight: 900;
  font-family: "aileronsemibold" !important;
}

:deep(.title-sec:not(i)) {
  font-size: 80% !important;
}

:deep(.fv-light-DetailsList .fv-details-list-content span i),
:deep(div.content-row > span > p > i) {
  font-family: FabricMDL2Icons !important;

  vertical-align: top;
  margin: 10% 10px !important;
}

:deep(div.content-row > span > p) {
  display: grid;
  grid-auto-flow: column;
  place-content: start;
}

:deep(div.content-row > span:nth-child(2) > div:nth-child(1) > i:nth-child(1)) {
  /* font-size: 18px !important; */
  overflow: visible !important;
  font-size: 1.7em !important;
}

:deep(.ms-Icon--PeopleAdd, .ms-Icon--ArrangeBringToFront) {
  margin-top: 10% !important;
}

:deep(.action-icons) {
  display: grid;
  grid-auto-flow: column;
  padding: 12px 14px;
  width: 100%;
  line-height: normal;
}

/* :deep(.new-icon-set) {
  display: block;
  position: relative;
  overflow: visible !important;
} */
:deep(span.col:nth-child(7) > span:nth-child(1)),
:deep(div.content-row > .col:nth-child(6)) {
  justify-content: flex-end;
}
:deep(.Table-Icon) {

  display: grid;
  place-content: center;
  width: 1.7em;
height: 1.7em;
overflow: hidden;
align-content: center;
justify-content: center;

}
</style>