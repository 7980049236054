export default {
  data() {
    return {
        classes: [
        "bg-blue",
        "bg-indigo",
        "bg-purple",
        "bg-pink",
        "bg-red",
        "bg-orange",
        "bg-yellow",
        "bg-green",
        "bg-cyan",
        "bg-peach",
        "bg-misty",
        "bg-iron",
        "bg-sunset"
      ]
    }
    },
  methods: {
    getBgColor: function(index) {
      // Get length of  classes array
      let length = this.classes.length;

      // Get the current turn (how many times the classes have been used from start to finish)
      let turn = Math.floor(index / length);

      // Multiply turn by the length then subtract result from current index
      let colorIndex = index - turn * length;

      return this.classes[colorIndex];
    }},
}
