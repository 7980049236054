<template>
  <div class="CardActionButtonContainer" :class="{'ZCABC': types.isZC, 'MNC': types.isEM}">
    <div
      v-if="this.front"
      class="formactionbutton formactionbuttonspeichern"
      v-on:click="FlipTheCard()"
    >
      <span>löschen</span>
    </div>
    <div
      v-if="!this.front && !this.isNew && this.types.isEM || !this.front && !this.isNew && this.types.isZC "
      class="formactionbutton formactionbuttonspeichern"
      v-on:click="FlipTheCardBack()"

    >
      <span>abbrechen</span>
    </div>
    <div
      v-if="!this.front && this.types.isZC && this.isNew && !this.types.isEM"
      class="formactionbutton formactionbuttonspeichern"
      v-on:click="AbortTheCart()"

    >
      <span>abbrechen</span>
    </div>
    <div
      v-if="!this.front && !this.new && !this.types.isEM && !this.types.isZZ"
      class="formactionbutton formactionbuttonspeichern"
      v-on:click="KillTheCard($event)"
    >
      <span>sicher löschen</span>
    </div>
        <div
      v-if="!this.front && !this.new && this.types.isEM"
      class="formactionbutton formactionbuttonspeichern"
      v-on:click="KillTheCard($event)"
    >
      <span>sicher löschen</span>
    </div>
    <div :class="{'comeone': this.isEdit, 'comeoff': !this.isEdit}" class="formactionbutton formactionbuttonspeichern"   v-if="this.types.isEM && !this.isNew && this.front || this.types.isZC && !this.isNew && this.front"  v-on:click="updateForm()">
      <span>Speichern</span>

    </div>
     <div :class=" {'feedbacksuccess' : this.EditSuccess, 'nofeedback' : !this.EditSuccess}"  v-if="this.types.isEM || this.types.isZC"  class=" formactionbuttonspeichern">
      <span>Änderungen erfolgreich gespeichert</span>

    </div>
      <!-- <div :class="{'comeone': this.isEdit, 'comeoff': !this.isEdit}" class="formactionbutton formactionbuttonspeichern"  v-if="this.types.isZZ"  v-on:click="updateZZ()">
      <span>Speichern</span>

    </div> -->

     <div :class=" {'feedbacksuccessZZ' : this.EditSuccess, 'nofeedback' : !this.EditSuccess}"  v-if="this.types.isZZ"  class="formactionbuttonspeichern">
       <div class="checkont"><i class="material-icons check">check</i></div>
      <span class="feedbacktextZZ">erfolgreich gespeichert</span>

    </div>
    <div v-if="this.new && !this.types.isEM" class="formactionbutton formactionbuttonspeichern"
   v-on:click="SubmitTheForm1()">
      <span>Jetzt anlegen</span>
    </div>

        <div v-if="this.new && this.types.isEM" class="formactionbutton formactionbuttonlong formactionbuttonspeichern"

   v-on:click="!isUsed ? {'click' : SubmitTheFormMN() } : SubmitTheFormOMN() ">
      <span>Maßnahme jetzt anlegen</span>
    </div>
  </div>
</template>
<script>
// import ExDelete from "../../../ExDelete";
export default {
  props: {
    front: Boolean,
    edited: Boolean,
    new: Boolean,
    // back: Boolean,

    cid: Number,
     isNew: {
          type: Boolean,
          default: false,
        },
        isUsed: {
          type: Boolean,
          default: false,
        },
    parentnode: String,
         isEdit: {
          type: Boolean,
          default: false,
        },
        EditSuccess: {
          type: Boolean,
          default: false,
        },
    types: {
      cid: Number,
      isEP: Boolean,
      isEB: Boolean,
      isZC: Boolean,
      isEM: Boolean,
      isZZ:Boolean
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      backshown: false,
      back: true,
    };
  },
  methods: {
    AbortTheCart: function() {
 this.$parent.$emit('abort')
    },
    // toggle Class in Parent that flips the Card 3D
    FlipTheCard: function () {
      let flippi
      if(this.types.isEM) flippi = document.getElementById("CardMasz" + this.cid)
      if(this.types.isZC) flippi = document.getElementById("CardZiel" + this.cid)

      if(flippi) {
      
        this.back = true;
        flippi.firstChild.nextSibling.classList.toggle("DarkSideoftheMoon");

        //Masznahmencard gets flipped - Helper hidden
        if (this.types.isEM) {
          this.$parent.$emit("flipped")
        }
      }
    },

    //Masznahmencard gets backflipped - Helper shown
    FlipTheCardBack: function () {
      let flippi
      if(this.types.isEM) flippi = document.getElementById("CardMasz" + this.cid)
      if(this.types.isZC) flippi = document.getElementById("CardZiel" + this.cid)

      if(flippi) {
        this.back = true;
        console.log(flippi)
        flippi.firstChild.nextSibling.classList.toggle("DarkSideoftheMoon");
        if (this.types.isEM) {
          this.$parent.$emit("backflipped")
        }
      }
    },
    KillTheCard: function () {
      // delete goal
      if (this.types.isZC) {
        this.$store.dispatch("removeEntZiel", {zyklusId: this.$parent.zid, goalId: this.cid});
      }
      // delete measure
      if (this.types.isEM) {
        this.$store.dispatch("removeEntMki", {zyklusId: this.$parent.zid, measureId: this.cid});
      }

      //   // delete ReallifeMNM
      //    if (typeof _spPageContextInfo !== "undefined" && this.types.isEM) {
      //   let url =
      //     "/_api/web/lists/getbyTitle('Mki')/GetItemById(" +
      //     this.cid +
      //     ")";
      //   ExDelete.post(url)
      //     .then((response) => {
      //       console.log(response);
      //       // update pdf
      //       this.$root.$emit("updateZyklusPdf")
      //     })
      //     .catch((error) => {
      //       console.log("Error while deleting action: " + error + "  ");
      //     });
      //     this.$store.commit("deleteAction", this.cid);
      // }
    },
    SubmitTheForm1: function() {
      this.$parent.$emit('submittheform', {})
    },
    SubmitTheFormMN: function() {
       this.$parent.$emit('submittheformmn', {})
    },
        SubmitTheFormOMN: function() {
       this.$parent.$emit('submittheformomn', {})
    },
        updateForm: function() {
      this.$parent.$emit('updateSave', {})
    },
    // updateZZ: function() {
    //   this.$parent.$emit('updateZZ', {})
    // }
  },
  name: "GeneralCardActionButtons",
};
</script>
<style>
.CardActionButtonContainer {
  width: 90%;
  margin-top: 0%;

  display: flex;
  justify-content: space-evenly;
  margin-bottom: 0px;
  position: sticky;
  bottom: 0px;
  /* bottom: 35px; */
}
.CardActionButtonContainer:focus {
    outline: none;
}
.ZCABC {
  grid-area: CardActionButtonContainer;

padding: 24px 0 0 0;
}
.MNC {
  margin-top: 14px;
}
.formactionbutton {
  width: calc(
    150px + (200 - 150) * ((100vw - 300px) / (1900 - 300))
  ) !important;
}
.formactionbuttonlong {
      position: relative;
    color: rgb(68,68,68);
    height: 48px;
    line-height: 48px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    text-align: center;
     text-align: -webkit-center;
   text-align: -moz-center;
    font-family: 'aileronregular', sans-sarif;
    width: 40% !important;

}
.Killswitch {
  animation: downsizing 1s ease;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
@keyframes downsizing {
  40% {
    opacity: 1;
  }
  90% {
    opacity: 0.21;
  }
  100% {
    opacity: 0;
    display: none;
  }
}
.nofeedback,
.comeoff {
  opacity: 0;
  pointer-events: none;
}
.nofeedback {
  display: none;
}
.feedbacksuccess {
  font-family: "aileronheavy", sans-serif;
  animation: arrivetext ease 2.2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards; /*when the spec is finished*/
  -webkit-animation: arrivetext ease 2.2s;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards; /*Chrome 16+, Safari 4+*/
  -moz-animation: arrivetext ease 2.2s;
  -moz-animation-iteration-count: 1;
  -moz-animation-fill-mode: forwards; /*FF 5+*/
  -o-animation: arrivetext ease 2.2s;
  -o-animation-iteration-count: 1;
  -o-animation-fill-mode: forwards; /*Not implemented yet*/
  -ms-animation: arrivetext ease 2.2s;
  -ms-animation-iteration-count: 1;
  -ms-animation-fill-mode: forwards; /*IE 10+*/
      position: absolute;
    height: 48px;
    line-height: 48px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    text-align: center;
     text-align: -webkit-center;
   text-align: -moz-center;
    left: 50%;
    bottom: 25%;
}
.feedbacksuccessZZ {
   position: absolute;
    height: 48px;
    line-height: 48px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    text-align: center;
     text-align: -webkit-center;
   text-align: -moz-center;
    left: 0%;
    bottom: 25%;
    width: 100%;
        overflow: hidden;
        background-image:linear-gradient(to left, #0ba36121 0%, #3cba9245 100%);
}
.check {
color: white;
font-weight: 900  !important;
font-size: 48px !important;
 animation: check ease 2.2s;
      animation-iteration-count: 1;
animation-fill-mode: forwards; /*when the spec is finished*/
  -webkit-animation: check ease 2.2s;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards; /*Chrome 16+, Safari 4+*/
  -moz-animation: check ease 2.2s;
  -moz-animation-iteration-count: 1;
  -moz-animation-fill-mode: forwards; /*FF 5+*/
  -o-animation: check ease 2.2s;
  -o-animation-iteration-count: 1;
  -o-animation-fill-mode: forwards; /*Not implemented yet*/
  -ms-animation: check ease 2.2s;
  -ms-animation-iteration-count: 1;
  -ms-animation-fill-mode: forwards; /*IE 10+*/

      z-index: 999;
      position: absolute;
      right: 45%;
}
.feedbacksuccessZZ::after {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    background-image: linear-gradient(to top, #0ba360 0%, #3cba92 100%);;
    top: 0;
    left: 0;
    animation: slide ease 2.2s;
      animation-iteration-count: 1;
  animation-fill-mode: forwards; /*when the spec is finished*/
  -webkit-animation: slide ease 2.2s;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards; /*Chrome 16+, Safari 4+*/
  -moz-animation: slide ease 2.2s;
  -moz-animation-iteration-count: 1;
  -moz-animation-fill-mode: forwards; /*FF 5+*/
  -o-animation: slide ease 2.2s;
  -o-animation-iteration-count: 1;
  -o-animation-fill-mode: forwards; /*Not implemented yet*/
  -ms-animation: slide ease 2.2s;
  -ms-animation-iteration-count: 1;
  -ms-animation-fill-mode: forwards; /*IE 10+*/
  border: none


}
.feedbacktextZZ {
opacity: 0;
  animation: seeme ease 2.5s;
      animation-iteration-count: 1;
      animation-delay: 2s;
  animation-fill-mode: forwards; /*when the spec is finished*/
  -webkit-animation: seeme ease 2.5s;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-delay: 2s;
  -webkit-animation-fill-mode: forwards; /*Chrome 16+, Safari 4+*/
  -moz-animation: seeme ease 2.5s;
  -moz-animation-iteration-count: 1;
  -moz-animation-delay: 2s;
  -moz-animation-fill-mode: forwards; /*FF 5+*/
  -o-animation: seeme ease 2.5s;
  -o-animation-iteration-count: 1;
  -o-animation-delay: 2s;
  -o-animation-fill-mode: forwards; /*Not implemented yet*/
  -ms-animation: seeme ease 2.5ss;
  -ms-animation-iteration-count: 1;
  -ms-animation-delay: 2s;
  -ms-animation-fill-mode: forwards; /*IE 10+*/
}
@keyframes seeme {
  0% {
     opacity: 0;
  }
  100% {
opacity: 1;
  }
}
@keyframes slide {
  0% {
     transform: translateX(500px);
  }
  100% {
transform: translateX(-500px);
  }
}
@keyframes check {
  0% {
     opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
opacity: 0
  }
}
.comeone {

  font-family: "aileronheavy", sans-serif;
  animation: arrive ease 2.2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards; /*when the spec is finished*/
  -webkit-animation: arrive ease 2.2s;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards; /*Chrome 16+, Safari 4+*/
  -moz-animation: arrive ease 2.2s;
  -moz-animation-iteration-count: 1;
  -moz-animation-fill-mode: forwards; /*FF 5+*/
  -o-animation: arrive ease 2.2s;
  -o-animation-iteration-count: 1;
  -o-animation-fill-mode: forwards; /*Not implemented yet*/
  -ms-animation: arrive ease 2.2s;
  -ms-animation-iteration-count: 1;
  -ms-animation-fill-mode: forwards; /*IE 10+*/
}
@keyframes arrive {
  0% {
    opacity: 0;
    box-shadow: 0 0 60px 35px #fff,  /* inner white */
    0 0 19px 8px blue, /* middle green*/
    0 0 14px 9px white;
  }


  100% {
    opacity: 1;
    box-shadow: none
  }
}
@keyframes arrivetext {
  0% {
    opacity: 0;
    transform: scale(0.95);


  }

30% {
		transform: scale(1);

	}
  50% {
    opacity: 1;
    transform: scale(0.95);
  }
  100% {
    opacity: 0;
  }
}
</style>
