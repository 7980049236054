// Home.vue

<template>
<div>
  <div id="scren" v-shared-element:structure>
  </div>
  <div id="paracont">
    <img id="para2" :src="BG2" />
    <img id="para1" :src="BG1" v-shared-element:hallo />
  </div>

  <div>
    <div id="begrcontainer">
      <div id="startseiteueberschriftcontainer">
        <span id="startseiteub1">Willkommen auf der</span>
        <span id="startseiteub2">WebApp</span>
          <span id="startseiteub3">für personalisierte Entwicklungsplanung</span>
      </div>

      <div id="startseitebuttoncontainer" style="text-align: center">
           <div class="btn btn-four" id="startbuttonbutton"  @click="ill()"  >
            <span id="startbuttontext"  class="">JETZT BEGINNEN</span>
          </div>
      </div>

    </div>
  </div>
  <div id="wolk">
  </div>

</div>
</template>
<script>
import {
  illusory,
  IllusoryElement
} from 'illusory'

export default {
  name: 'Startseite',
  data() {
    return {
      title: 'Startseite',
      username: "",
      password: "",
      siteurl: "https://pep.orga-cloud.de/devH",
      BG1: require(`../assets/bgg2comp.jpg`),
      BG2: require(`../assets/bgg2compblur.jpg`),
      illu: {
        "start": [],
        "end": [],
        "startbg": [],
        "endbg": {},
        "endbge": [],
      }
    }
  },
  computed: {
    api() {
      return this.$store.getters.api
    }
  },
  methods: {
    ill() {
      // console.log("triggered");
      const start = document.querySelector('#startseitebuttoncontainer');
      const end = document.querySelector('#scren');
      const startbg = document.querySelector('#para1');
      const endbg = document.querySelector('#para2');
      const endbge = new IllusoryElement(endbg);

      // if pepApi has no token for database, go to Login Page
      if (this.$store.getters.api === "pepApi" && !document.cookie.includes("pep_token")) {
        this.ill2(start, end, startbg, endbge, "Login")
      } else {
        this.ill2(start, end, startbg, endbge, "UebersichtPlaene")
      }
    },
    ill2(start, end, startbg, endbge, pushLoc) {
      endbge.hideNatural();


      // console.log(start,end,startbg,endbge);

      illusory(startbg, endbge, {
          duration: '1.0s',

          async beforeAnimate(from, to) {
            to.hideNatural()

            from.showNatural()
            from.hide()
          },
          beforeDetach(to) {
            to.show();
          },
          element: {
            includeChildren: false
          }
        }),
        illusory(start, end, {
          duration: '.85s',
          element: {
            includeChildren: false
          },
          ignoreTransparency: true,
          beforeDetach(from, to) {
            to.setStyle('z-index', 99);
            from.hideNatural()
          },

        }),
        setTimeout(() => this.$router.push({
          name: pushLoc
        }), 700);
    },
    pppush() {
      this.$router.push({
        name: "UebersichtPlaene",
        // params: {
        //   id: pid
        // }
      })
    },
    buttonsizing: function() {
      let button = document.getElementById("startbuttonbutton");
      let text = document.getElementById("startseiteueberschriftcontainer");
      let image = document.getElementById("para");
      button.classList.toggle("clicked");
      text.classList.toggle("textclicked");
      image.classList.toggle("imageblur");
      /// Hier Timeout der Animation -- aktuell 6000ms
      /// Dann Router push
      // this.$router.push({ name: "Login"})
      setTimeout(() => this.$router.push({
        name: 'UebersichtPlaene'
      }), 2500);
    }
  },



}
</script>
<style>
html {
  height: 100% !important;
}
</style>
<style scoped>
#hintergrund {
  opacity: 0;
}

.startseiteueberschriftcontainer {
  width: fit-content;
}
#startseiteueberschriftcontainer {
  display: grid;
place-content: space-evenly;
line-height: normal;
backdrop-filter: blur(15px);
}
.btn {
  position: relative;
  color: inherit;
  /* width: 23%; */
  height: 34px;
  line-height: 34px;
  transition: all 0.3s;
  padding: 14px 33px;
  font-size: 17px;
  /* margin-top: 30px; */
  margin-top: 60px;
  width: 40%;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  cursor: pointer;
}

.btn-four::before {
  opacity: 0;
  /* background: rgb(128 128 128 / 70%); */
  transform: scale(0.1, 0.1);
  background: rgb(36 40 52 / 20%);
  background-image: radial-gradient(rgb(36 40 52 / 20%), rgb(210 170 170 /20%));
}

.btn::before,
.btn::after {
  content: '';
  position: absolute;
  transition: all 0.3s;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

#startbuttontext {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  padding: 3rem;
  font-family: 'Aileronsemibold', sans-serif;
  text-decoration: none;
  width: 100%;
  font-size: calc(18px + (26 - 18) * ((100vh - 300px) / (1900 - 300))) !important;
  z-index: 6;

}

.btn-four::after {
  transition: all 0.3s;
  border: 1.2px solid rgb(68 68 68);
  background: #ffffff96;
}

.btn::before,
.btn::after {
  content: '';
  position: absolute;
  transition: all 0.3s;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  backdrop-filter: blur(2px) brightness(1.3) contrast(.5);
}

.btn-four:hover {
  color: rgb(255 255 255);
  z-index: 10;
}

.btn-four:hover::before {
  opacity: 1;
  transform: scale(1, 1);
  z-index: -1;
}

#begrcontainer {
  position: absolute;
  /* top: 33%;
  right: 50%;
  transform: translate(50%, -50%);
  padding: 50px;
  min-width: 80vw;
  min-height: 20vh; */
  display: grid;
  place-content: space-evenly;
  height: 100%;
  width: 100%;
  grid-gap: 15vh;

}

.clicked {

  position: fixed;
  margin-top: 0px;
  margin-left: 50px;
  animation: sizing 2s ease;
  animation-iteration-count: 1;
  background-image: linear-gradient(90deg, rgb(42 42 46 / 0%) 50px, rgb(255 255 255 / 22%) 0%);
  pointer-events: none;
  animation-fill-mode: forwards;


}

#scren {
  background-image: linear-gradient(90deg, rgb(42 42 46 / 100%) 50px, rgb(255 255 255 / 22%) 0%);
  position: absolute;
  height: 100%;
  width: 100%;
}

.textclicked {
  opacity: 0;
  transition: opacity 1s ease;
}

.clicked>span {
  display: none;
}

.imageblur {
  animation: blurring 1.6s ease;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  animation-delay: 1.2s;
}

@keyframes blurring {
  10% {
    filter: blur(1px)
  }

  70% {
    filter: blur(5px)
  }

  100% {
    filter: blur(8px) saturate(1.1) brightness(1.1)
  }
}

@keyframes sizing {
  40% {
    height: 40vh;
    width: calc(50vw - 50px);
    background-image: linear-gradient(90deg, rgb(42 42 46 / 00%) 50px, rgb(255 255 255 / 22%) 0%);
  }

  90% {
    width: calc(100vw - 50px);
  }

  100% {
    height: 100vh;
    width: calc(100vw - 61px);
    margin-left: 0px;
    background-image: linear-gradient(90deg, rgb(42 42 46 / 90%) 50px, rgb(255 255 255 / 22%) 0%);

  }
}

#startseiteub2,
#startseiteub1 {
  font-size: calc(49px + (87 - 49) * ((100vh - 300px) / (1900 - 300))) !important
}

#startseiteub1 {
  font-family: 'aileronultralight', sans-serif;
  text-transform: uppercase;
}

#startseiteub2 {
  font-family: 'aileronregular', sans-serif;
}
#startseiteub3 {
 font-size: calc(21.875px + 2.375vw) !important;
font-family: 'aileronthin',sans-serif;
}

#hintergrund::after {
  background: none !important;
}

#paracont {
  max-width: 100%;
  max-height: 100vh;
  position: fixed;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

#para2,
#para1 {
  min-width: 100%;
  max-height: 100%;
  bottom: 0;
  left: 0;
  margin: auto;
  overflow: auto;
  position: fixed;
  right: 0;
  top: 0;
  object-fit: cover;
}

#wolk {
  --size: 30vmin;
  display: none;

  height: 237%;
  width: 139%;
  position: fixed;
}

.wolkani {
  transform: scale(1);
  animation: zoomsi 1.1s cubic-bezier(0.4, 0, 1, 1);

  top: -244px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  animation-play-state: paused;
  animation-delay: calc(var(--scroll) * -1s);
}

@keyframes zoomsi {
  to {
    transform: scale(.5);
    transform-origin: top;
  }
}

</style>
<style scoped>
#s4-workspace {
  overflow-y: hidden !important;
}
</style>
