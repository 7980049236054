<style >
body.modal-open {
  overflow: hidden;
}
.zyklusansicht {
  width: 100%;
  text-align: left;
     text-align: -webkit-left;
   text-align: -moz-left;
  display: grid;
}
.page-enter-active {
  transition: opacity 35ms ease 35ms;
}

.page-leave-active {
  position: absolute;
  transition: opacity 35ms ease;
}

.page-leave-to,
.page-enter {
  opacity: 0;
}


</style>

<template >
  <div class="zyklusansicht"   >

    <ZyklusAuR id="AL" :zid="zid" :studentId="sid" ref="zyklusAuR"/>
    <ZyklusEBereiche id="EB" :zid="zid" :zykluskonkret="zyklus" :ready="ready"/>
    <ZyklusZiele :zid="zid" id="EZ" />
    <ZyklusMasznahmen :zid="zid" id="MN" />
    <ZyklusZuordnung :zid="zid" :studentId="sid" id="ZZ"/>
   
    <template v-if="!isZyklusAbschlussLoaded">
  <LazyLoadZyklusComponent :title="'Abschluss'"/>
</template>
<template v-else>
  <component :is="zyklusAbschlussComponent" id="AB" :zid="zid" :studentId="sid" :isZyklusAnsichtActive="isZyklusAnsichtActive" />
</template>

  </div>
</template>

<script>
import ZyklusAuR from "./ZyklusAuR";
import ZyklusEBereiche from "./ZyklusEBereiche";
import ZyklusZiele from "./ZyklusZiele";
import ZyklusMasznahmen from "./ZyklusMaßnahmen";
import ZyklusZuordnung from "./ZyklusZuordnung"
import LazyLoadZyklusComponent from "./LazyLoadZyklusComponent.vue";
// import ZyklusAbschluss from "./ZyklusAbschluss";

export default {
  name: "ZyklusAnsicht",
  props: {
    zid: {
      type: Number,
      default: 1,
    },
    sid: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      ready: null,
      isZyklusAbschlussLoaded: false,
      zyklusAbschlussComponent: null,
      isZyklusAnsichtActive: false,


    }
  },
  watch: {
    zyklus: function () {
      if (this.zyklus) {
        this.ready = true
      }
    }
  },
  beforeRouteLeave(to, from, next) {
  if (this.$refs.zyklusAuR) {
    this.$refs.zyklusAuR.routeUpdate(to, from, next);
  } else {
    next();
  }
},
methods: {
  loadZyklusAbschluss() {
  import(/* webpackChunkName: "zyklus-abschluss-chunk" */ '../Zyklus/ZyklusAbschluss.vue')
    .then((component) => {
      this.isZyklusAbschlussLoaded = true;
      this.zyklusAbschlussComponent = component.default;
    })
    .catch((error) => {
      console.error("Fehler beim Laden der ZyklusAbschluss-Komponente:", error);
    });
}

},
mounted() {
  this.loadZyklusAbschluss();
  this.isZyklusAnsichtActive = true;

},
  computed: {
    zyklus() {
      if (!this.sid || !this.zid) {
    console.warn('Both sid and zid must be provided. Aborting.');
    return;
  }
  console.warn('Call from ZyklusAnsicht ');
      return this.$store.getters.zyklusById({sid: this.sid, zid: this.zid})
    },
    colorClass() {
      return this.$store.getters.getColorClass;
    },
  },
  components: {
    ZyklusAuR,
    ZyklusEBereiche,
    ZyklusZiele,
    ZyklusMasznahmen,
    ZyklusZuordnung,
    LazyLoadZyklusComponent
},
};
</script>
