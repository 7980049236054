  <!-- How to use in Parent -->
  
  <!-- Planen-Button-->
          <!-- <ActionStatusButton
          :active="true"
          :checked="zyklus.Abschluss"
          uncheckedText="Planen"
          checkedText="Geplant"
          :navigateTo="() => goToCyl(id)"
        /> -->
    
        <!-- Evaluieren-Button -->
        <!-- <ActionStatusButton
          :active="zyklus.Abschluss"
          :checked="zyklus.Eval"
          uncheckedText="Evaluieren"
          checkedText="Evaluiert"
          :navigateTo="() => goToCylEval(id)"
        /> -->


<template>
    <div class="action-status-button" :class="[
        active ? 'action-button-active' : 'action-button-disabled',
        checked ? 'checked' : 'unchecked',
    ]" @click="navigateToTask">

        <div class="action-button-text">{{ buttonText }}</div>
        <div class="action-button-icon">
            <i :class="iconClass"></i>
        </div>
    </div>
</template>
  
<script>
export default {
    props: {
        // Determines if the button is active or disabled
        active: {
            type: Boolean,
            default: false,
        },
        // Determines if the status is checked or unchecked
        checked: {
            type: Boolean,
            default: false,
        },
        // Text displayed when unchecked
        uncheckedText: {
            type: String,
            default: 'Planen',
        },
        // Text displayed when checked
        checkedText: {
            type: String,
            default: 'Geplant',
        },
        // Navigation function passed from the parent component
        navigateTo: {
            type: Function,
            default: null,
        },
    },
    computed: {
        // Determines which text to display based on the checked status
        buttonText() {
            return this.checked ? this.checkedText : this.uncheckedText;
        },
        // Determines which icon to display based on the checked status
        iconClass() {
            return this.checked ? 'ms-Icon ms-Icon--CheckboxComposite' : 'ms-Icon ms-Icon--Checkbox';
        },
    },
    methods: {
        // Handles button click, triggers navigation if active and navigation function is provided
        navigateToTask() {
            console.log("Button clicked. Active:", this.active, "NavigateTo:", this.navigateTo);
            if (this.active && this.navigateTo) {
                this.navigateTo();
            }
        },
    },
};
</script>
  
<style scoped>
.action-status-button {
    display: grid;
    grid-auto-flow: column;
    place-items: center;
    grid-gap: 8%;
    cursor: pointer;
    padding: 0px 21px;
    max-height: 50px;
    place-content: center;
    min-width: 64px;
    font-size: 16px;
    overflow: hidden;
    position: relative;
    box-sizing: border-box;
    width: fit-content;
}

.small-button {
    max-height: 30px;
    padding: 0px 13px;
}

.action-button-active {
    border: 1px solid black;
    box-shadow: var(--button-elevation-medium);
    transition: box-shadow 0.15s ease;
    background-color: rgba(255, 255, 255, 0.37);
    backdrop-filter: blur(10px);
}

.action-button-disabled {
    background: #ccc;
    color: #5b5b5b;
    pointer-events: none;
    border: 1px solid #aaa;
    box-shadow: var(--button-elevation-low);
    transition: box-shadow 0.15s ease;
}

.action-button-icon {
    padding: 8px 0 8px 8px;
}

.action-button-text {
    padding: 8px 8px 8px 0;
}

.action-status-button.action-button-active:hover {
    background: white;
    transition: all 0.3s ease;
}
</style>
  