
/*eslint no-undef: "error"*/
import store from "../index"
import api from '../../api/api.js'

const state = {
  groupadmin: null
}

const actions = {
   loadGroupAdministration({commit}) {
    api.getGroupAdministration()
    .then(res => {
      commit("setGroupAdministration", res)
    })
    .catch(err => {
      console.log("Error while loading group object: " +err)
    })
  },
  UpdateSettings1(payload) {
    api.updateSettings(payload)
     .then((r) => {
      console.log(r)
     })
      .catch(err => {
           alert("Es trat ein Problem mit der Serververbindung oder Session auf. Die Einstellungen konnte leider nicht aktualisiert werden. Sie können versuchen die Browserseite neu zu laden.")
           console.log("Error while updating Settings: " +err)
       })
   
  },
}

const getters = {
  groupadmin: state => {
    return state.groupadmin
  },
  groupadminselected: (state, groupName) => {
    return state.groupadmin.filter(item => groupName === item.Title)
  },
  
}

const mutations = {
  setGroupAdministration: (state, groupadmin) => {
    state.groupadmin = groupadmin
  },
  SettingChange: (state, payload) => {
    console.log(payload)
    if(payload) {
      
      console.log(payload.Settings)
      console.log(store.state.config.admin.groupsSet)
      store.state.config.admin.groupsSet.forEach(gd => {
       if(gd.Id === payload.gdId) {
        // if (gd.Settings === null) {
          gd.Settings = 
              payload.Settings
            //  }
        }
      })
     
    }
    let pl = {
      gdId: payload.gdId,
      Settings: payload.Settings
    }
    console.log(pl)

    store.dispatch("UpdateSettings", pl)
// store.state.config.admin.groupsSet.push({Id: "placeholder"})
// store.state.config.admin.groupsSet = store.state.config.admin.groupsSet.filter(item => item.Id !== "placeholder")
  
 },
}

export default {
  state,
  actions,
  mutations,
  getters
}
