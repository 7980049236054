<template>
  <div>
    <svg>
  <filter id="pixelate" x="0" y="0">
    <feFlood x="4" y="4" height="2" width="2"/>
    
    <feComposite width="4" height="4"/>
    
    <feTile result="a"/>
    
    <feComposite in="SourceGraphic" in2="a" 
                 operator="in"/>
    
    <feMorphology operator="dilate"
                  radius="1"/>
  </filter>
  </svg>
    <div v-if="this.types.isEP && this.CardView === 'card'">


<!-- EP placeholder -->
  <fv-Shimmer style="height: auto;" v-if="epIsLoading">
    <div style="width: 100%; height: 100%; display: flex; flex-wrap: wrap;">
      <div v-for="(item, index) in 5" :key="index" style="margin: 1.5%; margin-top: 5px; display: flex; align-items: center;">
        <div class="sample" style="background-color: white; padding-bottom: calc(225px + 110*(100vw - 300px)/1600)!important;width: calc(355px + 110*(100vw - 300px)/1600)!important;margin-left: 15px;background-image: inherit;">
          <div class="sample" style="width: 40%;margin-left: 4%;background-image: inherit;margin-top: 40px;background-color: rgb(178 178 178 / 59%);"></div>
          <div class="sample" style="width: 78%;height: 50px; margin-left: 4%;background-image: inherit;margin-top: 10px;background-color: rgb(228 228 228 / 63%);"></div>
          <div class="sample" style="width: 60%;margin-left: 4%;background-image: inherit;margin-top: 15%;background-color: rgb(178 178 178 / 59%);"></div>
          <div class="sample" style="width: 68%;margin-left: 4%;background-image: inherit;margin-top: 5px;background-color: rgb(228 228 228 / 63%);"></div>
          <div class="sample" style="width: 68%;margin-left: 4%;background-image: inherit;margin-top: 10px;background-color: rgb(178 178 178 / 59%);"></div>
          <div class="sample" style="width: 40%;margin-left: 4%;background-image: inherit;margin-top: 5px;background-color: rgb(228 228 228 / 63%);"></div>
        </div>
      </div>
    </div>
  </fv-Shimmer>
  <transition-group v-else name="kommup-in9" class="ep" > 
   <CardComponent
        v-for="(kaert, $index) in kaertchen"
        :id="'Card' + kaert.Id"
        v-bind:key="kaert.Id"
        :types="types"
        :cid="kaert.Id"
        :cardtitle="kaert.RefS.Title + '; ' + kaert.RefS.FirstName"
        :cardextra="kaert.RefS.Klasse"
        :contentone="getVerantwortliche(kaert)"
        :contenttwo="kaert.Modified | dateformer"
        :index="$index"
        :unlocked="kaert.unlocked"
        v-shared-element:[kaert.Id]

        :href="`/Student/${kaert.Id}`"

      />
      <div
      @click="deleteLocalData()"
      v-if="(api === 'local' || api ==='localStorage' ) && kaertchen.length > 3"
      :key="998989"
      class="EntwicklungsplanCard GeneralCard deletecard">
      <div class="delete-card-container">
      <span class="HeaderTitle EPExtraHeader title-container" style="line-height: 200%;font-family: aileronregular;">
      eigene Testdaten jetzt
      </span>
      <span class="HeaderTitle EPExtraHeader title-container" style="line-height: 200%;">LÖSCHEN</span></div>
    </div>


     
      <!-- ="{ restrictToRoutes: ['/Student']}" -->
       </transition-group> 
    </div>

    <div v-if="this.types.isEB" :class="{ EbereichContainer: types.isEB }">
      <div
        style="
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          grid-template-rows: 1fr;
          gap: 7px 10px;
          justify-items: stretch;
          width: 100%;
        "
      >
        <CardForm
          v-for="(EBForm, $index) in selectedEb"
          v-bind:key="'EBForm' + $index"
          :types="types"
          :showback="true"
          :colorClass="getColor(EBForm)"
          :zid="zid"
          :cardtitle="EBForm"
        >
          <template v-slot:bereichslot>
            <h3 class="anlegentext bereichanlegen">
              {{ EBForm }}
            </h3></template
          >
        </CardForm>
      </div>
      <div
        style="
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          grid-template-rows: 1fr 1fr 1fr;
          gap: 7px 10px;
          grid-template-rows: auto;
          align-items: self-start;
        "
      >
        <CardComponent
          v-for="(kaert, $index) in ebKaertchen"
          :id="'Card' + kaert.Id"
          v-bind:key="$index"
          :types="types"
          :cid="kaert.Id"
          :cardtitle="kaert.Title"
          :contentone="kaert.kurzbeschreibung"
          :index="$index"
          :selectOff="selectOff"
          :aktivierte="isActive(kaert.Title)"
          ref="bereichchild"
        >
        </CardComponent>
      </div>
    </div>
    <div v-if="this.types.isZC" style="display: grid">
      <CardForm :types="types" :zid="zid" />
      <transition-group name="zyklus-list" class="zykluscontainer">
        <CardComponent
          v-for="(kaert, index) in zielkaertchen"
          :id="'CardZiel' + kaert.Id"
          v-bind:key="kaert.Id"
          :types="types"
          :cid="kaert.Id"
          :cardtitle="kaert.Title"
          :contentone="kaert.IstStand"
          :contenttwo="kaert.Entwicklungsziel"
          :index="index"
          :zid="zid"
        />
      </transition-group>
    </div>
    <div v-if="this.types.isEM" class="maszcontainer">
      <CardForm
        :zid="zid"
        :types="types"
        :emethods="methskaertchen"
        :maszind="maszkaertchen"
      />
      <transition-group name="mnm-list" class="mnmncontainer">
        <CardComponent
          v-for="(kaert, index) in zymaszkaertchen"
          :id="'CardMasz' + kaert.Id"
          v-bind:key="kaert.Id"
          :types="types"
          :cid="kaert.Id"
          :cardtitle="kaert.Title"
          :contentone="kaert.Kurzbeschreibung"
          :contenttwo="kaert.Sozialform"
          :contentthree="kaert.Voraussetzungen_x002f_Materialie"
          :contentfour="kaert.Zeitumfang"
          :methcontent="{
            Id: kaert.RefMeth.Id,
            Title: kaert.RefMeth.Title,
            Beschreibung: kaert.RefMethBeschr,
            Ziel: kaert.RefMethZiel,
          }"
          :index="index"
          :zid="zid"
        />
      </transition-group>
    </div>
  </div>
</template>
   <script>
import CardComponent from "./CardComponent";
import CardForm from "./CardForm";
import getBGcolor from "../../../Mixins/getBGcolor";
// import ExMerge from "../../../../src/ExMerge";
//import func from 'vue-editor-bridge';

const constants = {
  entwicklungsbereiche: ["Entwicklungsbereich 1", "Entwicklungsbereich 2", "Entwicklungsbereich 3"]
}

export default {
  props: {
    kaertchen: {
      type: Array
    },
    epIsLoading: {
      type: Boolean
    },
    zid: {
      type: Number,
      default: 0
    },
    types: {
      isEB: {
        type: Boolean,
        default: false,
      },
      isEP: {
        type: Boolean,
        default: false,
      },
      isZC: {
        type: Boolean,
        default: false,
      },
      isEM: {
        type: Boolean,
        default: false,
      },
    },
    zykluskonkret: {type: Object,
      default:  () => {},
    },
    ready: {
      type: Boolean,
      default: false,
    },
    CardView: {
      type: String
    }
  },
  data() {
    return {
      componentKey: 0,
      selectOff: false,
      savetimer: false,
      initial: false,
      storecallback: false,
    };
  },
  created() {
    // this.GetTheGoals();

    // if (this.types.isEM == true) {
    //   if (typeof _spPageContextInfo === "undefined") {
    //     // this.$store.dispatch("loadMethoden");
    //     // this.$store.dispatch("loadIndividualEntwicklungsMasz");
    //     // this.$store.dispatch("loadZEntwicklungsMasz");
    //   } else {
    //     // let UserId = _spPageContextInfo.userId;
    //     // let ZyklId = this.$parent.$props.zid;
    //     // this.$store.dispatch("loadMethods");
    //     // this.$store.dispatch("loadIndividualActions", UserId);
    //     // this.$store.dispatch("loadZActions", ZyklId);
    //   }
    // }

    this.$on("add", function (selectedT) {
      this.$store.commit("addZyklusEb", {id: this.id, zid: this.zid, eb: selectedT.title})
      // this.$root.$emit("updateZyklusPdf")
    })
    this.$on("unadd", function (selectedT) {
      this.$store.commit("removeZyklusEb", {id: this.id, zid: this.zid, eb: selectedT})
      // this.$root.$emit("updateZyklusPdf")
    })
  },
  mixins: [getBGcolor],
  methods: {
    deleteLocalData() {
      if(window.confirm("Sind Sie sicher, dass Sie Ihre Testdaten endgültig löschen möchten?")) {
        localStorage.clear()
        this.$store.dispatch("resetData")
        // setTimeout(() => {
        //   this.$router.push({ name: 'UebersichtPlaene'})
        // }, 500)
      }
    },
    getVerantwortliche(kaert) {
      if (this.$store.getters.api === "pepApi") {
        if(kaert.PiC && kaert.PiC.length) return kaert.PiC.map(pic => pic.Title).toString()
        return kaert.Author.Title
      }
      return kaert.Verantwortlich
    },
    getColor(ebTitle) {
      if(constants.entwicklungsbereiche.includes(ebTitle) !== true) {
        let id = this.$store.getters.ebKaertchenId(ebTitle)
        if(id) return this.getBgColor(id)
      }
      return ""
    },
    isActive(ebTitle) {
      if(this.selectedEb.includes(ebTitle)) return true
      else return false
    },
    // GetTheGoals() {
    //   if (typeof _spPageContextInfo === "undefined" && this.types.isZC == true) {
    //     this.$store.dispatch("loadEntwicklungsZiele");
    //   } if (typeof _spPageContextInfo !== "undefined" && this.types.isZC == true) {
    //     let ZyklId = this.$parent.$props.zid;
    //     this.$store.dispatch("loadGoals", ZyklId);
    //   }
    // },
    // colorChecked(selectedT, hier) {
    //   if (this.types.isEB == true) {
    //     let bereichdata = this.ebKaertchen;
    //     let hiertitle = bereichdata.find(
    //       (element) => element.Title == selectedT.title
    //     );
    //     let iiii = hiertitle.Id;
    //     let child = this.$children;
    //     let chhier = child.find((element) => element.cid == iiii);
    //     let cla = chhier.colorClass;
    //     hier.colorClass = cla;
    //  //   console.log(cla);
    //   }
    // },
  },
  filters: {
    nameshorter(value) {
      if (value) {
        return value.split("Entwicklungsplan")[1];
      }
    },
    authortitle(value) {
      if (value) {
        return value.Title
      }
    }
  },

  computed: {
    api() 
    {
      return this.$store.getters.api
    },
    selectedEb() {
      // make sure that there are always exactly three development areas
      let ebArray = this.$store.getters.zyklusEbById({id: this.id, zid: this.zid})
      let selectedEb = [...constants.entwicklungsbereiche]
      if(Array.isArray(ebArray) && ebArray.length) {
        for(let index in ebArray) {
          let eb = ebArray[index]
          if(eb) selectedEb[index] = eb
        }
      }
      return selectedEb
    },
    ebKaertchen() {
      let ebKaertchen = this.$store.getters.ebKaertchen
      if(ebKaertchen) return ebKaertchen
      else return []
    },
    zielkaertchen() {
      return this.$store.getters.entZiele(this.zid)
    },
    // zielkaertchen: function () {
    //   if (this.types.isZC == true) {
    //     let zk = this.$store.getters.EZiele;
    //     this.$store.commit("setEZ", zk);
    //
    //     return zk;
    //   }
    //   return "not";
    // },
    methskaertchen: function () {
      let methskaertchen = this.$store.getters.entplMethods
      if(methskaertchen) return methskaertchen
      else return []
      // if (this.types.isEM == true) return this.$store.getters.Meths;
      // return "not";
    },
    zymaszkaertchen: function () {
      return this.$store.getters.entMkbyZid(this.zid)
      // if (this.types.isEM == true) {
      //   let mk = this.$store.getters.EMasz;
      //  this.$store.commit("setEM", mk);
      //   return mk;
      // }
      // return "not";
    },
    maszkaertchen: function () {
      let maszkaertchen = this.$store.getters.entMk
      if(maszkaertchen) {
        let uniqueArray = maszkaertchen.filter(function(item, pos) {
          // if object with same Title and Kurzbeschreibung is already shown, hide object
          return maszkaertchen.findIndex(i => i.Title === item.Title && i.Kurzbeschreibung === item.Kurzbeschreibung) == pos;
        })
        return uniqueArray
      }
      else return []
    }
  },

  name: "CardCollection",
  components: {
    CardComponent,
    CardForm,
  },
};
</script>
<style>
.deletecard::before {
  content: "";
  position: absolute;
  height: 90%;
  width: 90%;
  left: 5%;
  top: 5%;
  z-index: -1;
  border-radius: 25rem;
  background: linear-gradient(180deg, #EF516D 0%, rgba(239, 81, 109, 0) 100%), radial-gradient(94.51% 124.88% at 94.32% 94.43%, rgba(65, 244, 255, 0.78) 0%, rgba(131, 218, 255, 0.6552) 32.29%, rgba(99, 175, 240, 0.3978) 64.06%, rgba(43, 90, 211, 0) 100%), linear-gradient(313.04deg, #341D65 0.93%, #FFA700 125.68%);
  background-blend-mode: color, multiply, screen, saturation, darken, lighten;
  filter: blur(81px);
  background-blend-mode: normal,normal,normal,normal,normal,normal;
  transform: scale(0.9) translateY(20%);
  transition: .3s cubic-bezier(.65,.05,.36,1);
}
.deletecard:hover::before {
  transform: rotate(80deg) scale(3);
transition: .3s cubic-bezier(.65,.05,.36,1);
}
.deletecard {
  background: #ffffff87;
  font-family: aileronblack;
  border: inset;
}
.delete-card-container {
  display: grid;
  place-content: center;
  height: 100%
}
/*.zykluscontainer, */
.EbereichContainer {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
.ZielCard {
  transition: all 1s;
  display: block;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.mnm-list-enter,
.mnm-list-leave-to,
.EB-list-enter,
.EB-list-leave-to,
.zyklus-list-enter,
.zyklus-list-leave-to {
  opacity: 0;
  transform: translateY(30px);
}
.mnm-list-leave-active,
.zyklus-list-leave-active {
  position: absolute;
}
.ep,
.zykluscontainer {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: normal;
}
/* .zykluscontainer .ZielCard:first-child {
  width: calc((100% - 10vw) / 2);
  min-height: 45vh;
  max-height: 45vh;
} */
.maszcontainer {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.mnmncontainer {
  width: 100%
}
.EntwicklungsplanCard  {
  cursor: pointer
}.kommup-in-enter-from,
.kommup-in-leave-to {
    opacity: 0
}
.kommup-in-move {
    transition: all 600ms ease-in-out 50ms;
}
.kommup-in-enter-active {
  transition: all 400ms ease-out;
}
.kommup-in-enter-active,
.kommup-in-leave-active {


 animation: slide-up-fade-in ease 1.2s;
  animation-iteration-count: 1;
  transform-origin: 50% 50%;
  animation-fill-mode: forwards; /*when the spec is finished*/
  -webkit-animation: slide-up-fade-in ease 1.2s;
  -webkit-animation-iteration-count: 1;
  -webkit-transform-origin: 50% 50%;
  -webkit-animation-fill-mode: forwards; /*Chrome 16+, Safari 4+*/
  -moz-animation: slide-up-fade-in ease 1.2s;
  -moz-animation-iteration-count: 1;
  -moz-transform-origin: 50% 50%;
  -moz-animation-fill-mode: forwards; /*FF 5+*/
  -o-animation: slide-up-fade-in ease 1.2s;
  -o-animation-iteration-count: 1;
  -o-transform-origin: 50% 50%;
  -o-animation-fill-mode: forwards; /*Not implemented yet*/
  -ms-animation: slide-up-fade-in ease 1.2s;
  -ms-animation-iteration-count: 1;
  -ms-transform-origin: 50% 50%;
  -ms-animation-fill-mode: forwards; /*IE 10+*/

  opacity: 0;
  opacity: 1\9;
}

@keyframes slide-up-fade-in {
  0% {
    opacity: 0;
    transform: translate(0px, 40px);
  }
  100% {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}

@-moz-keyframes slide-up-fade-in {
  0% {
    opacity: 0;
    -moz-transform: translate(0px, 40px);
  }
  100% {
    opacity: 1;
    -moz-transform: translate(0px, 0px);
  }
}

@-webkit-keyframes slide-up-fade-in {
  0% {
    opacity: 0;
    -webkit-transform: translate(0px, 40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(0px, 0px);
  }
}

@-o-keyframes slide-up-fade-in {
  0% {
    opacity: 0;
    -o-transform: translate(0px, 40px);
  }
  100% {
    opacity: 1;
    -o-transform: translate(0px, 0px);
  }
}

@-ms-keyframes slide-up-fade-in {
  0% {
    opacity: 0;
    -ms-transform: translate(0px, 40px);
  }
  100% {
    opacity: 1;
    -ms-transform: translate(0px, 0px);
  }
}
.sample {
  
  animation: animate 5.4s cubic-bezier(0.39, 0.58, 0.57, 1) infinite;
}
@keyframes animate {
  0%, 100% {
    transform: translateX(0);  /* Startposition */
    opacity: .5;
    box-shadow: var(--shadow-elevation-low);
  }
  25% {
      /* Endposition */
    opacity: 1;
    box-shadow: var(--shadow-elevation-low);
  }
  75% {
  
    opacity: .8;
    box-shadow: var(--shadow-elevation-medium);
  }
}

</style>
