// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../src/assets/optimiert-PEP-Logo_trans-400x200.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../../src/assets/optimiert-footer_partner_uni-150x100.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../../../src/assets/optimiert-footer_partner_lemas-150x100.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../../../src/assets/optimiert-footer_partner_bmbf-150x100.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n#pepfooter {\n  /* enable scrolling in firefox. stick content to sharepoints overlay container  */\n  position: sticky;\n}\n#pepfooter {\n  width: 100%;\n  position: relative;\n  background: linear-gradient(0deg,rgba(253, 251, 251,  .7),rgba(255, 255, 255, 0.4));\n\tmin-height: 350px;\n}\n#branding {\n\tdisplay: inline-flex;\nalign-items: center;\n}\n#partnerbrand {\n\tdisplay: inline-flex;\n}\n.partnerwrap {\n\tmargin: 20px;\n}\n#pepbrand {\n\tbackground-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n\theight: 200px;\nwidth: 400px;\nmargin: 50px;\ndisplay: inline-block;\n}\n#uni {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n  max-width: 150px;\n  height: 100px;\n  width: 150px;\n}\n#lms {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n   background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n  max-width: 150px;\n  height: 100px;\n  width: 150px;\n}\n#bmbf {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ");\n  max-width: 150px;\n  height: 100px;\n  width: 150px;\n}\n", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
