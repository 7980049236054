import store from "../index"
import api from '../../api/api.js'

const state = {
  entMTKi: null,
  loading: null,
  savedData: "",
}

const actions = {
    loadEntMTki({commit, state}) {
    return new Promise((resolve, reject) => {
      if(state.entMTKi === null) {
        commit('setEntMTKiLoading', true)
        api.loadEntMTki()
        .then(res => {
          commit('setEntMTKi', res)
         
          commit('setEntMTKiLoading', false)
          resolve(res)
        })
        .catch(err => {
          setTimeout(() => {
            commit('setEntMTKiLoading', false)
          }, 1000)
          console.log("Error while getting library of measures: " +err)
          reject(err)
        })
      } else {
        if(state.entMTKi && Array.isArray(state.entMTKi)) {commit('setEntMTKiLoading', false), resolve(state.entMTKi)  }
        else resolve([])
      }
    })
  },
  addEntMTki({commit}, measureTi) {
    return new Promise((resolve, reject) => {
      // checks if element what should be added does not already exist
      if(!state.loading && !state.entMTKi.some(item => item.Id === measureTi.Id)) {
        commit('setEntMTKiLoading', true)
        api.addEntMTki(measureTi)
        .then(res => {
          setTimeout(() => {
            commit("addEntMTki", res)
            commit('setEntMTKiLoading', false)
            commit('setMTKiSavedData', res)
            resolve()
          }, 500)
        })
        .catch(err => {
          commit('setEntMTKiLoading', false)
          alert("Es trat ein Problem mit der Serververbindung oder Session auf. Es konnte leider kein neues Entwicklungsziel angelegt werden. Sie können versuchen die Browserseite neu zu laden.")
          console.log("Error while add measure: " +err)
          reject(err)
        })
      }
    })
  }, 
  updateEntMTki({commit}, payload) {
    api.updateEntMTki(payload)
    .then((res) => {
      commit('setMTKiSavedData', res)

    })
    .catch(err => {
      alert("Es trat ein Problem mit der Serververbindung oder Session auf. Die Entwicklungsmaßnahme konnte leider nicht aktualisiert werden. Sie können versuchen die Browserseite neu zu laden.")
      console.log("Error while updating measure: " +err)
    })
  },
}
  const getters = {
    entMTKi: state => {
    
        // fetch data if no other data is available or there is no other fetching process.
        if(state.entMTKi === null && !state.loading) {
          store.dispatch("loadEntMTki")
        }
        return state.entMTKi
      },
      entMTKiLoading: state => {
        return state.loading
      },
      entMTKisavedData: state => {
        return state.savedData
      },
    }
    const mutations = {
        setEntMTKi: (state, entMTKi) => {
          state.entMTKi = entMTKi
        }, 

      setEntMTKiLoading: (state, loading) => {
        state.loading = loading
      },
      setMTKiSavedData: (state, data) => {
        console.log('setMTKiSavedData', data)
        state.savedData = data
      },
      addEntMTki: (state, measureTi) => {
        if(measureTi) state.entMTKi.push(measureTi)
      },
      updateEntMTki: (state, payload) => {
        if(payload && payload.Id) {
          state.entMTKi.forEach(measureTi => {
            console.log(measureTi)
            if(measureTi.Id === payload.Id) {
              // update measure
              measureTi.Title = payload.Title
              measureTi.RefMethZiel = payload.RefMethZiel
              measureTi.RefMethBeschr = payload.RefMethBeschr
              measureTi.RefMeth = payload.RefMeth
              measureTi.Zeitumfang = payload.Zeitumfang
              measureTi.Sozialform = payload.Sozialform
              measureTi.Voraussetzungen_x002f_Materialie = payload.Voraussetzungen_x002f_Materialie
              measureTi.Kurzbeschreibung = payload.Kurzbeschreibung
              measureTi.Evaluationskriterien_x0020__x002 = payload.Eval
              measureTi.RefMethId = payload.RefMethId
              measureTi.Lernumgebung = payload.Lernumgebung
              measureTi.Altersgruppe = payload.Altersgruppe
              measureTi.Praxistipps = payload.Praxistipps
              measureTi.EntwicklBereiche = payload.EntwicklBereiche
              measureTi.Plan_und_Durch = payload.Plan_und_Durch
              measureTi.Beispiel = payload.Beispiel
              measureTi.RefTemplategroup = payload.RefTemplategroup

            }
          })
          // fluent design list for already used measures has only shallow object update recognition, force component to update
          state.entMTKi.push({Id: "placeholder"})
          state.entMTKi = state.entMTKi.filter(item => item.Id !== "placeholder")
        }
        // update measure in data storage
        store.dispatch("updateEntMTki", payload)
      },
    }
    export default {
        state,
        mutations,
        getters,
        actions
      }