<style >
.ep-table-list > 
.fv-custom-row .col:first-child, 
.ep-table-list > .fv-custom-row .col:nth-child(2) {
  min-width: 33% !important;
  max-width: 33% !important;
}
</style>

<style scoped>
:deep(.content-row.fv-custom-row) {
  height: 53px !important;
}
:deep(.fv-details-list-head > span:nth-child(2)) {
  display: none !important;
}

:deep(.fv-details-list-head) {
  background: #efeef0;
}
.fv-light-DetailsList {
    width: 100% !important;
    height: auto !important;
}

#epc1 {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    position: relative;
}

.table-line:hover {
  cursor: pointer;
}

.remove-list-margin {
  width: 100%;
  height: 100%;
  margin-left: -12px;
  padding-right: 44px;
  margin-right: -32px;
}

.add-list-margin {
  margin-left: 12px;
}

.fv-light-DetailsList .list-content .content-row .col .sec {
  font-size: 15px;
}

.bg-blue-180 {
    background-image: linear-gradient( 180deg, rgb(137 247 254 / 40%), rgb(102 166 255 / 40%));
}

.bg-cyan-180 {
    background-image: linear-gradient( 180deg, rgb(79 172 254 / 40%) 0, rgb(0 242 254 / 40%));
}

.bg-indigo-180 {
    background-image: linear-gradient( 180deg, rgb(10 207 254 / 40%) 0, rgb(73 90 255 / 40%));
}

.bg-purple-180 {
    background-image: linear-gradient( 180deg, rgb(205 156 242 / 40%) 0, rgb(246 243 255 / 40%));
}

.bg-pink-180 {
    background-image: linear-gradient( 180deg, rgb(247 112 98 / 40%) 0, rgb(254 81 150 / 40%));
}

.bg-red-180 {
    background-image: linear-gradient( 180deg, rgb(255 226 159 / 40%), rgb(255 169 159 / 40%) 48%, rgb(255 113 154 / 40%));
}

.bg-orange-180 {
    background-image: linear-gradient( 180deg, rgb(255 88 88 / 40%), rgb(240 152 25 / 40%));
}

.bg-yellow-180 {
    background-image: linear-gradient( 180deg, rgb(230 185 128 / 40%) 0, rgb(234 205 163 / 40%));
}

.bg-green-180 {
    background-image: linear-gradient( 180deg, rgb(19 84 122 / 40%), rgb(128 208 199 / 40%));
}
::v-deep div.list-head.fv-custom-head > span.col,
::v-deep div.list-content.fv-custom-row > div > span.col,
::v-deep .col {
  min-width: calc(100% / 3) !important;
  max-width: calc(100% / 3) !important;
  display: flex !important;
}
::v-deep div.list-head.fv-custom-head > span:nth-child(2) {
  display: none !important;
}
::v-deep .fv-light-DropDown input {
  font-size: calc(
    16px + (22 - 16) * ((100vh - 300px) / (1900 - 300))
  ) !important;
  min-height: 35px;
  font-family: "aileronsemibold", sans-serif;
}
::v-deep .fv-light-DetailsList p,
.list-content span p {
  font-size: calc(
    16px + (22 - 16) * ((100vh - 300px) / (1900 - 300))
  ) !important;
  min-height: 35px;
  font-family: "aileronsemibold", sans-serif;
}
::v-deep .fv-light-DetailsList .list-content span p {
  font-size: calc(
    16px + (22 - 16) * ((100vh - 300px) / (1900 - 300))
  ) !important;
  min-height: 35px;  font-family: "aileronregular", sans-serif;
}
::v-deep .fv-light-DetailsList .col {
  min-width: 33% !important;
  max-width: 33%;
}
.fv-light-DetailsList {
  width: 95%;
}
::v-deep .fv-light-DetailsList {
  height: auto;
}
::v-deep .fv-custom-row {
  background: rgba(243, 242, 241, 0.4);
  cursor: pointer;
}
::v-deep .list-content {
  background: rgba(243, 242, 241, 0) !important;
}
::v-deep .list-content::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}
::v-deep .list-content::-webkit-scrollbar-thumb {
  border-radius: 0px;
  background-image: linear-gradient(
    to bottom,
    #1e3c7217 0%,
    #1e3c7280 1%,
    #2a5298d9 100%
  );
}
::v-deep .fv-light-DetailsList .list-content .content-row {
  height: 67px;
  width: 100%;
}

::v-deep .fv-light-DetailsList .list-content .content-row .col *,
::v-deep .fv-light-DetailsList .list-head .col .col-content p.default-title {
  font-size: calc(
    16px + (22 - 16) * ((100vh - 300px) / (1900 - 300))
  ) !important;
  font-family: "aileronregular";
}
::v-deep p.default-title {
  font-size: calc(
    18px + (24 - 18) * ((100vh - 300px) / (1900 - 300))
  ) !important;
}
</style>

<template>

<div id="epc1" v-if="view === 'table'">

  <!-- Loading screen -->
  <div v-if="isLoading" class="waitscreen">
    <h2 class="waitheader">Bitte warten!</h2>
    <h3 class="waitsubheader">Der Entwicklungsplan wird soeben angelegt.</h3>
    <div class="loading-symbol-container">
      <fv-progressRing color="rgba(0,204,153,1)" size="5" style="margin-top: 6%;"></fv-progressRing>
    </div>
  </div>

  <fv-DetailsList v-else :value="plaene" :head="head" class="ep-table-list">
      <template v-slot:column_0="x">
        <div @click="gotoProfilansicht(plaene[x.row_index])" class="remove-list-margin table-line" >
          <!-- v-bind:class="getBgColor(x.row_index).concat('-180')"> -->
          <p class="sec add-list-margin">{{x.item.Title | nameshorter }}</p>
        </div>
      </template>
      <template v-slot:column_1="x">
        <div @click="gotoProfilansicht(plaene[x.row_index])" class="remove-list-margin table-line" >
        <!-- v-bind:class="getBgColor(x.row_index).concat('-180')"> -->
          <p class="sec add-list-margin">{{x.item.Author.Title}}</p>
        </div>
      </template>
      <template v-slot:column_2="x">
        <div @click="gotoProfilansicht(plaene[x.row_index])" class="remove-list-margin table-line" >
          <!-- v-bind:class="getBgColor(x.row_index).concat('-180')"> -->
          <p class="sec add-list-margin">{{x.item.Modified | dateformer }}</p>
        </div>
      </template>
  </fv-DetailsList>
</div>

</template>

<script>


export default {
    name: "EPTable",
    props: ["plaene", "isLoading", "view"],
    data() {
        return {
            head: [{
                content: "Name",
                minWidth: 200,
                width: 300,
                sortName: "Title"
            }, {
                content: "Verantwortlich",
                minWidth: 200,
                width: 300,
                sortName: "Author"
            }, {
                content: "Letzte Veränderung",
                minWidth: 80,
                width: 100,
                sortName: "Modified"
            }],
            classes: [
                "bg-blue",
                "bg-indigo",
                "bg-purple",
                "bg-pink",
                "bg-red",
                "bg-orange",
                "bg-yellow",
                "bg-green",
                "bg-cyan"
            ]
        };
    },
    filters: {
      nameshorter(value) {
          if (value) {
              return value.split("Entwicklungsplan")[1];
          }
      }
    },
    methods: {
      gotoProfilansicht(plan) {
        if(plan && plan.Id) {
          this.scrollToTop()
          this.$router.push({
            name: "Profil",
            params: {
              sid: plan.Id
            }
          })
        } else {
          console.log("error: missing id for EP")
        }
      },
      scrollToTop() {
        // window does not work in sharepoint
        if (typeof _spPageContextInfo !== "undefined") {
          document.getElementById('s4-workspace').scrollTo(0, 0)
        } else {
          window.scrollTo(0, 0)
        }
      }
    },
}

</script>
