import store from "../index"
import api from '../../api/api.js'

const state = {
  svz: null,
  loading: false,
  order: "alphaPupilAsc",
  view: "card"
}

const actions = {
  loadSVz({commit, state}) {
    if(store.getters.api === "pepApi" && !store.getters.token) return
    // only load, if necessary
    if(state.svz === null) {
      commit('setSVzLoading', true) // Set loading to true
      commit('setApiStatus', { module: 'svz', key: 'loaded', value: false });
      api.loadSVz()
      .then(res => {
        if (res) {
          commit('setSVz', res)
          commit('setApiStatus', { module: 'svz', key: 'loaded', value: true });
        }
      })
      .catch(err => {
        console.log("Error while getting svz: " + err)
        commit('setApiStatus', { module: 'svz', key: 'loaded', value: false });
      })
      .finally(() => {
        setTimeout(() => {
          commit('setSVzLoading', false) // Set loading to false after timeout
        }, 1000)
      })
    }
  },
  
  createNewSVz({commit}, student) {
    commit('setSVzLoading', true)
    api.addEntPl(student)
    .then(student => {
      commit('addSVz', student)
    })
    .catch(err => {
      alert("Es trat ein Problem mit der Serververbindung auf. Es konnte leider kein neuer Entwicklungsplan gespeichert werden.")
      console.log("Error while saving entpl: " +err)
    })
    .then(() => {
      commit('setSVzLoading', false)
    })
  },
  updateSVz({commit}, payload) {
    api.updateSVz(payload)
    .then(() => {
       commit('updateEntplfromSVz', payload)
     })
      .catch(err => {
           alert("Es trat ein Problem mit der Serververbindung oder Session auf. Die Schüler:in  konnte leider nicht aktualisiert werden. Sie können versuchen die Browserseite neu zu laden.")
           console.log("Error while updating SVz: " +err)
      })
      .then(() => {
        commit("setEntPl", null)
      })
  },
  deleteUserSVz({commit}, id) {
   // commit('setSVzLoading', true)
    api.deleteUserSVz(id)
    .then(() => {
      commit('DeleteUserSVz', id)
    })
    .catch(err => {
      alert("Es trat ein Problem mit der Serververbindung auf. Das Löschen der Schüler:in konnte leider nicht verarbeitet werden.")
      console.log("Error while deleting student: " +err)
    })
    .then(() => {
  //    commit('setSVzLoading', false)
    })
  }
}

const getters = {
  svz: state => {
    
    // fetch data if no other data is available or there is no other fetching process.
    if(state.svz === null && !state.loading) {
      store.dispatch("loadSVz")
    }
    return state.svz
  },
  SVzloading: state => {
    return state.loading
  },
  SVzorder: state => {
    return state.order
  },
  SVzview: state => {
    return state.view
  },
  SVzbyId: state => id => {
    
    // fetch data if needed
    if(state.svz === null && !state.loading) {
      store.dispatch("loadSVz")
    }
    // get entpl by id
    if(state.svz !== null && id) return state.svz.find(item => item.Id === id)
    return null
  },
  studentSVzById: () => id => {
    let svz = store.getters.entPlbyId(id)
    if(svz && svz.RefS) return svz.FirstName +" " +svz.Title
    return null
  },
  studentFirstNames: state => {
    if(state.svz === null && !state.loading) {
      store.dispatch("loadSVz")
    }
    if (state.svz) {
      return state.svz.map(f => f.Vorname || f.FirstName);
        }
   else return []
  }
}

const mutations = {
  setSVz: (state, svz) => {
    state.svz = svz;

  },
  setSVzLoading: (state, loading) => {
    state.loading = loading
  },
  setSVzOrder: (state, order) => {
    state.order = order
  },
  setSVzView: (state, view) => {
    state.view = view
  },
  addSVz: (state, student) => {
    let mutatedSvz
    if(student && student['ImportId']) {
      mutatedSvz = {
        FirstName: student.RefS.FirstName,
        Title: student.RefS.Title,
        ImportId: student['ImportId'],
        Klasse: student.RefS.Klasse,   
        Modified: student.Modified,
        Created: student.Modified,
        Id: student.RefS.FirstName.length + student.RefS.Title.length
      }
  }
  else {
    mutatedSvz = {
      FirstName: student.RefS.FirstName,
      Title: student.RefS.Title,
      Klasse: student.RefS.Klasse,
      Modified: student.Modified,
      Created: student.Modified
    }
  }
  if(Array.isArray(state.svz)) state.svz.push(mutatedSvz)
  else state.svz = [mutatedSvz]

  
  },
  DeleteUserSVz: (state, id) => {
    if(id && Array.isArray(state.svz) && state.svz.length) state.svz = state.svz.filter(item => item.Id !== id)
  },
  updateSVz: (state, payload) => {
     if(payload) {
       console.log(payload)
       state.svz.forEach(svz => {
        if(svz.Id === payload.svzId) {
          console.log(svz)
          // update svz
           svz.Title = payload.Title
           svz.FirstName = payload.FirstName
           svz.ImportId = payload.ImportId
           svz.Klasse = payload.Klasse
         }
       })
     }
 //   console.log(payload)
     // fluent design list for already used measures has only shallow object update recognition, force component to update
     state.svz.push({Id: "placeholder"})
     state.svz = state.svz.filter(item => item.Id !== "placeholder")
    // update svz in data storage
    store.dispatch("updateSVz", payload)
  },
}

export default {
  state,
  mutations,
  getters,
  actions
}
