import { entgendern } from "../../variables.js"
import store from "../index"
import api from '../../api/api.js'

const state = {
  studentId: null,
  aurList: null,
  loading: null
}

const actions = {
  loadAurList({commit, state}, studentId) {
    // only load, if necessary
    if(studentId && state.studentId !== studentId && !state.loading) {
      commit('setAurLoading', true)
      api.loadAurList(studentId)
      .then(res => {
        commit('setAurList', res)
        commit('setAurStudentId', studentId)
      })
      .catch(err => console.log("Error while loading list of aur: " +err))
      .then(() => {
        setTimeout(() => {
          commit('setAurLoading', false)
        }, 1000)
      })
    }
  },
  addAur({commit}, aur) {
    // checks if element what should be added does not already exist
    if(!state.loading && !state.aurList.some(item => item.Id === aur.Id)) {
      commit('setAurLoading', true)
      api.addAur(aur)
      .then(res => {
        commit("addAur", res)
        commit('setAurLoading', false)
      })
      .catch(err => {
        commit('setAurLoading', false)
        alert("Es trat ein Problem mit der Serververbindung oder Session auf. Es konnte leider keine neue Ausgangslage angelegt werden. Sie können versuchen die Browserseite neu zu laden.")
        console.log("Error while add aur: " +err)
      })
    }
  },
  createNewAur({commit}, payload) {
    let aur = store.getters.aurById({
      studentId: payload.sid,
      zid: payload.zid
    })
    if(payload.aurF === undefined) payload.aurF = getBasicAurF()
    console.log("Neues AuRF:", payload.aurF);
    // if aur already exists: create new aurF for this entry ; if not: create new aur list entry
    if(aur) {
      commit("setAurF", payload)
      store.dispatch("saveAur", aur)
    }
    else {
      aur = {
        Id: store.getters.nextAurId,
        AuRF: payload.aurF,
        RefPEPId: payload.sid,
        RefZId: payload.zid,
        StratusFormsData: null,
        Title: store.getters.zyklusTitleById(payload)
      }
      store.dispatch("addAur", aur)
    }
  },
  saveAur(_, aur) {
    if(aur) {
      api.saveAur(aur)
      .catch(err => console.log("Error while saving aur entry " +err))
    }
  },
  deleteAur({commit}, aur) {
    if(aur && aur.Id) {
      commit("deleteAur", aur.Id)
      api.saveAur(aur)
      .catch(err => {
        commit('setAurLoading', false)
        alert("Es trat ein Problem mit der Serververbindung oder Session auf. Die Ausgangslage konnte leider nicht zurück gesetzt werden. Sie können versuchen die Browserseite neu zu laden.")
        console.log("Error while remove aur: " +err)
      })
    }
  },
  createLastZyklusAur({state}, payload) {
    console.log("Payload in createLastZyklusAur:", payload);
    let aurTitle = store.getters.zyklusTitleById(payload)
    console.log(store.getters.zyklusById(payload))
    console.log("Zurückgegebener Zyklustitel:", aurTitle);
    let lastAurf = getLastAurF(state.aurList, aurTitle)
    console.log(lastAurf)
    // remove symbolic link to AuRF from last zyklus and create new item
    payload.aurF = JSON.parse(JSON.stringify(lastAurf))
    store.dispatch("createNewAur", payload)
  },
}

const getters = {
  aurList: state => studentId => {
    console.log("Aktuelle aurList:", state.aurList);
    // fetch data if no other data is available or there is no other fetching process.
    if(state.studentId !== studentId && !state.loading) {
      store.dispatch("loadAurList", studentId)
    }
    return state.aurList
  },
  aurIsLoading: state => {
    return state.loading
  },
  aurById: state => payload => {
    let aurList = store.getters.aurList(payload.studentId)
    // return only aur matching to zyklus from user
    if(aurList && Array.isArray(aurList) && aurList.length) {
      for(let aur of aurList) {
        if(aur.RefPEPId === state.studentId && aur.RefZId === payload.zid) return aur
      }
    }
    return null
  },
  nextAurId: state => {
    let aurList = state.aurList
    let id = 0
    if(aurList) {
      for(let aur of aurList) {
        if(aur.Id > id) id = aur.Id
      }
    }
    return ++id
  },
  canImportFromLastAurF: state => payload => {
    let aurTitle = store.getters.zyklusTitleById(payload)
    let aurF = getLastAurF(state.aurList, aurTitle)
    if(aurF) return true
    else return false
  },
  getBasicAurF: () => {
    return getBasicAurF()
  }
}

const mutations = {
  setAurStudentId: (state, studentId) => {
    state.studentId = studentId
  },
  setAurList: (state, aurList) => {
    state.aurList = aurList
  },
  setAurLoading: (state, loading) => {
    state.loading = loading
  },
  addAur: (state, aur) => {
    if(aur && state.aurList) state.aurList.push(aur)
  },
  setAurF: (state, payload) => {
    let aur = store.getters.aurById(payload)
    if(aur) aur.AuRF = payload.aurF
  },
  setAurFTextareaValue: (state, payload) => {
    let aur = store.getters.aurById(payload)
    if(aur) {
      aur.AuRF.forEach(item => {
        if(item.id === payload.itemId) {
          item.value = payload.itemValue
        }
      })
    }
  },
  deleteAur: (state, aurId) => {
    if(Array.isArray(state.aurList) && state.aurList.length && aurId) {
      state.aurList.find(item => item.Id === aurId).AuRF = null
    }
  }
}

function getBasicAurF() {
  let data = [
    {id: "AuR1", text: "Schulische Interessenbereiche", tab: "Interessen", value: "",
      tooltip: `Welche Aktivitäten machen der ${entgendern.pupil} in der Schule am  meisten Spaß? In welchen Situationen im Unterricht, auf Ausflügen, in  Arbeitsgemeinschaften oder Pausen haben Sie die  ${entgendern.pupil} als  besonders freudig bei der Sache erlebt? In welchen Momenten waren Sie von den Aktivitäten / Reaktionen /  Beiträgen der ${entgendern.pupil} positiv überrascht? Was macht die ${entgendern.pupil} besonders gern und aus  eigenem Impuls? Worin vertieft ${entgendern.they} sich?`},
    {id: "AuR2", text: "Außerschulische Interessenbereiche", tab: "Interessen", value: "",
      tooltip: `Wofür  interessiert  sich  die  ${entgendern.pupil}  außerhalb  des  Unterrichts? Wobei fühlt ${entgendern.they} sich besonders wohl? Was macht die ${entgendern.pupil} gerne in  ${entgendern.possessiv_genitiv_pl}  Freizeit?  Was  macht  die  ${entgendern.pupil}  neugierig?  Was  hat  der  ${entgendern.pupil}  früher  Spaß  gemacht und würde ${entgendern.they} gerne wieder tun? `},
    {id: "AuR3", text: "Vorstellungen, Visionen Träume, Berufswusch", tab: "Interessen", value: "",
      tooltip: `Welche  Ziele  oder  Wünsche  für  die  Zukunft  äußert  die  ${entgendern.pupil}? Was ist der ${entgendern.pupil} in der nächsten Zeit am Wichtigsten? Hat die ${entgendern.pupil} schon  konkrete  Berufswünsche  formuliert?  Welche  Haltungen  oder  Ideale  leiten  die  ${entgendern.pupil} in  ${entgendern.possessiv_akkusativ_sg} Vorstellungen von der Zukunft?`},
    {id: "AuR4", text: "Stärken, Potenziale, Leistungen", tab:"Stärken", value: "",
      tooltip: `Welche  besonderen  Stärken  oder  Potenziale  zeigt  die  ${entgendern.pupil}  in  einzelnen  Bereichen,  z.B.  kognitiv,  künstlerisch,  sozial?  In  welchen  Gebieten  kann  ${entgendern.they}  sich  in  besonderer  Weise  in  den  Lernprozess  einbringen?  Welche Fähigkeiten nimmt ${entgendern.they} bei sich selbst wahr? Welche Fähigkeiten bemerken  Andere?   Welche   Fähigkeiten   sind   im   Vergleich   zu   Gleichaltrigen   besonders  ausgeprägt? Worauf ist ${entgendern.they} stolz? Welche Situationen hat die ${entgendern.pupil} besonders gut  gemeistert?`},
    {id: "AuR5", text: "Gewohnheiten", tab:"Stärken", value: "",
      tooltip: `Gibt es etwas, dass die ${entgendern.pupil} besonders häufig tun? Hat  die  ${entgendern.pupil}  bestimmte  (negative/  positive)  Lerngewohnheiten?  Welche  (negativen  /  positiven) kontextbezogenen Verhaltensweisen zeigt die ${entgendern.pupil}? Welche möchte ${entgendern.they} verändern? Auf welche Gewohnheiten ist die ${entgendern.pupil} stolz?`},
    {id: "AuR6", text: "Arbeitsmethoden", tab: "Arbeitsweisen", value: "",
      tooltip: `Gibt  es  Arbeitsweisen,  bei  denen  sich  die  ${entgendern.pupil} in  der  Vergangenheit besonders entfaltet oder besonders leidenschaftlich eingebracht hat?  Unter   welchen   Bedingungen   kann   die   ${entgendern.pupil}   besonders   gut   lernen?   Welche  Arbeitsmethoden verwendet die ${entgendern.pupil} häufig? Welche Arbeitsmethoden könnten der  ${entgendern.pupil} helfen, ${entgendern.possessiv_akkusativ_pl} Ziele zu erreichen? `},
    {id: "AuR7", text: "(Organisations)-Strategien", tab: "Arbeitsweisen", value: "",
      tooltip: `Welche Strategien verwendet die ${entgendern.pupil} bei der Erfüllung  von Aufgaben besonders häufig? Wie geht ${entgendern.they} mit Herausforderungen um? Wie  geht die ${entgendern.pupil} vor, wenn mehrere Aufgaben zeitgleich bearbeitet werden müssen? Ist  die  ${entgendern.pupil}  dazu  in  der  Lage  ${entgendern.possessiv_akkusativ_pl}  Aufgaben  nach  Wichtigkeit  und  Umfang  zu  priorisieren? Welche Strategien wären hilfreich, um die eigenen Ziele zu erreichen? `},
    {id: "AuR8", text: "Sozialform", tab: "Arbeitsweisen", value: "",
      tooltip: `Mit  wem  lernt  die  ${entgendern.pupil}  am  liebsten?  Arbeit  die  ${entgendern.pupil}  lieber alleine oder in der Gruppe? Wie verhält ${entgendern.they} sich bei Partnerarbeit? Was sagt  die  Sozialform  über  die  Arbeitsweise  der  ${entgendern.pupil}  aus?  Wie  ist  die  ${entgendern.pupil}  in  den  Klassenverband integriert?`},
    {id: "AuR9", text: "Was mache ich für mich selbst?", tab: "Verantwortung", value: "",
      tooltip: `Was tut die ${entgendern.pupil} bereits für sich selbst, z.B. um zu  entspannen, sich etwas zu gönnen? Was könnte ${entgendern.they} zukünftig für sich tun? Wie  geht die ${entgendern.pupil} mit Stress oder Leistungsdruck um? Was benötigt ${entgendern.they}, um auf sich  Acht zu geben oder sich zu entspannen? Ernährt die ${entgendern.pupil} sich beispielsweise  gesund und betreibt Sport als Ausgleich?`},
    {id: "AuR10", text: "Was mache ich für Andere?", tab: "Verantwortung", value: "",
      tooltip: `Was  macht  die  ${entgendern.pupil}  bereits  für  Andere?  Übernimmt  die ${entgendern.pupil} beispielsweise das Amt der ${entgendern.class_spokesperson} oder eine Lernpatenschaft?  Übernimmt ${entgendern.they} außerschulisch ein Ehrenamt? Gibt es etwas, was die ${entgendern.pupil} gern  für Andere machen würde, wo ${entgendern.they} helfen möchte? `},
    {id: "AuR11", text: "Was mache ich für die Welt?", tab: "Verantwortung", value: "",
      tooltip: `Interessiert  oder  engagiert  sich  die  ${entgendern.pupil}  zum  Beispiel  bei Projekten für die Umwelt, den Klimawandel oder den Erhalt gefährdeter Tierarten?  Interessiert sich die ${entgendern.pupil} für politische Themen? Gibt es angelehnt an die Interessen  der ${entgendern.pupil} mögliche Engagements, die ausgeübt werden könnten?`},
    {id: "AuR12", text: "Zusätzliche Informationen", tab: "Sonstiges", value: "",
      tooltip: `Hier können zusätzliche Informationen abgelegt werden.`}
  ]
  return data
}

function getAurCount(title) {
  if(title) {
    let regex = /\./
    let count = parseInt(title.split(regex)[0])
    if(Number.isInteger(count)) return count
  }
  return null
}

function getLastAurF(aurList, aurTitle) {
  console.log("aurTitle in getLastAurF:", aurTitle);
  if(aurTitle) {
    let nofAur = getAurCount(aurTitle)
    console.log("nofAur in getLastAurF:", nofAur);
    if(nofAur) {
      // get number of last zyklus
      nofAur--
      if(aurList && Array.isArray(aurList) && aurList.length) {
        let lastAur = aurList.find(item => getAurCount(item.Title) === nofAur)
        if(lastAur && lastAur.AuRF) return lastAur.AuRF
      }
    }
  }
  return null
}

export default {
  state,
  mutations,
  getters,
  actions
}
