<!-- ActionsTree Component -->
<template>
  <div>
    <!-- Tree Header -->
    <div class="tree-header">
      <div class="tree-header-typ">Typ </div>
      <div class="tree-title-group">
        <div class="tree-title">
          Titel //
        </div>
        <div class="tree-method">
          Methode
        </div>
      </div>
      <div class="tree-lua-group">
        <div class="tree-lernumgebung"> Lernumgebung // </div>
        <div class="tree-altersgruppe">
          Altersgruppe
        </div>
      </div>
      <div class="tree-zus-group">
        <div class="tree-zeitumfang"> Zeitumfang // </div>
        <div class="tree-sozialform"> Sozialform</div>
      </div>
      <div class="tree-header-action">
        Aktion
      </div>
    </div>

    <!-- TreeView Component -->
    <fv-TreeView v-if="masznahmenTree" v-model="treeModel" style="width:100%" :space="5" background="transparent"
      :expandClickMode="'icon'" :style="{ height: Height }" :draggable="false">

      <!-- Tree Rows -->
      <template v-slot:default="item">

        <div class="tree-row" :class="item.item.clickDisabled ? 'locked' : ''">
          <div class="tree-icon"> <i class="ms-Icon ms-Icon--ViewDashboard"
              :class="item.item.Type === 'MKi' ? 'own-action' : item.item.Type === 'MTKi' ? 'own-template-action' : 'group-template-action'"
              aria-hidden="true"></i></div>

          <div class="tree-title-group">
            <fv-Badge v-if="item.item.Neu" :isDot="false" :type="'#319F31'" :value="'Neu'">
              <div class="tree-title">
                {{ item.item.label }}
              </div>
            </fv-Badge>
            <div class="tree-title" v-else>
              {{ item.item.label }}
            </div>
            <div class="tree-method">
              {{ item.item.RefMethTitle }}
            </div>
          </div>

          <div class="tree-lua-group">
            <div class="tree-lernumgebung"> <i class="ms-Icon ms-Icon--ArrangeBringToFront"></i>{{ item.item.Lernumgebung
            }}</div>
            <div class="tree-altersgruppe">
              <i class="ms-Icon ms-Icon--PeopleAdd"></i>{{ item.item.Altersgruppe }}
            </div>
          </div>
          <div class="tree-zus-group">
            <div class="tree-zeitumfang"> <i class="ms-Icon ms-Icon--DateTime2"></i>{{ item.item.Zeitumfang }}</div>
            <div class="tree-sozialform"> <i class="ms-Icon ms-Icon--PeopleAdd"></i>{{ item.item.Sozialform }}</div>
          </div>
          <div class="tree-action">
            <action-button v-if="item.item.hasOwnProperty('approveDisabled') && !item.item.approveDisabled"
              :size="'small'" :action="'freigabe'" v-on:releaseClick="checkactiondetails(item.item, item)"
              :isSaved="!(item.item.AdminChecked === null || item.item.AdminChecked === false)"></action-button>

            <action-button :size="'small'" :action="'open'" v-if="!item.item.clickDisabled"
              v-on:openClick="setactiondetails(item.item, item)"></action-button>
          </div>
        </div>

      </template>
      <!-- </TransitionGroup> -->
    </fv-TreeView>
    <fv-Pagination v-if="pagination" :total="totalPages" @page-click="changePage" />
  </div>
</template>

<script>
import ActionButton from '../Buttons/ActionButton.vue'
export default {

  name: 'ActionsTree',
  props: {
    masznahmen: {
      type: Array,
      default() {
        return []
      },
    },
    masznahmentemplateI: {
      type: Array,
      default() {
        return []
      },
    },
    masznahmentemplateG: {
      type: Array,
      default() {
        return []
      },
    },
    searchText: {
      type: String,
      default: "",
    },
    mkiVisible: {
      type: Boolean,
      default: true
    },
    mtkiVisible: {
      type: Boolean,
      default: true
    },
    mtkgVisible: {
      type: Boolean,
      default: true
    },
    pagination: {
      type: Boolean,
      default: false
    },
    Height: {
      type: String,
      default: '100%'
    },
    isMobile: {
      type: Boolean,
      default: false
    },
    masznahmenUpdatedAt: {
      type: Date,
      default: () => new Date()
    },
    sortOrder: {
    type: Array,
    default: () => []
  },


  },

  data() {
    return {
      masznahmenTree: [],
      currentPage: 1,
      itemsPerPage: 9,
      isCustomSortActive: false,




    }
  },
  methods: {
    changePage(pageNumber) {
      this.currentPage = pageNumber;
    },

    checkDataAvailabilityAndInitializeTree() {
      if (
        !this.masznahmenLoading &&
        !this.masznahmentemplateILoading &&
        !this.masznahmentemplateGLoading
      ) {
        this.initializeTree();
      }
    },
    initializeTree() {
      let treeData = this.isCustomSortActive 
                   ? this.combineAndCustomSortData(this.masznahmen, this.masznahmentemplateI, this.masznahmentemplateG)
                   : this.combineData(this.masznahmen, this.masznahmentemplateI, this.masznahmentemplateG);
    const formattedTree = treeData.map(this.convertToTreeFormat);
    this.masznahmenTree = formattedTree;
    
    },
    convertToTreeFormat(item) {
      const createdDate = new Date(item.Created);
      const currentDate = new Date();
      const oneDay = 24 * 60 * 60 * 1000; // Stunden * Minuten * Sekunden * Millisekunden

      const daysDifference = Math.round(Math.abs((createdDate - currentDate) / oneDay));

      const treeItem = {
        label: item.Title,
        expanded: false,
        selected: false,
        checkboxStatus: null,
        children: typeof item.children !== "undefined" && Array.isArray(item.children) ? item.children.map(this.convertToTreeFormat) : '',
        // Überprüfen, ob item.children existiert
        Autor: item['Erstellt von'] || (typeof item.Author === 'object' && item.Author.Title) ? item.Author.Title : item.Author,
        RefMeth: item.RefMeth,
        RefMethTitle: (typeof item.RefMeth === 'object' && item.RefMeth.Title) ? item.RefMeth.Title : "",
        Lernumgebung: item.Lernumgebung,
        Altersgruppe: item.Altersgruppe,
        Zeitumfang: item.Zeitumfang,
        Sozialform: item.Sozialform,
        Type: item.Type,
        Id: item.Id,
        RefTemplateindiId: item.RefTemplateindiId || null,
        RefTemplategroupId: item.RefTemplategroupId || null,
        Id3: item.Type + '-' + item.Id,
        Neu: daysDifference <= 1
      };
      if (item.Title) {
        treeItem.label = item.Title;
      } else {
        treeItem.label = "(Ohne Titel)";
      }
      if (item.FileSystemObjectType === 0) {
        treeItem.icon = "https://img.icons8.com/bubbles/2x/folder-invoices.png";
      } else {
        treeItem.icon = "FileCode";
      }
      if (item.Type === "MTKg") {
        treeItem.AdminChecked = item.AdminChecked || null;

        if (treeItem.AdminChecked === false || treeItem.AdminChecked === null) {
          treeItem.clickDisabled = !(
            this.$store.getters.groupsAdmin && this.$store.getters.groupsAdmin.includes(this.$store.getters.userTitle) ||
            this.$store.getters.userTitle === treeItem.Autor
          );
        }
        else {
          treeItem.clickDisabled = false;
        }
        treeItem.approveDisabled = !(this.$store.getters.groupsAdmin && this.$store.getters.groupsAdmin.includes(this.$store.getters.userTitle));
      }
      treeItem.Lernumgebung = item.Lernumgebung === true ? 'innerhalb des Unterrichts' : 'außerhalb des Unterrichts';

      return treeItem;
    },
    combineAndCustomSortData(MKi, MTKi, MTKg) {
  let combined = this.combineData(MKi, MTKi, MTKg);

  if (this.isCustomSortActive) {

  
  // Anwenden der Standard-Sortierung, gefolgt von der benutzerdefinierten Sortierreihenfolge
  combined.sort((a, b) => {
    // Standard-Sortierung nach Typ
    if (a.Type === 'MTKg' && b.Type !== 'MTKg') {
      return -1;
    } else if (a.Type !== 'MTKg' && b.Type === 'MTKg') {
      return 1;
    } else {
      // Benutzerdefinierte Sortierung, wenn die Typen gleich sind
      for (let criterion of this.sortOrder) {
        let comparison = this.compareByCriterion(a, b, criterion);
        if (comparison !== 0) return comparison;
      }
      return 0;
    }
  })
  
}
else {
      // Standard-Sortierung
      combined.sort((a, b) => {
        if (a.Type === 'MTKg' && b.Type !== 'MTKg') {
          return -1;
        } else if (a.Type !== 'MTKg' && b.Type === 'MTKg') {
          return 1;
        } else {
          return (a.Title || "").localeCompare(b.Title || "");
        }
      });
    }

    return combined;
  },

compareByCriterion(a, b, criterion) {
  const getDefaultValue = (criterion) => {
    // Hier können Sie Standardwerte für verschiedene Kriterien definieren
    switch (criterion) {
      case 'Änderungsdatum': return new Date(0);
      default: return '';
    }
  };

  const aValue = a[criterion] ?? getDefaultValue(criterion);
  const bValue = b[criterion] ?? getDefaultValue(criterion);

  switch (criterion) {
    case 'Änderungsdatum':
      return new Date(aValue) - new Date(bValue);
      case 'Lernumgebung':
      // Behandlung von Boolean-Werten
      if (typeof aValue === 'boolean' && typeof bValue === 'boolean') {
        return aValue === bValue ? 0 : aValue ? -1 : 1;
      }
      break;
    case 'Title':
    case 'Altersgruppe':
    case 'Zeitumfang':
    case 'Sozialform':
      // Verwenden Sie localeCompare für String-Vergleiche
      return aValue.toString().localeCompare(bValue.toString());
    default:
      return 0; // Standard-Fallback für nicht behandelte Kriterien
  }
},

    combineData(MKi, MTKi, MTKg) {
      // Erstellt ein neues Array und fügt MTKg-Objekte hinzu
      let combined = [];

      if (MTKg && MTKg.length) {
        combined = MTKg.map(item => ({
          ...item,
          Type: "MTKg",
          Id2: `MTKg-${item.Id}`,
        }));
      }

      if (MTKi && MTKi.length) {
        combined = [...combined, ...MTKi.map(item => ({
          ...item,
          Type: "MTKi",
          Id2: `MTKi-${item.Id}`,
          RefTemplategroupId: item.RefTemplategroupId ? `MTKg-${item.RefTemplategroupId}` : null,
        }))];
      }

      // Sortiere die combined Liste, sodass alle MTKg-Elemente zuerst kommen, gefolgt von MTKi-Elementen
      combined.sort((a, b) => {
        if (a.Type === 'MTKg' && b.Type !== 'MTKg') {
          return -1;
        } else if (a.Type !== 'MTKg' && b.Type === 'MTKg') {
          return 1;
        } else {
          // wenn beide Typen gleich sind, sortiere nach Titel oder einer anderen Eigenschaft
          return (a.Title || "").localeCompare(b.Title || "");
        }
      });

      if (MKi && MKi.length) {
        MKi.forEach(item => {
          const newItem = {
            ...item,
            Type: "MKi",
            Id2: `MKi-${item.Id}`,
            RefTemplategroupId: item.RefTemplategroupId ? `MTKg-${item.RefTemplategroupId}` : null,
            RefTemplateindiId: item.RefTemplateindiId ? `MTKi-${item.RefTemplateindiId}` : null,
          };

          // Finde den Parent für das MKi-Element in der combined Liste
          const parent = combined.find(cItem =>
            cItem.Id2 === newItem.RefTemplateindiId ||
            (cItem.Id2 === newItem.RefTemplategroupId && !newItem.RefTemplateindiId)
          );

          if (parent) {
            // Erstelle das children Array nur wenn es tatsächlich Kinder gibt
            if (!parent.children) {
              parent.children = [];
            }
            parent.children.push(newItem);
          } else {
            combined.push(newItem);
          }
        });
      }

      // Filtert die Elemente nach Sichtbarkeit und Volltextsuche
      let filteredCombined = [];
      if (combined && combined.length) {
        filteredCombined = combined.filter((item) => {
          const isVisible = (this.mtkgVisible && item.Type === "MTKg") || (this.mtkiVisible && item.Type === "MTKi") || (this.mkiVisible && item.Type === "MKi");
          const matchesSearch = item.Title ? item.Title.toLowerCase().includes(this.searchText.toLowerCase()) : false;
          return isVisible && matchesSearch;
        });
      }

      return filteredCombined;
    },

    isItemNotInTree(item, tree) {
      let notFound = true;

      const checkInChildren = (children) => {
        for (const child of children) {
          if (child.Id === item.Id) {
            notFound = false;
            break;
          }

          if (child.children.length > 0) {
            checkInChildren(child.children);
          }
        }
      };

      checkInChildren(tree);

      return notFound;
    },

    findInChildren(children, id) {
      let result = null;
      children.some(child => {
        if (child.Id === id) {
          result = child;
          return true;
        }
        if (child.children && child.children.length > 0) {
          result = this.findInChildren(child.children, id);
          if (result) return true;
        }
        console.log('Current child: ', child);
        console.log('Result: ', result);
        return false;
      });
      return result;
    },


    getOriginalRecordById(type, id) {
      let originalRecord = null;
      switch (type) {
        case "MKi":
          originalRecord = this.masznahmen.find((item) => item.Id === id);
          break;
        case "MTKi":
          originalRecord = this.masznahmentemplateI.find((item) => item.Id === id);
          break;
        case "MTKg":
          originalRecord = this.masznahmentemplateG.find((item) => item.Id === id);
          break;
      }
      return originalRecord;
    },

    setactiondetails(payload) {

      const clickedColumn = event.target.parentNode.parentNode.parentNode;

      const originalRecord = this.getOriginalRecordById(payload.Type, payload.Id);
      this.$emit('setaction', [originalRecord, payload, clickedColumn]);
    },
    checkactiondetails(payload) {
      const clickedColumn = event.target.parentNode.parentNode.parentNode;

      const originalRecord = this.getOriginalRecordById(payload.Type, payload.Id);
      this.$emit('checkaction', [originalRecord, payload, clickedColumn]);
    }
  },
  watch: {
    searchText: {
      handler() {
        this.checkDataAvailabilityAndInitializeTree();
      },
    },
    mkiVisible: {
      deep: true,
      handler() {
        this.checkDataAvailabilityAndInitializeTree();
        this.toogleText('personalisierte Maßnahmen', '̶e̶i̶g̶e̶n̶e̶ ̶M̶a̶ß̶n̶a̶h̶m̶e̶n̶', 0, this.mkiVisible)
      },
    },
    mtkiVisible: {
      deep: true,
      handler() {
        this.checkDataAvailabilityAndInitializeTree();
        this.toogleText('eigene Vorlage', '̶e̶i̶g̶e̶n̶e̶ ̶V̶o̶r̶l̶a̶g̶e̶', 2, this.mtkiVisible)
      },
    },
    mtkgVisible: {
      deep: true,
      handler() {
        this.checkDataAvailabilityAndInitializeTree();
        this.toogleText('Schulkatalog', '̶̶̶S̶c̶h̶u̶l̶k̶a̶t̶a̶l̶o̶g̶', 4, this.mtkgVisible)

      },
    },

    masznahmen: {
      handler() {
        this.checkDataAvailabilityAndInitializeTree();
      },
      deep: true,
    },
    masznahmentemplateI: {
      handler() {
        this.checkDataAvailabilityAndInitializeTree();
      },
      deep: true,
    },
    masznahmentemplateG: {
      handler() {
        this.checkDataAvailabilityAndInitializeTree();
      },
      deep: true,
    },
    isMobile: function (newVal) {
      if (newVal) {
        document.documentElement.style.setProperty('--font-size-slightly-larger', '110%', 'important');
        document.documentElement.style.setProperty('--font-size-averege', 'calc(16px + (17 - 16) * ((100vw - 300px) / (1900 - 300)))', 'important');
      } else {
        document.documentElement.style.setProperty('--font-size-slightly-larger', '120%');
        document.documentElement.style.setProperty('--font-size-averege', 'calc(16px + (18 - 16) * ((100vw - 300px) / (1900 - 300)))', 'important');
      }
    },
    sortOrder: {
    deep: true,
    handler(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.isCustomSortActive = newVal.some(criterion => criterion !== 'Standard');
        this.initializeTree();
      }
    }
  }

  },
  computed: {
    formattedTree() {
      return !this.pagination ? this.masznahmenTree : this.getVisibleItems;
      // const formattedTree = this.masznahmenTree.map(this.convertToTreeFormat);
      // return formattedTree
    },
    totalPages() {
      return Math.ceil(this.masznahmenTree.length / this.itemsPerPage);
    },
    getVisibleItems() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.masznahmenTree.slice(start, end);
    },
    treeModel() {
      return !this.pagination ? this.masznahmenTree : this.getVisibleItems;
    },
    masznahmenLoading() {
      return this.$store.getters.entMkLoading;
    },
    masznahmentemplateILoading() {
      return this.$store.getters.entMTKiLoading;
    },
    masznahmentemplateGLoading() {
      return this.$store.getters.entMTKgLoading;
    },
  },
  mounted() {
    this.checkDataAvailabilityAndInitializeTree();
    if (this.isMobile) {
      document.documentElement.style.setProperty('--font-size-slightly-larger', '100%', 'important');
      document.documentElement.style.setProperty('--font-size-averege', 'calc(16px + (17 - 16) * ((100vw - 300px) / (1900 - 300)))', 'important');
    } else {
      document.documentElement.style.setProperty('--font-size-slightly-larger', '120%');
      document.documentElement.style.setProperty('--font-size-averege', 'calc(16px + (18 - 16) * ((100vw - 300px) / (1900 - 300)))', 'important');
    }
  },
  components: {
    ActionButton
  }
}

</script>
<style >
:root {
  --font-size-slightly-larger: 120% !important;
  --font-size-averege: calc(16px + (18 - 16) * ((100vw - 300px) / (1900 - 300))) !important;
}
</style>

<style scoped>
.tree-row {
  width: 100%;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: .1fr 1fr 1fr 1fr minmax(auto, 1fr);
  text-align: left;
  grid-gap: 4%;
  min-height: 80px;
  height: auto !important;
  max-height: 180px !important;
  overflow: hidden;
  border-bottom: 1px solid #edebe9;
  padding: 12px;
  min-height: 31px;
  font-family: "aileronregular", sans-serif;
  font-size: var(--font-size-averege);
  opacity: 0;
  animation: tree-appear 200ms ease 300ms forwards
}

.tree-title {
  font-size: var(--font-size-slightly-larger);
  font-weight: 900;
  font-family: "aileronregular" !important;
}

.tree-icon {
  font-size: 1.7rem !important;
  display: grid;
  place-content: center;
  width: 1.7rem;
  height: 1.7rem;
  overflow: hidden;
  align-content: center;
  justify-content: center;

}

.tree-lua-group i,
.tree-zus-group i {
  margin: 0 10px !important;
}

.tree-action {
  display: grid;
  grid-auto-flow: column;
  place-content: center;
  align-content: baseline;
  grid-gap: inherit;
}

.tree-header {
  width: calc(100% - 24px);
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: .1fr 1fr 1fr 1fr minmax(auto, 1fr);
  text-align: left;
  grid-gap: 4%;
  min-height: 80px;
  height: auto !important;
  max-height: 180px !important;
  overflow: hidden;
  border-bottom: 4px solid #edebe9;
  padding: 12px;
  min-height: 31px;
  font-family: "aileronregular", sans-serif;
  font-size: var(--font-size-averege);
  animation: tree-appear 200ms ease forwards;
  opacity: 0;
  background: hsla(0, 0%, 100%, 0.6)
}

@keyframes tree-appear {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.tree-header-typ {
  margin-left: 30px;
}

.tree-header-action {
  margin-right: 20px;
  text-align: center;
}

.tree-lernumgebung {
  display: inline-flex;
  width: 100%;
  white-space: nowrap;
}

.list-enter-active,
.list-leave-active {
  transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(-2rem);
}

:deep(.fv-TreeView--item:has(.active)) {
  box-shadow: var(--shadow-elevation-medium);
}

@supports not selector(:has(*)) {

  :deep(.active) {
    box-shadow: var(--shadow-elevation-medium);
  }
}

:deep(.fv-light-TreeView .fv-TreeView--item .fv-TreeView--item-field) {
  margin: 0;
  transition: box-shadow .3s ease;
}

:deep(.fv-TreeView--children) {
  box-shadow: inset 4px 3px 10px 4px #00000021;
  border-radius: 5px
}

:deep(.fv-TreeView--children > .fv-TreeView--item) {
  background: transparent !important;
}

.fv-TreeView--children>li>div {
  background: #f0efef6b !important;
}

:deep(.fv-light-TreeView) {
  border-bottom-width: 2px;
  border-bottom-style: solid;
  overflow-y: auto;
}

:deep(.fv-light-Badge .fv-badge-container) {
  top: -11px;
  right: -30px;
}

:deep(.locked::after) {
  background: #8080800f;
  left: 0;
  top: 0;
  backdrop-filter: blur(28px);
  z-index: 999999999;
  backdrop-filter: blur(3px) grayscale(1);
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
}
</style>