import store from "../index"
import api from '../../api/api.js'

const state = {
  zyklusId: null,
  entZiele: null,
  loading: null
}

const actions = {
  loadEntZiele({commit, state}, zid) {
    return new Promise((resolve, reject) => {
      // only load, if necessary
      if(zid && state.zyklusId !== zid) {
        commit('setEntZieleLoading', true)
        api.loadEntZiele(zid)
        .then(res => {
          commit('setEntZiele', res)
          commit('setEntZieleZyklusId', zid)
          commit('setEvaluationZyklusId', zid)
          commit('createEvaluationGoals', res)
          commit('setEntZieleLoading', false)
          resolve(res)
        })
        .catch(err => {
          setTimeout(() => {
            commit('setEntZieleLoading', false)
          }, 1000)
          console.log("Error while getting goals: " +err)
          reject(err)
        })
      } else {
        if(state.entZiele && Array.isArray(state.entZiele)) resolve(state.entZiele)
        else resolve([])
      }
    })
  },
  addEntZiel({commit}, goal) {
    return new Promise((resolve, reject) => {
      // checks if element what should be added does not already exist
      if(!state.loading && !state.entZiele.some(item => item.Id === goal.Id)) {
        commit('setEntZieleLoading', true)
        api.addEntZiel(goal)
        .then(res => {
          setTimeout(() => {
            commit("addEntZiel", res)
            commit("addEvaluationGoal", res)
            commit('setEntZieleLoading', false)
            resolve(res.Id)
          }, 500)
        })
        .catch(err => {
          commit('setEntZieleLoading', false)
          alert("Es trat ein Problem mit der Serververbindung oder Session auf. Es konnte leider kein neues Entwicklungsziel angelegt werden. Sie können versuchen die Browserseite neu zu laden.")
          console.log("Error while add goal: " +err)
          reject(err)
        })
      }
    })
  },
  removeEntZiel({commit}, payload) {
    // checks whether the goal to be deleted exists
    if(!state.loading && state.entZiele.some(item => item.Id === payload.goalId)) {
      commit('setEntZieleLoading', true)
      api.removeEntZiel(payload)
      .then(() => {
        setTimeout(() => {
          commit("removeEntZiel", payload)
          commit("removeEvaluationGoal", payload)
          commit('setEntZieleLoading', false)
        }, 100)
      })
      .catch(err => {
        commit('setEntZieleLoading', false)
        alert("Es trat ein Problem mit der Serververbindung oder Session auf. Es konnte leider kein Entwicklungsziel entfernt werden. Sie können versuchen die Browserseite neu zu laden.")
        console.log("Error while remove goal: " +err)
      })
    }
  },
  updateEntZiel({commit}, payload) {
    api.updateEntZiel(payload)
    .then(() => {
      commit("updateEvaluationGoal", payload)
    })
    .catch(err => {
      alert("Es trat ein Problem mit der Serververbindung oder Session auf. Das Entwicklungsziel konnte leider nicht aktualisiert werden. Sie können versuchen die Browserseite neu zu laden.")
      console.log("Error while updating goal: " +err)
    })
  },
  saveZielEvaluation({commit}, item) {
    api.saveZielEvaluation(item)
    .then(() => {
      if(item && item.Kriterien && item.Kriterien.Vorgehen && typeof item.Kriterien.Vorgehen.Value === 'number' && Array.isArray(item.Kriterien.Vorgehen.Answers) && item.Kriterien.Vorgehen.Answers.length) {
        // check reuse state
        let reuse = false
        let vorgehen = item.Kriterien.Vorgehen.Answers[item.Kriterien.Vorgehen.Value]
        if(vorgehen === "Erneuter Versuch der Umsetzung" || vorgehen === "Weiterführung") reuse = true
        // apply reuse state
        let payload = {goalId: item.Id, reuse: reuse, zyklusId: item.RefZ}
        commit("setEntZielReuse", payload)
        api.saveEntZielReuse(payload)
        .catch(err => {
          console.log("Error while saving reuse from goal: " +err)
        })
      }
    })
    .catch(err => {
      // reopen edit view
      commit("setPayload", {
        id: item.Id,
        type: item.Type,
        isEdited: true,
        task: "isEdited"
      })
      // show warning
      alert("Die Evaluation konnte leider nicht gespeichert werden und ist beim nächsten Laden der Seite leider verloren. Bitte überprüfen Sie Ihre Internetverbindung und versuchen Sie die Browserseite neu zu laden.")
      console.log("Error while saving evaluation measure: " +err)
    })
  },
  setEntZielEBereiche(_, payload) {
    api.setEntZielEBereiche(payload)
    .catch(err => {
      alert("Es trat ein Problem mit der Serververbindung oder Session auf. Das Entwicklungsziel konnte leider nicht aktualisiert werden. Sie können versuchen die Browserseite neu zu laden.")
      console.log("Error while set EBereiche in goal: " +err)
    })
  }
}

const getters = {
  entZiele: state => zid => {
    
    // fetch data if no other data is available or there is no other fetching process.
    if(state.zyklusId !== zid && !state.loading) {
      store.dispatch("loadEntZiele", zid)
    }
    return state.entZiele
  },
  entZieleLoading: state => {
    return state.loading
  },
  nextEntZielId: state => {
    let goals = state.entZiele
    let id = 0
    if(goals) {
      for(let goal of goals) {
        if(goal.Id > id) id = goal.Id
      }
    }
    return ++id
  }
}

const mutations = {
  setEntZieleZyklusId: (state, zyklusId) => {
    state.zyklusId = zyklusId
  },
  setEntZiele: (state, entZiele) => {
    state.entZiele = entZiele
  },
  setEntZieleLoading: (state, loading) => {
    state.loading = loading
  },
  addEntZiel: (state, goal) => {
    if(goal) state.entZiele.push(goal)
  },
  removeEntZiel: (state, payload) => {
    if(payload && payload.goalId && payload.zyklusId === state.zyklusId) {
      state.entZiele = state.entZiele.filter(item => item.Id !== payload.goalId)
    }
  },
  updateEntZiel: (state, payload) => {
    if(payload && payload.goalId && payload.zyklusId === state.zyklusId) {
      state.entZiele.forEach(goal => {
        if(goal.Id === payload.goalId && goal.RefZ === payload.zyklusId) {
          // update goal
          goal.Title = payload.Title
          goal.IstStand = payload.IstStand
          goal.Entwicklungsziel = payload.Entwicklungsziel
        }
      })
    }
    // update goal in data storage
    store.dispatch("updateEntZiel", payload)
  },
  setEntZielReuse: (state, payload) => {
    let goal = state.entZiele.find(item => item.Id === payload.goalId)
    if(goal) goal.Reuse = payload.reuse
  },
  setEntZielEBereiche: (state, payload) => {
    let goal = state.entZiele.find(item => item.Id === payload.goalId)
    if(goal && goal.EBereiche !== payload.eBereiche) {
      goal.EBereiche = payload.eBereiche
      // save eBereiche in data storage
      store.dispatch("setEntZielEBereiche", payload)
    }
  }
}

export default {
  state,
  mutations,
  getters,
  actions
}
