

<template>
  <div style="overflow-y: auto" v-click-outside="onClickOutside">


    <div v-if="useCase === 'SvZ'">
      <div class="ContentBlockContainer">
        <div class="ContentEditContainer" style="display: flex !important">
          <span>Verantwortlich</span>

        </div>
      </div>

    </div>
    <div class="verantwortliche-preview-container" :style="useCase === 'Entpl' ? 'display:flex' : ''">
      <transition-group name="list" tag="ul" class="verantwortliche-preview-list"
        :style="useCase === 'Entpl' ? 'textAlign: end' : ''">
        <li v-for="(name, $index) in PiCfullNames" :key="name.Id" class="persona-item" :style="styleObject">
          <div class="verantwortliche-preview-item-person">
            <fv-Persona size="40" :class="condenseddata && useCase === 'Entpl'
              ? 'condensed'
              : 'not-condensed'
              " :name="name.Title" :style="$index >= 1 && condenseddata ? 'marginLeft: -60px' : ''" :showInfo="true"
              v-if="useCase !== 'Display'">
              <template v-slot:name>
                <p :class="condenseddata ? 'noData' : 'showData'">
                  {{ name.Title }}
                </p>
              </template>
              <template v-slot:secondary v-if="name.Id !== admin">
                <div @click="removePiC(name)" style="display: flex" :class="condenseddata ? 'noData' : 'showData'">
                  <p>Person entfernen</p>
                  <i class="ms-Icon ms-Icon--Cancel"></i>
                </div>
              </template>
              <template v-slot:secondary v-else>
                <div style="display: flex" :class="condenseddata ? 'noData' : 'showData'">
                  <p>Gruppenadmin</p>
                </div>
              </template>
            </fv-Persona>
            <fv-Persona size="40" :name="name.Title" :showInfo="true" v-else />
          </div>
        </li>
      </transition-group>

      <hr :style="useCase === 'Entpl' ? 'margin:0' : ''" />
      <div @click="showCalloutfunc" class="fv-light-Persona" style="margin: 10px 10px 10px 20px; cursor: pointer"
        v-if="useCase !== 'Display'">
        <div class="persona-avatar" ref="calloutbutton">
          <div class="add-button" :class="showCallout ? 'opened' : 'closed'">
            <i class="ms-Icon ms-Icon--Add" style="line-height: 40px"></i>
          </div>
        </div>
        <div class="persona-content-block" v-if="!showCallout && condenseddata && useCase === 'Entpl'"
          style="color: #00a3ff"></div>
        <div class="persona-content-block" v-if="!showCallout && condenseddata && useCase !== 'Entpl'"
          style="color: #00a3ff">
          hinzufügen
        </div>
        <div class="persona-content-block" v-if="!showCallout && !condenseddata && useCase !== 'Entpl'"
          style="color: #00a3ff">
          hinzufügen
        </div>
        <div class="persona-content-block" v-if="!showCallout && !condenseddata && useCase === 'Entpl'"
          style="color: #00a3ff">
          hinzufügen
        </div>
        <div class="persona-content-block" v-if="showCallout && !condenseddata" style="color: #f87979">
          schließen
        </div>
      </div>
    </div>
    <transition name="fv-callout-fade">
      <div class="callout" :class="showCallout ? 'visible' : 'invisible'" v-if="showCallout" :style="{
        left: this.calloutSizes.left,
        top: this.calloutSizes.top,
        maxWidth: this.calloutSizes.maxWidth,
        minWidth: this.calloutSizes.minWidth,
        right: this.calloutSizes.rightPosition,
        position: this.calloutSizes.Pposition
      }" ref="callout">
        <div class="cbefore" :style="{
          top: this.arrowPositiontop,
          transform: this.calloutSizes.transform,
          right: this.calloutSizes.right,
        }"></div>
        <div class="callout-header">
          <h3>Übersicht der Kolleg:innen</h3>
        </div>

        <div class="callout-body" :style="{ maxHeight: this.calloutSizes.maxHeight }">
          <fv-CommandBar class="note-preview-command-bar" :options="optionsCallout">
            <template v-if="searchbar" v-slot:right-space>
              <div class="note-preview-command-bar-search">
                <input id="searchBar" class="note-preview-command-bar-search-input" type="text" @keyup.enter.prevent
                  v-model="searchNoteQuery" placeholder="Suche" />
                <i class="material-icons note-preview-command-bar-close" @click.stop.prevent="hideSearchbar">close</i>
              </div>
            </template>
          </fv-CommandBar>
          <div v-for="(name) in CollQuery" :key="name.Id">
            <div class="verantwortliche-preview-item-person" v-on:click="addPiC(name)">
              <fv-Persona size="40" :name="name.Title" :showInfo="true" />
            </div>
          </div>
          <div v-if="useCase === 'Zykn'" style="font-size: 16px; margin-top: 16px">
            <hr />
            Möchten Sie weitere Kolleg:innen zum Zyklus hinzuziehen? Dann müssen
            Sie diesen Entwicklungsplan zunächst für die Kollegin/den Kollegen
            freigaben. Die Freigabe der erfolgt über die Wahl der
            Verantwortlichen oben.
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import vClickOutside from "v-click-outside";

export default {
  name: "ResponsiblePersonDropdown",
  props: {
    owner: {
      type: Number,
      default: 0,
    },
    admin: {
      type: Number,
      default: 0,
    },
    student: {
      type: Number,
      default: 0,
    },
    zykn: {
      type: Number,
      default: 0,
    },
    condensed: {
      type: Boolean,
      default: false,
    },
    useCase: {
      validator: function (value) {
        // The value must match one of these strings
        return ["Entpl", "SvZ", "Zykn", "Display", "EntplCenter"].indexOf(value) !== -1;
      },
    },
    position: {
      validator: function (value) {
        // The value must match one of these strings
        return (
          ["center", "left", "right", "top", "bottom"].indexOf(value) !== -1
        );
      },
    },
  },
  data() {
    return {
      showCallout: false,
      loaded: false,
      warning: false,
      windowWidth: window.innerWidth,
      windowOffset: window.pageYOffset,
      windowHeight: window.innerHeight,
      condenseddata: false,
      hohe: "",
      topcallout: window.innerHeight / 2 + "px",
      tc: window.innerHeight / 2,
      optionsCallout: [
        {
          name: "Suche",
          icon: "Search",
          iconColor: "rgba(0, 120, 212, 1)",
          func: this.showSearchbar,
        },
      ],
      searchbar: false,
      searchNoteQuery: null,
      options: [
        {
          key: "Martin Müller",
          text: "Martin Müller",
        },
        {
          key: "Sabine Christiansen",
          text: "Sabine Christiansen",
        },
        {
          key: "Svenja Bochholm",
          text: "Svenja Bochholm",
        },
        {
          key: "Leif Obermayer",
          text: "Leif Obermayer",
        },
        {
          key: "Reinhardt Blochmann",
          text: "Reinhardt Blochmann",
        },
        {
          key: "Augustine von Reichenhall",
          text: "Augustine von Reichenhall",
        },
        {
          key: "Maximiliane Schütter",
          text: "Maximiliane Schütter",
        },
      ],
      verantwortlichEntpl: null,
      verantwortlichZykn: null
    };
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  methods: {
    Userobject(allGroupUser, groupAdmin, owner) {
      if (
        allGroupUser &&
        Array.isArray(allGroupUser) &&
        allGroupUser.length &&
        groupAdmin &&
        groupAdmin.length
      ) {
        //  let UserList = [];
        let GroupAdminId = groupAdmin.Id;
        console.log(owner);
        //   let OwnerId = owner.Id

        for (let item of allGroupUser) {
          if (item.Id === GroupAdminId) {
            item.choosen == true;
          }
          if (item.Id === this.valuechange["Id"]) {
            item.choosen == true;
          } else {
            item.choosen == false;
          }
          // if (item.id === OwnerId) {
          //   item.choose = true
          // }
        }
        return allGroupUser.filter((u) => u.choose === true);
      } else {
        return null;
      }
    },
    async addPiC(payload) {
      if (this.$store.getters.api === "pepApi") {
        console.log("Initial payload:", payload);
        console.log("Initial verantwortlichEntpl:", this.verantwortlichEntpl);

        if (this.useCase === "Zykn") {
          // deep copy
          let pic = JSON.parse(JSON.stringify(this.verantwortlichZykn))
          // add pic
          console.log(payload)
          pic.push({
            "Title": payload.Title,
            "Id": payload.Id
          })
          // create sharepoint object with zyklus
          let sharepointObj = {
            zid: this.zykn,
            pic: pic
          }

          await Promise.all([
            this.$store.dispatch("updateZyklusVerantwortliche", sharepointObj),
            this.refreshZyknPiC()
          ]);

        }
        if (this.useCase === "Entpl" || this.useCase === "EntplCenter") {
          console.log("Before deep copy, verantwortlichEntpl:", this.verantwortlichEntpl);

          // deep copy
          let pic = JSON.parse(JSON.stringify(this.verantwortlichEntpl))
          // add pic
          pic.push({
            "Title": payload.Title,
            "Id": payload.Id
          })
          console.log("After adding pic:", pic);

          // create sharepoint object with zyklus
          let sharepointObj = {
            id: this.student,
            pic: pic
          }
          console.log("SharepointObj:", sharepointObj);
          console.log("Dispatching updateEntplVerantwortliche");
          await Promise.all([
            this.$store.dispatch("updateEntplVerantwortliche", sharepointObj),
            this.refreshEntplPiC()
          ]).then(() => {
            console.log("Finished dispatching updateEntplVerantwortliche");
            console.log("After refresh, verantwortlichEntpl:", this.verantwortlichEntpl);
          }).catch(error => {
            console.error('Ein Fehler ist aufgetreten:', error);
          });


        }
        return
      }

      if (payload) {
        console.log("Payload exists:", payload); // Debug-Log
        let IdArray = [];
        console.log("Initial IdArray:", IdArray); // Debug-Log
        if (this.StudentPiC === "") {
          IdArray.push(payload.Id);
        }
        if (this.StudentPiC !== "" && this.StudentPiC.PiCId !== null) {
          IdArray.push(this.StudentPiC.PiCId.results);
          IdArray.push(payload.Id);
        }
        if (this.StudentPiC !== "" && this.StudentPiC.PiCId === null) {
          IdArray.push(payload.Id);
        }

        let cleanArray = IdArray.flat();
        console.log("Cleaned Array:", cleanArray); // Debug-Log
        //   console.log(cleanArray);
        //   console.log(this.StudentPiC.Id);
        if (this.useCase === "Entpl" || this.useCase === "EntplCenter") {
          let payloadobject = {
            itemId: this.StudentPiC.Id,
            PiCId: cleanArray,
          };
          console.log(payloadobject);
          console.log("Dispatching changePiC with payload:", payloadobject); // Debug-Log
          console.log("Dispatch done. Refreshing..."); // Debug-Log

          await Promise.all([
            this.$store.dispatch("changePiC", payloadobject),
            this.refreshEntplPiC()
          ]).then(() => {
            console.log("Refresh done");
          }).catch(error => {
            console.error('Ein Fehler ist aufgetreten:', error);
          });



        }
        else if (this.useCase === "SvZ") {
          let payloadobject = {
            itemId: this.StudentPiC.Id,
            PiCId: cleanArray,
          };
          console.log(payloadobject);
          console.log("Dispatching changePiC with payload:", payloadobject); // Debug-Log
          console.log("Dispatch done. Refreshing..."); // Debug-Log
          await Promise.all([
            this.$store.dispatch("changePiC", payloadobject),
            this.refreshEntplPiC()
          ]).then(() => {
            console.log("Refresh done");
          }).catch(error => {
            console.error('Ein Fehler ist aufgetreten:', error);
          });




        }
        else if (this.useCase === "Zykn") {
          let payloadobject = {
            itemId: this.zykn,
            PiCId: cleanArray,
          };
          console.log("Dispatching changePiC with payload:", payloadobject); // Debug-Log
          console.log("Dispatch done. Refreshing..."); // Debug-Log
          await Promise.all([
            this.$store.dispatch("changeZyknPiC", payloadobject),
            this.refreshZyknPiC()
          ]);

        }
      }
    },
    async removePiC(payload) {
      if (this.$store.getters.api === "pepApi") {
        if (this.useCase === "Zykn") {
          // check if there is someone left
          if (this.verantwortlichZykn.length === 1) {
            this.showWarning("Die Verantwortlichkeit konnte leider nicht gelöscht werden, da mindestens eine Person ausgewählt werden muss.");
            return;
          }
          // deep copy
          let pic = JSON.parse(JSON.stringify(this.verantwortlichZykn));
          // remove pic
          pic = pic.filter(item => item.Id !== payload.Id);
          // create sharepoint object with zyklus
          let sharepointObj = {
            zid: this.zykn,
            pic: pic
          };
          await Promise.all([
            this.$store.dispatch("updateZyklusVerantwortliche", sharepointObj),
            this.refreshZyknPiC()
          ]);
        }
        if (this.useCase === "Entpl" || this.useCase === "EntplCenter") {
          // check if there is someone left
          if (this.verantwortlichEntpl.length === 1) {
            this.showWarning("Die Verantwortlichkeit konnte leider nicht gelöscht werden, da mindestens eine Person ausgewählt werden muss.");
            return;
          }
          // check if removed is already used
          let verantwortlichZykn = this.verantwortlichZyklen;
          if (verantwortlichZykn && verantwortlichZykn.includes(payload.Id)) {
            this.showWarning("Die Verantwortlichkeit konnte leider nicht entfernt werden, da sie noch in einem Zyklus genutzt wird. Bitte löschen Sie diese zuerst.");
            return;
          }
          // deep copy
          let pic = JSON.parse(JSON.stringify(this.verantwortlichEntpl));
          // remove pic
          pic = pic.filter(item => item.Id !== payload.Id);
          // create sharepoint object with zyklus
          let sharepointObj = {
            id: this.student,
            pic: pic
          };
          await Promise.all([
            this.$store.dispatch("updateEntplVerantwortliche", sharepointObj),
            this.refreshEntplPiC()
          ]);

        }
        return;
      }

      if (payload) {
        let array = this.PiCselected.map((i) => i.Id).filter((p) => p !== payload.Id);

        if (array.length >= 1) {
          let payloadobject = {
            itemId: this.StudentPiC.Id,
            PiCId: array,
          };

          if (this.useCase === "Entpl" || this.useCase === "EntplCenter") {
            await Promise.all([
              this.$store.dispatch("changePiC", payloadobject),
              this.refreshEntplPiC()
            ]);
          }
          else if (this.useCase === "SvZ") {
            await Promise.all([
              this.$store.dispatch("changePiC", payloadobject),
              this.refreshEntplPiC()
            ]);
          }
          else if (this.useCase === "Zykn") {
            await Promise.all([
              this.$store.dispatch("changeZyknPiC", payloadobject),
              this.refreshZyknPiC()
            ]);

          }
        } else {
          this.warning = true;
          let popup = document.getElementById("popup-notification");
          popup.textContent = "Achtung! Es muss mindestens eine verantwortliche Person gewählt werden.";
          popup.style.display = "grid";
          popup.style.backgroundColor = "rgba(248, 121, 121,1)";
          setTimeout(() => {
            popup.style.display = "none";
          }, 2000);
        }
      }
    },
    showWarning(warning) {
      let popup = document.getElementById("popup-notification")
      popup.textContent = warning
      popup.style.display = "grid"
      popup.style.backgroundColor = "rgba(248, 121, 121,1)"

      setTimeout(() => {
        popup.style.display = "none";
      }, 4000);
    },
    getCalloutDimension(buttonposition, windowWidth) {
      if (!this.showCallout) {
        return {
          position: 0,
          top: 0,
          left: 0,
          maxWidth: 0,
          minWidth: 0,
          maxHeight: 0,
          arrowPosition: 0,
          transform: 0,
          rightPosition: 0,
          Pposition: 0
        };
      }

      if (!this.loaded) return "";

      const positionCalcleft = (windowWidth - buttonposition.left) / windowWidth;
      const positionCalctop = (this.windowHeight - buttonposition.top) / this.windowHeight;
      const maxHeight = this.windowHeight / 2.5 + "px";
      let dimension = {};

      switch (this.position) {
        case "top":
          Object.assign(dimension, {
            position: 'top',
            top: buttonposition.top + 100 + "px",
            maxWidth: "400px",
            minWidth: "300px",
            arrowPositiontop: "-30px",
            transform: "rotateZ(90deg)",
            right: "30%",
            rightPosition: "33px",
            Pposition: "fixed"
          });
          break;

        case "center":
          Object.assign(dimension, {
            position: 'center',
            top: '15%',
            //this.topcallout,
            left: '10%',
            //buttonposition.left - 0 + "px",
            maxWidth: "400px",
            minWidth: "300px",
            arrowPosition: "200px",
            transform: "rotateZ(180deg)"
          });
          break;

        case "right":
          Object.assign(dimension, {
            position: 'right',
            top: buttonposition.top / 2 + "px",
            left: -(buttonposition.left / 3 - 50) + "px",
            maxWidth: (buttonposition.left / 3) - 50 + "px",
            minWidth: (buttonposition.left / 3) - 250 + "px",
            transform: "rotateZ(180deg)",
            Pposition: "absolute"
          });
          break;

        default:
          if (positionCalcleft >= 0.66) {
            Object.assign(dimension, {
              position: 'left',
              top: buttonposition.top / 2 + "px",
              left: -(buttonposition.left - 250) + "px",
              minWidth: buttonposition.left - 250 + "px",
              maxWidth: null
            });
          } else if (positionCalcleft <= 0.34) {
            Object.assign(dimension, {
              position: 'right',
              top: buttonposition.top / 2 + "px",
              left: -(buttonposition.left - 250) + "px",
              minWidth: buttonposition.left - 250 + "px",
              maxWidth: buttonposition.left - 50 + "px"
            });
          } else {
            Object.assign(dimension, {
              position: 'center',
              top: '15%',
              // buttonposition.top / 2 + "px",
              left: "100px",
              minWidth: buttonposition.left - 250 + "px",
              maxWidth: buttonposition.left - 150 + "px",
              arrowPosition: "200px",
              transform: "rotateZ(180deg)"
            });
          }

          if (positionCalctop >= 0.56) {
            Object.assign(dimension, {
              positiontop: 'top',
              top: buttonposition.top + 100 + "px",
              maxWidth: "400px",
              minWidth: "300px",
              arrowPositiontop: "-30px",
              transform: "rotateZ(90deg)",
              right: "50%",
              rightPosition: "0px"
            });
          } else {
            Object.assign(dimension, {
              positiontop: 'bottom'
            });
          }
          break;
      }

      dimension.maxHeight = maxHeight;
      return dimension;
    },

    createStyle() {
      if (!this.loaded) {
        let style = {
          position: 0,
          top: 0,
          left: 0,
          maxWidth: 0,
          minWidth: 0,
          maxHeight: 0,
          arrowPosition: 0,
          transform: 0,
        };
        return style;
      }
      if (this.loaded) {
        let display;
        if (this.useCase === "Entpl") {
          display = "inline-block";
        } else if (this.useCase === "SvZ" || this.useCase === "EntplCenter") {
          display = "flex";
        }
        let style = {
          display: display,
        };
        return style;
      } else return "";
    },
    showSearchbar() {
      this.searchbar = true;
      this.optionsCallout.pop();
      // wait until searchbar is rendered
      this.$nextTick(function () {
        document.getElementById("searchBar").focus();
        document.getElementById("searchBar").select();
      });
    },
    hideSearchbar() {
      this.searchNoteQuery = "";
      this.searchbar = false;
      this.optionsCallout.push({
        name: "Suche",
        icon: "Search",
        iconColor: "rgba(0, 120, 212, 1)",
        func: this.showSearchbar,
      });
    },
    showCalloutfunc() {
      if (this.useCase === "Entpl") {
        if (this.condenseddata && !this.showCallout) {
          this.condenseddata = !this.condenseddata;
        } else if (!this.condenseddata && !this.showCallout) {
          this.showCallout = !this.showCallout;
        } else if (this.showCallout && !this.condenseddata) {
          this.showCallout = !this.showCallout;
          this.condenseddata = !this.condenseddata;
        }
      } else {
        this.showCallout = !this.showCallout;
      }
    },
    onClickOutside() {
      this.showCallout = false;
    },
    // Hilfsfunktion zum Parsen der Verantwortlichen
    parseVerantwortliche(verantwortliche) {
      if (verantwortliche && Array.isArray(verantwortliche) && verantwortliche[0] && verantwortliche[0].Id) {
        return verantwortliche;
      }

      let parsedVerantwortliche = [];
      if (verantwortliche && Array.isArray(verantwortliche)) {
        for (let item of verantwortliche) {
          if (typeof item === 'string' && item.includes(";")) {
            let parts = item.split(";");
            parsedVerantwortliche.push({
              Id: parseInt(parts[0].split("#")[1]),
              Title: parts[1].split("#")[1]
            });
          }
        }
      }
      return parsedVerantwortliche;
    },
    async refreshEntplPiC() {
      this.verantwortlichEntpl = await this.$store.getters.entplPiC(this.student);
    },
    async refreshZyknPiC() {
      try {
        this.verantwortlichZykn = await this.$store.getters.zyklusPiC(this.zykn);
      } catch (error) {
        console.error("Ein Fehler ist aufgetreten:", error);
      }
    }

  },
  computed: {
    calloutSizes() {

      if (this.UserOfGroupSelectable === null) return "";
      if (!this.$refs.calloutbutton) return "";

      if (this.loaded) {
        let buttonposition = this.$refs.calloutbutton.getBoundingClientRect();
        let sizes = this.getCalloutDimension(buttonposition, this.windowWidth);
        return sizes;
      } else return "";
    },
    styleObject() {
      if (
        this.UserOfGroupSelectable === null &&
        this.$refs.calloutbutton === null
      )
        return "";
      if (this.loaded) {
        let style = this.createStyle();
        return style;
      } else return "";
    },
    StudentPiC() {
      let plan;
      if (this.useCase == "SvZ") {
        plan = this.$store.getters.entPlbyRefSId(this.student);
        if (plan) return plan;
        else return "";
      } else if (this.useCase == "Entpl" || this.useCase == "Display" || this.useCase == "EntplCenter") {
        plan = this.$store.getters.entPlbyId(this.student);
        if (plan) return plan;
        else return "";
      } else if (this.useCase == "Zykn") {
        if (!this.student || !this.zykn) {
    console.warn('Both sid and zid must be provided. Aborting.');
    return;
  }
  console.warn('Call from StudentPiC ');
        plan = this.$store.getters.zyklusById({
          sid: this.student,
          zid: this.zykn,
        });
        if (plan) return plan;
        else return "";
      } else return "";
    },
    verantwortlichZyklen() {
      if (this.zykn !== null || this.zykn !== 0)
        return this.$store.getters.zyklenPiC
      else return null
    },
    PiCfullNames() {
      // Initialisiere array als leeren Array
      let array = [];

      // Überprüfe, ob die API "pepApi" ist und setze 'verantwortlichEntpl' oder 'verantwortlichZykn' entsprechend
      if (this.$store.getters.api === "pepApi") {
        if (this.useCase === "Entpl" || this.useCase === "EntplCenter") {
          return this.verantwortlichEntpl
          // return this.parseVerantwortliche(this.verantwortlichEntpl);
        }
        if (this.useCase === "Zykn") {
          return this.verantwortlichZykn;
        }
      } else {
        if (this.useCase === "Entpl" || this.useCase === "EntplCenter") {
          if (Array.isArray(this.verantwortlichEntpl) && this.verantwortlichEntpl.length > 0) {
            return this.verantwortlichEntpl;
          }
        }
        if (this.useCase === "Zykn") {
          if (Array.isArray(this.verantwortlichZykn) && this.verantwortlichZykn.length > 0) {
            return this.verantwortlichZykn;
          }
        }
      }

      // Überprüfe, ob StudentPiC null ist und setze array als leeren Array
      if (this.StudentPiC === null) {
        return array;
      }

      // Überprüfe alle notwendigen Eigenschaften, um sicherzustellen, dass sie nicht null oder undefined sind
      const PiCResults = this.StudentPiC?.PiC?.results ?? this.StudentPiC?.PiCId?.results;

      if (this.StudentPiC && this.UserOfGroup && PiCResults) {
        console.log("PiCResults:", PiCResults);
        console.log("UserOfGroup:", this.UserOfGroup);

        // Überprüfe, ob PiCResults ein Array von Objekten oder ein Array von IDs ist
        if (PiCResults[0]?.Id) {
          // PiCResults ist ein Array von Objekten
          array = this.UserOfGroup.filter((i) => PiCResults.map(p => p.Id).includes(i.Id));
        } else {
          // PiCResults ist ein Array von IDs
          array = this.UserOfGroup.filter((i) => PiCResults.includes(i.Id));
        }

        console.log("Filtered Array:", array);
      }

      // Rückgabe des Arrays
      return array;
    }
    ,

    PiCselected() {
  // Überprüfe, ob die API "pepApi" ist
  if (this.$store.getters.api === "pepApi") {
    return "";
  }

  // Überprüfe, ob StudentPiC null oder undefined ist
  if (!this.StudentPiC) {
    return [];
  }

  // Überprüfe alle notwendigen Eigenschaften, um sicherzustellen, dass sie nicht null oder undefined sind
  const PiCResults = this.StudentPiC?.PiC?.results ?? this.StudentPiC?.PiCId?.results;

  if (this.useCase === "Zykn") {
    // Überprüfe, ob PiCResults ein Array von Objekten oder ein Array von IDs ist
    if (PiCResults && PiCResults[0]?.Id) {
      // PiCResults ist ein Array von Objekten
      return this.UserOfGroup.filter((i) => PiCResults.map(p => p.Id).includes(i.Id));
    } else if (PiCResults) {
      // PiCResults ist ein Array von IDs
      return this.UserOfGroup.filter((i) => PiCResults.includes(i.Id));
    }
  } else {
    // Für andere use cases
    let ids = PiCResults;
    if (ids) {
      this.$store.commit("setuserColleaguesselected", [...ids]);
      let groups = this.$store.getters.groups;
      let g = groups.userColleaguesFull.selected;
      return g;
    }
  }

  // Rückgabe eines leeren Arrays, falls keine Bedingungen zutreffen
  return [];
},
    UserOfGroupSelectable() {
      if (this.$store.getters.api === "pepApi") {
        return this.$store.getters.colleagues;
      }

      if (this.StudentPiC === null) {
        return null;
      }

      const PiCResults = this.StudentPiC?.PiC?.results ?? this.StudentPiC?.PiCId?.results;

      if (this.StudentPiC && PiCResults) {
        if (this.useCase === "Zykn") {
          let plan = this.$store.getters.entPlbyId(this.student);

          if (plan && plan.PiCId && plan.PiCId.results) {
            let planPiC = (plan.PiCId && plan.PiCId.results.length > 0) ? plan.PiCId.results : plan.PiC.results;
            let planPiCfiltered = planPiC.filter((i) => !PiCResults.includes(i));
            console.log("planPiCfiltered: ", planPiCfiltered);

            let filteredArray;
            if (PiCResults[0]?.Id) {
              console.log("UserOfGroup: ", this.UserOfGroup);
              filteredArray = this.UserOfGroup.filter((i) => planPiCfiltered.map(p => p.Id).includes(i.Id));
            } else {
              filteredArray = this.UserOfGroup.filter((i) => planPiCfiltered.includes(i.Id));
            }
            console.log("filteredArray: ", filteredArray);
            return filteredArray;
          } else {
            return "";
          }
        } else {
          let ids = PiCResults.map(p => p.Id ?? p);
          this.$store.commit("setuserColleaguesselectable", [...ids]);
          let groups = this.$store.getters.groups;
          let g = groups.userColleaguesFull.selectable;
          return g;
        }
      } else {
        return "";
      }
    },

    UserOfGroup: function () {
      if (this.$store.getters.api === "pepApi") {
        return this.$store.getters.colleagues
      }

      let groups = this.$store.getters.groups;

      if (groups) {
        let g = groups.userColleaguesFull;
        let ids;
        if (this.owner && this.admin) {
          ids = [this.owner, this.admin];
        } else {
          ids = [];
        }
        this.$store.commit("setuserColleaguesFull", ids);

        return g;
      } else return null;
    },
    CollQuery() {
      // Verantwortliche parsen
      let verantwortlichEntpl = this.parseVerantwortliche(this.verantwortlichEntpl);
      let verantwortlichZykn = this.parseVerantwortliche(this.verantwortlichZykn);

      if (this.$store.getters.api === "pepApi") {
        if (this.useCase === "Entpl" || this.useCase === "EntplCenter") {
          let colleagues = this.$store.getters.colleagues;
          let verantwortlicheEntplIds = verantwortlichEntpl.map(ver => ver.Id);
          colleagues = colleagues.filter(col => !verantwortlicheEntplIds.includes(col.Id));
          return colleagues;
        }

        if (this.useCase === "Zykn") {
          let colleagues = this.$store.getters.colleagues;
          let verantwortlicheZyknIds = verantwortlichZykn.map(ver => ver.Id);
          colleagues = colleagues.filter(col => !verantwortlicheZyknIds.includes(col.Id));
          let verantwortlicheEntplIds = verantwortlichEntpl.map(ver => ver.Id);
          colleagues = colleagues.filter(col => verantwortlicheEntplIds.includes(col.Id));
          return colleagues;
        }
        return "";
      }

      if (
        this.searchNoteQuery &&
        Array.isArray(this.UserOfGroupSelectable) &&
        this.UserOfGroupSelectable.length
      ) {
        return this.UserOfGroupSelectable.filter((item) => {
          return this.searchNoteQuery
            .toLowerCase()
            .split(" ")
            .every((v) => item.Title.toLowerCase().includes(v));
        });
      } else {
        return this.UserOfGroupSelectable;
      }
    },
    calloutPositiontop() {
      let result;
      if (!this.showCallout && this.loaded) {

        this.$nextTick(function () {
          if (this.$refs.calloutbutton) {
            if (this.$refs.calloutbutton) {
              this.topcallout = this.windowHeight / 2 + "px"
              this.topcallout = this.$refs.calloutbutton.getBoundingClientRect().top / 2 + 200 + "px";
            }
          }
        })

        result = this.topcallout;
      } else if (this.showCallout && this.position === "center") {
        this.$nextTick(function () {
          if (this.$refs.calloutbutton) {
            if (this.$refs.calloutbutton) {
              let a = this.$refs.callout.getBoundingClientRect().height;
              let b = this.$refs.calloutbutton.getBoundingClientRect().top;
              this.topcallout = b - (a / 2) + "px";
            }
          }
        });
        result = this.topcallout;
      }
      return result;
    },

    arrowPositiontop() {
      let result;
      if (!this.showCallout) {
        result = "100px";
      } else if (this.showCallout && this.position === "center") {
        this.$nextTick(() => {
          if (this.$refs.callout && this.$refs.calloutbutton) {
            let a = this.$refs.callout.getBoundingClientRect().top;
            let b = this.$refs.calloutbutton.getBoundingClientRect().top;
            this.hohe = -(a - b - 300) + "px";
          }
        });
        result = this.hohe;
      } else if (this.showCallout && this.position === "right") {
        this.$nextTick(() => {
          if (this.$refs.callout && this.$refs.calloutbutton) {
            let a = this.$refs.callout.getBoundingClientRect().top;
            let b = this.$refs.calloutbutton.getBoundingClientRect().top;
            this.hohe = -(a - b) + "px";
          }
        });
        result = this.hohe;
      } else if (this.showCallout && this.position === "top") {
        result = "-30px";
      }
      return result;
    },
  },
  watch: {
    // Der Watcher reagiert auf Änderungen der zid-Eigenschaft
    async zykn(newZid) {
      if (newZid === 0 || newZid === null) {
        console.log("verantwirtl", this.verantwortlichEntpl)
        // Ihre Logik für verantwortlichZykn, wenn zykn 0 oder null ist
      } else if (newZid !== undefined && newZid >= 1) {
        try {
          this.verantwortlichZykn = await this.$store.getters.zyklusPiC(newZid);
        } catch (error) {
          console.error("Ein Fehler ist aufgetreten:", error);
        }
      }
    },
    async StudentPiC(newVal, oldVal) {
    if (newVal !== oldVal && this.student) {
      try {
        this.verantwortlichEntpl = await this.$store.getters.entplPiC(this.student);
      } catch (error) {
        console.error("Fehler beim Abrufen von entplPiC:", error);
      }
    }
  }


  },
  async mounted() {
    this.loaded = true;
    if (this.condensed === true) {
      this.condenseddata = true;
    }
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
      this.windowOffset = window.pageYOffset;
    });
    if (this.student) {
      try {
        this.verantwortlichEntpl = await this.$store.getters.entplPiC(this.student);
      } catch (error) {
        console.error("Fehler beim Abrufen von entplPiC:", error);
      }
    } else {
      console.warn("this.student ist nicht definiert");
    }

  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>
<style scoped>
.persona-item {
  margin-right: 10px;
  box-shadow: var(--shadow-elevation-low);
  padding-bottom: 1%;
  margin-bottom: 1%;
}

.add-button {
  position: absolute;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  /* display: flex; */
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  color: white;
}

.closed {
  background: #00a3ff;
  transform: rotateZ(0deg);
  transition: transform 200ms ease-in-out;
}

.opened {
  background: #f87979;
  transform: rotateZ(45deg);
  transition: transform 200ms ease-in-out;
}

.list-enter-active,
.list-leave-active {
  transition: all 1s;
}

.list-enter,
.list-leave-to

/* .list-leave-active below version 2.1.8 */
  {
  opacity: 0;
  transform: translateX(-30px);
}

.persona-title {
  font-size: calc(16px + (18 - 16) * ((100vw - 300px) / (1900 - 300))) !important;
}

.verantwortliche-callout-list {
  display: flex;
  flex-wrap: wrap;
}

.verantwortliche-preview-item-person>.fv-light-Persona:hover {
  filter: drop-shadow(0px 0px 39px #0091e8);
  cursor: pointer;
  background: #37ffec05;
}

.verantwortliche-callout * .fv-light-Persona::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: -1;
}

.persona-secondary {
  display: flex;
}

.persona-content-block {
  overflow: hidden
}

:deep(.left-command-bar-container),
:deep(.right-command-bar-container) {
  overflow: hidden;
  background: #f2f2f2;
}

:deep(.left-command-bar-container * span.command-bar-item) {
  width: 100%;
}


:deep(.fv-light-DropDown .list-container .list-item) {
  height: 48px;
}

:deep(.list-container) {
  max-height: 400px;
  overflow-y: auto;
  top: 100%;
}

:deep(.command-bar-item.normal.not_disabled),
:deep(.left-command-bar-container * span.command-bar-item) {
  width: 100%;
  justify-content: start;
}

.verantwortliche-container {
  height: 425px;
  display: grid;
  grid-template-rows: min-content auto;
  grid-template-areas:
    "header"
    "preview";
  padding: 33px 16px 10px 16px;
  text-align: left;
  text-align: -webkit-left;
  text-align: -moz-left;
  background-color: rgba(255, 255, 255, 0.7);
}

.verantwortliche-header {
  grid-area: header;
  justify-self: start;
  margin-left: 33px;
  margin-bottom: 15px;
}

.verantwortliche-header-title {
  margin: 0;
  padding: 0;
}

.verantwortliche-preview-container {
  grid-area: preview;

}

.verantwortliche-preview-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow-y: auto;
  overflow-x: hidden;
}

.verantwortliche-preview-item-person {
  margin: 10px 10px 10px 20px;
}

.callout {
  position: absolute;
  background-color: rgba(255, 255, 255, 1);
  width: 100%;
  box-shadow: 0 6.4px 14.4px 0 rgb(0 0 0 / 13%),
    0 1.2px 3.6px 0 rgb(0 0 0 / 11%), 7px 0 3.6px 0 rgb(0 0 0 / 13%);
  padding-bottom: 12px;
  z-index: 9;
  box-shadow: none;


}

.callout.visible {
  box-shadow: var(--shadow-elevation-high);
  transition: box-shadow .3s ease-out .1s;
}

.callout-body {
  /* display: flex;
  flex-wrap: wrap; */
  padding: 28px;
  overflow-y: auto;
}

.callout-header {
  padding: 28px 28px 0 28px;
}

.cbefore {
  content: "";
  position: absolute;
  z-index: 1;
  right: -30px;
  border: solid 15px transparent;
  border-right-color: #fff;
}

.note-preview-command-bar {
  margin-bottom: 10px;
  background-color: transparent;
  z-index: 1;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: minmax(0, 1fr) auto;
  place-content: stretch;


}

.note-preview-command-bar-search {
  margin-right: 50px;
  margin-top: 2px;
}

.note-preview-command-bar-search-input {
  margin: 5px 0px 5px 5px;
  padding: 5px;
  padding-right: 35px;
  font-size: 16px;
  background: inherit;
  outline: none;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 30, 0.36);
}

.note-preview-command-bar-search-input:hover {
  outline: none;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 30, 0.36);
}

.note-preview-command-bar-search-input:focus {
  outline: none;
  border: none;
  border-bottom: 1px solid rgb(0, 120, 212);
}

.condensed:not(:first-of-type) {
  margin-left: -60px;
}

.showData {
  opacity: 1;
  width: 100%;
  transform: translateY(0);
  transition: opacity 300ms ease-in-out 300ms, transform 300ms ease-in-out 300ms;
}

.noData {
  opacity: 0;
  width: 0;
  transition: opacity 300ms ease-in-out;
  height: 0;
  overflow: hidden;
  transition: opacity 300ms ease-in-out 300ms, transform 300ms ease-in-out 300ms;
}

.not-condensed,
.condensed {
  transition: margin 300ms ease-in-out;
}

hr {
  border-width: 1px !important;
}
</style>
