export default {

  methods: {
    changebodyColor: function(event) {
     // console.log(event.target.closest('.EntwicklungsplanCard'))
      if (event.target.closest('.EntwicklungsplanCard') !== null) {


        var Farbe = document.querySelector("#colorhighlight");
        // console.log(event.target.closest('.EntwicklungsplanCard'))
        var styller = event.target.closest('.EntwicklungsplanCard').classList[2];
        var oldClass = Farbe.className;
        if (oldClass.length > 0) {
          Farbe.classList.remove(oldClass);

          Farbe.classList.add(styller);
        } else {

          Farbe.classList.add(styller);
          // console.log(event.target.closest(".EntwicklungsplanCard").classList[2]);
        }
        (this.classclicked = true);

       //  this.$router.push({ name: "Profil", params: { id: event.target.closest(".carde").getAttribute('data-id') } });

      //  console.log(Farbe);
      // console.log("h" + event)
      }
    },

  },
}
