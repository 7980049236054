<style>
.resultcontainer {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-image: -webkit-gradient(linear, left bottom, left top, from(#141e30), to(#243b55));
  background-image: linear-gradient(to top, #141e30, #243b55);
  z-index: 999;
}
.result-container-passive {
max-height: 90%;
overflow-y: scroll;
padding-bottom: 115px;
}
.result-overview {
    color: white;
display: block;
text-align: left;
width: 60%;
margin: 5% auto;
}
.question-title {
    font-size: 18px;
}
.question-answer {
    font-size: 16px;
}
.results {
    display: block;
color: white;
font-size: 28px;
margin-top: 80px;
}
</style>
<template>
<div class="resultcontainer">
    <header class="vff-header"><div class="f-container"><span>Ergebnis</span><span class="material-icons CloseIcon" @click="closetest()"> close </span></div></header>
     
       <div  class="result-container-passive">
          <div class="results">
        {{result.Ergebnis}} <span> Fragen richtig beantwortet</span>
    </div>
          <ul class="result-overview">
            <li v-for="(question, $index) in result.Antworten" :key="$index" class="result-block">
            <span class="question-title">  {{question.title}}</span>
            <div v-if="question.type === 'FlowFormMultipleChoiceType'">
            <span class="question-answer"  v-for="(answer, $index) in question.options" :key="$index"> 
              <span v-if="question.answer !== answer.value" class="answer-checkbox" :class="rightwrong(question.id, answer.value)">☐</span>
              <span v-else class="answer-checkbox" :class="rightwrong(question.id, answer.value)">☑</span>{{answer.label}}</span>
            </div>
            <div v-if="question.type === 'FlowFormLongTextType'">
<span class="question-answer" >{{question.answer}}</span>
<span class="question-answer" >{{result.Antworten_Set[question.id]}}</span>

            </div>
            </li>
          </ul>
        </div>
</div>
</template>
<script>

export default {
  name: "TestResult",
  data() {
    return {
 
    }
  },
  methods: {
       rightwrong(id, value) {
      if (value == this.result.Antworten_Set[id]) {
        return "right-answer"
      }
      else return "wrong-answer"
    },
        closetest() {
      this.$parent.$emit("closeresult", true);
    },
  },
  computed: {
      result() {
          return this.$store.getters.showResult
      }
  }
}
</script>
