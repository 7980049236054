import axios from 'axios';
import store from './store'

const baseURL = 'https://api.pep.schule'

export const connect = token => {
  const options = {headers:{}};
  options.baseURL = baseURL;
  options.headers.Authorization = 'bearer ' + token
  options.headers.ContentType = 'application/json;odata=verbose'

  const instance = axios.create(options);

  instance.interceptors.response.use(response => {
    return response;
  }, err => {
    let status = err.response.status
    let cookie = document.cookie
    if(status === 401 && cookie && cookie.includes("pep_token")) {
        cookie = cookie.split(";")
        for(let c of cookie) {
          if(c.includes("pep_token")) {
            let token = c.split("=")[1]
            // test if token is still valid
            const config = {
              headers: { Authorization: `Bearer ${token}`}
            }
            axios.get(baseURL +'/GetMyself', config)
            .then(() => {
              // if session is valid: restore auth token
              store.commit("setToken", token)
              // restart token renew interval
              store.dispatch("startTokenRenewInterval")
              // load data, which is not triggered by UI
              store.dispatch("loadColleagues")
              store.dispatch("loadMyself")
              store.dispatch("loadColGroup")
            })
            .catch(() => {
              // if session is not valid: remove cookie and clear session
              document.cookie = "pep_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
              store.dispatch("clearTokenRenewInterval")
              store.dispatch("clearStore")
              // go to login page
              location.href = window.location.origin + "#/Login"
              showWarning("Leider ist die Session abgelaufen, bitte melden Sie sich erneut an.")
            })
          }
        }
      } else if (status === 401) {
        showWarning("Es wurde keine aktive Session gefunden, bitte loggen Sie sich ein.")
        store.dispatch("clearTokenRenewInterval")
        store.dispatch("clearStore")
        // no session yet, go to login page
        location.href = window.location.origin + "#/Login"
      }

    })
  return instance
}


export const auth = () => {
  const options = {headers:{}};
  options.baseURL = baseURL;
  options.headers.ContentType = "multipart/form-data"
  const instance = axios.create(options);

  instance.interceptors.response.use(response => {
    return response;
  }, err => {
    if(err.response && err.response.data && err.response.data.detail ==="user not found in column colleagues")
    alert("Die Nutzer:in wurde nicht in der Datenspalte Colleagues gefunden. Bitte kontaktieren Sie Ihren It-Support.")
    if(err.response && err.response.status === 401) {
      showWarning("Ein Login war nicht möglich: Passwort, Benutzungsname oder Datenbankauswahl waren nicht korrekt. Bitte versuchen Sie es noch einmal.")
    }
    if(err.message=== 'Network Error'){
      showWarning('Es konnte keine Verbindung zum Authentifizierungsserver hergestellt werden.');
    }
  });
  return instance;
};

function showWarning(warning) {
  setTimeout(() => {
    let popup = document.getElementById("popup-notification")
    if (popup) {
      popup.textContent = warning
      popup.style.display = "grid"
      popup.style.backgroundColor = "rgba(248, 121, 121,1)"

      setTimeout(() => {
        popup.style.display = "none";
      }, 4000);
    }
  }, 500)
}
