<style scoped>
.sus-container {
  height: 100vh;
  width: 100%;
  position: relative;
  /* backdrop-filter: blur(12.6px); */
  opacity: 0.9;
  overflow-y: auto;
  animation: appear-start 0.3s forwards cubic-bezier(0.55, 0.09, 0.68, 0.53) 0s;
  transition: background .2s ease;
  background-size: cover;
  background-repeat: no-repeat;
  background-blend-mode: soft-light;
  background: #ffffff08;
  display: grid;
grid-auto-flow: row;
grid-template-rows: min-content min-content 100%;
}

@keyframes appear-start {
  0% {
    height: 22vh;
  }

  100% {
    height: 100vh;
  }
}

.Header {
    display: grid;
  gap: 0px 0px;
  grid-auto-flow: column;
  text-align: start;
  padding: 30px;
  background: rgba(255, 255, 255, 0.52);
}

.Header::before {
  content: "";
  background-repeat: no-repeat;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  opacity: .7;
}

.Header-title {
  line-height: normal;
  align-self: center;
}

.Header-title * h1 {
  color: #2c3e50
}
.action-icons {
  display: grid;
  grid-auto-flow: column;
  width: 100%;
  place-content: end;
  grid-gap: 4%;
  place-items: center;
}
.susc-content {
    text-align: left;
padding: 50px;
}
</style>
<template>
    <div class="sus-container" id="susc" ref="susc">
        <ScrollHeader :title="title" :containerIDs="['susc']">



            <!-- Speichern Button -->
            <ActionButton :size="'small'" :action="'save'" :isSaved="false" @saveClick="onSave" :isDisabled="true" :hasChanged="true">
            </ActionButton>

        </ScrollHeader>
            <div class="Header" :class="this.isScrolled ? 'hidden-header' : ''">

                <div class="Header-title" style="margin-bottom: 4%">
               <h1>  {{ title }}</h1>   
                </div>
                <div class="Header-action">
        <div class="action-icons">
                           <!-- Speichern Button -->
            <ActionButton :size="'large'" :action="'save'" :isSaved="false" @saveClick="onSave" :isDisabled="true" :hasChanged="true">
            </ActionButton>
        </div>
                </div>
            </div>
            <div class="susc-content">
            <ZyklusAuR id="AL" :zid="null" :studentId="null" ref="zyklusAuR" :showFormImmediately="true"/>
        </div>
    </div>
</template>
<script>
import ScrollHeader from '../components/layout/Reusable/ScrollHeader.vue';
import ActionButton from '../components/layout/Buttons/ActionButton.vue';
import ZyklusAuR from '../components/layout/Zyklus/ZyklusAuR.vue';


export default {
    name: 'FutureSuS',
    props: {
            },
    data() {
        return {
           title: "WebAPP - Dein Zugang",
           isScrolled: false,
        }
    },
    components: {
        ActionButton,
        ScrollHeader,
        ZyklusAuR
    },
    methods: {
        onSave() {
            //Api Call zum Speichern
        },
       
    },
    mounted() {
        this.$refs.susc.addEventListener("scroll", this.handleScrollHeader, { passive: true });

},

beforeDestroy() {
  if (typeof _spPageContextInfo !== "undefined") {
    document.getElementById("s4-workspace").style.overflow = "auto";
  } else {
    document.body.style.overflow = "auto";
  }
  this.$refs.susc.removeEventListener("scroll", this.handleScrollHeader);
},
}
</script>