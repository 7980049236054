// Login.vue



<template>
<div class="tp">
 <h1> TestPage </h1>   
 <!-- <CardCollection 
 :types="{isEB: true}"/>
 <CardCollection 
 :types="{isEP: true}"/> -->
 <CardCollection 
 :types="{isEM: true}"/>
 
</div>
</template>
 
<script>

 import CardCollection from '../components/layout/Cards/CardCollection'


export default {
     data (){
   return{
    title:'Testpage',

    
    
   }},


 
    name: 'Testpage',
       components: {
        
CardCollection
    }
}
</script>
<style>
.tp {
    position: absolute;
    height: 100%;
    width: calc(100% - 50px);
    margin-left: 50px;
}
.bg-blue {
  background-image: linear-gradient(
    120deg,
    rgb(137 247 254 / 40%),
    rgb(102 166 255 / 40%)
  );
}
.bg-cyan {
  background-image: linear-gradient(
    90deg,
    rgb(79 172 254 / 40%) 0,
    rgb(0 242 254 / 40%)
  );
}
.bg-indigo {
  background-image: linear-gradient(
    90deg,
    rgb(10 207 254 / 40%) 0,
    rgb(73 90 255 / 40%)
  );
}

.bg-purple {
  background-image: linear-gradient(
    0deg,
    rgb(205 156 242 / 40%) 0,
    rgb(246 243 255 / 40%)
  );
}

.bg-pink {
  background-image: linear-gradient(
    0deg,
    rgb(247 112 98 / 40%) 0,
    rgb(254 81 150 / 40%)
  );
}

.bg-red {
  background-image: linear-gradient(
    -225deg,
    rgb(255 226 159 / 40%),
    rgb(255 169 159 / 40%) 48%,
    rgb(255 113 154 / 40%)
  );
}

.bg-orange {
  background-image: linear-gradient(
    -60deg,
    rgb(255 88 88 / 40%),
    rgb(240 152 25 / 40%)
  );
}

.bg-yellow {
  background-image: linear-gradient(
    0deg,
    rgb(230 185 128 / 40%) 0,
    rgb(234 205 163 / 40%)
  );
}

.bg-green {
  background-image: linear-gradient(
    15deg,
    rgb(19 84 122 / 40%),
    rgb(128 208 199 / 40%)
  );
}
    .bg-peach {
      background-image: linear-gradient(0deg, rgb(254 173 166 / 40%) 0%, rgb(245 239 239 /40%) 100%);
    
    }
    .bg-misty {
      background-image: linear-gradient(60deg, rgba(171, 236, 214, 0.4) 0%, rgba(251, 237, 150, 0.4) 100%);
    }
    .bg-iron {
    background-image: linear-gradient(to right,rgba(215, 210, 204, 0.4) 0%, rgba(48, 67, 82, 0.4) 100%);
    }
    .bg-sunset {
      background-image: linear-gradient( 109.6deg,  rgb(247 202 201 / 81%) 20.6%, rgb(146 168 209 / 61%) 85.9% )
    }
</style>