<template>
   <div v-if="show" class="overlay">
    <div class="ring-container">

    <fv-progressRing value="progress" r="100" background="rgba(0, 0, 0, 0.3)" color="rgba(255, 255, 255, .7)">
   
</fv-progressRing>
 </div>
 <div class="text-container">
    <span>  {{ statusText }}</span>
  </div>
  </div>
</template>
 
<script>
export default {
  name: 'globalOverlay',
  props: {
    show: Boolean,
    progress: Number,
    statusText: String
  },
  computed: {
    loadingIndi() {
      return this.$store.getters.getloadingIndi;
    },
    
  

  },
  mounted() {
    console.log(this.$store.state.showOverlay);
  }
}
</script>

<style scoped>
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: grid;
  place-content: center;
  grid-gap: 5%;
  z-index: 1000; /* Hoch genug, um über anderen Inhalten zu liegen */
  backdrop-filter: blur(10px);
  color: white;
grid-auto-flow: row;
}
.ring-container, 
.text-container {
  width: 100vw;
  place-items: center;
  display: grid;
}
span {
  font-size: 200%;
  font-family: "aileronultralight";
}
.fv-light-progressRing {
  display: flex !important;
}
</style>
