<template>
  <div class="modal">
     <object class="iframne" data="https://pwd.system-hoster.com/default.aspx"></object>
  </div>
</template>

<style scoped>
    .modal {
        background: white;
        min-height: 50%;
        max-width: 50%;
          height: 50%;
        width: 50%;
    }
    
    .close-button {
      justify-self: end;
      margin: 10px;
      border: 1px rgba(0, 0, 0, 0.5) solid;
      background: white;
      padding: 5px 15px 5px 15px;
    }
    .close-button:hover {
      background: rgba(0, 0, 0, 0.1);
      border-color: white;
      cursor: pointer;
    }
    .iframne {
      min-height: 100%;
      min-width: 100%;
      position: relative;
    }
    .removeButtonStyle {
      all: inherit;
      z-index: 1;
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
</style>

<script>

export default {
  name: "PWModal",
  data() {
    return {
 
    }
  },
  methods: {

    closeModal() {
      this.$xmodal.close()
    }
  }
}
</script>
