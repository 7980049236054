import store from "../index"
import api from '../../api/api.js'

const state = {
    studentId: null,
    notes: null,
    loading: null,
};
const actions = {
    loadNotes({commit}, studentId) {
      // only load, if necessary
      if(state.studentId !== studentId) {
        commit('setNotesLoading', true)
        api.loadNotes(studentId)
        .then(res => {
          commit('setNotes', res)
          commit('setNotesStudentId', studentId)
        })
        .catch(err => {
          console.log("Error while getting notes: " +err)
        })
        .then(() => {
          setTimeout(() => {
            commit('setNotesLoading', false)
          }, 1000)
        })
      }
    },
    addNote({commit, state}, note) {
      // checks if element what should be added does not already exist
      if(!state.loading && !state.notes.some(item => item.Id === note.Id) && state.studentId === note.RefPEPId) {
        commit('setNotesLoading', true)
        return new Promise((resolve, reject) => {
          api.addNote(note)
          .then(note => {
            commit("addNote", note)
            // return a promise with id from the new note
            resolve(note.Id)
            // delay the time until the next time the button triggers the creation of a new note.
            setTimeout(() => {
              commit('setNotesLoading', false)
            }, 1000)
          })
          .catch(err => {
            commit('setNotesLoading', false)
            alert("Es trat ein Problem mit der Serververbindung oder Session auf. Es konnte leider keine neue Notiz angelegt werden. Sie können versuchen die Browserseite neu zu laden.")
            console.log("Error while add note: " +err)
            reject(err)
          })
        })
      }
    },
    removeNote({commit, state}, payload) {
      // checks whether the note to be deleted exists
      if(state.notes.some(item => item.Id === payload.noteId)) {
        commit('setNotesLoading', true)
        api.removeNote(payload)
        .then(() => {
          commit("removeNote", payload.noteId)
          commit('setNotesLoading', false)
        })
        .catch(err => {
          commit('setNotesLoading', false)
          alert("Es trat ein Problem mit der Serververbindung oder Session auf. Die Notiz konnte leider nicht entfernt werden. Sie können versuchen die Browserseite neu zu laden.")
          console.log("Error while remove note: " +err)
        })
      }
    },
    updateNote(_, payload) {
      api.updateNote(payload)
      .catch(err => {
        alert("Es trat ein Problem mit der Serververbindung oder Session auf. Die Notiz konnte leider nicht aktualisiert werden. Sie können versuchen die Browserseite neu zu laden.")
        console.log("Error while updating note: " +err)
      })
    }
}
const getters = {
    notes: state => studentId => {
      
      // fetch data if data is not already loaded or there is no other fetching process.
      if(state.studentId !== studentId && !state.loading) {
        store.dispatch("loadNotes", studentId)
      }
      return state.notes
    },
    notesLoading: state => {
      return state.loading
    },
    getNoteById: state => id => {
        return state.notes.find(note => note.Id === id)
    },
    nextNoteId: state => {
      let notes = state.notes
      let id = 0
      if(notes) {
        for(let note of notes) {
          if(note.Id > id) id = note.Id
        }
      }
      return ++id
    }
}
const mutations = {
    setNotesStudentId: (state, studentId) => {
      state.studentId = studentId
    },
    setNotes: (state, notes) => {
        state.notes = notes
    },
    setNotesLoading: (state, loading) => {
      state.loading = loading
    },
    updateNote: (state, payload) => {
      state.notes.forEach(note => {
        if(note.Id === payload.id) {
          if(payload.title || payload.title === "") {
            note.Title = payload.title
          }
          if(payload.message || payload.message === "") {
            note.OData__Comments = payload.message
          }
          if(payload.refZid || payload.refZid === null) {
            note.RefZId = payload.refZid
          }
        }
      })
    },
    notedatesort: (state) => {
     let notes = state.notes
     if(notes && Array.isArray(notes) && notes.length) {
       notes.sort((a, b) => (a.Created > b.Created) ? 1 : -1 )
       state.notes = notes
     }
    },
    notealphasort: (state) => {
      let notes = state.notes
      if(notes && Array.isArray(notes) && notes.length) {
        notes.sort((a, b) => (a.Title > b.Title) ? 1 : -1 )
        state.notes = notes
      }
     },
    addNote: (state, note) => {
      if(note) state.notes.push(note)
    },
    removeNote: (state, id) => {
      state.notes = state.notes.filter(note => note.Id !== id)
    }
}
export default {
    state,
    mutations,
    getters,
    actions
}
