import store from "../index"
import { entgendern } from "../../variables.js"

const state = {
  eb: null,
  loading: null
}

const actions = {
    loadEb({commit}) {
        commit('setEbLoading', true)
        let eb = [{
            Id: 999991,
            type: {
                  isEB: true,
              },
              Title: 'Persönlichkeit/ Selbstkonzept',
            kurzbeschreibung:`Dieser Bereich „Persönlichkeit / Selbstkonzept“ beschäftigt sich mit der Wahrnehmung und dem Wissen der ${entgendern.pupils} bezüglich ihrer eigenen Person. Die Selbsteinschätzung der ${entgendern.pupils} sind geprägt von (schulischen) Erfahrungen, die sie in ihrem Leben gemacht haben. Sie kann sich im Entwicklungsverlauf sowohl positiv als auch negativ verfestigen.`,
          },
          {
            Id: 999992,
            type: {
                  isEB: true,
              },
            Title: 'Fachlicher Bereich: MINT',
            kurzbeschreibung:`Dieser Fachbereich umfasst die Schulfächer aus den Gebieten Mathematik, Informatik, Naturwissenschaften und Technik. In diesem Entwicklungsbereich können die ${entgendern.pupils} z.B. ihr Interesse für Technik und Umwelt ausbauen, Lösungswege für Problemstellungen finden und ein forschendes Grundverhalten entwickeln. Sie verstehen logische und mathematische Prinzipien und nutzen lösungsorientierte Strategien.`,
          },
          {
            Id: 999993,
            type: {
                  isEB: true,
              },
            Title: 'Fachlicher Bereich: Sprachlich',
            kurzbeschreibung: `Dieser Fachbereich umfasst unter anderem den Wortschatz. Die ${entgendern.pupils} besitzen ein umfassendes und korrektes Vokabular, um Erlebtes, Gedanken, Bedürfnisse, Gefühle und Erfahrungen zu teilen. Sie können verschiedene Bedeutungen von Wörtern erkennen. Die Erfassung von komplexen Aufgabenstellungen wird in diesem Fachbereich erprobt. Ebenso können ${entgendern.pupils} ihre Ausdrucksfähigkeit entwickeln.`,
          },
          {
            Id: 999994,
            type: {
                  isEB: true,
              },
              Title: 'Fachlicher Bereich: Gesellschaftswissenschaften',
            kurzbeschreibung: `Dieser Bereich umfasst Soziologie, Politik, Wirtschaft, Geografie, Geschichte, Ethik, Kultur und Medien. Soziologische und kulturelle Konstrukte werden diskutiert und kritisch erörtert. Die ${entgendern.pupils} ordnen historische Ereignisse im gesellschaftlichen Kontext ein und diskutieren innen- sowie außenpolitischen und wirtschaftliche Themen. Sie setzen sich mit verschiedenen Aspekten von Medien auseinander.`,
          },
          {
            Id: 999996,
            type: {
                  isEB: true,
              },
              Title: 'Sozialität/ Soziale Zugehörigkeit',
            kurzbeschreibung: `Dieser Bereich umfasst die soziale Zugehörigkeit und Umwelt der ${entgendern.pupils}. Die ${entgendern.pupils} verbringen Zeit mit ihren ${entgendern.classmates} und gehen Freundschaften ein. Sie erhalten emotionale und physische Unterstützung sowohl durch die Klassengemeinschaft, als auch durch die Eltern oder Erziehungsberechtigten.`,
          },
          {
            Id: 999997,
            type: {
                  isEB: true,
              },
              Title: 'Motivation/ Ausdauer/ Resilienz',
            kurzbeschreibung: `Dieser Bereich umfasst die mentale, psychologische und physiologische Ebene, die die Zielstrebigkeit anspricht. Die ${entgendern.pupils} bauen eine ausdauernde Arbeitsweise aus oder festigen diese und lernen mit Misserfolg umzugehen. Der Bereich beinhaltet ebenso die Aufmerksamkeit sowie die Eigeninitiative bezogen auf persönliche Ziele und Aktivitäten auch über die schulischen Erfolge hinaus.`,
          },
          {
            Id: 999998,
            type: {
                  isEB: true,
              },
              Title: 'Kreativität/ Divergentes Denken',
            kurzbeschreibung: `Dieser Bereich umfasst die Fähigkeit vom innovativen und originellen Denken sowie Handeln. Die ${entgendern.pupils} entwickeln oder verfügen über vielfältige Ideen, die ein hohes Maß an Flexibilität aufweisen. Sie erkennen Probleme aus verschiedenen Perspektiven, können sich in andere Situationen hineinversetzen und lernen. Neugier wird durch Hinterfragen und Diskussionen vertieft.`,
          },
          {
            Id: 999999,
            type: {
                  isEB: true,
              },
              Title: 'Künstlerisch - Sensorisch',
            kurzbeschreibung: `Dieser Bereich umfasst Schauspiel, Musik, Tanz, Zeichnen und Gesang. Die ${entgendern.pupils} zeigen eine akustische Sensibilität, können Tonhöhen, Harmonien und Klangfarben besonders gut unterscheiden. Ebenso vertiefen sie ihr künstlerisches Potential. Auf der schauspielerischen Ebene entwickeln sie z.B. durch Aufführungen ihr Talent. Der Entwicklungsbereich umfasst ebenso außergewöhnliche schriftstellerische Fähigkeiten.`,
          },
          {
            Id: 99999910,
            type: {
                  isEB: true,
              },
              Title: 'Kinästhetisch - Motorisch',
            kurzbeschreibung: `Dieser Bereich umfasst (psycho-)motorische Fähigkeiten. Die ${entgendern.pupils} zeigen ausgeprägte Hand- und Fingergeschicklichkeit, ein hohes Maß an Bewegungspräzision und die Fähigkeit, Objekte zielgerichtet einzusetzen. Sie können Bewegungsabläufe analysieren und modifizieren, ihre praktische Veranlagung ausbauen sowie ihre Leistung durch feinmechanische, experimentelle und technische Aufgaben vertiefen.`,
          },
          {
            Id: 99999911,
            type: {
                  isEB: true,
              },
              Title: 'Arbeitshaltung/ Methoden',
            kurzbeschreibung: `Dieser Bereich umfasst unter anderem die Bewältigungsstrategien der ${entgendern.pupils} in Bezug auf Arbeitsabläufe. Die ${entgendern.pupils} können ihren Umgang mit Stress- und Prüfungssituationen reflektieren und trotz Zeitdruck zielstrebig arbeiten. Ebenso fallen unter diesen Bereich das Zeitmanagement, eine selbstständige Arbeitsweise sowie eine realistische Einschätzung der eigenen Leistung.`,
          }
        ]
        commit('setEb', eb)
        commit('setEbLoading', false)
    }
}

const getters = {
  ebKaertchen: state => {
    
    // fetch data if no other data is available or there is no other fetching process.
    if(state.eb === null && !state.loading) {
      store.dispatch("loadEb")
    }
    return state.eb
  },
  ebKaertchenId: state => title => {
    let ebKaertchen = store.getters.ebKaertchen
    if(ebKaertchen) return state.eb.find(item => item.Title === title).Id
    else return null
  },
  ebZuordnung: () => eb => {
    let ebKaertchen = store.getters.ebKaertchen
    let ebZuordnung = []
    // create eb object that has all the properties needed by zuordnung
    if(ebKaertchen && Array.isArray(eb) && eb.length) {
      for(let item of ebKaertchen) {
        let title = item.Title
        let color = getColor(item.Id)
        if(eb.includes(title)) {
          ebZuordnung.push({
            id: item.Id,
            name: title,
            colorClass: color,
            class: "EBDnD " +color,
            children: []
          })
        }
      }
    }
    return ebZuordnung
  }
}

const mutations = {
  setEb: (state, eb) => {
    state.eb = eb
  },
  setEbLoading: (state, loading) => {
    state.loading = loading
  },
}

// function already exists as getBgColor mixin: i did not know how to import it, can be replaced by future
function getColor(index) {
  let classes = [
    "bg-blue",
    "bg-indigo",
    "bg-purple",
    "bg-pink",
    "bg-red",
    "bg-orange",
    "bg-yellow",
    "bg-green",
    "bg-cyan",
    "bg-peach",
    "bg-misty",
    "bg-iron",
    "bg-sunset"
  ]
  // Get length of  classes array
  let length = classes.length;
  // Get the current turn (how many times the classes have been used from start to finish)
  let turn = Math.floor(index / length);
  // Multiply turn by the length then subtract result from current index
  let colorIndex = index - turn * length;
  return classes[colorIndex]
}

export default {
  state,
  mutations,
  getters,
  actions
}
