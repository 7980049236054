// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??clonedRuleSet-12.use[1]!../../../../node_modules/@vue/vue-loader-v15/lib/loaders/stylePostLoader.js!../../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-12.use[2]!../../../../node_modules/@ditdot-dev/vue-flow-form/dist/vue-flow-form.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../../../../node_modules/css-loader/dist/cjs.js??clonedRuleSet-12.use[1]!../../../../node_modules/@vue/vue-loader-v15/lib/loaders/stylePostLoader.js!../../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-12.use[2]!../../../../node_modules/@ditdot-dev/vue-flow-form/dist/vue-flow-form.theme-minimal.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n:root {\n--vff-bg-color: #313640;\n--vff-main-text-color: #fff;\n--vff-secondary-text-color: #aeb6bf;\n--vff-tertiary-text-color: #41464f;\n--vff-main-accent-color: #41b883;\n--vff-secondary-accent-color: #a0dbc1;\n}\n.testContainer {\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  top: 0;\n  left: 0;\n  background-image: linear-gradient(to top, #141e30, #243b55);\n  z-index: 999;\n  overflow-y: auto;\n}\nheader.vff-header {\n  max-height: 50px;\n  color: white;\n  background-color: #313640 !important;\n}\n.f-container {\n  font-family: \"aileronblack\";\n}\n.vff-footer .footer-inner-wrap {\n  background-color: rgba(49,54,64,.75) !important;\n}\n.vff a, .vff a:active, .vff input, .vff select option, .vff textarea,\n.vff, .vff.vff-not-standalone {\n  color: #fff;\n}\n.CloseIcon {\n  position: absolute;\n  top: 14px;\n  right: 14px;\n  cursor: pointer;\n}\n.f-radios * span {\n  font-family: \"aileronregular\";\n  color: white;\n  font-weight: 300;\n}\n.result-container {\n  overflow-y: scroll;\nmax-height: 63vh;\npadding-bottom: 115px;\n}\n.result-overview {\nlist-style: none;\n-moz-column-count: 2;\n     column-count: 2;\n-moz-column-gap: 20px;\n     column-gap: 20px;\npadding-left: 0;\n}\n.result-block {\n  margin-bottom: 10px;\nmargin-top: 10px;\ndisplay: grid;\ngrid-auto-rows: auto auto;\n}\n.question-answer {\n  font-size: 80%;\n  font-family: 'aileronregular';\n  display: block;\n  margin: 10px;\n}\n.answer-checkbox {\n  margin-right: 12px;\n}\n.wrong-answer {\n  color: tomato\n}\n.right-answer {\n  color: green\n}\n.vff .fh2, .vff h2 {\n      font-size: clamp(22px, 4vw, 42px);\n}\n.vff{\n      padding: 0 6%;\n}\n", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
