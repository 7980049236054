import Vue from 'vue'
import Vuex from 'vuex'
import aur from "./modules/aur"
import config from "./modules/config"
import entMTKi from './modules/entMTki'
import entMTKg from './modules/entMTKg'
import entMk from "./modules/entMk"
import entPl from "./modules/entPl"
import entwicklungsbereiche from './modules/entwicklungsbereiche'
import entwicklungsmethoden from './modules/entwicklungsmethoden'
import entZiele from "./modules/entZiele"
import evaluation from "./modules/evaluation"
import notizen from './modules/notizen'
import SVz from './modules/SVz'
import zykn from "./modules/zykn"
import groupadmin from './modules/groupadmin'
import faq from "./modules/faq"
import documents from "./modules/documents"

Vue.use(Vuex)

Vue.config.devtools = true
const debug = process.env.NODE_ENV !== 'production'

const loadingWatcher = store => {
  const watchLoadingState = (moduleName) => {
    store.watch(
      state => state[moduleName].loading,
      (newVal) => {
         // Überspringen, wenn der neue Status 'null' ist
         if (newVal === null && moduleName === 'aur') {
          return;
        }

        const anyLoading = Object.keys(store.state).some(
          mod => store.state[mod].loading
        );
        store.commit('setLoadingIndi', anyLoading);
      }
    );
  };

  // Liste der Module, die beobachtet werden sollen
  const modulesToWatch = ['aur',  'entMk', 'entMTKi', 'entMTKg', 'entPl', 'entZiele', 'evaluation', 'notizen', 'SVz', 'zykn'];

  modulesToWatch.forEach(watchLoadingState);
};

export default new Vuex.Store({

strict: debug,
state: {
  apiStatus: {
    config: {
      myselfIsLoading: false,
      myselfIsLoaded: false,
      colGroupIsLoading: false,
      colGroupIsLoaded: false,
      colleaguesIsLoading: false,
      colleaguesIsLoaded: false,
      logIsLoading: false,
      logIsLoaded: false,
      admin_loading: false,
      admin_loaded: false,
      settingsIsLoading: false,
      settingsIsLoaded: false
    },
    svz: {
      loading: false,
      loaded: false
    },
    // Weitere Module können hier hinzugefügt werden
  },
},
getters : {
  gesamtStatus: (state, getters, rootState) => {
    return {
      config: {
        myselfIsLoading: rootState.config.myselfIsLoading,
        myselfIsLoaded: state.apiStatus.config.myselfIsLoaded,
        colGroupIsLoading: rootState.config.colGroupIsLoading,
        colGroupIsLoaded: state.apiStatus.config.colGroupIsLoaded,
        colleaguesIsLoading: rootState.config.colleaguesIsLoading,
        colleaguesIsLoaded: state.apiStatus.config.colleaguesIsLoaded,
        logIsLoading: rootState.config.logIsLoading,
        logIsLoaded: state.apiStatus.config.logIsLoaded,
        admin_loading: rootState.config.admin_loading,
        admin_loaded: state.apiStatus.config.admin_loaded,
        settingsIsLoading: rootState.config.settingsIsLoading,
        settingsIsLoaded: state.apiStatus.config.settingsIsLoaded,
      },
      svz: {
        loading: rootState.SVz.loading,
        loaded: state.apiStatus.svz.loaded,
      },
      // Weitere Module können hier hinzugefügt werden
    };
},
},
mutations: { 
  setApiStatus(state, { module, key, value }) {
  if (state.apiStatus[module]) {
    state.apiStatus[module][key] = value;
  }
  else {
    console.error(`Modul ${module} existiert nicht im apiStatus`);
  }
}, 
},
actions:{},
plugins: [loadingWatcher],
modules: {
  aur,
  config,
  entMTKi,
  entMTKg,
  entMk,
  entPl,
  entwicklungsbereiche,
  entwicklungsmethoden,
  entZiele,
  evaluation,
  notizen,
  zykn,
  groupadmin,
  SVz,
  faq,
  documents
}
})

