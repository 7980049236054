import store from '../store/index'

// interface for api connections, register all functions which will interact with api
export default {
  async loadEntPl() {
    const apiSocket = await getApiSocket();
    return apiSocket.loadEntPl?.();
  },
  async addEntPl(student) {
    const apiSocket = await getApiSocket();
    return apiSocket.addEntPl?.(student);
  },
  async loadSVz() {
    const apiSocket = await getApiSocket();
    return apiSocket.loadSVz?.();
  },
  async updateSVz(payload) {
    const apiSocket = await getApiSocket();
    return apiSocket.updateSVz?.(payload);
  },
  async getGroupObject() {
    const apiSocket = await getApiSocket();
    return apiSocket.getGroupObject?.();
  },
  async getColleagues() {
    const apiSocket = await getApiSocket();
    return apiSocket.GetColleagues?.();
  },
  async getMyself() {
    const apiSocket = await getApiSocket();
    return apiSocket.GetMyself?.();
  },
  async getGroup() {
    const apiSocket = await getApiSocket();
    return apiSocket.getGroup?.();
  },
  async getAdministrationObject() {
    const apiSocket = await getApiSocket();
    return apiSocket.getAdministrationObject?.();
  },
  async getGroupAdministration() {
    const apiSocket = await getApiSocket();
    return apiSocket.getGroupAdministration?.();
  },
  async getDiagnostikObject() {
    const apiSocket = await getApiSocket();
    return apiSocket.getDiagnostikObject?.();
  },
  async addDiaResult(ergebnis) {
    const apiSocket = await getApiSocket();
    return apiSocket.addDiaResult?.(ergebnis);
  },
  async getUserObject() {
    const apiSocket = await getApiSocket();
    return apiSocket.getUserObject?.();
  },
  async updateSettings(payload) {
    const apiSocket = await getApiSocket();
    return apiSocket.updateSettings?.(payload);
  },
  async loadZyklen(id) {
    const apiSocket = await getApiSocket();
    return apiSocket.loadZyklen?.(id);
  },
  async addZyklus(zyklus) {
    const apiSocket = await getApiSocket();
    return apiSocket.addZyklus?.(zyklus);
  },
  async deleteCycl(id) {
    const apiSocket = await getApiSocket();
    return apiSocket.deleteCycl?.(id);
  },
  async agreeDatenschutz(id) {
    const apiSocket = await getApiSocket();
    return apiSocket.agreeDatenschutz?.(id);
  },
  async changePiC(id) {
    const apiSocket = await getApiSocket();
    return apiSocket.changePiC?.(id);
  },
  async changeZyknPiC(id) {
    const apiSocket = await getApiSocket();
    return apiSocket.changeZyknPiC?.(id);
  },
  async deleteUser(id) {
    const apiSocket = await getApiSocket();
    return apiSocket.deleteUser?.(id);
  },
  async deleteUserSVz(id) {
    const apiSocket = await getApiSocket();
    return apiSocket.deleteUserSVz?.(id);
  },
  async saveZyklusEb(payload) {
    const apiSocket = await getApiSocket();
    return apiSocket.saveZyklusEb?.(payload);
  },
  async saveZyklusAbschluss(payload) {
    const apiSocket = await getApiSocket();
    return apiSocket.saveZyklusAbschluss?.(payload);
  },
  async saveZyklusEval(payload) {
    const apiSocket = await getApiSocket();
    return apiSocket.saveZyklusEval?.(payload);
  },
  async saveZyklusKlasse(payload) {
    const apiSocket = await getApiSocket();
    return apiSocket.saveZyklusKlasse?.(payload);
  },
  async saveZyklusStartDate(payload) {
    const apiSocket = await getApiSocket();
    return apiSocket.saveZyklusStartDate?.(payload);
  },
  async saveZyklusEndDate(payload) {
    const apiSocket = await getApiSocket();
    return apiSocket.saveZyklusEndDate?.(payload);
  },
  async loadEntZiele(zid) {
    const apiSocket = await getApiSocket();
    return apiSocket.loadEntZiele?.(zid);
  },
  async addEntZiel(goal) {
    const apiSocket = await getApiSocket();
    return apiSocket.addEntZiel?.(goal);
  },
  async removeEntZiel(payload) {
    const apiSocket = await getApiSocket();
    return apiSocket.removeEntZiel?.(payload);
  },
  async updateEntZiel(payload) {
    const apiSocket = await getApiSocket();
    return apiSocket.updateEntZiel?.(payload);
  },
  async saveEntZielReuse(payload) {
    const apiSocket = await getApiSocket();
    return apiSocket.saveEntZielReuse?.(payload);
  },
  async saveZielEvaluation(item) {
    const apiSocket = await getApiSocket();
    return apiSocket.saveZielEvaluation?.(item);
  },
  async setEntZielEBereiche(payload) {
    const apiSocket = await getApiSocket();
    return apiSocket.setEntZielEBereiche?.(payload);
  },
  async loadEntMTki() {
    const apiSocket = await getApiSocket();
    return apiSocket.loadEntMTki?.();
  },
  async addEntMki(measure) {
    const apiSocket = await getApiSocket();
    return apiSocket.addEntMki?.(measure);
  },
  async loadEntMTkg() {
    const apiSocket = await getApiSocket();
    return apiSocket.loadEntMTkg?.();
  },
  async addEntMTkg(measureTg) {
    const apiSocket = await getApiSocket();
    return apiSocket.addEntMTkg?.(measureTg);
  },
  async loadEntMk() {
    const apiSocket = await getApiSocket();
    return apiSocket.loadEntMk?.();
  },
  async addEntMTki(measureTi) {
    const apiSocket = await getApiSocket();
    return apiSocket.addEntMTki?.(measureTi);
  },
  async updateEntMTki(payload) {
    const apiSocket = await getApiSocket();
    return apiSocket.updateEntMTki?.(payload);
  },
  async updateEntMTkg(payload) {
    const apiSocket = await getApiSocket();
    return apiSocket.updateEntMTkg?.(payload);
  },
  async removeEntMki(payload) {
    const apiSocket = await getApiSocket();
    return apiSocket.removeEntMki?.(payload);
  },
  async updateEntMki(payload) {
    const apiSocket = await getApiSocket();
    return apiSocket.updateEntMki?.(payload);
  },
  async saveEntMkiReuse(payload) {
    const apiSocket = await getApiSocket();
    return apiSocket.saveEntMkiReuse?.(payload);
  },
  async saveMkiEvaluation(item) {
    const apiSocket = await getApiSocket();
    return apiSocket.saveMkiEvaluation?.(item);
  },
  async setEntMkiRefZie(payload) {
    const apiSocket = await getApiSocket();
    return apiSocket.setEntMkiRefZie?.(payload);
  },
  async loadAurList(studentId) {
    const apiSocket = await getApiSocket();
    return apiSocket.loadAurList?.(studentId);
  },
  async addAur(aur) {
    const apiSocket = await getApiSocket();
    return apiSocket.addAur?.(aur);
  },
  async saveAur(aur) {
    const apiSocket = await getApiSocket();
    return apiSocket.saveAur?.(aur);
  },
  async loadNotes(studentId) {
    const apiSocket = await getApiSocket();
    return apiSocket.loadNotes?.(studentId);
  },
  async addNote(note) {
    const apiSocket = await getApiSocket();
    return apiSocket.addNote?.(note);
  },
  async removeNote(payload) {
    const apiSocket = await getApiSocket();
    return apiSocket.removeNote?.(payload);
  },
  async updateNote(payload) {
    const apiSocket = await getApiSocket();
    return apiSocket.updateNote?.(payload);
  },
  async createLog() {
    const apiSocket = await getApiSocket();
    return apiSocket.createLog?.();
  },
  async loadLogData() {
    const apiSocket = await getApiSocket();
    return apiSocket.loadLogData?.();
  },
  async loadDocuments(payload) {
    const apiSocket = await getApiSocket();
    return apiSocket.loadDocuments?.(payload);
  },
  async updateZyklusVerantwortliche(payload) {
    const apiSocket = await getApiSocket();
    return apiSocket.updateZyklusVerantwortliche?.(payload);
  },
  async updateEntplVerantwortliche(payload) {
    const apiSocket = await getApiSocket();
    return apiSocket.updateEntplVerantwortliche?.(payload);
  }
}

async function getApiSocket() {
  let api = store.getters.api;
  // Warten, bis api gesetzt ist
  while (api === null) {
    await new Promise(resolve => setTimeout(resolve, 100)); // Kurze Verzögerung
    api = store.getters.api; // Erneutes Abrufen des api-Wertes
  }
  console.log("API value in getApiSocket:", api); // Debugging-Ausgabe

  switch(api) {
    case "sharepoint":
      return (await import('./sharepoint.js')).default;
    case "localStorage":
      return (await import('./localStorage.js')).default;
    case "pepApi":
      return (await import('./pepApi.js')).default;
    default:
      return (await import('./local.js')).default;
  }
}

