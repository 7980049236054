import store from "../index"
import { entgendern } from "../../variables.js"

const state = {
  methods: null,
  loading: false,
};
const actions = {
  loadMethods({ commit }) {
    commit('setMethodsLoading', true)
    let methods = [
      {
        Id: 1,
        Title: "Werkstattunterricht",
        Ziel: "Selbstständigkeit",
        Beschreibung_x0020__x0028_kurz_x: "Form des offenen Unterrichts"
      },
      {
        Id: 2,
        Title: "Stationenlernen",
        Ziel: "Sichtbarmachung der vielfältigen Lernzugänge",
        Beschreibung_x0020__x0028_kurz_x: "m Klassenraum werden verschieden Stationen zu einem Thema vorbereitet. Lernmaterialien, Anweisungen und Aufgaben unterscheiden sich auch in den Schwierigkeitsgraden. Lernziele können je nach Station unterschiedlich angelegt sein (Datensammlung, Analyse, Problemlösung etc.). "
      },
      {
        Id: 3,
        Title: "Lerntagebuch",
        Ziel: "Unterstützung im Reflexionsprozess, in der Selbstoptimierung, als Erinnerungshilfe",
        Beschreibung_x0020__x0028_kurz_x: "Unter Berücksichtung von Leit- und Reflexionsfragen werden  kursbegleitend Tagbucheinträge zum Lernprozess verfasst."
      },
      {
        Id: 4,
        Title: "Wochenplan",
        Ziel: "Öffnung des Unterrichts, Alternative Frontalunterricht, Förderung Selbsständigkeit und sozialer Kompetenzen",
        Beschreibung_x0020__x0028_kurz_x: "Aufgabenblatt für einen bestimmten Zeitraum wird erstellt (Woche oder Monat). Entsprechend der Fächerübersicht, werden Lerninhalte gennant, welche in dafür vorgesehenen Unterrichtsstunden bearbeitet werden können. Die Zugänge gestalten sich unterschiedlich, da sie in den Sozialformen variieren"
      },
      {
        Id: 5,
        Title: "Projektarbeit",
        Ziel: "Die eigenständige Entwicklung, Bearbeitung und Präsentation eines Projektes durch die Lernenden.",
        Beschreibung_x0020__x0028_kurz_x: "Bearbeitung und Diskussion komplexer Fragestellung mit Rückgriff auf bereits vorhandenem Fachwissen in Kleingruppen."
      },
      {
        Id: 6,
        Title: "Förderkurse",
        Ziel: "Individuelle Förderung mehrerer " +entgendern.pupils  +" mit besonderen Lernanforderungen",
        Beschreibung_x0020__x0028_kurz_x: "Eine gesonderte Form des Unterrichts um Lern- und Kompetenzunterschiede auszugleichen."
      },
      {
        Id: 7,
        Title: "" +entgendern.pupils  +"austausch",
        Ziel: "Erwerb interkultureller Kompetenzen und Erweiterung der fremdsprachlichen Fähigkeiten",
        Beschreibung_x0020__x0028_kurz_x: "In Form eines  " +entgendern.pupils  +"austausch-Programms können die " +entgendern.pupils +" verschiedene Kompetenzen erwerben und ihre Fähigkeiten im Fremdsprachenbereich ausbauen."
      },
      {
        Id: 8,
        Title: "Frühstudium (auch Juniorstudium,  " +entgendern.pupil  +" studium)",
        Ziel: "Frühzeitige Orientierung innerhalb der Hochschullandschaft, Vertiefung innerhalb ausgewählten Themengebiet, Hochschulleben wird kennengelernt und die reguläre Studienzeit kann verkürzt werden.",
        Beschreibung_x0020__x0028_kurz_x: "Während der Schulzeit erhalten besonders begabte und interessierte " +entgendern.pupils +" den Zugang zu regulären Hochschulveranstaltungen, wie Seminaren und Vorlesungen. Hier werden bereits entsprechende Prüfungen abgelegt, welche in einem späteren Studium angerechnet werden können."
      },
      {
        Id: 9,
        Title: entgendern.pupil  +" Uni (auch KinderUni)",
        Ziel: "Erster Kontakt mit dem Hochschulleben und dem Feld der Wissenschaft, Förderung von Neugier",
        Beschreibung_x0020__x0028_kurz_x: "An teilnehmenden Hochschulen erhalten Kinder im Alter von 8-12 Jahren die Möglichkeit an für sie angepasste Vorlesungen teilzunehmen und sie in ihrem Juniorstudentenausweis abzustempeln."
      },
      {
        Id: 10,
        Title: entgendern.pupil  +"akademien",
        Ziel: "Persönlichkeitsförderung, fachliche Weiterentwicklung, Austausch mit  Gleichgesinnten",
        Beschreibung_x0020__x0028_kurz_x: entgendern.pupil  +"akademien sind fester Bestandteil der Begabtenförderung, richten sich an besonders begabte, interessierte und leistungsbereite " +entgendern.pupils +" und ermöglichen nach einem zweistufigen Bewerbungsverfahren eine intensive Auseinandersetzung mit spezifischen Fachwissen und den Austausch mit Gleichgesinnten."
      },
      {
        Id: 11,
        Title: "Arbeitsgemeinschaften",
        Ziel: "Verbindung von " +entgendern.pupils +" durch persönliche Interessen und Neigungen in der Weiterentwicklung persönlicher Kompetenzen und/oder der Gestaltung eines Endprodukts.",
        Beschreibung_x0020__x0028_kurz_x: "Förderung und Entwicklung überfachlicher Kompetenzen im Rahmen außerunterrichtlicher Angebote"
      },
      {
        Id: 12,
        Title: "Wettbewerbe",
        Ziel: "Förderung von Selbstständigkeit, Problemlösekompetenz, Sachkompetenz, Sozialkompetenz und Stresstoleranz",
        Beschreibung_x0020__x0028_kurz_x: "Die Teilnahme an Wettbewerben und Olympiaden ermöglicht auch außerhalb des schulischen Rahmens eine Steigerung des Leistungsniveaus. Der Austausch mit Gleichgesinnten, das gemeinsame Erarbeiten von Problemlösungen und die damit einhergehende Kompetenzentwicklung, stellt für die Lernenden eine Herausforderung dar."
      },
      {
        Id: 13,
        Title: "Drehtürmodell",
        Ziel: "parallel zum regulären Unterricht werden den " +entgendern.pupils +" neue Bildungsräume eröffnent und individuelle Auseinandersetzungen mit bestimmten Themenfedern ermöglicht",
        Beschreibung_x0020__x0028_kurz_x: "Die " +entgendern.pupil +" arbeitet über längeren Zeitraum an einem mit der Lehrkraft vereinbarten Projekt/Thema. Die Lehrkraft unterstützt bei der Entdeckung von Interessen, Zielformulierungen und der  Arbeit mit dem Projektplan. Die \"Rückkehr\" erfolgt in Form von Plakaten, Referaten oder Blitzberichten."
      },
      {
        Id: 14,
        Title: "Enrichment",
        Ziel: "Beachtung der Bedürfnisse, welche im Klassenunterricht nicht erfüllt werden können. Selbstwirksamkeit",
        Beschreibung_x0020__x0028_kurz_x: "Enrichment dient der Ergänzung und Anreicherung des regulären Unterrichts. Angebote, wie zusätzliche oder vertiefende Lerninhalte, interdisziplinäre Angebote oder zusätzliche Wissensanwednung, werden den hochbegabten " +entgendern.pupils  +" einzeln oder in Gruppen zur Verfügung gestellt."
      },
      {
        Id: 15,
        Title: "Akzeleration",
        Ziel: entgendern.pupils +" werden in ihren Stärken gefördert: Entwicklungsvorsprung und Lerngeschwindigkeit",
        Beschreibung_x0020__x0028_kurz_x: "Die strukturellen Bedingungen werden an den besonders begabten " +entgendern.pupils +" angepasst um dem Entwicklungsvorsprung und der höheren Lerngeschwindigkeit gerecht zu werden. Das Überspringen von Klassenstufen geht mit umfangreichen Chancen und Risiken einher und bedarf einer entsprechenden Diagnostik."
      },
      {
        Id: 16,
        Title: "Beobachtung",
        Ziel: "Diagnostik individueller Lernformen und damit verbundener Lernprozesse",
        Beschreibung_x0020__x0028_kurz_x: "Das \"typisch hochbegabte\" Kind gibt es nicht, daher sind umangreiche mehrperspektivische Beobachtungen notwendig."
      },
      {
        Id: 17,
        Title: "Portfolio",
        Ziel: "Sichtbarmachung von Lernfortschritten, Begabungen und Stärken, Reflexion des Gelernten und der Lernbiographie; Unterstützung in der Eigenverantwortung, Selbststeuerung und Selbsbeurteilung. Das Portfolio kann auch der Lesitungsfeststellung und -beurteilung dienen.",
        Beschreibung_x0020__x0028_kurz_x: "Der Fokus liegt auf der ganzheitlichen Betrachtung der " +entgendern.pupils +" , welche je nach Alter mehr oder weniger umfangreich gestaltet werden kann. Ein Kategoriensystem (Stärken, Interessen, Neigungen, ausgewählte Produkte, schulische und außerschulische Aktivitäten) unterstützt die Übersichtlichkeit. Die " +entgendern.pupils +" werden in ihrer Sammlung von Lehrkräften unterstützt und gelenkt."
      },
      {
        Id: 18,
        Title: "Leistungstests",
        Ziel: "Erfassung individueller Leistungsfähigkeit",
        Beschreibung_x0020__x0028_kurz_x: "Motorische, sensorische oder intellektuelle Leistungen können in Allgemeinen oder spezifischen Leistungstests erfasst werden."
      },
      {
        Id: 19,
        Title: "Lernvereinbarung",
        Ziel: "Mehr Eigenverantwortung und Mitspracherecht für die Lernenden im Lernprozess kann Wertschätzung stiften und das Bewusstsein für eigenverantwortliches Lernen und die damit verbundene intrinsische Motivation erhöhen.",
        Beschreibung_x0020__x0028_kurz_x: "Individuelle Lernverträge: In freien Arbeitsphasen können Betreuung, Arbeitszeiten, Inhalte, Lernziele, Arbeitsprodukte und Lerntransfer festgehalten werden. Lernverträge mit Klasse/Contracting: Am Schuljahresanfang können Unterrichtsgegenstände beschlossen werden. Elemente des Unterrichts und Rahmenbedinungen können den Orientierungsrahmen für den Vertrag geben."
      },
      {
        Id: 20,
        Title: entgendern.pupil  +"gespräche (auch offenes/freies Unterrichtsgespräch)",
        Ziel: "Eigenintiative der " +entgendern.pupils +", Weiterentwicklung sprachlicher Fähigkeiten, Förderung von aktivem Zuhören, Entwicklung einer Fragehaltung, Begründung der eigenen Meinung ",
        Beschreibung_x0020__x0028_kurz_x: "Durch die " +entgendern.pupil +" geleitetes Interessengespräch: Fragen und eigene Themen können somit in den Unterricht getragen werden. Notwendig: Moderationsfähigkeiten der Lehrkraft, gute Gesprächskultur (u.a. in Form von Kommunikationsregeln)"
      },
      {
        Id: 21,
        Title: "Elterngespräche",
        Ziel: "Information, Austausch, Beratung, Mitarbeit, Beteiligung, Mitverantwortung, Vernetzung mit den Erziehungsberechtigten",
        Beschreibung_x0020__x0028_kurz_x: "Terminlich vereinbarte Gespräche zwischen Lehrkräften und den Erziehungsberechtigten ermöglichen einen erweiterten Blick auf das Kind und dessen schulischer und außerschulischer Interessenlage, Begabungen und Fördermöglichkeiten"
      },
      {
        Id: 22,
        Title: "Lehrergespräche (auch Lehrerkooperation)",
        Ziel: "Informationen zum individuellen Lern- und Leistungsstand von " +entgendern.pupils,
        Beschreibung_x0020__x0028_kurz_x: "Im kooperativen Austausch mit Lehrkräften aus dem Kollegium kann eine weitere Perspektive auf die " +entgendern.pupil  +" erarbeitet werden."
      },
      {
        Id: 23,
        Title: "Formen der Selbstkontrolle",
        Ziel: "Verzicht auf Lehrerkontrolle. Selbsterkennen korrekter bzw. fehlerhafter Antworten",
        Beschreibung_x0020__x0028_kurz_x: "Selbstkontrolle durch eigene Fehlerüberprüfung: Probeaufgaben, Austausch, Wörterbuch"
      },
      {
        Id: 24,
        Title: "psych. Gutachten",
        Ziel: "Kann ein Teil der diagnostischen Statuserfassung der " +entgendern.pupil +" darstellen (u.a.Intelligenztests)",
        Beschreibung_x0020__x0028_kurz_x: "Achtung: Das diagnostische Gutachten wird von einer psychologischen Fachkraft erstellt."
      },
      {
        Id: 25,
        Title: "soz.päd. Beurteilung",
        Ziel: "Realistische und realisierbare Zielsetzung von Hilfeentscheidungen, Fokus liegt auf Aktivierung und Wahrnehmung von Stärken und Chancen, Partizipation aller Beteiligten",
        Beschreibung_x0020__x0028_kurz_x: "Inhaltliche Bestandteile der sozialpädagogischen Beurteilung: Problemlagen, Interessen und Stärken der " +entgendern.pupil  +", " +entgendern.possessiv_akkusativ_pl  +" Familie, Beziehungsstrukturen und -dynamiken, soziales Umfeld (Schule, Freunde, Bezugspersonen)."
      }]

    commit('setMethods', methods)
    commit('setMethodsLoading', false)
  }
}

const getters = {
  entplMethods: state => {
    
    // fetch data if no other data is available or there is no other fetching process.
    if(state.methods === null && !state.loading) {
      store.dispatch("loadMethods")
    }
    return state.methods
  }
}

const mutations = {
  setMethods: (state, methods) => {
    state.methods = methods
  },
  setMethodsLoading: (state, loading) => {
    state.loading = loading
  },
}

export default {
  state,
  mutations,
  getters,
  actions
}
