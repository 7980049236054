//  EP.vue

<template>
    <div>
    <div id="kopfkoerper" >
      <transition name="kommup-in" class="ep" :duration="1000" appear>
        <HeaderLeiste>
          <template v-slot:ueberschrift>
            <h1 slot="ueberschrift">Übersicht der Entwicklungspläne</h1>
          </template>
        </HeaderLeiste>
      </transition>

      <UebersichtSeite :showHide="this.showHide" :hideAdmin="this.hideAdmin" :showChiffre="this.showChiffre">
        <template v-slot:absatzslot>
          <span id="absatz" style="absatzstyle">
            Willkommen auf der WebApp für personalisierte Entwicklungsplanung! Auf dieser Seite finden Sie alle
            Entwicklungspläne Ihrer Schule. Sie können mit einem Klick auf einen
            Plan, diesen öffnen und bearbeiten, insofern Sie von
            {{ $entgendern.article_dativ }} Verantwortlichen berechtigt wurden.
            Klicken Sie auf "Hinzufügen“, um einen Plan für eine neue
            {{ $entgendern.pupil }} anzulegen.
          </span>
        </template>

        <template v-slot:CS>
          <div slot="CS" class="slide-up-fade-in">
            <CardCollection
              :kaertchen="searchPlaene"
              :epIsLoading="isLoading"
              :CardView="view"
              :types="types"
            />
            <EPTable
              :plaene="searchPlaene"
              :isLoading="isLoading"
              :view="view"
            />
          </div>
          <!-- <div
            v-if="!isLoading && !searchPlaene.length"
            class="ep-options-container"
          >
            <div
              v-if="api === 'localStorage'"
              class="ep-options-card"
              @click.stop.prevent="importLocalData"
            >
              Lade Testdaten
            </div>
            <div class="ep-options-card" @click.stop.prevent="createNewStudent">
              Lege neue {{ $entgendern.pupil }} an
            </div>
          </div> -->
        </template>
      </UebersichtSeite>
      <PEPFooter />
    </div>
  </div>
</template>
<script>
import UebersichtSeite from "./UebersichtSeite";
import HeaderLeiste from "./layout/AppHeader";
import CardCollection from "../components/layout/Cards/CardCollection";
import PEPFooter from "./layout/AppFooter";
import EPTable from "./EPTable";


export default {
  name: "EP",
  data() {
    return {
      title: "Übersicht der Entwicklungspläne",
      types: {
        isEP: true,
      },
      CardView: true,
      hideAdmin: false,
    };
  },
  components: {
    CardCollection,
    UebersichtSeite,
    HeaderLeiste,
    EPTable,

    PEPFooter,
  },
  created() {

    this.$on("EPClick", function ({ id, cc }) {
  let pid = id;
  let colorClass = cc;
  console.log("EPClick event triggered");
  console.log("Received id:", pid);
  console.log("Received colorClass:", colorClass);

  if (id) {
    this.scrollToTop();
    console.log("Setting colorClass in Vuex store");
    this.$store.commit('setColorClass', colorClass);
    console.log("colorClass set in Vuex store:", this.$store.state.colorClass);

    console.log("Navigating to Profil route");
    this.$router.push({
      name: "Profil",
      params: {
        sid: pid,
        colorClass: colorClass,
      }
    }).then(() => {
      console.log("Navigation to Profil route successful");
    }).catch(err => {
      console.log("Error navigating to Profil route:", err);
    });

  } else {
    console.log("error: missing id for EP");
  }
});


      // this.hideAdmin = true,
      this.$on("showhide", function () {
        if (this.hideAdmin) {
          this.hideAdmin = !this.hideAdmin;
        } else this.hideAdmin = true;
      });


  },
  watch: {
    '$store.getters.entPl': {
  immediate: true,
  deep: true,
  handler(newVal, oldVal) {
    if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
      this.$store.dispatch('updateEntPl', newVal);
    }
  }
  },
    searchPlanQuery: function (newVal, oldVal) {
      if (newVal != oldVal) {
        this.setQuery();
      }
    },
  },
  computed: {
        showHide() {
          if( this.$store.getters.api === "pepApi") {
            return false
          }
      let as = this.$store.getters.adminsettings;
      return Array.isArray(as) && as.length && as.includes("EP2");
    },
    showChiffre() {    if( this.$store.getters.api === "pepApi") {
            return false
          }
          else {
      let as = this.$store.getters.adminsettings;

      if( this.$store.getters.api === "pepApi" && this.$store.getters.hasSetting("DS1")) {
        return true;
      }
      else {
        return Array.isArray(as) && as.length && as.includes("DS1");
      }
    }
    },
 
    plaene() {
 //      let api = this.$store.getters.api;
   //    if(api === "localStorage" || api === "local" || api === "sharepoint") {
 //       this.$store.commit("setEntPl", this.$store.getters.entPl)
   //   }
      
      if (!this.hideAdmin) {
        return this.$store.getters.entPl;
      } else if (this.$store.getters.nonadminentPl != null) {
        return this.$store.getters.nonadminentPl;
      } else return this.$store.getters.entPl;
    },
    plaene2() {
      return this.showHide ? this.$store.getters.entPl : this.$store.getters.nonadminentPl;
    },
    sortedPlaene() {
      let plaene = this.plaene;
      let order = this.order;
      let query = this.query;
      // preserve data state, return sorted copy of entPl if order is set
      if (plaene !== null && order !== null) {
        if (order === "dateAsc")
          return [...plaene].sort(
            (a, b) => new Date(a.Modified) - new Date(b.Modified)
          );
        if (order === "dateDesc")
          return [...plaene].sort(
            (a, b) => new Date(b.Modified) - new Date(a.Modified)
          );
        if (order === "alphaPupilAsc")
          return [...plaene].sort((a, b) =>
            a.Title.toUpperCase().localeCompare(b.Title.toUpperCase())
          );
        if (order === "alphaPupilDesc")
          return [...plaene].sort((a, b) =>
            b.Title.toUpperCase().localeCompare(a.Title.toUpperCase())
          );
        if (order === "alphaAuthorAsc" && this.$store.getters.api === "pepApi")
          return [...plaene].sort((a, b) =>
            a.Author["Title"].toUpperCase().localeCompare(
              b.Author["Title"].toUpperCase()
            )
          );
        if (order === "alphaAuthorAsc")
          return [...plaene].sort((a, b) =>
            a.Verantwortlich.toUpperCase().localeCompare(
              b.Verantwortlich.toUpperCase()
            )
          );
        if (order === "alphaAuthorDesc" && this.$store.getters.api === "pepApi")
          return [...plaene].sort((a, b) =>
            b.Author["Title"].toUpperCase().localeCompare(
              a.Author["Title"].toUpperCase()
            )
          );
        if (order === "alphaAuthorDesc")
          return [...plaene].sort((a, b) =>
            b.Verantwortlich.toUpperCase().localeCompare(
              a.Verantwortlich.toUpperCase()
            )
          );
        if (order === "alphaClassAsc")
          return [...plaene]
            .filter((a) => a.RefS["Klasse"] != null)
            .sort((a, b) => a.RefS["Klasse"].localeCompare(b.RefS["Klasse"]))
            .concat([...plaene].filter((a) => a.RefS["Klasse"] === null));
        if (order === "alphaClassDesc")
          return [...plaene]
            .filter((a) => a.RefS["Klasse"] != null)
            .sort((a, b) => b.RefS["Klasse"].localeCompare(a.RefS["Klasse"]))
            .concat([...plaene].filter((a) => a.RefS["Klasse"] === null));
        else return plaene;
      }
      if (query && Array.isArray(plaene) && plaene.length) {
        return plaene.filter((item) => {
          return query
            .toLowerCase()
            .split(" ")
            .every((v) => item.Title.toLowerCase().includes(v));
        });
      } else return plaene;
    },
    lockedPlaene() {
      let plaene = this.sortedPlaene
      if(plaene) {
        let user = this.$store.getters.userTitle
        plaene.forEach(plan => {
          if (plan && plan.PiC && Array.isArray(plan.PiC) && plan.PiC.length) {
            for (let pic of plan.PiC) {
              if(pic.Title === user) plan.unlocked = true
            }
          }
          else if (plan && plan.Author && plan.Author.Title === user) plan.unlocked = true
          else plan.unlocked = false
        })
      }
      return plaene
    },
    searchPlaene() {
      let plaene
      if( this.$store.getters.api === "pepApi") {
        if(this.$store.getters.hasSetting("EP1")) plaene = this.lockedPlaene;
        else plaene = this.sortedPlaene;
      }
      else {
        plaene = this.sortedPlaene;
      }
      let query = this.query;
      if (query === "") return plaene;
      else if (query && Array.isArray(plaene) && plaene.length && query != "") {
        return plaene.filter((item) => {
          return query
            .toLowerCase()
            .split(" ")
            .every(
              (v) =>
                item["RefS"].Title.toLowerCase().includes(v) ||
                item["RefS"].FirstName.toLowerCase().includes(v)
            );
        });
      } else return plaene;
    },
    order() {
      return this.$store.getters.entPlOrder;
    },
    view() {
      return this.$store.getters.entPlView;
    },
    query() {
      return this.$store.getters.entPlQuery;
    },
    isLoading() {
      
      return this.$store.getters.entPlLoading;
    },
    api() {
      return this.$store.getters.api;
    },
  },

  methods: {
        scrollToTop() {
      // window does not work in sharepoint
      if (typeof _spPageContextInfo !== "undefined") {
        document.getElementById("s4-workspace").scrollTo(0, 0);
      } else {
        window.scrollTo(0, 0);
      }
    },

    importLocalData() {
      if (this.api === "localStorage") this.$store.dispatch("importLocalData");
    },
    createNewStudent() {
      this.$root.$emit("createNewStudent");
    },
    getPiCfullNames(eps) {
      if (eps && Array.isArray(eps) && eps.length) {
        let groups = this.$store.getters.groups;

        if (groups) {
          let g = groups.userColleaguesFull;
          let plaene = [];
          for (let item of eps) {
            let PiCfull = g.filter((i) => item.PiCId.results.includes(i.Id));
            let PiCmapped = PiCfull.map((t) => t.Title);
            let plan = {
              Title: item.Title,
              FirstName: item.RefS.FirstName,
              Verantwortlich: PiCmapped.toString(),
              Author: item.Author,
              PiCId: item.PiCId,
              RefS: {
                FirstName: item.RefS.FirstName,
                Title: item.RefS.Title,
              },
              DSchutzE: item.DSchutzE,
              Id: item.Id,
              Modified: item.Modified,
              RefSId: item.RefSId,
              g: PiCmapped,
            };
            plaene.push(plan);
          }
          return plaene;
        } else {
          return null;
        }
      }
    },
  },
};
</script>
<style scoped>
:deep(.fv-light-CommandBar) {
  height: 46px;
  display: grid;
grid-auto-flow: column;
place-content: stretch;
grid-auto-columns: max-content auto;
}
:deep(.item-content > .name) {
  font-size: 16px !important
}
</style>
<style>
.kommup-enter-active,
.kommup-leave-active {
  opacity: 1;
}

.kommup-enter-from,
.kommup-leave-to {
  opacity: 0;
}
</style>
<style scoped>
#kopfkoerper {
  z-index: 0;
  margin-left: 50px;
  transition: 0.3s padding-left;
  /* background-color: rgba(255,255,255,.7); */
  opacity: 1;
  min-height: 100vh;
  width: calc(100vw - 52px - (100vw - 100%));
  overflow-y: hidden;
  display: flex;
  flex-wrap: wrap;
  top: 0;
  transition: width 0.3s;
  position: absolute;
  /* padding-left: 33px;
    padding-right: 33px; */
}

#kopfkoerper.collapsed {
  width: calc(100vw - 52px - (100vw - 100%));
  margin-left: 50px;
  transition: 0.3s width;
  transition: 0.3s margin-left;
}

.container {
  /* max-width: 900px; */
  width: 100%;
}

h1 {
  font-family: "AileronThin", sans-serif !important;
  /* font-size: 4.3em !important; */
  font-weight: 100 !important;
  margin-bottom: 0;
}

#absatz {
  width: 80%;
  text-align: justify !important;
  font-family: "aileronregular", sans-serif;
  /* font-size: 120%; */
}

.fv-light-CommandBar
  .left-command-bar-container
  .command-bar-item
  .s1-container {
  font-size: 16.5px !important;
}

.s1-container {
  font-size: 16.5px !important;
}

.EntwicklungsplanCard {
  cursor: pointer;
}

.ep-options-container {
  display: flex;
  flex-wrap: wrap;
  row-gap: 1rem;
  column-gap: 2.5rem;
}
.ep-options-card {
  width: 300px;
  min-height: 4.5em;
  background-color: white;
  margin: 5px;
  padding: 0.8em;
  padding-left: 30px;
  border-radius: 20px;
  line-height: 1em;
  font-family: aileronsemibold;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  display: grid;
  align-content: center;
}
.ep-options-card:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.1);
}
</style>
