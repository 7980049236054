<style scoped>

.zyklus-aur-container {
  width: 100%;
  margin-bottom: 50px;
  cursor: default;
}

.zyklus-aur-title {
  margin: 24px 0 24px 0;
  font-size: 120%;
}

.zyklus-aur-loading {
  opacity: 0.3;
  margin-top: 35px;
}

.separator {
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 30, 0.36);
  margin-top: -1px;
}

.zyklus-aur-textarea-title {
  margin: 20px 0px 2px 0px;
  font-family: "aileronsemibold", sans-serif;
  display: flex;
  align-items: center;
  font-size: 120%;
}

.zyklus-aur-textarea-title-icon {
  margin-right: 10px;
  margin-left: 5px;
  color: #b5b5b5;
}

.tip {
  display: grid;
}
.tip:hover {
    cursor: help;
    position: relative
}
.tip span {
    display: none
}
.tip:hover span {
    display: block;
    position: absolute;
    font-size: calc( 14px + (22 - 16) * ((100vw - 300px) / (1900 - 300)) ) !important;
    font-family: aileronthin;
    padding: 15px;
    margin-left: 5px;
    margin-top: 25px;
    z-index: 100;
    width: 50vw;
    background-color: white;
    border: 1px solid #a1a0ab;
    text-decoration: none;
}

.zyklus-aur-textarea-container {
  max-width: inherit;
  margin-top: 5px;
  margin-right: 25px;
  display: grid;
  grid-template-columns: auto;
}

.zyklus-aur-textarea {
  resize: vertical;
border: 1px #a1a0ab solid;
font-size: calc( 16px + (22 - 16) * ((100vh - 300px) / (1900 - 300)) ) !important;
font-family: aileronregular !important;
padding: 20px;
transition: background-color .3s ease .3s, min-height .3s ease, border-radius .3s ease;
max-height: max-content;
border-radius: 20px;
background-color: #ffffffb2;
min-height: calc(7vh + 20px);
}

.zyklus-aur-button {
  margin: 30px 0px 50px 5px;
  padding: 0 20px 0 20px;
  height: 48px;
  line-height: 48px;
  font-size: calc( 16px + (22 - 16) * ((100vh - 300px) / (1900 - 300)) ) !important;
  text-align: center;
  text-align: -webkit-center;
   text-align: -moz-center;
  font-family: aileronregular, sans-sarif;
  outline: none;
}

::v-deep  .pivot-container .pivot-item p {
  font-size: calc(18px + 6*(100vw - 300px)/1600) !important;
}

/* aur options */
.zyklus-aur-options-container {
  display: flex;
  flex-wrap: wrap;
  row-gap: 1rem;
  column-gap: 2.5rem;
}
.zyklus-aur-options-card {
  width: 300px;
  min-height: 4.5em;
  background-color: white;
  margin: 5px;
  padding: 0.8em;
  padding-left: 30px;
  border-radius: 20px;
  line-height: 1em;
  font-family: aileronsemibold;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  display: grid;
  align-content: center;
}
.zyklus-aur-options-card:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.1);
}
@media only screen and (min-width: 1200px ) {
  /* on a medium screen */
  .zyklus-aur-options-card {
    width: 350px;
    min-height: 130px;
  }
}
.fv-light-Pivot .pivot-container .pivot-item {
  padding: 16px
}

</style>

<template>
  <div class="zyklus-aur-container">
    <h2 class="zyklus-aur-title">Entwicklungsressourcen</h2>
    <!-- loading data from sharepoint -->
    <div v-if="isLoading" class="zyklus-aur-loading">
      Lade Daten ...
    </div>
    <!-- data loaded -->
    <div v-else>
      <!-- case 1: there is already data, which will be showed -->
      <div v-if="(aurF && (Array.isArray(aurF) && aurF.length)) && !showFormImmediately">
        <fv-Pivot :items="items" v-on:change="handleClick" />
        <div class="separator" />
        <div v-for="item in aurF" :key="item.id">
          <div v-if="currentTab === item.tab">
            <div class="zyklus-aur-textarea-title" >
              {{item.text}}
              <helper-icon-callout
                :Title="'Info'"
                :Main="item.tooltip"
            ></helper-icon-callout>
            </div>
            <div class="zyklus-aur-textarea-container">
              <textarea :value="item.value" @input="setTextareaValue(item.id, $event.target.value)"  class="zyklus-aur-textarea" />
            </div>
          </div>
        </div>

        <!-- save and delete buttons -->
        <div>
          <button class="zyklus-aur-button" @click.stop.prevent="triggerSaveAur">Änderungen speichern</button>
          <button class="zyklus-aur-button" @click.stop.prevent="triggerDeleteAurF">löschen</button>
        </div>

      </div>
      <div v-else-if="(!aurF && !(Array.isArray(aurF) && !aurF.length)) && showFormImmediately">
        <fv-Pivot :items="items" v-on:change="handleClick" />
        <div class="separator" />
        <div v-for="item in basicaurF" :key="item.id">
          <div v-if="currentTab === item.tab">
            <div class="zyklus-aur-textarea-title" >
              {{item.text}}
               <helper-icon-callout
                :Title="'Info'"
                :Main="item.tooltip"
            ></helper-icon-callout>
            </div>
            <div class="zyklus-aur-textarea-container">
              <textarea :value="item.value" @input="setTextareaValue(item.id, $event.target.value)"  class="zyklus-aur-textarea" />
            </div>
          </div>
        </div>

        <!-- save and delete buttons -->
        <div>
          <button class="zyklus-aur-button" @click.stop.prevent="triggerSaveAur">Änderungen speichern</button>
          <button class="zyklus-aur-button" @click.stop.prevent="triggerDeleteAurF">löschen</button>
        </div>

      </div>
      <!-- case 2: there exists no data, show options to import data -->
      <div v-else class="zyklus-aur-options-container">
        <div class="separator" />
        <!-- a) import data from old pep -->
        <div v-if="!aurF && stratusFormsData" class="zyklus-aur-options-card" @click.stop.prevent="showModal">
          Importiere aus früheren Angaben
        </div>
        <!-- b) import data from last zyklus -->
        <div v-if="!aurF && hasLastAurF" class="zyklus-aur-options-card"  @click.stop.prevent="createLastZyklusAur">
          Übernahme aus letztem Zyklus
        </div>
        <!-- c) create your own questions -->
        <div v-if="!aurF" class="zyklus-aur-options-card" @click.stop.prevent="createNewAur">
          Erstelle neue Entwicklungsressourcen
        </div>
      </div>
      <!-- component to import stratusFormsData -->
      <FormAuR v-if="modalIsOpen" :zid="zid" :studentId="studentId" :stratusFormsData="stratusFormsData" @close="hideModal" />
    </div>
  </div>
</template>

<script>
import FormAuR from "../Forms/FormAuR"



export default {
  name: 'ZyklusAuR',
  props: {
  studentId: {
    type: [String, Number],
    required: true
  },
  zid: {
    type: [String, Number],
    required: true
  },
  showFormImmediately: {
    type: Boolean,
    default: false
  }
},
  data() {
    return {
      modalIsOpen: false,
      next: null,
      items: [
          {
              name: "Interessen",
         //     width: 'auto',
              show: true,
              disabled: false,
              value: "Interessen"
          }, {
              name: "Stärken",
         //     width: 'auto',
              show: true,
              disabled: false,
              value: "Stärken"
          }, {
              name: "Arbeitsweisen",
       //     width: 'auto',
              show: true,
              disabled: false,
              value: "Arbeitsweisen"
          }, {
              name: "Verantwortung",
        //   width: 'auto',
              show: true,
              disabled: false,
              value: "Verantwortung"
          }, {
              name: "Sonstiges",
        //    width: 'auto',
              show: true,
              disabled: false,
              value: "Sonstiges"
          },
      ],
      currentTab: 'Interessen',
    }
  },
  computed: {
    aur() {
      return this.$store.getters.aurById({studentId: this.studentId, zid: this.zid})
    },
    aurF() {
      if(this.aur && this.aur.AuRF) return this.aur.AuRF
      else return null
    },
    basicaurF() {
      return this.$store.getters.getBasicAurF
    },
    stratusFormsData() {
      let aur = this.aur
      if(aur && aur.StratusFormsData) return aur.StratusFormsData
      else return null
    },
    isLoading() {
      return this.$store.getters.aurIsLoading
    },
    hasLastAurF() {
      return this.$store.getters.canImportFromLastAurF({studentId: this.studentId, zid: this.zid})
    }
  },
  mounted() {
    // add event listener to update tab width
    this.onResize()
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
  },
  beforeMount() {
    // window.addEventListener("beforeunload", this.preventNav)
  },
  beforeDestroy() {
    // window.removeEventListener("beforeunload", this.preventNav)
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    setTextareaValue(id, value) {
      this.$store.commit("setAurFTextareaValue", {
        zid: this.zid,
        itemId: id,
        itemValue: value
      })
    },
    createNewAur() {
      this.$store.dispatch("createNewAur", {
        id: this.studentId,
        zid: this.zid
      })
    },
    createLastZyklusAur() {
      this.$store.dispatch("createLastZyklusAur", {sid: this.studentId, zid: this.zid})
    },
    // fluent design pivot has no own resize method
    onResize() {
      let tabWidth = [190, 170, 270, 270, 170]
      this.items.forEach((item, i) => {
        if(window.innerWidth < 1100) item.width = (window.innerWidth-200)/5
        else item.width = tabWidth[i]
      })
      // fluent design pivot has only shallow object update recognition, force component update
      this.items.push({})
      this.items.pop()
    },
    routeUpdate(to, from, next) {
      next()
    },

    showModal() {
      this.modalIsOpen = true
      document.body.classList.add("modal-open");
    },
    hideModal() {
      this.modalIsOpen = false
      document.body.classList.remove("modal-open");
    },
    handleClick(newTab) {
      this.currentTab = newTab.value;
    },
    triggerSaveAur() {
      this.$store.dispatch("saveAur", this.aur)
    },
    triggerDeleteAurF() {
      // reset fluent design pivot tab
      this.currentTab = 'Interessen'
      this.items.push({})
      this.items.pop()

      if(window.confirm("Sind Sie sicher, dass Sie die Entwicklungsressourcen endgültig löschen möchten? Alle bisher eingetragenen Daten gehen dabei verloren.")) {
        this.$store.dispatch("deleteAur", this.aur)
      }
    }
  },
  components: {
      FormAuR
  }
}

</script>
