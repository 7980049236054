<template>
    <fv-callout
              :lockScroll="true"
              :position="'topCenter'"
              theme="light"
              :popperStyle="{ width: '30vh', fontFamily: 'aileronregular' }"
            >
            <div  class="info-Icon"
      :style="{ backgroundImage: 'url(' + Icon + ')'}"
     
    >
            </div>
              <header :style="{ fontFamily: 'aileronblack' }" v-if="this.Title">{{ this.Title }}</header>
              <main>
                <div style="white-space: pre-wrap;">{{ this.Main }}</div>
              </main>
            </fv-callout>
</template>
<script>
export default {
    name: 'HelperIconCallout',
    props: {
      Title: {
        type: String,
        required: false,
      },
      Main: {
        type: String,
        require: true,
        default: "keine Information verfügbar"
      }
    },
    data() {
    return {
        Icon: require(`../../../assets/icons/info.svg`),
    }}

}
</script>
<style scoped>
.info-Icon {
    height: 24px;
    width: 24px;
    padding: 10px;
    background-size: 24px;
    background-position: center;
    background-repeat: no-repeat;
    align-self: center;
    cursor: pointer;
}
</style>