<style scoped>

.modal-fade-enter,
 .modal-fade-leave-active {
   opacity: 0;
 }

 .modal-fade-enter-active,
 .modal-fade-leave-active {
   transition: opacity .5s ease
 }

.modal-backdrop {
   position: fixed;
   top: 0;
   left: 100px;
   height: 100%;
   background-color: rgba(255, 255, 255, 0.7);
   z-index: 1000;
 }

 .separator {
   width: 100%;
   border-bottom: 1px solid rgba(0, 0, 30, 0.36);
 }

 .form-container {
   margin: 25px;
   font-size: 16px;
   background-color: white;
   height: 100%;
   display: grid;
   grid-template-rows: 100px auto;
 }

 .form-header-container {
   display: grid;
   grid-template-columns: minmax(min-content, 850px) auto min-content;
   background-color: #add8e65c;
 }

 .form-header-text{
   margin: 20px 10px 20px 35px;
   text-align: justify;
    text-align: -webkit-justify;
   text-align: -moz-justify;
   overflow: auto;
 }

 .form-header-save {
   margin: 0 50px 0 10px;
   align-self: center;
   justify-self: end;
 }

 .save-button {
   z-index: 2;
 }

 .form-header-close {
   justify-items: end;
   background-color: #cee8f100;
 }

.form-header-close-icon {
  margin: 5px 15px 15px 0;
}

 .form-list-container {
   overflow-y: auto;
 }

 .form-list {
   list-style:none;
   margin-top: 20px;
   margin-bottom: 20px;
 }

 .form-item-container {
   display: grid;
   grid-template-columns: minmax(150px, 1fr) minmax(150px, 1fr) 50px;
   grid-template-rows: auto min-content;
 }

 .item-title-question {
   margin: 4px 20px 15px 0;
   font-weight: bold;
 }

 .item-select-container {
   margin: 0 20px 15px 0;
   display: grid;
   grid-template-rows: min-content;
   grid-template-columns: 100%;
 }

 .item-select {
   padding: 3px;
   font-size: 16px;
   -webkit-appearance: auto;
 }

 .item-answer {
   margin: 0 20px 0 0;
   text-align: left;
    text-align: -webkit-left;
   text-align: -moz-left;
   word-wrap: anywhere;
 }

 .item-status {
   margin-top: 6px;
   display: grid;
   align-items: start;
 }

 .remove-margin {
   margin: 0;
 }

 .hide {
   opacity: 0.3;
 } </style>




 <template>
   <transition name="modal-fade">
      <div class="modal-backdrop">
        <form class="form-container" @submit.prevent="saveFormsDataObject">
          <div class="form-header-container">
            <div class="form-header-text">
              Bitte wählen Sie für jeden Antwortblock auf der linken Seite eine neue passende Fragekategorie aus. In Fällen, in denen die neuen und alten Fragen gleich sind, wurden diese bereits vorausgewählt.
            </div>
            <p class="form-header-save formactionbutton formactionbuttonspeichern">
              <input class="save-button remove-margin removeButtonStyle" type="submit" value="Speichern" tabindex="1" />
            </p>
            <button class="form-header-close removeButtonStyle" @click.prevent="$emit('close')">
              <i class="form-header-close-icon material-icons">close</i>
            </button>
          </div>
          <ul class="form-list-container">
            <li class="form-list" v-for="form in formsData" :key="form.oldId">
              <div class="form-item-container">
                <div class="item-title-question" :class="{hide: form.id}">
                  {{form.oldQuestion}}
                </div>

                <div class="item-select-container">
                  <select required v-model="form.id" class="item-select">
                    <option disabled value="" :key="form.oldId+':placeholder'">Wählen Sie eine passende Frage:</option>
                    <option v-for="option in aurF" :value="option.id" :key="form.oldId+':'+option.id">{{option.text}}</option>
                  </select>
                </div>

                <div class="item-status">
                    <i v-if="form.id" class="material-icons">check_circle</i>
                </div>

                <div class="item-answer" :class="{hide: form.id}">
                  {{form.answer}}
                </div>

                <div v-if="form.id" class="item-answer">
                  {{form.answer}}
                </div>
              </div>
              <div class="separator" />
            </li>
          </ul>
        </form>
      </div>
   </transition>
 </template>

 <script>
 export default {
   name: 'FormAuR',
   props: ['zid', 'studentId','stratusFormsData'],
   data() {
     return {
       formsData: null,
       aurF: null
     }
   },
   created() {
     this.formsData = this.generateFormsDataObject()
     // get deep copy of AuRF from current aur
     this.aurF = JSON.parse(JSON.stringify(this.$store.getters.getBasicAurF))
   },
   methods: {
     close() {
       this.$emit('close')
     },
     generateFormsDataObject() {
       let formItemList = []
       Object.entries(this.stratusFormsData).forEach(entry => {
         const [key, value] = entry
         let formItem = {
           id: this.migrateIds(key),
           oldId: key,
           answer: this.parseValue(value),
           oldQuestion: this.getoldQuestions(key)
         }
         // check if item has data
         if(formItem.oldId && formItem.answer && formItem.oldQuestion) {
           formItemList.push(formItem)
         }
       })
       return formItemList
     },
     migrateIds(oldId) {
       if(oldId === "frage1") return "AuR4"
       else if(oldId === "frage2") return "AuR4"
       else if(oldId === "frage3") return "AuR3"
       else if(oldId === "frage4") return "AuR12"
       else if(oldId === "frage5") return "AuR1"
       else if(oldId === "frage6") return "AuR2"
       else if(oldId === "frage7") return "AuR5"
       else if(oldId === "frage8") return "AuR4"
       else if(oldId === "frage9") return "AuR3"
       else if(oldId === "frage11") return "AuR6"
       else if(oldId === "frage13") return "AuR10"
       else return ""
     },
     parseValue(value) {
       if(typeof value === "string") {
         value = value.replace(/&#13;/g, "\n")
         value = value.replace(/&#39;/g, "'")
       }
       return value
     },
     getoldQuestions(oldId) {
       if(oldId === "frage1") return "Anlass für die Erstellung eines Entwicklungsplans (optional)"
       else if(oldId === "frage2") return "Aus der Sicht der " +this.$entgendern.pupil +" : Dabei könnte mir ein Entwicklungsplan helfen"
       else if(oldId === "frage3") return "Ziele, die mit Hilfe des Entwicklungsplans erreicht werden sollen"
       else if(oldId === "frage4") return "Für die Entwicklungsplanung relevante Informationen (optional):"
       else if(oldId === "frage5") return "Schulische Interessenbereiche"
       else if(oldId === "frage6") return "Außerschulische Interessenbereiche (optional):"
       else if(oldId === "frage7") return "Schulische und private Bereiche, in denen zukünftig das Interesse ausgebaut werden könnte (optional):"
       else if(oldId === "frage8") return "Besondere Stärken:"
       else if(oldId === "frage9") return "Perspektiven und Chancen (optional):"
       else if(oldId === "frage10") return "Das fällt der " +this.$entgendern.pupil +" noch schwer (optional):"
       else if(oldId === "frage11") return "Mit diesen Maßnahmen kann die " +this.$entgendern.pupil +" Fähigkeiten gut ausbauen (optional):"
       else if(oldId === "frage12") return "Diese Maßnahmen haben in der Vergangenheit nicht gut funktioniert (optional):"
       else if(oldId === "frage13") return "Dies könnte die " +this.$entgendern.pupil +" für " +this.$entgendern.possessiv_akkusativ_pl +" Umwelt tun (soziale Sicht) (optional):"
       else return null
     },
     saveFormsDataObject() {
       // transfer forms data into AuRF
       for(let item of this.formsData) {
         let id = item.id
         for(let aur of this.aurF) {
           if(aur.id === id) {
             if(aur.value) aur.value = aur.value +"\n" +item.answer
             else aur.value = item.answer
           }
         }
       }
       // save into store and close modal
       this.$store.dispatch("createNewAur",{
         sid: this.studentId,
         zid: this.zid,
         aurF: this.aurF
       })
       this.$emit('close')
     }
   },
 };
 </script>
