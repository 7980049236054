<template>
  <div id="app">
    <fv-ProgressBar v-if="loadingIndi" loading="true">
</fv-ProgressBar>
       <globalOverlay :show="initialLoading" :progress="progress" :statusText="statusText"/>

    <Aussehen />
    <div v-if="!isDataLoaded">kdsjhdjfdsklfj</div>
    <router-view v-else />
  </div>
</template>

<script>
import Aussehen from './components/aussehen-vue'
import Vue from 'vue'
import VueSidebarMenu from 'vue-sidebar-menu'
import store from './store'  
import globalOverlay from './components/layout/Reusable/globalOverlay.vue'

Vue.use(VueSidebarMenu)

// function getAllComponentNames(component, names = []) {
//   if (component.$children.length === 0) {
//     return;
//   }
  
//   component.$children.forEach(child => {
//     const name = child.$options.name;
//     if (name) {
//       names.push(name);
//     }
//     getAllComponentNames(child, names);
//   });
  
//   return names;
// }
export default {
  name: 'App',
  data() {
    return {
      progress: 0,
    statusText: '',
    initialLoading: false, 
    isDataLoaded: false,
    };
  },
  created() {
    if (this.$store.getters.api !== "pepApi") {
    this.loadData();
    }
    if (this.$store.getters.api !== "local") {
      this.isDataLoaded = true
    }
    else {
      this.isDataLoaded = true
    }
  },
//   mounted() {
//   const allComponentNames = getAllComponentNames(this);
//   console.log(allComponentNames);
// },
computed: {
  loadingIndi() {
    return this.$store.getters.getloadingIndi
  },
},
methods: {
  async loadData() {
    this.initialLoading = true;
    this.progress = 0;
    this.statusText = 'Starte Laden...';
    const loadingStartedAt = Date.now();

    try {
      if (this.$store.getters.api === "pepApi") {
        this.isDataLoaded = true;
       // await this.updateProgressAndStatus("loadSVz", 'SVz geladen', 50);
       // await this.updateProgressAndStatus("createLog", 'Log erstellt', 50);
       
      }
      if (this.$store.getters.api !== "pepApi") {
        await this.updateProgressAndStatus("loadUserObject", 'Lade Daten 1/7', 12.5);
        await this.updateProgressAndStatus("loadGroupObject", 'Lade Daten 2/7', 12.5);
        await this.updateProgressAndStatus("loadGroupAdministration", 'Lade Daten 3/7', 12.5);
        await this.updateProgressAndStatus("loadAdminObject", 'Lade Daten 4/7', 12.5);
        await this.updateProgressAndStatus("loadSettings", 'Lade Daten 5/7', 12.5);
        await this.updateProgressAndStatus("loadSVz", 'Lade Daten 6/7', 12.5);
        await this.updateProgressAndStatus("createLog", 'Lade Daten 7/7', 25);
      }   
      // if (this.$store.getters.api === "pepApi") {
      //   this.isDataLoaded = true;
      //   await this.updateProgressAndStatus("loadSVz", 'SVz geladen', 50);
      //   await this.updateProgressAndStatus("createLog", 'Log erstellt', 50);
       
      // }
    } catch (err) {
      this.statusText = `Fehler: ${err}`;
      console.error("Fehler:", err);
    } finally {
      const loadingDuration = Date.now() - loadingStartedAt;
      const minimumLoadingTime = 4000; // 2 Sekunden

      if (loadingDuration < minimumLoadingTime) {
      setTimeout(() => {
        this.initialLoading = false;
        this.isDataLoaded = true; // Stellen Sie sicher, dass dies immer gesetzt wird
      }, minimumLoadingTime - loadingDuration);
    } else {
      this.initialLoading = false;
      this.isDataLoaded = true; // Stellen Sie sicher, dass dies immer gesetzt wird
    }
  
    }
  },
  async updateProgressAndStatus(dispatchAction, statusMessage, progressIncrement) {
    await this.$store.dispatch(dispatchAction);
    this.progress += progressIncrement;
    this.statusText = statusMessage;
    await this.$nextTick();
    // Fügen Sie eine kleine Verzögerung hinzu, damit der Benutzer den Fortschritt sehen kann
    await new Promise(resolve => setTimeout(resolve, 200));
  }
  },
  components: {
    Aussehen,
    globalOverlay,
  },
  store
}
</script>
<style>
@import './css/VueAppCSS.css';

/* remove sharepoint header text */
.ms-webpart-titleText {
    display:none;
}
/* remove invisible click blocking sharepoint header table */
.ms-webpartPage-root{
  display: none;
}

.enlarge-enter-active, .enlarge-leave-active {
  transition: opacity .5s;
}
.enlarge-enter, .enlarge-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
:deep(.fv-light-ProgressBar) {
  background: linear-gradient(to right,rgb(234, 235, 207),rgb(157, 233, 248),rgb(164, 191, 244),rgb(52,170,220),rgb(185, 198, 223),rgb(247, 211, 210));
  height: 15px !important;
  width: 100% !important;
  position: absolute !important;
  z-index: 999 !important;
  top: 0 !important;
  left: 0 !important;
  box-shadow: 0px 0px 20px 1px rgb(0 0 0 / 19%);
    animation: revealbar 300ms;
    overflow: hidden !important;

}

.fv-light-ProgressBar {
  background: linear-gradient(to right,rgb(234, 235, 207),rgb(157, 233, 248),rgb(164, 191, 244),rgb(52,170,220),rgb(185, 198, 223),rgb(247, 211, 210));
  height: 15px !important;
  width: 100% !important;
  position: absolute !important;
  z-index: 999 !important;
  top: 0 !important;
  left: 0 !important;
  z-index: 99;
  box-shadow: 0px 0px 20px 1px rgb(0 0 0 / 19%);
    animation: revealbar 300ms;
    overflow: hidden !important;

}

@keyframes revealbar {
  0% {
    transform: translateY(-100px);
  }
  100% {
    transform: translateY(0);
  }
}

:deep(.fv-light-ProgressBar p.unit) {
  position:absolute;
  left:calc(50% - 2.5px);
  top:1.5px !important;
  width:68px !important;
  height:10px !important;
  margin:0 ;
  background:#6495ed;
  border-radius:0% !important;
  opacity:0;
  -webkit-animation:progressbar 6s infinite;
  animation:progressbar 6s infinite;
  mix-blend-mode: soft-light;
  background: #0000004d !important;
}
.fv-light-ProgressBar p.unit {
  position:absolute;
  left:calc(50% - 2.5px);
  top:1.5px !important;
  width:68px !important;
  height:10px !important;
  margin:0 ;
  background:#6495ed;
  border-radius:0% !important;
  opacity:0;
  -webkit-animation:progressbar 6s infinite;
  animation:progressbar 6s infinite;
  mix-blend-mode: soft-light;
  background: #0000004d !important;
}

</style>
