import store from "../index"
import api from '../../api/api.js'

const state = {
  entMTKg: null,
  loading: null,
  savedData: "",
  check: false,
}

const actions = {
    loadEntMTkg({commit, state}) {
    return new Promise((resolve, reject) => {
      if(state.entMTKg === null) {
        commit('setEntMTKgLoading', true)
        api.loadEntMTkg()
        .then(res => {
          commit('setEntMTKg', res)
         
          commit('setEntMTKgLoading', false)
          resolve(res)
        })
        .catch(err => {
          setTimeout(() => {
            commit('setEntMTKgLoading', false)
          }, 1000)
          console.log("Error while getting library of measures: " +err)
          reject(err)
        })
      } else {
        if(state.entMTKg && Array.isArray(state.entMTKg)) {commit('setEntMTKgLoading', false), resolve(state.entMTKg)}
        else resolve([])
      }
    })
  },
  addEntMTkg({commit}, measureTg) {
    return new Promise((resolve, reject) => {
      // checks if element what should be added does not already exist
      if(!state.loading && !state.entMTKg.some(item => item.Id === measureTg.Id)) {
        commit('setEntMTKgLoading', true)
        api.addEntMTkg(measureTg)
        .then(res => {
          setTimeout(() => {
            commit("addEntMTkg", res)
            commit('setEntMTKgLoading', false)
            commit('setMTKgSavedData', res)
            resolve()
          }, 500)
        })
        .catch(err => {
          commit('setEntMTKgLoading', false)
          alert("Es trat ein Problem mit der Serververbindung oder Session auf. Es konnte leider kein neues Entwicklungsziel angelegt werden. Sie können versuchen die Browserseite neu zu laden.")
          console.log("Error while add measure: " +err)
          reject(err)
        })
      }
    })
  }, 
  updateEntMTkg({commit}, payload) {
    api.updateEntMTkg(payload)
    .then((res) => {
      commit('setMTKgSavedData', res)
    })
    .catch(err => {
      alert("Es trat ein Problem mit der Serververbindung oder Session auf. Die Entwicklungsmaßnahme konnte leider nicht aktualisiert werden. Sie können versuchen die Browserseite neu zu laden.")
      console.log("Error while updating measure: " +err)
    })
  },
}
  const getters = {
    entMTKg: state => {
    
        // fetch data if no other data is available or there is no other fetching process.
        if(state.entMTKg === null && !state.loading) {
          store.dispatch("loadEntMTkg")
        }
        return state.entMTKg
      },
      entMTKgLoading: state => {
        return state.loading
      },
      entMTKgsavedData: state => {
        return state.savedData
      },
      entMTKgcheck: state => {
        return state.check
      },
    }
    const mutations = {
        setEntMTKg: (state, entMTKg) => {
          state.entMTKg = entMTKg
        }, 
        setEntMTKgLoading: (state, loading) => {
    state.loading = loading
  },
  setMTKgSavedData: (state, data) => {
    console.log('setMTKgSavedData', data)
    state.savedData = data
  },
      addEntMTkg: (state, measureTg) => {
        if(measureTg) state.entMTKg.push(measureTg)
      },
      updateEntMTkg: (state, payload) => {
        console.log(payload)
        if(payload && payload.Id) {
          state.entMTKg.forEach(measureTg => {
            console.log(measureTg)
            if(measureTg.Id === payload.Id) {
              // update measure
              measureTg.Title = payload.Title
              measureTg.RefMethZiel = payload.RefMethZiel
              measureTg.RefMethBeschr = payload.RefMethBeschr
              measureTg.RefMeth = payload.RefMeth
              measureTg.Zeitumfang = payload.Zeitumfang
              measureTg.Sozialform = payload.Sozialform
              measureTg.Voraussetzungen_x002f_Materialie = payload.Voraussetzungen_x002f_Materialie
              measureTg.Kurzbeschreibung = payload.Kurzbeschreibung
              measureTg.Evaluationskriterien_x0020__x002 = payload.Eval
              measureTg.RefMethId = payload.RefMethId
              measureTg.Lernumgebung = payload.Lernumgebung
              measureTg.Altersgruppe = payload.Altersgruppe
              measureTg.Praxistipps = payload.Praxistipps
              measureTg.EntwicklBereiche = payload.EntwicklBereiche
              measureTg.Plan_und_Durch = payload.Plan_und_Durch
              measureTg.Beispiel = payload.Beispiel
              measureTg.AdminChecked = payload.AdminChecked

            }
          })
          // fluent design list for already used measures has only shallow object update recognition, force component to update
          state.entMTKg.push({Id: "placeholder"})
          state.entMTKg = state.entMTKg.filter(item => item.Id !== "placeholder")
        }
        // update measure in data storage
        store.dispatch("updateEntMTkg", payload)
      },
      setCheck(state, value) {
        state.check = value;
      },
    }
    export default {
        state,
        mutations,
        getters,
        actions
      }