<style>
.lazy-loader {
  height: 40vh;
  width: 100%;
  
  place-content: center;
}
@keyframes dotPulse {
  0%, 100% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
}

.dots .dot {
  animation: dotPulse 1.5s infinite;
}

.dots .dot1 {
  animation-delay: 0.5s;
}

.dots .dot2 {
  animation-delay: 1s;
}

.dots .dot3 {
  animation-delay: 1.5s;
}

</style>

<template>
      <div>
        <div class="formContainer">
        <h2 style="margin-bottom: 24px">lade<span class="dots"><span class="dot dot1">.</span><span class="dot dot2">.</span><span class="dot dot3">.</span></span> {{ this.title }}</h2>
        <span class="divider"></span>
        

<div class="lazy-loader">
    <fv-Shimmer>

<div style="width: 100%; height: 100%; display: flex; flex-wrap: wrap;" class="title-shimmer">
<div class="sample" style="width: 80%;height: 40%; margin-left: 0%;background-image: inherit;margin-top: 20px;background-color: rgb(178 178 178 / 59%);"></div>
<div class="sample" style="width: 80%;height: 500px; margin-left: 10%;background-image: inherit;margin-top: 20px;background-color: rgb(228 228 228 / 63%);"></div>
</div>
</fv-Shimmer>
  </div>
        </div>
        </div>
</template>
<script>
export default {
  name: "LazyLoadZyklus",
  props: {
    title: {
      type: String,
      default: "",
    },
    width: {
      type: String,
      default: "80%",
    },
  },
  data() {
    return {
    }
  },

};
</script>
