// filters.js
import dayjs from 'dayjs';
import 'dayjs/locale/de'; // importieren Sie die deutsche Lokalisierung
dayjs.locale('de'); // verwenden Sie die deutsche Lokalisierung

export function dateformer(value) {
  if (!value) return null; // Gibt null zurück, wenn kein Wert vorhanden ist

  // Entfernen des Vorsatzes, falls vorhanden
  const cleanValue = value.includes(";#") ? value.split(";#")[1] : value;

  // Formatieren des bereinigten Datums
  return dayjs(cleanValue).format("DD.MM.YYYY");
}
