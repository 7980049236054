//  Helpdesk.vue

<template>
  <div>
    <i class="material-icons help-icon" :class="{'bright-icon': brightIcon}" @click="openHelpdesk">help_outline</i>

    <fv-Panel v-model="helpIsOpen" :title="title" width="1000px" :isLightDismiss="true" :isAcrylic="true" :is-footer="false">
      <template v-slot:container>
        <div v-if="isLoading" class="faq-loading">
          <div class="loading-btn grey-text">
            <div class="btn-large btn-floating pulse grey lighten-5"><i class="material-icons grey-text">cloud_download</i></div>
            <h5>
              Loading FAQ
            </h5>
          </div>
        </div>

        <div v-show="faq && faq.length && !isLoading" class="help-container">
          <div id="faq_container" class="faq-container">
            <div class="faq-title">Fragen und Antworten (FAQ)</div>
            <ul class="collapsible collapsible-ul">
                <li v-for="item in faq" :key="item.title">
                <div class="collapsible-header faq-header"><i class="material-icons">expand_more</i>{{item.title}}</div>
                <div class="collapsible-body faq-body">
                <p v-html="item.content" />
              </div>
            </li>
            </ul>
          </div>
        </div>



      </template>
    </fv-Panel>
  </div>
</template>

<script>

export default {
  name: "Helpdesk",
  props: ["routeName", "brightIcon"],
  data() {
    return {
      helpIsOpen: false
    };
  },
  mounted() {
    this.loadFaqData();
    this.$root.$on("openHelpdesk", () => {
    this.helpIsOpen = true;
  });
  this.$nextTick(() => {
  const collapsibles = document.querySelectorAll(".collapsible-header");
  collapsibles.forEach((collapsible) => {
    collapsible.addEventListener("click", () => {
      // Schließen Sie alle anderen Collapsible-Elemente
      collapsibles.forEach((otherCollapsible) => {
        if (otherCollapsible !== collapsible) {
          const otherBody = otherCollapsible.nextElementSibling;
          const otherIcon = otherCollapsible.querySelector("i");

          otherBody.style.maxHeight = null;
          otherIcon.style.transform = "";
          otherBody.classList.remove("collapsible-body-open");
        }
      });

      const body = collapsible.nextElementSibling;
      const icon = collapsible.querySelector("i");
      const verticalPadding = 40; // 2rem oben + 2rem unten, 1rem = 20px

      if (body.style.maxHeight) {
        body.style.maxHeight = null;
        icon.style.transform = "";
        body.classList.remove("collapsible-body-open");
      } else {
        body.style.maxHeight = body.scrollHeight + verticalPadding + "px";
        icon.style.transform = "rotate(180deg)";
        body.classList.add("collapsible-body-open");
      }
    });
  });
  
  // open faq links in a new tab
  document.getElementById("faq_container").addEventListener("click", (e) => {
    if (e.target.tagName === "A") {
      e.preventDefault();
      let url = e.target.href;
      window.open(url, "_blank");
    }
  });
});


},
  methods: {
    openHelpdesk() {
      this.helpIsOpen = true
    },
    loadFaqData() {
      if (this.$store.getters.faq === null) {
        this.$store.dispatch("loadFaq");
      }
    }
  },
  computed: {
    title() {
      if (!this.$store.getters.faq) {
      return "Lädt...Hilfebereich"; // oder einen anderen Standardtitel
    }
   
else {
 let title;

      switch (this.routeName) {
        case "Profil": 
        title =  "Hilfebereich zum Entwicklungsplandetail";
        break;
        case "Schulverwaltung": 
        title = "Hilfebereich zur Schuladministration";
        break;
        case "Zyklus": 
        title =  "Hilfebereich zum Zyklusdetail";
        break;
        case "ZyklusEvaluation": 
        title = "Hilfebereich zur Evaluation";
        break
        case "UebersichtPlaene": 
        title = "Hilfebereich zur Entwicklungsplanübersicht";
        break
        case "Maßnahmenkatalog": 
        title =  "Hilfebereich zum Maßnahmenkatalog";
        break;
        default: 
        title = "Hilfebereich"; // Standardtitel, wenn keine Übereinstimmung
    }
    return title
  }
       
    },
    faq() {
    if (this.$store.getters.faq) {
      switch (this.routeName) {
        case "Profil": return this.$store.getters.filteredFaq("Entwicklungsplandetail");
        case "Schulverwaltung": return this.$store.getters.filteredFaq("Schuladministration");
        case "Zyklus": return this.$store.getters.filteredFaq("Zyklusdetail");
        case "ZyklusEvaluation": return this.$store.getters.filteredFaq("Evaluation");
        case "UebersichtPlaene": return this.$store.getters.filteredFaq("Entwicklungsplanübersicht");
        case "Maßnahmenkatalog": return this.$store.getters.filteredFaq("Maßnahmenkatalog");
        
        default: return this.$store.getters.faq;
      }
    }
    return [];
  },
   
    isLoading() {
      return this.$store.getters.faqLoading || this.$store.getters.faq === null;
    }
  }
};
</script>


<style scoped>
.collapsible {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.collapsible-header {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 1rem;
  background-color: #f5f5f5;
  border-bottom: 1px solid #ddd;
  line-height: 1.5;
}

.collapsible-header i {
  transition: transform 0.3s;
}
.collapsible-header i {
  width: 2rem;
  font-size: 1.6rem;
  display: inline-block;
  text-align: center;
  margin-right: 1rem;
}
.collapsible-body {
  display: block;
  max-height: 0;
  overflow: hidden;
  padding: 0;
  background-color: #fff;
  transition: max-height 0.3s ease-in-out;
}
.collapsible-body-open {
  padding: 1rem;
  color: #616161;
padding: 2rem 3rem 2rem 3rem;
}
.help-icon {
  /* color: #2100ffa3; */
  color: #3f51b5;
  cursor: pointer;
  margin-left: 20px;
  margin-top: 10px;
  font-size: 40px;
  padding: 15px;
  margin-right: -15px;
}

.help-icon:hover {
  color: #250bd5;
}

.bright-icon {
  color:   #f6f3f3;
}

.bright-icon:hover {
  color: white;
}

@supports not ((-webkit-backdrop-filter: 25px) or (backdrop-filter: 25px)) {
  ::v-deep .fv-light-Panel .fv-panel-container.acrylic-style {
    background: hsla(0, 0%, 100%, .9);
  }
}

:deep(.panel-title) {
  font-family: aileron, sans-serif;
  font-weight: 400;
  letter-spacing: 1.2px;
  color: #0000008f !important;
  font-size: calc(24px + 6 * (100vh - 300px) / 1600) !important;
  line-height: 2rem;
  margin-left: 20px;
}

:deep(.fv-light-Panel) {
  text-align: left
}

:deep(.fv-panel-container) {
  width: 60%;
  min-width: 800px;
}

:deep(.ms-Icon--Cancel) {
  margin-bottom: 30px;
  margin-right: 30px;
  padding: 15px;
  cursor: pointer;
}


/* faq style */
.collapsible-ul {
  height: max-content;
  margin: 0 20px;
}

.help-container {
  display: grid;
  grid-gap: 15px;
  margin: 35px;
  width: 100%;
  font-size: calc(22px + 6 * (100vh - 300px) / 1600);
  color: rgba(35, 34, 34, 0.8);
}

.faq-container {
  overflow-y: scroll;
  font-size: calc( 13px + (22 - 16) * ((100vh - 300px) / (1900 - 300)) );
  color: #3f51b5;
  /* color: #283593; */
}

.faq-title {
  margin-bottom: 40px;
  margin-left: 10px;
  font-size: calc( 19px + (22 - 16) * ((100vh - 300px) / (1900 - 300)) );
  font-weight: bold;
}
.faq-header {
  align-items: center;
}



.faq-loading {
  width: 100%;
  display: grid;
  padding: 2rem 9rem 11rem 9rem;
}
.loading-btn {
  align-self: center;
  justify-self: center;
  display: grid;
  grid-template-columns: auto auto;
  align-content: center;
  grid-gap: 1rem;
  pointer-events: none;
  cursor: default;
}
.collapsible-header {
  font-family: 'aileronheavy'
}

.collapsible li.active i {
  -ms-transform: rotate(180deg); /* IE 9 */
  -webkit-transform: rotate(180deg); /* Chrome, Safari, Opera */
  transform: rotate(180deg);
}

/* hide faq elements from wordpress which should not be shown */
::v-deep .ewd-ufaq-faq-toggle {
  display: none;
}

::v-deep  .ewd-ufaq-faq-custom-fields {
  display: none;
}

::v-deep  .ewd-ufaq-faq-tags {
  display: none;
}

::v-deep  .ewd-ufaq-permalink {
  display: none;
}




</style>
