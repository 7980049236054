import { render, staticRenderFns } from "./TestResult.vue?vue&type=template&id=66e786de"
import script from "./TestResult.vue?vue&type=script&lang=js"
export * from "./TestResult.vue?vue&type=script&lang=js"
import style0 from "./TestResult.vue?vue&type=style&index=0&id=66e786de&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/openpep/www/html/meinPEP/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('66e786de')) {
      api.createRecord('66e786de', component.options)
    } else {
      api.reload('66e786de', component.options)
    }
    module.hot.accept("./TestResult.vue?vue&type=template&id=66e786de", function () {
      api.rerender('66e786de', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/layout/IndiForms/TestResult.vue"
export default component.exports