<template>
    <div>
        <div class="formContainer">
        <div class="formContainerlinks">
        <div class="headline-container">
            <h2 style="margin-bottom: 24px">Entwicklungsbereiche</h2>
            <helper-icon-callout 
                :Title="'Info'"
                :Main="'Wählen Sie mindestens einen der nachfolgenden Entwicklungsbereiche aus.'">
            </helper-icon-callout>
        </div>
        <span class="divider"></span>
        <div class="BCont">
          <CardCollection
          :zid="zid"
          :types="{isEB: true}"
          :zykluskonkret="zykluskonkret"
          :ready="ready"
  />
        </div>
         </div>
        </div>
    </div>
</template>
<script>

import CardCollection from '../../../components/layout/Cards/CardCollection'
export default {
    data (){
   return{

   }},
  props: {
        zid: Number,
        zykluskonkret: Object,
        ready: {
            type: Boolean,
            default: false,
        }
        },
    name: 'EntwBereiche',
    components: {  CardCollection},
}
</script>
<style scoped>
.divider {
    margin-top: 0px;
}
.formContainer {
    width: 100%;
}
.formContainerlinks {
    width: 100%;
}
.BCont {
        display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
}

.headline-container {
    display: grid;
grid-auto-flow: column;
place-content: flex-start;
line-height: initial;
}
</style>
