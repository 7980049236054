import axios from 'axios';
/*global _spPageContextInfo*/
/*eslint no-undef: "error"*/
let BURL
let requestDigest = null
if (typeof _spPageContextInfo === "undefined") {
  BURL = "leer";
} else {
  BURL = _spPageContextInfo.webAbsoluteUrl;
}

if (document.getElementById('__REQUESTDIGEST')) requestDigest = document.getElementById('__REQUESTDIGEST').value

const binstance = axios.create({
  baseURL: BURL,
  headers: {
    'accept': "application/json;odata=verbose",
    'X-RequestDigest': requestDigest,
    'content-type': 'application/json;odata=verbose'
  },
  method: 'post',

});



export default binstance;
