<template>
<!-- <transition name="kommup-in" class="ep" :duration="1000"
 appear>  -->
  <div class="uberseite"  v-bind:class="{ 'profile-margin': $route.name === 'Profil' }">

    <div id="SubHeader"  style="text-align: initial;">
      <br>
      <slot name="absatzslot"></slot>

    </div>

    <div class="commandBar" v-if="!$route.meta.hideCB">
      <span class="divider"></span>
      <fv-command-bar v-model="value" :options="options" style="font-size: 16.5px !important">
        <template v-slot:right-space>
          <diV>
              <div class="note-preview-command-bar-search"  v-if="searchbar">
            <input id="searchBar" class="note-preview-command-bar-search-input" type="text" @keydown.enter.prevent @keyup.enter.prevent v-model="searchPlanQuery" @change="setQuery" placeholder="Suche" autocomplete="off"/>
            <i class="material-icons note-preview-command-bar-close" @click.stop.prevent="hideSearchbar">close</i>
          </div>
          </diV>
          <div>
          <div class="sort-select-container">
            <i class="material-icons sort-icon">
              swap_vert
            </i>
            <div class="sort-text name">
              Sortieren nach:
            </div>
            <select :value="order" @change="setOrder" class="sort-select" id="sortselect">
              <option value="alphaAuthorAsc">✏️ Verantwortlich aufsteigend A-Z</option>
              <option value="alphaAuthorDesc">✏️ Verantwortlich absteigend Z-A</option>
              <option value="dateAsc">📅 Datum aufsteigend 0-9</option>
              <option value="dateDesc">📅 Datum absteigend 9-0</option>
              <option value="alphaPupilAsc">📚 {{$entgendern.pupil}} aufsteigend A-Z</option>
              <option value="alphaPupilDesc">📚 {{$entgendern.pupil}} absteigend Z-A</option>
              <option value="alphaClassAsc">🏫 Klasse aufsteigend 1A-12Z</option>
              <option value="alphaClassDesc">🏫 Klasse absteigend 12Z-1A</option>
            </select>
          </div>
          </div>
        </template>
      </fv-command-bar>
      <span class="divider"></span>
    </div>

    <section class="contentSection" :style="{ backgroundColor: $route.name === 'Student' ? 'rgba(255,255,255,.2)' : 'rgba(255,255,255,.0)' }">
 <slot name="CS"></slot>
    </section>

    <FormNewStudent v-if="showNewStudentModal" @close="hideNewStudentModal" :showChiffre="showChiffre" />
  </div>
  <!-- </transition> -->
</template>
<script>
import FormNewStudent from "./layout/Forms/FormNewStudent"

export default {
  name: 'UebersichtSeite',
  components: {
    FormNewStudent,
    
  },
   props: {
    showHide: {
      type: Boolean,
      default: false
    },
    hideAdmin: {
      type: Boolean,
      default: false
    },
    showChiffre: {
      type: Boolean,
      default: false
    },
    componentLoaded: {
      type: Boolean,
      default: false
    }
   },
  data() {
    return {
      showNewStudentModal: false,
      WindowWidth: window.innerWidth,
      CardView: true,
      value: "",
      TableView: false,
      searchbar: false,
      searchPlanQuery: "",
      options: [{
          name: "Hinzufügen",
          icon: "Add",
          iconColor: "rgba(0, 120, 212, 1)",
          func: this.openNewStudentModal,
        },
        {
          name: "Ansicht",
          icon: "RedEye",
          iconColor: "rgba(0, 153, 204, 1)",
          secondary: [{
              name: "Cards",
              icon: "GridViewSmall",
              iconColor: "rgba(0, 153, 204, 1)",
              func: this.setViewCard,
            },
            {
              type: "divider"
            },
            {
              name: "Tabelle",
              icon: "Table",
              iconColor: "rgba(0, 153, 204, 1)",
              func: this.setViewTable,
            },
          ]
        },
 { type: "divider" },
        {
         name: "Suche",
        icon: "Search",
        iconColor: "rgba(0, 120, 212, 1)",
        func: this.showSearchbar
      },
      //  { type: "divider" },

      ]
    }
  },
  mounted() {
    this.$root.$on('createNewStudent', () => {
      this.showNewStudentModal = true
      document.body.classList.add("modal-open")
    })
 window.addEventListener("resize", () => {
      this.WindowWidth = window.innerWidth;
    });

  },
    beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  watch: {
			searchPlanQuery: function (newVal, oldVal) {
        if (newVal != oldVal) {
          this.setQuery()
        }
      },
      hideAdmin: function() {
        if (this.hideAdmin === true) {
          this.options[2].secondary[0].name =  "leere Pläne ☑"
          console.log("true")
        }
        else {
         this.options[2].secondary[0].name =  "leere Pläne ☐"
        }
      },
      showHide: function() {
if(this.showHide === true && this.options.some(e => e.name !== "Pläne ein-/ausblenden")) {
 {
      this.options.splice(2, 0, {

         name: "Pläne ein-/ausblenden",
        icon: "Hide",
        iconColor: "rgba(0, 120, 212, 1)",
         secondary: [{
              name: "leere Pläne ☐",
              icon: "Folder",
              iconColor: "rgba(0, 153, 204, 1)",
              func: this.showhide,
            }]
      }
      ,)
    }
} else {
  this.options.splice(2, 1)
}
      }
      },
  computed: {
    order() {
      return this.$store.getters.entPlOrder
    },
  },
  created() {
    this.searchPlanQuery = ""
    if(this.showHide === true && this.options.some(e => e.name !== "Pläne ein-/ausblenden")) {
 {
      this.options.splice(2, 0, {

         name: "Pläne ein-/ausblenden",
        icon: "Hide",
        iconColor: "rgba(0, 120, 212, 1)",
         secondary: [{
              name: "leere Pläne ☐",
              icon: "Folder",
              iconColor: "rgba(0, 153, 204, 1)",
              func: this.showhide,
            }]
      }
      ,)
    }
    }

  },
  methods: {
    ShowTheCards() {
      this.CardView = true
      this.TableView = false
      this.$parent.$emit('cardview', true)
    },
    showhide() {
this.$parent.$emit('showhide')

    },
    ShowTheTable() {
        this.TableView = true
        this.CardView = false
        this.$parent.$emit('cardview', false)
    },
    openNewStudentModal() {
      this.showNewStudentModal = true
      document.body.classList.add("modal-open")
    },
    hideNewStudentModal() {
      this.showNewStudentModal = false
      document.body.classList.remove("modal-open")
    },
    setOrder() {
      this.$store.commit("setEntPlOrder", event.target.value)
    },
    setViewCard() {
      this.$store.commit("setEntPlView", "card")
    },
    setViewTable() {
      this.$store.commit("setEntPlView", "table")
    },
    setQuery() {
      this.$store.commit("setEntPlQuery", this.searchPlanQuery)
    },
    showSearchbar() {
      this.searchbar = true
      this.options.pop()
       if (this.WindowWidth <= 1600) {
              this.options[0].name = "Hin..."
        this.options[1].name = "Ans..."
        if (this.showHide) {
        this.options[2].name = "Plä..." }
       }
      // wait until searchbar is rendered
      this.$nextTick(function() {
        document.getElementById("searchBar").focus()
        document.getElementById("searchBar").select()

      })

    },
    hideSearchbar() {
      this.searchPlanQuery = ""
      this.searchbar = false
      this.options.push({
        name: "Suche",
        icon: "Search",
        iconColor: "rgba(0, 120, 212, 1)",
        func: this.showSearchbar
      })
      if (this.WindowWidth <= 1600) {
       this.options[0].name = "Hinzufügen"
        this.options[1].name = "Ansicht"
         if (this.showHide) {
         this.options[2].name = "Pläne ein-/ausblenden" }
      }
    },
  }
}
</script>
<style>
.AnsichtKlasse {
  position: absolute !important;
  right: 0px;
}
</style>
<style scoped>
.uberseite {
  width: 100%;
  padding-right: 33px;
  padding-left: 33px;
  box-sizing: border-box;
}
.profile-margin {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.divider {
  width: 100%;
  display: block;
  margin-top: 10px;
  margin-bottom: 11px;
  height: 1px;
  position: relative;
  background: rgba(0, 0, 30, 0.36);
  border-radius: 3px;
  z-index: 1;
}

.commandBar {
  height: 48px;
  /* font-size: 15px; */
  /*  font-family: "Segoe UI Semibold", sans-serif; */
  padding-left: 5px;
  text-align: left;
   text-align: -webkit-left;
   text-align: -moz-left;
  display: table-cell;
  vertical-align: middle;
  font-family: "aileronblack", sans-serif;
  font-weight: bold;
  /* font-size: 120%; */
  width: 1%;

}

.sortContainer {
  border: none !important;
  vertical-align: baseline !important;
}

.contentSection {
  min-height: 87vh;
  margin-top: 48px;
  margin-bottom: 96px;
}

#SubHeader {
 /* width: 80%; */
  text-align: justify !important;
  font-family: "aileronthin", sans-serif;
  margin: 1.5% 0 1.5% 1.5%;
  /* font-size: 120%; */
}

.fv-light-CommandBar {
  font-family: aileronsemibold, sans-serif !important;
  font-size: 16.5px !important;
  background: none !important;
}

.name {
  font-family: aileronsemibold, sans-serif !important;
  font-size: 16.5px !important;

}

.fv-light-CommandBar {
  z-index: 5;
}

.fv-light-CommandBar .left-command-bar-container .command-bar-item .s1-container {
  font-size: 16.5px !important;
}

.s1-container {
  font-size: 16.5px !important;
}

.AnsichtKlasse {
  position: absolute;
  right: 0px;
}
#absatz {
  margin-bottom: 48px;
}

.sort-select-container {
  margin-right: 10px;
  display: grid;
  grid-template-columns: min-content auto auto;
  align-content: center;
}
.sort-select {
  opacity: 0.8;
  border: none;
  color: #555c63;
  background: #fff0;
  border-radius: 5px;
  padding: 10px;
  font-size: 19px;
  appearance: auto;
  -webkit-appearance: auto;
}
.sort-select:hover {
  background-color: #edebeb;
}

.sort-text {
  padding: 10px;
  align-self: center;
  font-size: 22px;
  font-weight: normal;
  cursor: default;
  font-family: aileronthin;
}
.sort-icon {
  align-self: center;
  color: rgb(0, 120, 212);
  cursor: default;
}
.note-preview-command-bar-search {
  margin-right: 50px;
  margin-top: 2px;
}

.note-preview-command-bar-search-input {
  margin: 5px 0px 5px 5px;
  padding: 5px;
  padding-right: 35px;
  font-size: 16px;
  background: inherit;
  outline: none;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 30, 0.36);
}

.note-preview-command-bar-search-input:hover {
  outline: none;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 30, 0.36);
}

.note-preview-command-bar-search-input:focus {
  outline: none;
  border: none;
  border-bottom: 1px solid rgb(0, 120, 212);
}
::v-deep .fv-light-CommandBar {
  justify-content: flex-start;
}
::v-deep  .left-command-bar-container {
  flex: 0
}
::v-deep .right-command-bar-container {
      justify-content: space-between;
    width: 100%;

}
</style>
