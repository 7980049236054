<template>
  <div class="modal">
    <button class="close-button" @click.prevent="closeModal">x</button>
    <form class="form" @submit.prevent="onSubmit">
      <div class="formheader">
        <h1 class="form-title">
          Feedback
        </h1>
      </div>
      <div class="form">
        <div class="form-field">
          <label for="title" class="label">Titel</label>
          <input required v-model="title" type="text" id="#title" tabindex="1" />
        </div>
        <div class="form-field">
          <label for="message" class="label">Nachricht</label>
          <textarea required v-model="message" class="feedback-textarea" type="text" id="#message" tabindex="2" />
        </div>
        <div class="formbuttonarea">
          <p class="formactionbutton formactionbuttonspeichern">
            <input class="removeButtonStyle" type="submit" value="Absenden" tabindex="3" />
          </p>
        </div>
      </div>
    </form>
  </div>
</template>

<style scoped>
    .modal {
        display: grid;
        grid-template-columns: minmax(auto, 550px);
        background: white;
    }
    .form {
      margin: 0px 10px;
    }
    .form-title {
      color: #777;
    }
    .close-button {
      justify-self: end;
      margin: 10px;
      border: 1px rgba(0, 0, 0, 0.5) solid;
      background: white;
      padding: 5px 15px 5px 15px;
    }
    .close-button:hover {
      background: rgba(0, 0, 0, 0.1);
      border-color: white;
      cursor: pointer;
    }
    .form-field {
      display: grid;
      grid-template-rows: 20px minmax(30px, auto);
      margin: 30px 0px;
      grid-row-gap: 10px;
    }
    .label {
      justify-self: start;
      margin-left: 1px;
    }
    .feedback-textarea {
        height: 100px;
        resize: none;
    }
    .removeButtonStyle {
      all: inherit;
      z-index: 1;
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
</style>

<script>
import Ex from "../../../Ex"
import { connect } from "../../../axiosPepApi"
export default {
  name: "FormFeedback",
  data() {
    return {
      title: null,
      message: null,
      link: null
    }
  },
  methods: {
    onSubmit() {
      let path = window.location.href;
      if (this.$store.getters.api === "pepApi") {
        let sharepointObject = {
          Titel: this.title,
          Kommentare: this.message,
          Link: path
        }

        connect(this.$store.getters.token).post('AddFeedback', {
          feedback: sharepointObject
        })
      } else {
        // send feedback to server
        let url = "/_api/web/lists/getbytitle('Feedback')/items"
        let feedback = {
          __metadata: { type: "SP.Data.FeedbackListItem" },
          Title: this.title,
          OData__Comments: this.message,
          Link: path
        }
        Ex.post(url, feedback)
        .catch(() => {
          console.log("error in feedback form submit")
        })
      }

      // reset variables after submit
      this.title = null
      this.message = null
      this.link = null

      // close modal
      this.closeModal()
    },
    closeModal() {
      this.$xmodal.close()
    }
  }
}
</script>
