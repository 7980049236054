import axios from 'axios'
const { convert } = require('html-to-text');

const state = {
  faq: null,
  loading: null
}

const actions = {
  loadFaq({commit, state}) {
    return new Promise((resolve, reject) => {
      // only load, if necessary
      if(!state.loading && state.faq === null) {
        commit('setFaqLoading', true)
        // load faq data
        axios.get("https://pep.erzwiss.uni-leipzig.de/wp-json/wp/v2/ufaq?per_page=100")
        .then(res => {
          let faqData = res.data
          // load faq tags
          axios.get("https://pep.erzwiss.uni-leipzig.de/wp-json/wp/v2/ufaq-tag")
          .then(res => {
            let tags = res.data
            // create faq
            let faq = []
            for(let item of faqData) {
              // find faq tags
              let tagIdList = item["ufaq-tag"]
              let tagList = []
              for (let tagId of tagIdList) {
                let tag = tags.find(tag => tag.id === tagId)
                if (tag) tagList.push(tag.name)
              }
              // add item to faq list
              faq.push({title: convert(item.title.rendered), content: item.content.rendered, tag: tagList})
            }
            commit('setFaq', faq)
            commit('setFaqLoading', false)
            resolve(faq)
          })
        })
        .catch(err => {
          setTimeout(() => {
            commit('setFaqLoading', false)
          }, 1000)
          console.log("Error while getting faq: " +err)
          reject(err)
        })
      }
    })
  }
}

const getters = {
  faq: state => {
    return state.faq;
  },
  faqLoading: state => {
    return state.loading;
  },
  filteredFaq: state => tag => {
    if (state.faq) {
      return state.faq.filter(item => item.tag.includes(tag));
    }
    return [];
  }
}

const mutations = {
  setFaq: (state, faq) => {
    state.faq = faq
  },
  setFaqLoading: (state, loading) => {
    state.loading = loading
  },
}



export default {
  state,
  mutations,
  getters,
  actions
}
