<template>
  <div>
    <div v-if="this.types.isEM">
      <div class="CardFormContainer GeneralCard">
        <div
          class="CardFormAppBar"
          :class="{ ABCU: !this.MaszCardStructure.heading }"
        >
          <div class="AppBarReturnZone" @click="backbutton()">
            <span class="material-icons" style="vertical-align: text-top">
              navigate_before
            </span>
            <span class="ReturnZoneBack">Zurück</span>
          </div>
          <div class="TitleZone" v-if="!this.MaszCardStructure.heading">
            <span v-if="MaszCardTitle" v-html="MaszCardTitle"> </span>
          </div>
        </div>
        <div
          v-bind:class="{
            FormTeaser: this.MaszCardStructure.heading,
            FormTeasernot: !this.MaszCardStructure.heading,
          }"
        >
          Wählen Sie bitte einen Einstieg. Sie können jederzeit zurück
          navigieren.
        </div>
        <!-- <div  v-for="(he, $index) in MaszCard" v-bind:key="$index">
        {{he.Title}}
        </div> -->
        <div class="natureBox" :style="{backgroundImage: 'url(' + EMBG + ')', backgroundSize: 'cover'}">
          <div
            class="natureBoxleft natureBoximage"
            :style="naturBoxComputed"
            :class="{ natureBoximagestart: this.MaszCardStructure.heading }"
          >
            <div
              class="ActionHeadingBox"
              v-if="!this.MaszCard[0].shrunken && !this.MaszCard[0].large"
            >
              <div class="callToActionHeading" @click="YouRChosen($event)">
                <span
                  v-if="!this.MaszCard[0].shrunken && !this.MaszCard[0].large"
                  v-html="this.MaszCard[0].Title"
                ></span>
              </div>
            </div>
            <div class="LeftiContainer">
              <div
                class="LeftiFirst"
                :class="{ firstscreen: this.SelectedMethod.weiter }"
              >
                <div

                  :style="methComputed3"
                  v-bind:class="[MethodeIntroClass]" v-on:click="isMobile() && MaszCardStructure.method ? {'click' : showMethInfo() } : null"

                  v-if="!this.MaszCard[1].aktiv"
                >
                  <div class="LeftiFirstIntroContainer" >
                    <span
                      class="IntroHeading"
                      :class="{
                        'slide-up-fade-in': !this.MaszCardStructure.heading && !isMobile() && !this.MaszCardStructure.method ,
                        vdt: this.SelectedMethod.weiter,
                      }"
                      >Methode wählen</span
                    >
                     <span class="closehelper" v-if="isMobile() && this.MaszCardStructure.method && !this.showMethInfos"
                        >X</span>
                <span class="material-icons" v-if="isMobile() && this.MaszCardStructure.method && this.showMethInfos" style="writing-mode: vertical-lr; cursor: pointer" >
info
</span>
                    <span
                      class="IntroDescription"
                      :class="{
                        'slide-up-fade-in': !this.MaszCardStructure.heading,
                        vdt: this.SelectedMethod.weiter,
                      }"
                      >
                      <br />
                      Methoden bieten das didaktische Gerüst, um planmäßig ein Entwicklungsziel zu erreichen. Im
                      nächsten  Schritt  wird  die  allgemeingültige  Handlungsempfehlung  der  Methode  zu  einer
                      Maßnahme präzisiert. Wählen Sie aus auf welcher Methode Ihre Maßnahme basiert.
                      <br /><br />
                      In der Übersicht finden Sie eine Auswahl von Methoden. Wollen Sie mehr über eine Methode
                      erfahren,  klicken  Sie  diese  an.  Haben  Sie  die  passende  Methode  gefunden,  klicken  Sie  bitte
                      unter dem Beschreibungstext auf „Weiter“.
                      <br /><br /><br />
                    </span>
                  </div>
                </div>
                <div v-bind:class="[MethodeDCClass]" >
                  <div
                    class="LeftiFirstSelection"
                    :style="methComputed"
                    :class="{ vd2: this.SelectedMethod.weiter }"
                  >
                    <div class="pillcontainer">
                      <div
                        class="methpill"
                        v-for="(meth, $index) in emethods"
                        v-bind:key="$index"
                        :class="{ highlight: meth.Id == SelectedMethod.Id }"
                        @click="selectM(meth)"
                      >
                        <div class="pilltextcontainer">
                          <span class="methpilltext">
                            {{ meth.Title | keepmain }}</span
                          >
                          <!-- <span class="methpilltextdescription"> {{ meth.Title | showelse }}</span> -->
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="LeftiFirstExplaination"
                    :style="methComputed2"
                    :class="{
                      vd3: this.SelectedMethod.weiter,
                      explainerhelper: this.CreatedAction.helper,
                      goneexplainer: this.MaszCard[1].aktiv,
                    }"
                    @click="showHelper()"
                  >
                    <div class="explainer" v-if="this.MaszCardStructure.method">
                      <!-- :class="{'slide-up-fade-in': this.MaszCardStructure.newmethod}" > -->
                      <span
                        class="DescriptionHeading"
                        :class="{
                          metheadline:
                            this.SelectedMethod.weiter &&
                            !this.CreatedAction.helper,
                        }"
                        >{{ this.SelectedMethod.Title | keepmain }}</span
                      >
                      <span class="closehelper" v-if="this.CreatedAction.helper"
                        >X</span
                      >
                      <div
                        v-if="
                          !this.SelectedMethod.weiter ||
                          this.CreatedAction.helper
                        "
                      >
                        <span class="DescriptionSubHeading">
                          {{ this.SelectedMethod.Title | showelse }}
                        </span>
                        <span class="ContentBlockContainer MethSub">Ziel</span>
                        <span>{{ this.SelectedMethod.Ziel }}</span>
                        <span class="ContentBlockContainer MethSub"
                          >Beschreibung</span
                        >
                        <span>{{ this.SelectedMethod.Beschreibung }}</span>
                        <div
                          style="margin-top: 24px"
                          v-if="!this.CreatedAction.helper"
                        >
                          <div
                            class="formactionbutton formactionbuttonweiter"
                            @click="MNext()"
                          >
                            <span class="bt">weiter</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="LeftiSecond"
                v-if="this.SelectedMethod.weiter"
                :class="{ LeftiSecondactive: this.CreatedAction.sichtbar }"
              >
                <div class="LeftiSecondIntro">
                  <div class="LeftiSecondtIntroContainer">
                    <span
                      class="IntroHeading"
                      v-if="this.SelectedMethod.weiter"
                      :class="{
                        'slide-up-fade-in2': this.SelectedMethod.weiter,
                      }"
                      >Maßnahme erstellen</span
                    >
                    <span
                      class="IntroDescription"
                      v-if="this.SelectedMethod.weiter"
                      :class="{
                        'slide-up-fade-in2': this.SelectedMethod.weiter,
                      }"
                      >
                      <br />
                      Maßnahmen  bilden  die  Kernelemente,  die  zur  Umsetzung  der  Entwicklungsziele  führen.
                      Individuell definierte Maßnahmen werden in der PEP-Methode für einen Zyklus geplant und
                      im Anschluss evaluiert.
                      <br /><br />
                      Erstellen Sie nun eine Maßnahme auf Grundlage Ihrer gewählten Methode. Füllen Sie dafür die
                      vorgegebenen Felder aus und klicken Sie auf „Maßnahme jetzt anlegen“.
                      <br /><br />
                      Indem  Sie  links  auf  den  Methodennamen  klicken,  öffnet  sich  erneut  die  Beschreibung.  Mit
                      einem  Klick  auf  „Zurück“,  gelangen  Sie  zur  Methodenauswahl.  Sie  können  Ihre  Eingaben
                      später noch ändern oder ergänzen.
                      <br /><br />
                    </span>
                  </div>
                </div>
                <div class="LeftiForm" v-if="this.CreatedAction.sichtbar">
                  <div class="grid-container">
                    <div class="Bezeichnung">
                      <div class="LeftiFormFieldWrap">
                        <span class="HeaderLabel MHeadL">Bezeichnung</span>
                        <input
                          class="LeftiFormField"
                          aria-multiline="true"
                          role="textbox"
                          contenteditable="true"
                          v-model="CreatedAction.Bezeichnung"
                        />
                      </div>
                    </div>

                    <div class="Sozialform">
                      <div class="LeftiFormFieldWrap">
                        <span class="HeaderLabel MHeadL">Sozialform</span>
                        <fv-DropDown
                          v-model="value.Sozialform"
                          :options="sozialform"
                          :style="selector"
                          placeholder="Sozialform wählen"
                          v-if="this.SelectedMethod.weiter"
                        >
                        </fv-DropDown>

                        <!-- <select class="LeftiFormSelect">
   <option>Einzelarbeit</option>
   <option>Gruppenarbeit</option>
   <option>Lehrervortrag</option>
 </select> -->
                      </div>
                    </div>
                    <div class="Zeitumfang">
                      <div class="LeftiFormFieldWrap">
                        <span class="HeaderLabel MHeadL">Zeitumfang</span>
                        <fv-DropDown
                          v-model="value.Zeitumfang"
                          :options="zeitumfang"
                          :style="selector"
                          placeholder="Zeitumfang bestimmen"
                          v-if="this.SelectedMethod.weiter"
                        >
                        </fv-DropDown>
                        <!-- <select class="LeftiFormSelect">
   <option>1-4 UE</option>
   <option>4-8 Wochen</option>
   <option>6 Monate +</option>
 </select> -->
                      </div>
                    </div>
                    <div class="Voraussetzungen">
                      <div class="LeftiFormFieldWrap">
                        <span class="HeaderLabel MHeadL"
                          >Voraussetzungen/Materialien</span
                        >
                        <textarea
                          class="LeftiFormField laengezwei"
                          aria-multiline="true"
                          role="textbox"
                          contenteditable="true"
                          v-model="CreatedAction.Voraussetzung"
                        >
                        </textarea>
                      </div>
                    </div>
                    <div class="Kurzbeschreibung">
                      <div class="LeftiFormFieldWrap">
                        <span class="HeaderLabel MHeadL">Kurzbeschreibung</span>
                        <textarea
                          class="LeftiFormField laengevier"
                          aria-multiline="true"
                          role="textbox"
                          contenteditable="true"
                          v-model="CreatedAction.Kurzbeschreibung"
                        >
                        </textarea>
                      </div>
                    </div>
                    <span class="Divider"></span>
                    <div class="EKinhaltlich">
                      <div class="LeftiFormFieldWrap">
                        <span class="HeaderLabel MHeadL"
                          >Evaluationskriterien inhaltlich</span
                        >
                        <textarea
                          class="LeftiFormField laengezwei"
                          aria-multiline="true"
                          role="textbox"
                          contenteditable="true"
                          v-model="CreatedAction.EKinhalt"
                        >
                        </textarea>
                      </div>
                    </div>
                    <div class="EKmethodisch">
                      <div class="LeftiFormFieldWrap">
                        <span class="HeaderLabel MHeadL"
                          >Evaluationskriterien methodisch</span
                        >
                        <textarea
                          class="LeftiFormField laengezwei"
                          aria-multiline="true"
                          role="textbox"
                          contenteditable="true"
                          v-model="CreatedAction.EKmethode"
                        >
                        </textarea>
                      </div>
                    </div>
                     </div>
                     <GeneralCardActionButtons
            :types="types"
            v-if="this.types.isEM"
            :cid="cid"
            :front="false"
            :new="true"
            tabindex="6"
            :isNew="true"
          />
                    <!-- <div class="ButtonS">
                      <div class="LeftiFormFieldWrap">
                        <div style="margin-top: 24px">
                          <div
                            class="formactionbutton formactionbuttonerstellen"
                            @click="MNsSave()"
                          >
                            <span class="bt">Maßnahme erstellen</span>
                          </div>
                        </div>
                      </div>
                    </div> -->

                </div>
              </div>
            </div>
          </div>

          <div
            class="natureBoxright natureBoximage"
            :style="naturBoxComputed2"

            :class="{ natureBoximagestart: this.MaszCardStructure.heading }"
          >
            <div
              class="ActionHeadingBox"
              v-if="!this.MaszCard[1].shrunken && !this.MaszCard[1].large"
            >
              <div class="callToActionHeading" @click="YouRChosen($event)">
                <span
                  v-if="!this.MaszCard[1].shrunken && !this.MaszCard[1].large"
                  v-html="this.MaszCard[1].Title"
                ></span>
              </div>
            </div>
            <div class="RightiContainer">
              <div class="RightiFirst" :class="{ vd4: this.goingold.weiter }">
                <div class="RightiList">
                  <div class="RightiFirstIntro" v-if="!this.MaszCard[0].aktiv">
                    <span
                      class="IntroHeading rightitexti"
                      :class="{
                        'slide-up-fade-in': this.MaszCard[1].aktiv,
                      }"
                      >Übersicht der bestehenden Maßnahmen</span
                    >
                    <span
                      class="IntroDescription rightitexti"
                      :class="{
                        'slide-up-fade-in': this.MaszCard[1].aktiv,
                      }"
                      >Wählen Sie bereits erstelle Maßnahmen aus ihrem
                      Maßnahmenkatalog aus. Sie können die Maßnahme
                      wiederverwenden oder als Vorlage benutzen und anschließend
                      bearbeiten.
                    </span>
                  </div>
                  <div class="ListFilterContainer">
                    <span class="Filterlabel">Stichwortsuche</span>
                    <input v-model="listfilter" class="LeftiFormField" @keyup.enter.prevent/>
                  </div>
                  <div class="RightiListContainer">
                    <fv-DetailsList
                      :value="maszind"
                      :head="rightihead"
                      :compact="false"
                      :filter="listfilter"
                      @choose-items="usedselection"
                    >
                      <template v-slot:column_0="x">
                        <p>{{ x.item.Title }}</p>
                      </template>
                      <template v-slot:column_1="x">
                        <p class="sec">{{ x.item.RefMeth.Title }}</p>
                      </template>
                      <template v-slot:column_2="x">
                        <p class="sec">{{ x.item.Zeitumfang }}</p> </template
                      ><template v-slot:column_3="x">
                        <p class="sec">{{ x.item.Sozialform }}</p>
                      </template>
                    </fv-DetailsList>
                  </div>
                </div>
              </div>
              <div class="RightiSecond" :class="{ vd4: !this.goingold.weiter }">
                <div class="RightiDataCombo">
                  <div
                    :class="{ hiddenRighti: this.goingold.change }"
                    class="RightiFirstExplaination"
                  >
                    <div class="explainer" v-if="this.goingold.weiter">
                      <!-- :class="{'slide-up-fade-in': this.MaszCardStructure.newmethod}" > -->
                      <span
                        class="DescriptionHeading"
                        :class="{
                          metheadline:
                            this.SelectedMethod.weiter &&
                            !this.CreatedAction.helper,
                        }"
                        >{{ this.UsedMethod.RefMeth.Title | keepmain }}</span
                      >
                      <span class="closehelper" v-if="this.CreatedAction.helper"
                        >X</span
                      >
                      <div
                        v-if="
                          !this.SelectedMethod.weiter ||
                          this.CreatedAction.helper
                        "
                      >
                        <span class="DescriptionSubHeading">
                          {{ this.UsedMethod.RefMeth.Title | showelse }}
                        </span>
                        <span class="ContentBlockContainer MethSub">Ziel</span>
                        <span>{{ this.UsedMethod.RefMethZiel }}</span>
                        <span class="ContentBlockContainer MethSub"
                          >Beschreibung</span
                        >
                        <span>{{ this.UsedMethod.RefMethBeschr }}</span>
                        <div
                          style="margin-top: 24px"
                          v-if="!this.CreatedAction.helper"
                        >
                          <div
                            class="formactionbutton formactionbuttonweiter"
                            @click="changeMeth()"
                          >
                            <span class="bt">ändern</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    :class="{
                      RightiChangeCombo: this.goingold.change,
                      vd2: !this.goingold.change,
                    }"
                  >
                    <div class="RightiFirstExplaination">
                      <div class="explainer" v-if="this.goingold.weiter">
                        <!-- :class="{'slide-up-fade-in': this.MaszCardStructure.newmethod}" > -->
                        <span
                          class="DescriptionHeading"
                          :class="{
                            metheadline:
                              this.SelectedMethod.weiter &&
                              !this.CreatedAction.helper,
                          }"
                          >{{ this.UsedMethod.RefMeth.Title | keepmain }}</span
                        >
                        <span
                          class="closehelper"
                          v-if="this.CreatedAction.helper"
                          >X</span
                        >
                        <div
                          v-if="
                            !this.SelectedMethod.weiter ||
                            this.CreatedAction.helper
                          "
                        >
                          <span class="DescriptionSubHeading">
                            {{ this.UsedMethod.RefMeth.Title | showelse }}
                          </span>
                          <span class="ContentBlockContainer MethSub"
                            >Ziel</span
                          >
                          <span>{{ this.UsedMethod.RefMethZiel }}</span>
                          <span class="ContentBlockContainer MethSub"
                            >Beschreibung</span
                          >
                          <span>{{ this.UsedMethod.RefMethBeschr }}</span>
                          <div
                            style="margin-top: 24px"
                            v-if="!this.CreatedAction.helper"
                          >
                            <div
                              class="formactionbutton formactionbuttonweiter"
                              @click="changedMeth()"
                            >
                              <span class="bt">übernehmen</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      :style="methComputed"
                      class="righti"
                      :class="{
                        LeftiFirstSelection: this.goingold.change,
                        vd2: !this.goingold.change,
                      }"
                    >
                     <div class="pillcontainer" :class="{pillinvisible : !this.goingold.change}">
                      <div
                        class="methpill"
                        v-for="(oldmeth, $index) in emethods"
                        v-bind:key="$index"
                        :class="{ highlight: oldmeth.Id == UsedMethod.RefMeth.Id }"
                        @click="selectOM(oldmeth)"
                      >
                        <div class="pilltextcontainer">
                          <span class="methpilltext">
                            {{ oldmeth.Title | keepmain }}</span
                          >
                          <!-- <span class="methpilltextdescription"> {{ meth.Title | showelse }}</span> -->
                        </div>
                      </div>
                    </div></div>
                    <div
                      class="LeftiFirstExplaination"
                      :style="methComputed2"
                      :class="{
                        vd3: this.SelectedMethod.weiter,
                        explainerhelper: this.CreatedAction.helper,
                        goneexplainer: this.MaszCard[1].aktiv,
                      }"
                      @click="showHelper()"
                    ></div>
                  </div>
                  <div
                    class="RightiForm"
                    :class="{ hiddenRighti: this.goingold.change }"
                  >
                    <div class="grid-container">
                      <div class="Bezeichnung">
                        <div class="LeftiFormFieldWrap">
                          <span class="HeaderLabel MHeadL">Bezeichnung</span>
                          <textarea
                            class="LeftiFormField"

                            v-model="UsedMethod.Title"

                         ></textarea>


                        </div>
                      </div>

                      <div class="Sozialform">
                        <div class="LeftiFormFieldWrap">
                          <span class="HeaderLabel MHeadL">Sozialform</span>
                          <fv-DropDown
                            v-model="CSozial"
                            :options="oldsozialform"
                            :style="selector"
                            :value="CSozial"
                            placeholder="Sozialform wählen"
                            v-if="this.goingold.weiter"
                          >
                          </fv-DropDown>
                        </div>
                      </div>
                      <div class="Zeitumfang">
                        <div class="LeftiFormFieldWrap">
                          <span class="HeaderLabel MHeadL">Zeitumfang</span>
                          <fv-DropDown
                            v-model="CZeit"
                            :options="oldzeitumfang"
                            :style="selector"
                            :value="CZeit"
                            placeholder="Zeitumfang bestimmen"
                            v-if="this.goingold.weiter"
                          >
                          </fv-DropDown>
                        </div>
                      </div>
                      <div class="Voraussetzungen">
                        <div class="LeftiFormFieldWrap">
                          <span class="HeaderLabel MHeadL"
                            >Voraussetzungen/Materialien</span
                          >
                          <textarea
                            class="LeftiFormField laengezwei"
                            aria-multiline="true"
                            role="textbox"
                            contenteditable="true"
                            v-model="UsedMethod.Voraussetzungen_x002f_Materialie"
                            />

                        </div>
                      </div>
                      <div class="Kurzbeschreibung">
                        <div class="LeftiFormFieldWrap">
                          <span class="HeaderLabel MHeadL"
                            >Kurzbeschreibung</span
                          >
                          <textarea
                            class="LeftiFormField laengevier"
                            aria-multiline="true"
                            role="textbox"
                            contenteditable="true"
                            v-model="UsedMethod.Kurzbeschreibung "

                            />

                        </div>
                      </div>
                      <span class="Divider"></span>
                      <div class="EKinhaltlich">
                        <div class="LeftiFormFieldWrap">
                          <span class="HeaderLabel MHeadL"
                            >Evaluationskriterien inhaltlich</span
                          >
                          <textarea
                            class="LeftiFormField laengezwei"
                            aria-multiline="true"
                            role="textbox"
                            contenteditable="true"
                            v-model="UsedMethod.Evaluationskriterien_x0020__x002 "
                          ></textarea>


                        </div>
                      </div>
                      <div class="EKmethodisch">
                        <div class="LeftiFormFieldWrap">
                          <span class="HeaderLabel MHeadL"
                            >Evaluationskriterien methodisch</span
                          >
                          <textarea
                            class="LeftiFormField laengezwei"
                            aria-multiline="true"
                            role="textbox"
                            contenteditable="true"
                            v-model="UsedMethod.Evaluationskriterien_x0020__x0020 "
                          ></textarea>

                                                  </div>
                      </div>
                      <!-- <div class="ButtonS">
                        <div class="LeftiFormFieldWrap">
                          <div style="margin-top: 24px">
                            <div
                              class="formactionbutton formactionbuttonerstellen"
                              @click="MNsSave()"
                            >
                              <span class="bt">Maßnahme erstellen</span>
                            </div>
                          </div>
                        </div>
                      </div> -->
                    </div>
                       <GeneralCardActionButtons
            :types="types"
            v-if="this.types.isEM"
            :cid="cid"
            :front="false"
            :new="true"
            tabindex="6"
            :isNew="true"
            :isUsed="true"
          />

                  </div>
                </div>
              </div>
              <div
                class="RightiFoot"
                :class="{
                  RightiFootShown: this.UsedMethodhelper.Id && !this.goingold.next,
                  RightiFootCollaps: this.goingold.next,
                }"
                v-if="this.MaszCard[1].aktiv"
              >
                <div
                  class="formactionbutton formactionbuttonweiter"
                  @click="RMNext()"
                >
                  <span class="bt">weiter</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="this.types.isZC">
      <div class="CardFormContainer GeneralCard">
        <div class="mountainBox">
          <object type="image/svg+xml" class="svgimg-goal" :data="meinsvg"></object>
          <div class="bannerContainer" >
            <div  v-bind:class="[ZielBannerClass]" v-on:click="isMobile() && zielfront ? {'click' : showInfo() } : null">
              <div
                class="LeftiFirstIntroContainer"
                style="overflow-y: auto; height: calc(100% - 56px)"
              >
                <div class="intro-headline-container">
                  <span class="IntroHeading">Neues Ziel anlegen</span>
                  <helper-icon-callout v-if="!isMobile()" :Title="'Info'" :Main="'Legen Sie so viele Entwicklungsziele an, dass alle ausgewählten Entwicklungsbereiche mit mindestens einem Ziel abgedeckt sind.'"></helper-icon-callout>
                </div>
                 <span class="closehelper" v-if="isMobile() && this.zielfront && !this.showInfos"
                        >X</span>
                <span class="material-icons" v-if="isMobile() && this.zielfront && this.showInfos" style="writing-mode: vertical-lr; cursor: pointer" >
                info
                </span>
                <span
                  class="IntroDescription"
                  :class="{
                    'slide-up-fade-in': !this.MaszCardStructure.heading,
                    vdt: this.SelectedMethod.weiter,
                  }"
                  >
                  <br />
                  Aus den Entwicklungsbereichen leiten sich die Entwicklungsziele ab. Je nach Anzahl der
                  Entwicklungsbereiche können sich mehrere Entwicklungsziele ergeben. Ein Entwicklungsziel
                  gibt an, was die {{$entgendern.pupil}} nach einem bestimmten Lernabschnitt können soll.
                  <br /><br />
                  Um ein neues Ziel zu erstellen, klicken Sie bitte in der Kachel rechts auf das „+“-Symbol.
                  Tragen Sie im Feld „Bezeichnung“ den Titel des Ziels ein. Im Feld „Entwicklungsstand“
                  beschreiben Sie die Ausgangslage. Das konkrete Entwicklungsziel formulieren Sie im
                  gleichnamigen Feld. Beschreiben Sie dabei das beobachtbare Endverhalten der {{$entgendern.pupil}}.
                  Nutzen Sie bei der Formulierung des Entwicklungsstands und -ziels zum Beispiel die Formel
                  „{{$entgendern.article_nominativ}} {{$entgendern.pupil}} kann ...“.
                  <br /><br /><br />
                  </span
                >
              </div>
            </div>
            <div  v-bind:class="[ZielBannerClassRight]">
              <div class="bannerrightfront" >
              <span class="material-icons addgoal" :class="{bannerlefthidden : zielfront}" @click="Zielfronta()">
              add_location
                </span>
              </div>

              <div class="bannerrightback"  :class="{bannerrightbackshown : zielfront}">
                <GeneralCardHeader
            :types="types"
            :cid="cid"
            :cardtitle="cardtitle"
            :isEdit="true"
            :isNew="true"
            tabindex="1"
            :helperIcon="{
              title: 'Info',
              main: 'Wählen Sie eine aussagekräftige Bezeichnung für das Ziel'
            }"
          >
            <template v-slot:headerslot>
              <input
                required
                v-model="FormField.Title"
                type="text"
                id="#title"
                tabindex="2"
                class="HeaderTitle"
              />
            </template>
          </GeneralCardHeader>
          <GeneralCardContent
            :types="types"
            :cid="cid"
            :contentone="contentone"
            :contenttwo="contenttwo"
            :isEdit="true"
            :isNew="true"
            :helperIcon="[
              {
                title: 'Info',
                main: 'Beschreiben Sie den aktuellen Entwicklungsstand im Hinblick auf das zu erreichende Ziel'
              },
              {
                title: 'Entwicklungsziele sind:',
                main: `1. positiv und in ganzen Sätzen formuliert,
                  \n2. für die SodS erreichbar,
                  \n3. messbar und
                  \n4. beschreiben einen konkreten Zustand.`
              }
            ]"
          >
            <template slot="contentslot" slot-scope="{a, aha}">
              <textarea
                class="ContentField ContentEditField"
                required
                :a="a"
                :aha="aha"
                v-model="FormField[aha]"
                type="text"
                tabindex="3"
              ></textarea>
            </template>
          </GeneralCardContent>

          <GeneralCardActionButtons
            :types="types"
            v-if="this.types.isZC"
            :cid="cid"
            :front="false"
            :new="true"
            :isNew="true"
          />
          <p v-if="isThereNoZielDefined" class="info-entwicklungsziel">Legen Sie mindestens 1 Entwicklungsziel an.</p>

          <p v-if="FormField.errors.length && !FormField.success" style="padding-left: 5%" class="submiterror">
            <span >Ziel kann nicht erstellt werden. Bitte füllen Sie noch folgende Felder aus:</span>
              <ul>
                <li v-for="(error, index) in FormField.errors" :key="index" > {{error}}</li>

                  </ul>


          </p>
          <p v-if="FormField.success" style="padding-left: 5%; text-align: center">
            <span :class="{goalSuccess: FormField.success}">Ziel erfolgreich angelegt</span>
          </p>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="GeneralCard CardContentContainer"
      v-bind:class="[classObject]"
      :id="'Card' + cid"
      v-if="!types.isZC "
    >
      <div class="formvorderseite">
        <div class="forminnenseite">
          <form >
          <GeneralCardHeader
            :types="types"
            :cid="cid"
            :cardtitle="cardtitle"
            :isEdit="true"
          >
            <template v-slot:headerslot>
              <input
                required
                v-model="FormField.Title"
                type="text"
                :id="'#title' + FormField.Title"
                tabindex="4"
                class="HeaderTitle HeaderEditTitle"
                v-if="!types.isEB"
              />
            </template>
          </GeneralCardHeader>
          <GeneralCardContent
            :types="types"
            :cid="cid"
            :contentone="contentone"
            :contenttwo="contenttwo"
            :isEdit="true"
          >
            <template slot="contentslot" slot-scope="{a, aha}" >
              <textarea
                class="ContentField ContentEditField"
                required
                :a="a"
                :aha="aha"
                v-model="FormField[aha]"
                type="text"
                tabindex="5"
              ></textarea>
            </template>
          </GeneralCardContent>

          <GeneralCardActionButtons
            :types="types"
            v-if="this.types.isZC"
            :cid="cid"
            :front="false"
            :new="true"
            tabindex="6"
          />
          </form>
        </div>
      </div>

      <div v-if="this.types.isEB" class="FormFront BereichFront">
        <div class="forminnenseite FormSideCont BereichFromInnen">
          <div
            class="BereichDotted"
            :class="[{ DottedBackground: !this.colorClass }, this.colorClass]"
          >
            <slot name="bereichslot"></slot>
            <!-- <h3 class="anlegentext">Entwicklungsbereiche definieren</h3> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/*global _spPageContextInfo*/
/*eslint no-undef: "error"*/
import GeneralCardActionButtons from "./GeneralCardActionButtons";
import GeneralCardContent from "./GeneralCardContent";
import GeneralCardHeader from "./GeneralCardHeader";
// import Ex from "../../../../src/Ex";

export default {
  props: {
    zid: {
      type: Number,
    },
    cardtitle: String,
    contentone: {
      type: String,
      default: null,
    },
    contenttwo: {
      type: String,
      default: null,
    },
    cid: {
      type: Number,
      default: 987654,
    },
    shrunken: Boolean,
    showback: Boolean,
    index: {
      type: Number,
      default: 0,
    },
    colorClass: {
      type: String,
      default: null,
    },
    emethods: {},
    maszind: {},
    types: {
      isEP: Boolean,
      isEB: Boolean,
      isZC: Boolean,
    },
  },

  data() {
    return {
      EMBG: require(`../../../assets/Blender.jpg`),
      selected: false,
      selectedvalue: "",
      datasets: null,
      back: false,
      zielfront: true,
      title: null,
      iststand: null,
      entwicklungsziel: null,
      Ausgangslage: null,
      saveButtonIsClicked: false,
      value: {
        Sozialform: "",
        Zeitumfang: "",
      },
      sozialform: [
        { key: "Einzelarbeit", text: "Einzelarbeit" },
        { key: "Gruppenarbeit", text: "Gruppenarbeit" },
        { key: "Lehrervortrag", text: "Lehrervortrag" },
      ],
      oldsozialform: [
        { key: "Einzelarbeit", text: "Einzelarbeit", choosen: false },
        { key: "Gruppenarbeit", text: "Gruppenarbeit", choosen: false },
        { key: "Lehrervortrag", text: "Lehrervortrag", choosen: false },
      ],
      CSozial: [],
      zeitumfang: [
        { key: "1-4 UE", text: "1-4 UE" },
        { key: "4-8 Wochen", text: "4-8 Wochen" },
        { key: "6 Monate +", text: "6 Monate +" },
      ],
      oldzeitumfang: [
        { key: "1-4 UE", text: "1-4 UE", choosen: false },
        { key: "4-8 Wochen", text: "4-8 Wochen", choosen: false },
        { key: "6 Monate +", text: "6 Monate +", choosen: false },
      ],
      CZeit: [],
      MaszCard: [
        {
          Title: "Eine neue Maßnahme anlegen",
          aktiv: false,
          shrunken: false,
          large: false,
        },
        {
          Title: "Vorhandene Maßnahme verwenden",
          aktiv: false,
          shrunken: false,
          large: false,
        },
      ],
      MaszCardStructure: {
        heading: true,
        method: false,
        newmethod: false,
      },
      SelectedMethod: {
        Title: "",
        Ziel: "",
        Beschreibung: "",
        Id: "",
        weiter: false,
      },
      CreatedAction: {
        Bezeichnung: null,
        Kurzbeschreibung: null,
        Voraussetzung: null,
        EKinhalt: "",
        EKmethode: "",
        sichtbar: false,
        helper: false,
        speicherbar: false,
      },
      FormField: {
        Title: null,
        Entwicklungsstand: null,
        Entwicklungsziel: null,
        errors: [],
        success: false,
      },
      rightihead: [
        {
          content: "Maßnahme",
          sortName: "Title",
        },
        {
          content: "Methode",
          sortName: "RefMeth.Title",
        },
        {
          content: "Zeitumfang",
          sortName: "Zeitumfang",
        },
        {
          content: "Sozialform",
          sortName: "Sozialform",
        },
      ],
      listfilter: "",
      UsedMethod: {
        RefMeth: {
          Title: ""
        }
      },
      UsedMethodhelper: {
        Id: "",
      },
      showInfos: {
        type: Boolean,
        default: false,
      },
      showMethInfos: {
        type: Boolean,
        default: false,
      },
      goingold: {
        weiter: false,
        next: false,
        change: false,
      },
      meinsvg: require(`../../../assets/bb3.svg`),
    };
  },
  filters: {
    keepmain: function (value) {
      if (value) {
        // return value.replace(/ \([\s\S]*?\)/g, '');
        return value.split("(")[0];
      }
    },
    showelse: function (value) {
      if (value) {
        //  var regExp = /\(([^)]+)\)/;
        // var short = regExp.exec(value);
        //  var text = short[0];
        // return value.replace(/ \([\s\S]*?\)/g, '');
        //  var text = value.split("(")[1]("a")[1]
        //return short[1]
        var regExp = /\(([^)]+)\)/;
        var val = value;
        if (val.indexOf("(") >= 1) {
          var short = regExp.exec(val);
          return short[1];
        } else "";
      }
    },
  },
  computed: {
    isThereNoZielDefined() {
      const ziele = this.$store.getters.entZiele()
      return ziele && ziele.length === 0
    },
    classObject: function () {
      return {
        EntwicklungsplanCard: this.types.isEP,
        DarkSideoftheMoon: !this.back,
        EBCardselected: this.types.isEB && this.selected,
        ZielCard: this.types.isZC,
        EntwicklungsbereichCard: this.types.isEB,
        EntwicklungsbereichCardFrame: this.types.isEB,
      };
    },
    color: function () {
      return {
        //  this.colorClass ? 'dark-theme' : 'light-theme';
        // 'DottedBackground': !this.colorClass
        // this.colorClass: this.colorClass
      };
    },
    MaszCardTitle: function () {
      let a = this.MaszCard;
      let mt = a.filter(function (elem) {
        if (elem.aktiv == true) return elem.Title;
      });
      // console.log(mt[0].Title);
      if (!this.MaszCardStructure.heading) {
        return mt[0].Title;
      } else {
        return "";
      }
    },
    naturBoxComputed: function () {
      let d = this.MaszCard[0];

      if (d.shrunken) {
        return {
          width: "0%",
        };
      }
      if (d.large) {
        return {
          width: "100%",
        };
      } else {
        return {};
      }
    },
    naturBoxComputed2: function () {
      let d = this.MaszCard[1];
      // console.log(d)
      if (d.shrunken) {
        return {
          width: "0%",
        };
      }
      if (d.large) {
        return {
          width: "100%",
        };
      } else {
        return {};
      }
    },
    methComputed: function () {
      let d = this.MaszCardStructure.method;
      // console.log(d)
      // let e = this.SelectedMethod.weiter;
      // //  console.log(d);
      // if (e) {
      //   return {
      //     width: "0%",
      //     padding: "0px",
      //     transition: "width 300ms cubic-bezier(0.4, 0, 0.2, 1)",
      //     "transition-delay": "450ms",
      //   };
      // }
      if (d) {
        return {
          width: "calc((100% / 3)*2)",

          transition: "width 300ms cubic-bezier(0.4, 0, 0.2, 1)",
        };
      }
      if (!d) {
        return {
          width: "100%",
          transition: "width 300ms cubic-bezier(0.4, 0, 0.2, 1)",
        };
      } else {
        return {};
      }
    },
    methComputed2: function () {
      let d = this.MaszCardStructure.method;
      let e = this.SelectedMethod.weiter;
      // console.log(d)
      if (!d) {
        return {
          width: "0%",
          padding: "0px",
          transition: "width 300ms cubic-bezier(0.4, 0, 0.2, 1)",
        };
      }
      if (d && !e) {
        return {
          //  width: "calc(100% / 3)",
          width: "50%",
          transition: "width 300ms cubic-bezier(0.4, 0, 0.2, 1)",
          padding: "28px",
        };
      }
      // if (e) {
      //   return {
      //     width: "0px",
      //     transition: "width 300ms cubic-bezier(0.4, 0, 0.2, 1)",
      //     "transition-delay": "500ms",
      //     "box-shadow": "4px 10px 14px 0px #000000",
      //   };
      // }
      else {
        return {};
      }
    },
    methComputed3: function () {
      let e = this.SelectedMethod.weiter;
      //  console.log(d);
      if (e) {
        return {
          //  width: "0%",
          padding: "0px",
          //  opacity: "0",
          //  transition: "width 300ms cubic-bezier(0.4, 0, 0.2, 1), opacity 150ms linear",
          // "animation-name": "verduenne",
          // animation : "verduenne cubic-bezier(0.4, 0, 0.2, 1)",
          //   "animation-duration": "5s",
          //    "animation-iteration-count": "1",
          // "transition-delay": "150ms",
        };
      } else {
        return {};
      }
    },
    selector: function () {
      return {
        //  width: "0%",
        "font-size": "20px",
      };
    },
    // methComputed4: function () {
    //   let e = this.SelectedMethod.weiter;
    //   //  console.log(d);
    //   if (e) {
    //     return {
    //       width: "56px",
    //       padding: "0px",
    //       transition: "width 300ms cubic-bezier(0.4, 0, 0.2, 1)",
    //       "transition-delay": "500ms",
    //     };
    //   } else {
    //     return {};
    //   }
    // },

  //   var such = {"Entwicklungsziel": "aaa", "Id": "22222", "IstStand": "bbbbb", "Title": "asdsds"}
  // $vm0.zielkaertchen =  $vm0.zielkaertchen.concat(such);
ZielBannerClass: function() {
return {
  bannerleft: !this.zielfront && this.showInfos || !this.isMobile() || this.showInfos,
  bannerleftmobile: this.isMobile() && this.zielfront && this.showInfos,
  bannerleftinfo: !this.showInfos

} },
MethodeIntroClass: function() {
  return {
 LeftiFirstIntro : !this.MaszCardStructure.method && this.showMethInfos  || this.MaszCardStructure.method && !this.isMobile() ,
  LeftiFICmobile: this.MaszCardStructure.method && this.isMobile() && this.showMethInfos &&  !this.SelectedMethod.weiter,
  LeftiFICmobileInfo: !this.showMethInfos,
  vd1: this.SelectedMethod.weiter
 // bannerleftmobile: this.isMobile() && this.zielfront && this.showInfos,
 // bannerleftinfo: !this.showInfos

} },
MethodeDCClass: function() {
  return {
 LeftiDataCombo : !this.MaszCardStructure.method || this.MaszCardStructure.method && !this.isMobile(),
 LDCmobile: this.MaszCardStructure.method && this.isMobile() &&  !this.SelectedMethod.weiter,



} },
ZielBannerClassRight: function() {
  return {
    bannerright: !this.zielfront || !this.isMobile(),
    bannerrightmobile: this.isMobile() && this.zielfront
  }
},
    goalLength() {
          return this.$store.getters.EZiele.length
        },
            maszLength() {
          return this.$store.getters.EMasz.length
        },
},
  created() {
    this.$on("click", function (selectedT) {
      this.selected = true;
      this.selectedvalue = selectedT;
    });
    this.$on("unclick", function (selectedT) {
      this.selected = false;
      this.selectedvalue = selectedT;
    });
    this.$on("abort", function () {
      this.zielfront = false;
    });
    // this.$on('submittheform', data => {
    //      console.log("Receiving events", data);
    //     this.SubmitTheForm() } )
    this.$on("submittheform", () => {
      // console.log("Receiving events", data);
      this.SubmitTheForm();
    });
     this.$on("submittheformmn", () => {
      // console.log("Receiving events");
      this.ReallySubmitTheFormMN();
    });
     this.$on("submittheformomn", () => {
      // console.log("Receiving events");
      this.ReallySubmitTheFormOMN();
    });
    if (this.showback == true) {
      this.back = true;
    }
  },
  methods: {
    Zielfronta: function () {
      this.zielfront = !this.zielfront;
      // console.log("pup2" + this.zielfront);
    },
    FlipTheForm: function () {
      //   let flippi = event.target.closest('.CardContentContainer');
      let flippi = document.querySelector(".CardContentContainer");
      // console.log(flippi);
      // console.log(flippi)
      this.back = true;
      flippi.classList.toggle("DarkSideoftheMoon");
    },
    SubmitTheForm() {
      this.FormField.success = false;

      if (
        this.FormField.Title &&
        this.FormField.Entwicklungsziel &&
        this.FormField.Entwicklungsstand
      ) {
        return this.ReallySubmitTheForm();
      }
      this.FormField.errors = [];
      if (!this.FormField.Title) {
        this.FormField.errors.push("Bezeichnung fehlt");
      }
      if (!this.FormField.Entwicklungsziel) {
        this.FormField.errors.push("Entwicklungsziel fehlt");
      }
      if (!this.FormField.Entwicklungsstand) {
        this.FormField.errors.push("Entwicklungsstand fehlen");
      }
      // document.querySelector('.eindeutig').scrollIntoView({block:"end", behaviour: "smooth"})
    },

    ReallySubmitTheForm() {
        let goal =  {
          Id: this.$store.getters.nextEntZielId,
          RefZ: this.zid,
          Title: this.FormField.Title,
          IstStand: this.FormField.Entwicklungsstand,
          Entwicklungsziel: this.FormField.Entwicklungsziel,
          EvalObj: null,
          Reuse: false,
          EBereiche: null
        }
        this.$store.dispatch("addEntZiel", goal)

        this.FormField.success = true;
        // reset variables after submit
        this.FormField.Title = null;
        this.FormField.Entwicklungsziel = null;
        this.FormField.Entwicklungsstand = null;
        // reset view
        setTimeout(() => {
          this.zielfront = false
        }, 200)
    },

    YouRChosen: function (event) {
      let chosentitle = event.target.textContent;
      // console.log(chosentitle);
      let titlechoice = this.MaszCard;
      let chosenelement = titlechoice.find(
        (element) => element.Title == chosentitle
      );
      let notchosenelement = titlechoice.find(
        (element) => element.Title != chosentitle
      );
      chosenelement.aktiv = true;
      notchosenelement.aktiv = false;
      chosenelement.shrunken = false;
      notchosenelement.shrunken = true;
      chosenelement.large = true;
      notchosenelement.large = false;
      this.MaszCardStructure.heading = false;
    },
    backbutton: function () {
      if (this.SelectedMethod.weiter ) {
        this.SelectedMethod.weiter = !this.SelectedMethod.weiter;
      }

      if (this.CreatedAction.sichtbar) {
        this.CreatedAction.sichtbar = !this.CreatedAction.sichtbar;
        this.SelectedMethod.weiter = false;
        this.CreatedAction.helper = false;
      }
      if (this.goingold.weiter) {
        this.goingold.weiter = !this.goingold.weiter;
        this.goingold.next = false;}

        if ((!this.SelectedMethod.weiter && !this.CreatedAction.sichtbar) || this.goingold.weiter )
        {
          let titlechoice = this.MaszCard;
          let chosenelement = titlechoice.find(
             (element) => element.aktiv == true
          );
          let notchosenelement = titlechoice.find(
            (element) => element.aktiv != true
          );
          if(chosenelement) {
            chosenelement.aktiv = false
            chosenelement.shrunken = false
            chosenelement.large = false
          }
          if(notchosenelement) notchosenelement.shrunken = false
          this.MaszCardStructure.heading = true
        }
      // } else {
      //   let titlechoice = this.MaszCard;
      //   let chosenelement = titlechoice.find(
      //     (element) => element.aktiv == true
      //   );
      //   let notchosenelement = titlechoice.find(
      //     (element) => element.aktiv != true
      //   );
      //   (chosenelement.aktiv = false),
      //     (chosenelement.shrunken = false),
      //     (chosenelement.large = false),
      //     (notchosenelement.shrunken = false),
      //     (this.MaszCardStructure.heading = true);
      // }
    },
    usedselection: function (value) {
      let usedid = value[0].Id;
      let dataofusedid = this.UsedMethodhelper.Id;
      if (usedid == dataofusedid) {
        this.UsedMethodhelper.Id = "";
        let query = document.querySelector("div.content-row.choose")
        if(query) query.classList.remove("choose");
        // console.log(document.find(".content-row "));
        //  event.target.classList.remove("choose")
      } else {
        this.UsedMethodhelper.Id = value[0].Id;
        this.UsedMethod = value[0]
      }
      // console.log(this.UsedMethod.Id)
    },
    findchosen: function () {
      let a = this.UsedMethod.Sozialform;
      let b = this.UsedMethod.Zeitumfang;
      let a1 = this.oldsozialform;
      let b1 = this.oldzeitumfang;
      let finda = a1.find((element) => element.key == a);
      let findb = b1.find((element) => element.key == b);
      if(finda) finda.choosen = true;
      if(findb) findb.choosen = true;
      this.Czeit();
      this.Csozial();
    },
    Czeit: function () {
      let a = [];
      let b = this.oldzeitumfang;
      // console.log(b);
      let e = b.find((element) => element.choosen == true);
      let arr = [...a, e];
      if(e) this.CZeit = arr;
      else this.CZeit = []
    },
    Csozial: function () {
      let a = [];
      let b = this.oldsozialform;
      // console.log(b);
      let e = b.find((element) => element.choosen == true);

      let arr = [...a, e];
      if(e) this.CSozial = arr;
      else this.CSozial = []
    },
    changeMeth: function () {
      this.goingold.change = true;
    },
    changedMeth: function () {
      this.goingold.change = false;
    },
    selectM: function (meth) {
      //console.log(meth);
      //this.MaszCardStructure.newmethod = false;
      this.MaszCardStructure.method = true;
      this.SelectedMethod.Title = meth.Title;
      this.SelectedMethod.Ziel = meth.Ziel;
      this.SelectedMethod.Beschreibung = meth.Beschreibung_x0020__x0028_kurz_x;
      this.SelectedMethod.Id = meth.Id;
      this.MaszCardStructure.newmethod = !this.MaszCardStructure.newmethod;
    },
    selectOM: function (oldmeth) {
      this.UsedMethod.RefMeth.Id = oldmeth.Id;
      this.UsedMethodhelper = oldmeth.Id;
      this.UsedMethod.RefMeth.Title = oldmeth.Title;
      this.UsedMethod.RefMethZiel = oldmeth.Ziel;
      this.UsedMethod.RefMethBeschr = oldmeth.Beschreibung_x0020__x0028_kurz_x;
    },
    MNext: function () {
      this.SelectedMethod.weiter = true;
      setTimeout(function () { this.showsecondpage() }.bind(this), 400)
      // setTimeout(
      //   function () {
      //     this.showsecondpage();
      //   }.bind(this),
      //   400
      // );
    },
    RMNext: function () {
      this.goingold.weiter = true;
      this.goingold.next = true;
      this.findchosen();
    },
    showsecondpage: function () {
      this.CreatedAction.sichtbar = true;
    },
    showHelper: function () {
      if (this.CreatedAction.sichtbar) {
        this.CreatedAction.helper = !this.CreatedAction.helper;
      } else {
        ("");
      }
    },
    showInfo: function () {
  this.showInfos = !this.showInfos
    },
    showMethInfo: function () {
      // console.log("showmethinfo triggered")
        this.showMethInfos = !this.showMethInfos
    },
    URtheChosen: function (event) {
      let area = event.target.closest(".natureBoximage");
      area.classList.toggle("chosenone");
      this.UnChosenShrink();
    },
    UnChosenShrink: function () {
      let getClassOne = document.querySelector(
        ".natureBoximage:not(.chosenone)"
      );
      getClassOne.classList.toggle("notchosen");
      // console.log(getClassOne);
    },
    // GetTheGoals() {
    //   if (typeof _spPageContextInfo === "undefined") {
    //     this.$store.dispatch("loadEntwicklungsZiele");
    //   } else {
    //     let ZyklId = this.$parent.$props.zid;
    //     this.$store.dispatch("loadGoals", ZyklId);
    //   }
    // },
        GetTheActions() {
      if (typeof _spPageContextInfo === "undefined") {
        this.$store.dispatch("loadEntwicklungsZiele");
      } else {
        let ZyklId = this.$parent.$props.zid;
        this.$store.dispatch("loadZActions", ZyklId);
      }
    },
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
     ReallySubmitTheFormMN() {
       let measure =  {
         Id: this.$store.getters.nextEntMkiId,
         RefZ: this.zid,
         Title: this.CreatedAction.Bezeichnung,
         RefMeth: {
           Id: this.SelectedMethod.Id,
           Title: this.SelectedMethod.Title,
         },
         RefMethId: this.SelectedMethod.Id,
         RefMethZiel: this.SelectedMethod.Ziel,
         RefMethBeschr: this.SelectedMethod.Beschreibung,
         Zeitumfang: this.value.Zeitumfang[0] ? this.value.Zeitumfang[0].text: null,
         Sozialform: this.value.Sozialform[0] ? this.value.Sozialform[0].text: null,
         Voraussetzungen_x002f_Materialie: this.CreatedAction.Voraussetzung,
         Kurzbeschreibung: this.CreatedAction.Kurzbeschreibung,
         Evaluationskriterien_x0020__x002: this.CreatedAction.EKinhalt,
         Evaluationskriterien_x0020__x0020: this.CreatedAction.EKmethode,
         RefZie: null,
         EvalObj: null,
         Reuse: false
       }
       this.$store.dispatch("addEntMki", measure)

       // reset variables after submit
       this.CreatedAction.Bezeichnung = null
       this.CreatedAction.Voraussetzung = null
       this.CreatedAction.Kurzbeschreibung = null
       this.CreatedAction.EKinhalt = null
       this.CreatedAction.EKmethode = null
       this.value.Sozialform = ""
       this.value.Zeitumfang = ""
       // go back to main view
       setTimeout(() => {
         this.backbutton()
       }, 500)




      //  let sozialform = null;
      //   let zeitumfang = null;
      //   if (this.value.Sozialform[0])
      //     sozialform = this.value.Sozialform[0].text;
      //   if (this.value.Zeitumfang[0])
      //     zeitumfang = this.value.Zeitumfang[0].text;
      // if (typeof _spPageContextInfo === "undefined") {
      //   let maszId = 1
      //     if(this.maszLength) {
      //       maszId = this.maszLength+1
      //     }

    //    this.$store.commit("createNewAction", {
    //
    //       Id: maszId,
    //       ID: maszId,
    //       Author: {
    //       "__metadata": {
    //           "id": "c6be87eb-b3e2-4521-98f7-9f26b8beacd1",
    //           "type": "SP.Data.UserInfoItem"
    //       },
    //       "Id": 120
    //   },
    //   RefMeth: {
    //       "__metadata": {
    //           "id": "ffce86e5-ee00-4993-949a-437332b73ec4",
    //           "type": "SP.Data.MKListItem"
    //       },
    //       "Id": this.SelectedMethod.Id,
    //       "Title": this.SelectedMethod.Title,
    //   },
    //   RefZ: {
    //       "__metadata": {
    //           "id": "9606f086-b64f-4fdf-9c60-7f470a93278d",
    //           "type": "SP.Data.ZyknListItem"
    //       },
    //       "Id": this.zid
    //   },
    //   RefZie: {
    //       "results": [
    //           {
    //               "__metadata": {
    //                   "id": "9910a5f0-ec01-4a2f-bdce-431b430f4548",
    //                   "type": "SP.Data.EntZieleListItem"
    //               },
    //               "Id": 12
    //           }
    //       ]
    //   },
    //   Title: this.CreatedAction.Bezeichnung,
    //   RefMethZiel: this.SelectedMethod.Ziel,
    //   RefMethBeschr: this.SelectedMethod.Beschreibung,
    //   Zeitumfang: zeitumfang,
    //   Sozialform: sozialform,
    //   Voraussetzungen_x002f_Materialie: this.CreatedAction.Voraussetzung,
    //   Kurzbeschreibung: this.CreatedAction.Kurzbeschreibung,
    //   Evaluationskriterien_x0020__x002: this.CreatedAction.EKinhalt,
    //   Evaluationskriterien_x0020__x0020: this.CreatedAction.EKmethode
    //
    // });
    //    this.$root.$emit("updateZyklusPdf")

      // } else {
      //   console.log("New Masz triggered");
      //   // send masz to server
      //   let url = "/_api/web/lists/getbytitle('MKi')/items";
      //   let maszindi = {
      //     __metadata: { type: "SP.Data.MKiListItem" },
      //     Title: this.CreatedAction.Bezeichnung,
      // RefMethZiel: this.SelectedMethod.Ziel,
      // RefMethBeschr: this.SelectedMethod.Beschreibung,
      // Zeitumfang: zeitumfang,
      // Sozialform: sozialform,
      // Voraussetzungen_x002f_Materialie: this.CreatedAction.Voraussetzung,
      // Kurzbeschreibung: this.CreatedAction.Kurzbeschreibung,
      // Evaluationskriterien_x0020__x002: this.CreatedAction.EKinhalt,
      // Evaluationskriterien_x0020__x0020: this.CreatedAction.EKmethode,
      // RefMethId: this.SelectedMethod.Id,
      // RefZId: this.zid,
      //
      //   };
      //   let maszi = JSON.stringify(maszindi);
      //   Ex.post(url, maszi).then(() => {
      //       // update maßnahmen
      //
      //       //update pdf
      //       this.$root.$emit("updateZyklusPdf")}).catch(() => {
      //     console.log("error in feedback form submit");
      //
      //   });
      //   setTimeout(
      //     function () {
      //       this.GetTheActions();
      //     }.bind(this),
      //     1000
      //   );
      // }

      // // reset variables after submit
      // this.CreatedAction.Bezeichnung = null;
      // this.CreatedAction.Voraussetzung = null;
      // this.CreatedAction.Kurzbeschreibung = null;
      // this.CreatedAction.EKinhalt = null;
      // this.CreatedAction.EKmethode = null;
      // this.value.Sozialform = "";
      // this.value.Zeitumfang = "";


    },
    ReallySubmitTheFormOMN() {
      let measure =  {
        Id: this.$store.getters.nextEntMkiId,
        RefZ: this.zid,
        Title: this.UsedMethod.Title,
        RefMeth: {
          Id: this.UsedMethod.RefMeth.Id,
          Title: this.UsedMethod.RefMeth.Title,
        },
        RefMethId: this.UsedMethod.RefMeth.Id,
        RefMethZiel: this.UsedMethod.RefMethZiel,
        RefMethBeschr: this.UsedMethod.RefMethBeschr,
        Zeitumfang: this.CZeit[0] ? this.CZeit[0].text: null,
        Sozialform: this.CSozial[0] ? this.CSozial[0].text: null,
        Voraussetzungen_x002f_Materialie: this.UsedMethod.Voraussetzungen_x002f_Materialie,
        Kurzbeschreibung: this.UsedMethod.Kurzbeschreibung,
        Evaluationskriterien_x0020__x002: this.UsedMethod.Evaluationskriterien_x0020__x002,
        Evaluationskriterien_x0020__x0020: this.UsedMethod.Evaluationskriterien_x0020__x0020,
        RefZie: null,
        EvalObj: null,
        Reuse: false
      }
      this.$store.dispatch("addEntMki", measure)

      // reset variables after submit
      // this.UsedMethod.Title = null
      // this.UsedMethod.Voraussetzungen_x002f_Materialie = null
      // this.UsedMethod.Kurzbeschreibung = null
      // this.UsedMethod.Evaluationskriterien_x0020__x002 = null
      // this.UsedMethod.Evaluationskriterien_x0020__x0020 = null
      // this.CZeit[0] = ""
      // this.CSozial[0] = ""
      // go back to main view
      setTimeout(() => {
        this.backbutton()
      }, 500)


    //    let sozialform = null;
    //     let zeitumfang = null;
    //     if (this.CSozial[0].text)
    //       sozialform = this.CSozial[0].text;
    //     if (this.CZeit[0].text)
    //       zeitumfang = this.CZeit[0].text
    //   if (typeof _spPageContextInfo === "undefined") {
    //     let maszId = 1
    //       if(this.maszLength) {
    //         maszId = this.maszLength+1
    //       }
    //
    //    this.$store.commit("createNewAction", {
    //
    //       Id: maszId,
    //       ID: maszId,
    //       Author: {
    //       "__metadata": {
    //           "id": "c6be87eb-b3e2-4521-98f7-9f26b8beacd1",
    //           "type": "SP.Data.UserInfoItem"
    //       },
    //       "Id": 120
    //   },
    //   RefMeth: {
    //       "__metadata": {
    //           "id": "ffce86e5-ee00-4993-949a-437332b73ec4",
    //           "type": "SP.Data.MKListItem"
    //       },
    //       "Id": this.UsedMethod.RefMeth.Id,
    //       "Title": this.UsedMethod.RefMeth.Title,
    //   },
    //   RefZ: {
    //       "__metadata": {
    //           "id": "9606f086-b64f-4fdf-9c60-7f470a93278d",
    //           "type": "SP.Data.ZyknListItem"
    //       },
    //       "Id": this.zid
    //   },
    //   RefZie: {
    //       "results": [
    //           {
    //               "__metadata": {
    //                   "id": "9910a5f0-ec01-4a2f-bdce-431b430f4548",
    //                   "type": "SP.Data.EntZieleListItem"
    //               },
    //               "Id": 12
    //           }
    //       ]
    //   },
    //   Title: this.UsedMethod.Title,
    //   RefMethZiel: this.UsedMethod.RefMethZiel,
    //   RefMethBeschr: this.UsedMethod.RefMethBeschr,
    //   Zeitumfang: zeitumfang,
    //   Sozialform: sozialform,
    //   Voraussetzungen_x002f_Materialie: this.UsedMethod.Voraussetzungen_x002f_Materialie,
    //   Kurzbeschreibung: this.UsedMethod.Kurzbeschreibung,
    //   Evaluationskriterien_x0020__x002: this.UsedMethod.Evaluationskriterien_x0020__x002,
    //   Evaluationskriterien_x0020__x0020: this.UsedMethod.Evaluationskriterien_x0020__x0020
    //
    // });
    //    this.$root.$emit("updateZyklusPdf")
    //
    //   } else {
      //   console.log("Old Masz triggered");
      //   // send masz to server
      //   let url = "/_api/web/lists/getbytitle('MKi')/items";
      //   let maszindi = {
      //     __metadata: { type: "SP.Data.MKiListItem" },
      //     Title: this.UsedMethod.Title,
      // RefMethZiel: this.UsedMethod.RefMethZiel,
      // RefMethBeschr: this.UsedMethod.RefMethBeschr,
      // Zeitumfang: this.CZeit[0].text,
      // Sozialform: this.CSozial[0].text,
      // Voraussetzungen_x002f_Materialie: this.UsedMethod.Voraussetzungen_x002f_Materialie,
      // Kurzbeschreibung: this.UsedMethod.Kurzbeschreibung,
      // Evaluationskriterien_x0020__x002: this.UsedMethod.Evaluationskriterien_x0020__x002,
      // Evaluationskriterien_x0020__x0020: this.UsedMethod.Evaluationskriterien_x0020__x0020,
      // RefMethId: this.UsedMethod.RefMeth.Id,
      // RefZId: this.zid,
      //
      //   };
      //   let maszi = JSON.stringify(maszindi);
      //   Ex.post(url, maszi).then(() => {
      //       // update maßnahmen
      //
      //       //update pdf
      //       this.$root.$emit("updateZyklusPdf")}).catch(() => {
      //     console.log("error in feedback form submit");
      //
      //   });
      //   setTimeout(
      //     function () {
      //       this.GetTheActions();
      //     }.bind(this),
      //     1000
      //   );
      // }

      // // reset variables after submit
      // this.UsedMethod.Title = null;
      // this.UsedMethod.Voraussetzungen_x002f_Materialie = null;
      // this.UsedMethod.Kurzbeschreibung = null;
      // this.UsedMethod.Evaluationskriterien_x0020__x002 = null;
      // this.UsedMethod.Evaluationskriterien_x0020__x0020 = null;
      // this.CZeit[0] = "";
      // this.CSozial[0] = "";


    },
    // MNsSave() {
    //   if (typeof _spPageContextInfo !== "undefined") {
    //     // prevent to create more than one item
    //     if (this.saveButtonIsClicked) return;
    //     this.saveButtonIsClicked = true;
    //
    //     // get selection data
    //     let sozialform = null;
    //     let zeitumfang = null;
    //     if (this.value.Sozialform[0])
    //       sozialform = this.value.Sozialform[0].text;
    //     if (this.value.Zeitumfang[0])
    //       zeitumfang = this.value.Zeitumfang[0].text;
    //
    //     // create data array
    //     let url = "/_api/web/lists/getbytitle('MKi')/items";
    //     let methodData = {
    //       __metadata: { type: "SP.Data.MKiListItem" },
    //       Title: this.CreatedAction.Bezeichnung,
    //       RefMethId: this.SelectedMethod.Id,
    //       Zeitumfang: zeitumfang,
    //       Sozialform: sozialform,
    //       Voraussetzungen_x002f_Materialie: this.CreatedAction.Voraussetzung,
    //       Kurzbeschreibung: this.CreatedAction.Kurzbeschreibung,
    //       Evaluationskriterien_x0020__x002: this.CreatedAction.EKinhalt,
    //       Evaluationskriterien_x0020__x0020: this.CreatedAction.EKmethode,
    //       RefZId: this.zid,
    //     };
    //     Ex.post(url, methodData)
    //       .then(() => {
    //         // update maßnahmen
    //         this.$store.dispatch("loadZActions", this.zid);
    //         //update pdf
    //         this.$root.$emit("updateZyklusPdf");
    //
    //         this.resetValues();
    //         this.backbutton();
    //       })
    //       .catch((err) => {
    //         this.saveButtonIsClicked = false;
    //         console.log("Error while saving method: " + err);
    //       });
    //   } else {
    //     this.resetValues();
    //     this.backbutton();
    //   }
    // },
    resetValues() {
      (this.MaszCardStructure = {
        heading: true,
        method: false,
        newmethod: false,
      }),
        (this.SelectedMethod = {
          Title: "",
          Ziel: "",
          Beschreibung: "",
          Id: "",
          weiter: false,
        }),
        (this.value = {
          Sozialform: "",
          Zeitumfang: "",
        }),
        (this.CreatedAction = {
          Bezeichnung: "",
          Kurzbeschreibung: "",
          Voraussetzung: "",
          EKinhalt: "",
          EKmethode: "",
          sichtbar: false,
          helper: false,
          speicherbar: false,
        });
      this.saveButtonIsClicked = false;
    },
  },

  name: "CardForm",
  components: {
    GeneralCardActionButtons,
    GeneralCardContent,
    GeneralCardHeader,
  },
};
</script>
<style scoped>
/* .rueckseite .innenseite .CardActionButtonContainer {
  top: 70%;
} */

.anlegentext {
  font-size: calc(18px + (24 - 18) * ((100vh - 300px) / (1900 - 300)));
  color: rgb(0, 120, 212);
}
.bereichanlegen {
  top: 50%;
}
.anlegenplus {
  font-size: calc(40vh - 10vw);
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  padding: 50px;
  color: rgb(0, 120, 212);
  cursor: pointer;
  z-index: 10;
}
.FormFront {
  min-height: 30vh;
  max-height: 45vh;
}
.CardContentBlock {
  max-height: calc((100vh / 3) - 5vh);
  margin-bottom: 0px;
}

.CardContentContainer {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-perspective: 1000px;

  perspective: 1000px;
  margin: 1.5%;
}

.formvorderseite,
.formrueckseite {
  background-size: cover;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  background-position: center;
  -webkit-transition: -webkit-transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
  transition: -webkit-transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
  -o-transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
  transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
  transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1),
    -webkit-transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  text-align: center;
   text-align: -webkit-center;
   text-align: -moz-center;
  min-height: 45vh;
  height: auto;
  border-radius: 10px;
  color: rgb(44 62 80);
}

.formvorderseite {
  background: #cedce7;
  background-image: linear-gradient(to top, #dfe9f3 0%, white 100%);
}

.formrueckseite:after {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  content: "";
  display: block;
  opacity: 0.6;
  background-color: #000;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 10px;
}
.CardContentContainer.DarkSideoftheMoon .formvorderseite,
.CardContentContainer.DarkSideoftheMoon .formrueckseite {
  -webkit-transition: -webkit-transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
  transition: -webkit-transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
  -o-transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
  transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
  transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1),
    -webkit-transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
}

.formvorderseite {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.forminnenseite {
  /* -webkit-transform: translateY(-50%) translateZ(60px) scale(0.94);
  transform: translateY(-50%) translateZ(60px) scale(0.94);
  top: 50%;
  position: absolute;
  left: 0; */
  width: 100%;
  padding: 2rem;
  padding-right: 0rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  outline: 1px solid transparent;
  -webkit-perspective: inherit;
  perspective: inherit;
  z-index: 2;
  height: 100%;
  text-align: left;
   text-align: -webkit-left;
   text-align: -moz-left;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

.CardContentContainer .formvorderseite {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.CardContentContainer .formrueckseite {
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.CardContentContainer.DarkSideoftheMoon .formvorderseite {
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.CardContentContainer.DarkSideoftheMoon .formrueckseite {
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
.ContentEditField {
  width: 93%;
  height: 75px;
  border: 1px solid;
  border-radius: 5px;
}
.HeaderEditTitle {
      border: 1px solid;
    border-radius: 5px;
}
.BereichFromInnen {
  padding: 0rem;
  position: absolute;
}
.BereichDotted {
  border: dashed 5px;
  border-color: rgb(0, 120, 212);
  border-radius: 20px;
  height: calc(100% - 10px);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
   text-align: -webkit-center;
   text-align: -moz-center;
  text-align: -moz-center;
}
/*.DottedBackground {
  /* background-image: radial-gradient(
    rgb(192 228 255 / 30%),
    rgb(0 120 212 / 35%)
  ); */
/*}*/
.BereichFront {
  min-height: 100px;
  position: relative;
  display: flex;
}
.CardFormContainer {
  width: calc((100% - 1vw));
}
.CardFormAppBar {
  display: flex;
  box-shadow: 4px 10px 14px 0px #000000;
}
.natureBoxleft {
  background-color: rgba(255, 227, 126, 0.7);
}
.LeftiContainer {
  display: flex;
  height: 100%;
}
.natureBoxright {
  background-color: rgba(108, 180, 210, 0.7);
}
.natureBox {
  display: flex;
  width: 100%;
  max-height: 75vh;
}
.natureBoxleft::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  background-position: center;
  filter: opacity(0.4);
  z-index: -1;
}
.natureBoxright::before {
  content: "";
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  background-position: center;
  z-index: -1;
  filter: opacity(0.4);
}
.natureBoximage {
  position: relative;
  min-height: 70vh;
  width: 50%;
  transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 1;
}
.natureBoximagestart:hover {
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
}

.chosenone {
  width: 100%;
}
.notchosen {
  width: 0%;
}
.callToActionHeading {
  font-size: calc(
    36px + (62 - 36) * ((100vh - 300px) / (1900 - 300))
  ) !important;
  line-height: calc(1.3em + (1.5 - 1.2) * ((100vh - 300px) / (1600 - 300)));
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  position: absolute;
  width: 50%;
  text-align: start;
   text-align: -webkit-start;
   text-align: -moz-start;
  font-weight: 700;
  color: rgb(36 50 64);
  cursor: pointer;
}
.ActionHeadingBox {
  height: 100%;
  width: 100%;
  text-align: center;
  text-align: -moz-center;
  position: relative;
}
.CardFormAppBar {
  width: 100%;
  max-height: 48px;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 6px;
  padding-right: 12px;
  transform-origin: top left;
  transition: transform 0.3s ease-in;
  transform: rotateX(90deg) translateY(-100px);
  background: white;
}
.ABCU {
  transform: none;
}
.CardFormAppBar.ABCU::after {
  position: absolute;
  height: 15px;
  width: 100%;
  /* box-shadow: 2px -5px 20px 0px #00000066; */
  -webkit-box-shadow: 0 8px 6px -6px black;
  -moz-box-shadow: 0 8px 6px -6px black;
  box-shadow: 0 8px 6px -6px black;
  z-index: 3;
  content: "";
  top: 38px;
  left: 0;
}
.AppBarReturnZone {
  width: 100px;
  padding-right: 48px;
  cursor: pointer;
  display: inline-flex;
}
.TitleZone {
  width: calc(100% - 100px - 48px);
  text-align: left;
   text-align: -webkit-left;
   text-align: -moz-left;
}
.FormTeaser {
  font-size: calc(
    26px + (32 - 26) * ((100vh - 300px) / (1900 - 300))
  ) !important;
  line-height: calc(1.3em + (1.5 - 1.2) * ((100vh - 300px) / (1600 - 300)));
  margin: 40px;
  margin-top: 0px;
  text-align: center;
   text-align: -webkit-center;
   text-align: -moz-center;
  transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1),
    margin 300ms cubic-bezier(0.4, 0, 0.2, 1),
    transform 300ms cubic-bezier(0.4, 0, 0.2, 1);
}
.FormTeasernot {
  font-size: calc(
    26px + (32 - 26) * ((100vh - 300px) / (1900 - 300))
  ) !important;
  line-height: calc(1.3em + (1.5 - 1.2) * ((100vh - 300px) / (1600 - 300)));
  margin: 0px;
  height: 0px;

  transform: translate(0px, -200px);
  transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1),
    margin 300ms cubic-bezier(0.4, 0, 0.2, 1),
    transform 300ms cubic-bezier(0.4, 0, 0.2, 1);
}
.LeftiSecond,
.LeftiFirst {
  width: 100%;
  height: 100%;
  display: flex;
}
.LeftiSecond {
  opacity: 0;
}
.LeftiSecondactive {
  animation: arriver;
  animation-duration: 300ms;
  animation-iteration-count: 1;
  animation-fill-mode: both;
  animation-delay: 500ms;
}
@keyframes arriver {
  0% {
    opacity: 0%;
  }
  100% {
    opacity: 1;
  }
}
.LeftiSecondIntro,
.LeftiFirstIntro {
  width: calc(100% / 3);
  height: 100%;
  display: grid;
  background: rgb(255 227 126 / 45%);
  overflow-y: auto;
  overflow-x: hidden;
}
.LeftiSecondtIntroContainer,
.LeftiFirstIntroContainer {
  padding: 28px;
  display: grid;
}
.RightiFirstIntro {
  height: auto;
  width: calc(100% - 56px);
  display: block;
  padding: 28px;
}
.rightitexti {
  display: block;
}
.IntroHeading {
  font-size: calc(
    36px + (52 - 36) * ((100vh - 300px) / (1900 - 300))
  ) !important;
  line-height: calc(1.3em + (1.5 - 1.2) * ((100vh - 300px) / (1600 - 300)));
  /* TODO: Check if this will have no side effect */
  /* width: 100%; */
  text-align: left;
   text-align: -webkit-left;
   text-align: -moz-left;
  font-weight: 700;
  color: rgb(36 50 64);
  font-family: "aileronheavy", sans-serif;
}
.DescriptionHeading {
  font-size: calc(
    20px + (34 - 20) * ((100vh - 300px) / (1900 - 300))
  ) !important;
  line-height: calc(1.3em + (1.5 - 1.2) * ((100vh - 300px) / (1600 - 300)));
  width: 100%;
  text-align: start !important;
  font-weight: 700;
  color: rgb(36 50 64);
  font-family: "aileronheavy", sans-serif;
  margin-bottom: 12px;
  display: block;
}
.DescriptionSubHeading {
  font-size: calc(
    16px + (22 - 16) * ((100vh - 300px) / (1900 - 300))
  ) !important;
  line-height: calc(1.3em + (1.5 - 1.2) * ((100vh - 300px) / (1600 - 300)));
  width: 100%;
  text-align: start !important;
  font-weight: 700;
  color: rgb(36 50 64);
  font-family: "aileronheavy", sans-serif;
  display: block;
}
.MethSub {
  font-family: "aileronheavy", sans-serif, sans-serif;
}
.IntroDescription {
  text-align: justify;
   text-align: -webkit-justify;
   text-align: -moz-justify;
  color: rgb(36 50 64);
  font-family: "aileronregular", sans-serif;
}
.explainer {
  margin: 24px;
  padding-bottom: 44px;
}
.LeftiDataCombo {
  width: calc((100% / 3) * 2);
  height: 100%;
  display: flex;
}

.RightiContainer {
  height: 100%;
  position: absolute;
  overflow: hidden;
  width: 100%;
}
.RightiSecond {
  position: relative;
  height: 100%;
}
.RightiDataCombo {
  height: 100%;
  display: flex;
  width: 100%;
}
.RightiChangeCombo {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  z-index: 99;
}
.righti {
  width: calc((100% / 3) * 2) !important;
}
.RightiFirstExplaination {
  width: calc(100% / 3);
  padding: 28px;
  background: rgb(255 255 255 / 73%);
  height: 100%;
  text-align: justify;
   text-align: -webkit-justify;
   text-align: -moz-justify;
  overflow-y: auto;
}
.hiddenRighti {
  opacity: 0;
}
.RightiForm {
  background: rgb(255 255 255 / 80%);
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  text-align: left;
   text-align: -webkit-left;
   text-align: -moz-left;
  width: calc((100% / 3) * 2);
}
.LeftiForm {
  width: calc((100% / 3) * 2);
  /* background: rgb(255 255 255 / 80%); */
  overflow-y: auto;
  overflow-x: hidden;
  background: linear-gradient(180deg, rgb(255 255 255 / 80%) 88%, rgb(255 255 255 / 92%) 12%)
}
.LeftiFirstSelection {
  background-color: rgb(255 255 255 / 80%);
  width: calc((100% / 3) * 2);
  height: 100%;
  overflow-y: auto;
}
.LeftiFirstExplaination {
  overflow-y: auto;
  padding: 0 28px 0 28px;
  background: rgb(255 255 255 / 73%);
  text-align: left;
   text-align: -webkit-left;
   text-align: -moz-left;
}
.goneexplainer {
  opacity: 0;
}
/* .RightiForm::-webkit-scrollbar,
.RightiFirstExplaination::-webkit-scrollbar,
.RightiFirst::-webkit-scrollbar,
.LeftiForm::-webkit-scrollbar,
.LeftiFirstExplaination::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}
.RightiForm::-webkit-scrollbar-thumb,
.RightiFirstExplaination::-webkit-scrollbar-thumb,
.RightiFirst::-webkit-scrollbar-thumb,
.LeftiForm::-webkit-scrollbar-thumb,
.LeftiFirstExplaination::-webkit-scrollbar-thumb {
  border-radius: 0px;
  background-image: linear-gradient(
    to bottom,
    #1e3c7217 0%,
    #1e3c7280 1%,
    #2a5298d9 100%
  );
} */
.LeftiFirstExplaination {
  width: calc(100% / 3);
  height: 100%;
}
.methpill {
  min-height: 60px;
  border: 2px solid;
  max-width: 300px;
  min-width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 4px;
  cursor: pointer;
}
.highlight {
  background: #ffe37e;
}
.highlightold {
  background: rgb(133 199 147);
}
.methpill:hover {
  background-color: rgba(255, 227, 126, 0.4);
}
.oldpill:hover {
  background-color: rgba(133, 199, 147, 0.4);
}
.methpilltext {
  font-family: "aileronregular", sans-serif;
}
.methpilltextdescription {
  font-size: 80%;
}
.pilltextcontainer {
  max-width: 300px;
  display: grid;
  padding-left: 12px;
  padding-right: 12px;
}
.formactionbuttonerstellen::after,
.formactionbuttonweiter::after {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  border: 1px solid rgb(0 0 0 / 50%);
}
.formactionbuttonerstellen:hover::before,
.formactionbuttonweiter:hover::before {
  opacity: 1;
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
}
.formactionbuttonerstellen:hover > .bt {
  color: black;
}
.formactionbuttonweiter:hover > .bt {
  color: white;
}
.formactionbuttonweiter::before {
  opacity: 0;
  background: rgb(0 120 212 / 90%);
  -webkit-transform: scale(0.1, 0.1);
  transform: scale(0.1, 0.1);
  z-index: -1;
}
.formactionbuttonerstellen {
  width: calc(
    250px + (300 - 250) * ((100vw - 300px) / (1900 - 300))
  ) !important;
}
.formactionbuttonerstellen::before {
  opacity: 0;
  background: rgb(0 126 28 / 90%);
  -webkit-transform: scale(0.1, 0.1);
  transform: scale(0.1, 0.1);
  z-index: -1;
}
.pillcontainer {
  /* width: calc(100% - 24px);
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 10px;
    grid-template-columns: 1fr 1fr max-content;
    grid-auto-rows: minmax(60px, auto);
    margin: 12px; */
  margin: 12px;
  display: flex;
  flex-flow: wrap;
  opacity: 1;
}
.pillinvisible {
  opacity: 0;
  display: none;
}
.metheadline {
  writing-mode: vertical-lr;
  transform: rotate(-180deg);
  font-size: calc(
    16px + (22 - 16) * ((100vh - 300px) / (1900 - 300))
  ) !important;
  cursor: pointer;
}
.LeftiFormField {
  border: 1px solid;
  border-radius: 5px;
  font-family: "aileronsemibold", sans-serif;
  font-size: 16px;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  width: 100%;
  min-height: 31px;
  font-size: calc(
    16px + (22 - 16) * ((100vh - 300px) / (1900 - 300))
  ) !important;
      resize: vertical;
}
.explainerhelper {
  position: absolute;
  z-index: 3;
  width: calc(100% / 3) !important;
  transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1);
}
.closehelper {
  position: absolute;
  top: 14px;
  right: 14px;
  z-index: 9;
  cursor: pointer;
}
.explainerhelper::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  z-index: -1;
  top: 0;
  left: 0;
}

@supports not ((-webkit-backdrop-filter: 10px) or (backdrop-filter: blur(10px))) {
  .RightiFootShown,
  .explainerhelper::after {
    /* filter: url(#blur); */
    opacity: 0.9;
      background: rgba(255, 255, 255, .95);
  }
}
.LeftiFormSelect {
  font-size: calc(
    16px + (22 - 16) * ((100vh - 300px) / (1900 - 300))
  ) !important;
  width: 100%;
  min-height: 31px;
  border: 1px solid;
  border-radius: 5px;
  font-family: "aileronsemibold", sans-serif;
}
.LeftiFormFieldWrap {
  display: flex;
  width: 95%;
  flex-wrap: wrap;
  padding: 28px;
  padding-bottom: 0px;
}
.laengevier {
  height: 189px;
  width: 100%;
}
.laengezwei {
  height: 85px;
  width: 100%;
}
.slide-up-fade-in2 {
  animation-delay: 10.1s;
  animation: slide-up-fade-in ease;
  animation-duration: 1.7s;
  animation-iteration-count: 1;
  transform-origin: 50% 50%;
  animation-fill-mode: forwards; /*when the spec is finished*/

  opacity: 0;
  opacity: 1\9;
}
.slide-up-fade-in {
  animation: slide-up-fade-in ease 1.2s;
  animation-iteration-count: 1;
  transform-origin: 50% 50%;
  animation-fill-mode: forwards; /*when the spec is finished*/
  -webkit-animation: slide-up-fade-in ease 1.2s;
  -webkit-animation-iteration-count: 1;
  -webkit-transform-origin: 50% 50%;
  -webkit-animation-fill-mode: forwards; /*Chrome 16+, Safari 4+*/
  -moz-animation: slide-up-fade-in ease 1.2s;
  -moz-animation-iteration-count: 1;
  -moz-transform-origin: 50% 50%;
  -moz-animation-fill-mode: forwards; /*FF 5+*/
  -o-animation: slide-up-fade-in ease 1.2s;
  -o-animation-iteration-count: 1;
  -o-transform-origin: 50% 50%;
  -o-animation-fill-mode: forwards; /*Not implemented yet*/
  -ms-animation: slide-up-fade-in ease 1.2s;
  -ms-animation-iteration-count: 1;
  -ms-transform-origin: 50% 50%;
  -ms-animation-fill-mode: forwards; /*IE 10+*/

  opacity: 0;
  opacity: 1\9;
}

@keyframes slide-up-fade-in {
  0% {
    opacity: 0;
    transform: translate(0px, 40px);
  }
  100% {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}

@-moz-keyframes slide-up-fade-in {
  0% {
    opacity: 0;
    -moz-transform: translate(0px, 40px);
  }
  100% {
    opacity: 1;
    -moz-transform: translate(0px, 0px);
  }
}

@-webkit-keyframes slide-up-fade-in {
  0% {
    opacity: 0;
    -webkit-transform: translate(0px, 40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(0px, 0px);
  }
}

@-o-keyframes slide-up-fade-in {
  0% {
    opacity: 0;
    -o-transform: translate(0px, 40px);
  }
  100% {
    opacity: 1;
    -o-transform: translate(0px, 0px);
  }
}

@-ms-keyframes slide-up-fade-in {
  0% {
    opacity: 0;
    -ms-transform: translate(0px, 40px);
  }
  100% {
    opacity: 1;
    -ms-transform: translate(0px, 0px);
  }
}
.vd1 {
  /* animation-name: verduenne;
  animation-duration: 1600ms;
  animation-iteration-count: 1;
  animation-fill-mode: both; */
  width: 0;
  height: 0;
  opacity: 0;
  -webkit-transition: opacity 0.5s, width 0.1s 0.5s, height 0.1s 0.5s;
  transition: opacity 0.5s, width 0.1s 0.5s, height 0.1s 0.5s;
}
.vd4 {
  width: 0;
  height: 0;
  opacity: 0;
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s;
}
.vdt {
  width: 0;
  height: 0;
  opacity: 0 !important;
  -webkit-transition: opacity 0.1s, width 0.1s 0.5s, height 0.1s 0.5s;
  transition: opacity 0.1s, width 0.1s 0.5s, height 0.1s 0.5s;
}
.vd2 {
  /* animation-name: verduenne;
  animation-duration: 1600ms;
  animation-iteration-count: 1;
  animation-fill-mode: both; */
  width: 0;
  height: 0;
  opacity: 0;
  -webkit-transition: opacity 0.5s, width 0.1s 0.5s, height 0.1s 0.5s;
  transition: opacity 0.5s, width 0.1s 0.5s, height 0.1s 0.5s;
  /* animation-delay: 500ms; */
}
.vd3 {
  width: 0px;
  transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1);
  background: rgb(255 255 255 / 73%);
  box-shadow: 4px 10px 14px 0px #000000;
}
.firstscreen {
  width: 56px;
}
.MHeadL {
  width: 100%;
  text-align: left;
   text-align: -webkit-left;
   text-align: -moz-left;
}
.grid-container {
  display: grid;
  padding: 0 28px 0 28px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 0.5fr 1.6fr 0.2fr 1.7fr 1fr;
  gap: 8px 10px;
  grid-template-areas:
    "Bezeichnung Sozialform"
    "Kurzbeschreibung Zeitumfang"
    "Kurzbeschreibung Voraussetzungen"
    "Divider Divider"
    "EKinhaltlich EKmethodisch"
    "ButtonS ButtonS";
    height: 88%;
    overflow-x: hidden;
    overflow-y: auto;
}
.Bezeichnung {
  grid-area: Bezeichnung;
}
.Sozialform {
  grid-area: Sozialform;
}
.Zeitumfang {
  grid-area: Zeitumfang;
}
.Voraussetzungen {
  grid-area: Voraussetzungen;
}
.Kurzbeschreibung {
  grid-area: Kurzbeschreibung;
}
.EKinhaltlich {
  grid-area: EKinhaltlich;
   margin-bottom: 48px
}
.EKmethodisch {
  grid-area: EKmethodisch;
  margin-bottom: 48px

}
.Divider {
  grid-area: Divider;
  width: 100%;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
  height: 1px;
  position: relative;
  background: rgb(0 0 30 / 36%);
  border-radius: 3px;
  z-index: 1;
}
.ButtonS {
  grid-area: ButtonS;
  margin-bottom: 24px;
}
.RightiFirst {
  overflow-y: auto;
  height: 100%;
  position: absolute;
  width: inherit;
}
.RightiList {
  padding: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: wrap;
  flex-flow: wrap;
  background: rgb(255 255 255 / 45%);
  padding-bottom: 174px;
  display: flow-root;
  height: 100%;

}
.RightiListContainer {
  padding: 28px;
  width: calc(100% - 56px);
}
.RightiFootCollaps,
.RightiFoot {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  text-align: -webkit-center;
  text-align: -moz-center;
  max-height: 48px;
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 6px;
  padding-right: 12px;
  -webkit-transform-origin: bottom left;
  transform-origin: bottom left;
  -webkit-transition: -webkit-transform 0.3s ease-in;
  transition: -webkit-transform 0.3s ease-in;
  transition: transform 0.3s ease-in;
  transition: transform 0.3s ease-in, -webkit-transform 0.3s ease-in;
  -webkit-transform: rotateX(90deg) translateY(-100px);
  transform: rotateX(90deg) translateY(-100px);
  background: rgb(115 175 141 / 34%);
}
.RightiFootShown {
  -webkit-transform: none;
  transform: none;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}
@supports not  ((-webkit-backdrop-filter:10px) or (backdrop-filter: 10px) ){
  .RightiFootShown {
    background: rgb(115 175 141 / 94%);
  }
}
.ListFilterContainer {
  width: 90%;
  padding: 28px;
  display: inline-flex;
}
.Filterlabel {
  padding: 4px 28px 4px 28px;
  font-size: calc(
    16px + (22 - 16) * ((100vh - 300px) / (1900 - 300))
  ) !important;
  font-family: "aileronblack", sans-serif;
}
@keyframes verduenne {
  0% {
    opacity: 100%;
  }
  30% {
    /* opacity: 40%; */
  }
  50% {
    width: 90%;
    transform: translateX(-100px);
    overflow: hidden;
    /* opacity: 30%; */
  }
  100% {
    width: 0px;
    transform: translateX(-1000px);
    /* opacity: 0%; */
  }
}
</style>
<style scoped>
::v-deep .fv-light-DropDown p {
  font-size: calc(
    16px + (22 - 16) * ((100vh - 300px) / (1900 - 300))
  ) !important;
  min-height: 31px;
  font-family: "aileronsemibold", sans-serif;
}
:deep(div.list-head.fv-custom-head > span.col),
:deep(div.list-content.fv-custom-row > div > span.col),
:deep(.col) {
  max-width: 24% !important;
  display: flex !important;
}
:deep(div.list-head.fv-custom-head > span:nth-child(2)) {
  display: none !important;
}
:deep(.fv-light-DropDown input) {
  font-size: calc(
    16px + (22 - 16) * ((100vh - 300px) / (1900 - 300))
  ) !important;
  min-height: 31px;
  font-family: "aileronsemibold", sans-serif;
}
:deep(.fv-light-DetailsList p),
:deep(.list-content span p) {
  font-size: calc(
    16px + (22 - 16) * ((100vh - 300px) / (1900 - 300))
  ) !important;
  min-height: 31px;
  font-family: "aileronsemibold", sans-serif;
}
:deep(.fv-light-DetailsList .list-content span p) {
  font-size: calc(
    16px + (22 - 16) * ((100vh - 300px) / (1900 - 300))
  ) !important;
  min-height: 31px;
  font-family: "aileronregular", sans-serif;
}
:deep(.fv-light-DetailsList .col) {
  min-width: 25% !important;
  max-width: 25%;
}
.fv-light-DetailsList {
  width: 95%;
}
:deep(.fv-light-DetailsList) {
  height: auto;
}
:deep(.fv-custom-row) {
  background: rgba(243, 242, 241, 0.4);
  cursor: pointer;
}
:deep(.list-content) {
  background: rgba(243, 242, 241, 0) !important;
}
:deep(.list-content::-webkit-scrollbar) {
  width: 10px;
  background-color: #f5f5f5;
}
:deep(.list-content::-webkit-scrollbar-thumb) {
  border-radius: 0px;
  background-image: linear-gradient(
    to bottom,
    #1e3c7217 0%,
    #1e3c7280 1%,
    #2a5298d9 100%
  );
}
:deep(.fv-light-DetailsList .list-content .content-row .col *),
:deep(.fv-light-DetailsList .list-head .col .col-content p.default-title) {
  font-size: calc(
    16px + (22 - 16) * ((100vh - 300px) / (1900 - 300))
  ) !important;
  font-family: "aileronregular";
}
:deep(p.default-title) {
  font-size: calc(
    18px + (24 - 18) * ((100vh - 300px) / (1900 - 300))
  ) !important;
}
.EntwicklungsbereichCardFrame {
  max-height: 200px;
  width: 97%;
}
.mountainBox {
  min-height: 80vh;
}
.svgimg-goal {
  z-index: -1;
  position: absolute;
}
.bannerleft {
  width: 45%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
}
.LDCmobile {
    width: calc((100%  - 50px ));
    margin-left: 50px;
  height: 100%;
  display: flex;

}
.LeftiFICmobile,
.bannerleftmobile {
      width: 0px;
    -webkit-transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1);
    transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1);
    height: 100%;
    text-align-last: right;

}
.LeftiFICmobileInfo,
.bannerleftinfo {
  position: absolute;
    width: 60%;
    z-index: 99;
    /* background: #ffffff70; */
    height: 100%;
        backdrop-filter: blur(15px);
        /* -webkit-backdrop-filter: blur(15px); */
        cursor: pointer

}
@supports not ((-webkit-backdrop-filter: 15px) or (backdrop-filter: 15px) ){
  .bannerleftinfo  {
    background: rgba(255, 255, 255, .95);
  }
}
.LeftiFICmobileInfo * span{
margin: 28px
}
.LeftiFICmobile > .LeftiFirstIntroContainer  {
  height: 100%;
  box-sizing: border-box;

}
.LeftiFICmobile > .LeftiFirstIntroContainer ,
.bannerleftmobile > .LeftiFirstIntroContainer  {
    box-shadow: 4px 10px 14px 0px #000000;
    justify-content: center;
}
.LeftiFICmobile * span,
.bannerleftmobile * .IntroHeading {
-webkit-writing-mode: vertical-lr;
    -ms-writing-mode: tb-lr;
    writing-mode: vertical-lr;
    -webkit-transform: rotate(
-180deg
);
    transform: rotate(
-180deg
);
    font-size: calc( 16px + (22 - 16) * ((100vh - 300px) / (1900 - 300)) ) !important;
    cursor: pointer;
}
.LeftiFICmobile * .IntroDescription,
.bannerleftmobile * .IntroDescription {
display: none
}
.bannerright {
  width: 55%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}
.bannerrightmobile {
  width: 95%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}
.bannerContainer {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}
.svgimg-goal {
  position: absolute;
  bottom: 0;
}
.addgoal {
  font-size: calc(25vh - 10vh);
  position: absolute;
  top: 50%;
  right: 50%;
  -webkit-transform: translate(50%, -50%);
  transform: translate(50%, -50%) scale(1);
  padding: 50px;
  color: rgba(0, 120, 212, 0.4);
  cursor: pointer;
  transition: transform 200ms ease-in-out;
  transition-delay: 200ms;
}
.bannerlefthidden {
  transform: scale(0);
  transition: transform 300ms ease-in-out;
}
.bannerrightback {
  padding: 28px;
  height: calc(100% - 56px - 96px);
  background: rgba(0, 120, 212, 0.2);
  margin: 48px;
  border-radius: 20px;
  transform: scale(0);
  transition: transform 300ms ease-in-out;
  overflow-y: auto;
  max-width: 700px;
  backdrop-filter: blur(2px);
}
.bannerrightbackshown {
  transform: scale(1);
  transition: transform 300ms ease-in-out;
  transition-delay: 200ms;
}

.goalSuccess {
  font-size: calc(
    26px + (32 - 26) * ((100vh - 300px) / (1900 - 300))
  ) !important;
  font-family: "aileronblack", sans-serif;
  animation: success ease 2.2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards; /*when the spec is finished*/
  -webkit-animation: success ease 2.2s;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards; /*Chrome 16+, Safari 4+*/
  -moz-animation: success ease 2.2s;
  -moz-animation-iteration-count: 1;
  -moz-animation-fill-mode: forwards; /*FF 5+*/
  -o-animation: success ease 2.2s;
  -o-animation-iteration-count: 1;
  -o-animation-fill-mode: forwards; /*Not implemented yet*/
  -ms-animation: success ease 2.2s;
  -ms-animation-iteration-count: 1;
  -ms-animation-fill-mode: forwards; /*IE 10+*/
}
@keyframes success {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@media only screen and (min-width: 1370px) {
  .BereichFront {
    min-height: 200px;
  }
 .natureBoximage  {
   min-height: 50vh;
 }
 .natureBox {
   max-height: 55vh;
 }

  .mountainBox {
    min-height: 80vh;
  }
  .bannerleft {
    width: 40%;
    height: 100%;
  }
  .bannerright {
    width: 60%;
  }
  .addgoal {
    -webkit-transform: translate(50%, -71%);
    transform: translate(50%, -71%);
  }
  .bannerlefthidden {
    transform: scale(0);
    transition: transform 300ms ease-in-out;
  }
  .IntroHeading {
    text-align: left;
     text-align: -webkit-left;
   text-align: -moz-left;
  }
}

::v-deep .CardHeader.ZCHeader > .HeaderEditContainer > .HeaderLabel{
      font-family: 'aileronheavy'  !important;
    padding: 8px;
}


</style>
<style scoped>


.info-entwicklungsziel {
  color: red;
  width: 90%;
  margin-top: 10px;
  margin-left: 3px;
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 0px;
  position: sticky;
  bottom: 0px;
}

.intro-headline-container {
  display: flex;
}

</style>
