<template>
    <div>
        <div class="formContainer">
        <div class="formContainerlinks">
        <h2 style="margin-bottom: 24px">Zuordnung</h2>
        <span class="divider"></span>
        <div class="zuordnungscontainer">
          <ZuordnungDnD :zid="zid" :studentId="studentId" />
        </div>
        </div>
        </div>
    </div>
</template>
<script>
import ZuordnungDnD from "../Interactive/ZuordnungDnD"

export default {
    data (){
   return{

   }},
  props: ['zid', 'studentId'],
  name: 'Zuordnung',
  components: {  ZuordnungDnD },
}
</script>
<style scoped>
.divider {
    margin-top: 0px;
}
.formContainer {
    width: 100%;
}
.formContainerlinks {
    width: 100%;
}
.zuordnungscontainer {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;

}
</style>
